// Third party modal

body.back-from-tp {
  background: $white;

  .back-from-tp-container {
    padding: 30px;
  }

  .alert {
    .icon {
      margin-right: 5px;
    }
  }
}
