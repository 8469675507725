/*
Breadcrumb
*/

.badge {
  font-size: 13px;
  font-weight: $font-weight-normal;

  &.badge-light {
    color: $grey-darker;
    background-color: $grey-smoke;
  }
}
