/*
Button Async
*/

.btn {
  .icon-loader {
    height: 13px;
    width: 13px;
  }

  &.btn-async {
    .icon {
      height: 20px;
      width: 20px;
    }

    .icon-loader,
    .icon-check,
    .icon-cross {
      display: none;
    }

    &.busy,
    &.success,
    &.error {
      .icon-text {
        margin-left: 6px;
      }

      &.btn-sm,
      &.btn-xs {
        .icon-text {
          margin-left: 3px;
        }
      }

      &.btn-link {
        background-color: transparent;
        border-color: transparent;
        color: $primary;

        &:hover,
        &:focus,
        &:active {
          color: $primary-dark;
        }
      }
    }

    &.busy {
      .icon-loader {
        display: inline-block;
      }

      .icon:not(.icon-loader) {
        display: none;
      }
    }

    &.success {
      .icon-check {
        display: inline-block;
      }

      .icon:not(.icon-check) {
        display: none;
      }
    }

    &.error {
      .icon-cross {
        display: inline-block;
      }

      .icon:not(.icon-cross) {
        display: none;
      }
    }

    .icon-text {
      margin-left: 0;
    }

    &.btn-link .icon-loader,
    &.btn-default .icon-loader {
      fill: $primary;
    }

    &.btn-link {
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        .icon-text {
          text-decoration: underline;
        }
      }
    }

    &.btn-sm {
      .icon {
        height: 15px;
        width: 15px;
      }
    }

    &.btn-xs {
      .icon {
        height: 10px;
        width: 10px;
      }
    }

    &.btn-async-loading,
    &.btn-async-error,
    &.btn-async-success {
      .icon-text {
        margin-left: 6px;
      }

      &.btn-sm,
      &.btn-xs {
        .icon-text {
          margin-left: 3px;
        }
      }

      &.btn-link {
        background-color: transparent;
        border-color: transparent;
        color: $primary;

        &:hover,
        &:focus,
        &:active {
          color: $primary-dark;
        }
      }
    }

    &.btn-async-loading {
      .icon-loader {
        display: inline-block;
      }
    }

    &.btn-async-success {
      .icon-check {
        display: inline-block;
      }
    }

    &.btn-async-error {
      .icon-cross {
        display: inline-block;
      }
    }

    &.btn-async-success {
      background-color: $success;
      border-color: $success;
      color: $white;

      &.btn-link .icon {
        fill: $success;
      }
    }

    &.btn-async-error {
      background-color: $error;
      border-color: $error;
      color: $white;

      &.btn-link .icon {
        fill: $error;
      }
    }
  }
}
