/*
Tool Interactive
*/

.tooltip-interactive {
  opacity: 1;
  position: relative;
  vertical-align: top;
  width: 100%;

  &.tooltip-with-dot {
    .tooltip-inner {
      padding-left: 50px;
    }

    .dot {
      position: absolute;
      top: 15px;
      left: 15px;
    }
  }

  &.tooltip-white {
    .tooltip-title {
      color: $grey-dark;
    }
  }

  .tooltip-inner {
    padding: 15px 20% 15px 30px;
    text-align: left;
    max-width: none;
  }

  .tooltip-right-btn {
    border-radius: 0 $border-radius-base $border-radius-base 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    max-width: 20%;

    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    .tooltip-info-btn {
      display: inline-block;
      vertical-align: bottom;
      font-size: $font-size-small;
      margin: 0 -5px;
    }

    .icon {
      fill: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    &.btn-default .icon {
      fill: $primary;
    }
  }

  p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
