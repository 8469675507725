.container-fluid {
  &.max-width-fixed {
    max-width: 1140px;
  }
}

body.solutions,
body.enterprise,
.new-design {
  &:not(.transparent) {
    background-color: $grey-smoke;
  }

  .container-fluid {
    max-width: 1140px;
  }

  h1 {
    &.title {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 20px;

      @include breakpoint($screen-sm) {
        font-size: 44px;
      }

      @include breakpoint($screen-lg) {
        font-size: 54px;
      }
    }
  }

  h2 {
    &.testimonial {
      font-size: 26px;

      @include breakpoint($screen-sm) {
        font-size: 36px;
      }
    }

    &.title {
      font-size: 28px;
      font-weight: 600;

      @include breakpoint($screen-sm) {
        font-size: 40px;
      }

      @include breakpoint($screen-lg) {
        font-size: 50px;
      }

      &.large {
        font-size: 48px;
        margin-bottom: 20px;

        @include breakpoint($screen-sm) {
          font-size: 50px !important;
        }

        @include breakpoint($screen-md) {
          font-size: 84px !important;
        }
      }
    }

    &.subtitle {
      font-size: 36px;
      font-weight: 600;
    }
  }

  h3 {
    &.title {
      font-size: 24px;
      font-weight: 600;
    }

    &.subtitle {
      font-size: 22px;
      font-weight: 600;
    }
  }

  p {
    &.title {
      font-size: 16px;
      line-height: 1.5;
      margin: 0 0 1em;

      @include breakpoint($screen-sm) {
        font-size: 20px;
      }

      @include breakpoint($screen-sm) {
        font-size: 22px;
      }
    }

    &.subtitle {
      font-size: 18px;
    }
  }
}

.bg-cut {
  width: 100%;
  margin-bottom: -2px;
  display: none;

  @include breakpoint($screen-sm) {
    display: block;
  }
}

.background {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;

  &.background-primary {
    background-image: url('/assets/img/pages/enterprise/wave_wave-hero-mobile.svg');

    @include breakpoint ($screen-sm) {
      background-image: url('/assets/img/pages/solutions/wave_wave-hero-11.svg');
      // background-image: url('/assets/img/pages/tools/wave-cut.svg');
    }
  }

  &.background-primary-dark {
    background-image: url('/assets/img/pages/enterprise/wave_wave-hero-enterprise-1.svg');
  }

  &.background-white-grey {
    background: $white;

    @include breakpoint ($screen-sm) {
      background: linear-gradient(90deg, $white 0%, $white 50%, $primary-lighter-fresh 50%, $primary-lighter-fresh 100%);
    }
  }

  &.background-grey-white {
    background: $white;

    @include breakpoint ($screen-sm) {
      background: linear-gradient(270deg, $white 0%, $white 50%, $primary-lighter-fresh 50%, $primary-lighter-fresh 100%);
    }
  }

  &.background-white-grey-vertical {
    background: $white;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(231, 242, 252, 1) 100%);
  }
}

.background-primary-lighter-fresh {
  background-color: $primary-lighter-fresh !important;
}

.section-padding {
  margin-top: 0;
  margin-bottom: 0;
  padding: 80px 0 60px;
  min-height: 300px;

  &.no-minheight {
    min-height: auto;
  }

  &.overflow-x-hidden {
    overflow-x: hidden;
  }

  &.dense {
    padding: 80px 0 60px;
  }

  @include breakpoint ($screen-lg) {
    padding: 140px 0;
  }
}

.title {
  line-height: 1.2em;
}

.subtitle {
  font-size: 24px;
  font-weight: 400;
}

.testimonial {
  color: $primary-fresh;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2em;
}

.element {
  padding: 80 100 60;
}

.left-wrapper {
  margin: 0 0 20px;

  @include breakpoint ($screen-sm) {
    margin: 0 0 30px;
  }

  @include breakpoint ($screen-lg) {
    margin: 0 0 60px;
  }
}

.right-wrapper {
  margin: 20px 0 0;

  @include breakpoint ($screen-sm) {
    margin: 40px 60px 0 0;
  }

  @include breakpoint ($screen-lg) {
    margin: 40px 120px 0 0;
    padding: 0;
  }
}

.shadow {
  box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1);
}

.card-holder {
  display: flex;
  align-content: center;
  flex-direction: column;

  @include breakpoint ($screen-sm) {
    flex-direction: row;
  }
}

.homepage-article-card {
  cursor: pointer;
  border: 1px solid transparent;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid $grey-smoke;
  }
}

.card {
  &.shadow {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  }

  .card-text {
    padding-top: 24px;
    flex: 1 0 auto;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
  }

  .card-image {
    &.fixed-sibling {
      @include breakpoint($screen-sm) {
        height: 200px;
        padding-top: 10px;
      }
    }
  }

  .btn-card {
    font-size: 18px;
    padding: 12px 20px;
  }
}

.btn-round {
  border-radius: 5px;
}

.btn-bold,
.bold {
  font-weight: 600;
}

.btn-float {
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:hover,
  &:active,
  &:focus {
    transform: translateY(-8px);
  }
}

.btn-block-xs {
  width: 100%;

  @include breakpoint ($screen-sm) {
    width: auto;
  }
}

.row-flex {
  display: block;

  @include breakpoint ($screen-sm) {
    display: flex;
    align-items: center;
  }
}

$fontSizes:
  ('16', 16px),
  ('18', 18px),
  ('20', 20px),
  ('22', 22px),
  ('24', 24px),
  ('26', 26px),
  ('32', 32px),
  ('36', 36px);

@each $fsize, $fontSizeValue in $fontSizes {
  .font-#{$fsize} {
    font-size: $fontSizeValue !important;
  }
}

.enterprise-logo {
  display: inline;
  max-width: 120px;
  height: 60px;
}

.connect-title {
  font-size: 28px;

  @include breakpoint ($screen-sm) {
    font-size: 40px;
  }
}

.gdpr-title {
  font-size: 28px;
  text-align: center;

  @include breakpoint ($screen-sm) {
    margin-top: 0;
    font-size: 36px;
    text-align: left;
  }
}

.gdpr-subtitle {
  font-size: 16px;
  text-align: center;

  @include breakpoint ($screen-sm) {
    width: 67%;
    text-align: left;
  }
}

.gdpr-img {
  width: 280px;
  margin: 0 auto;

  @include breakpoint ($screen-sm) {
    margin-right: 0;
  }
}

.support-title {
  font-size: 28px;

  @include breakpoint ($screen-sm) {
    font-size: 40px;
  }

  @include breakpoint ($screen-md) {
    font-size: 50px;
  }
}

.support-subtitle {
  font-size: 26px;
  line-height: 1.2em;

  @include breakpoint ($screen-sm) {
    font-size: 36px;
  }
}

.stat-title {
  font-size: 49px;
  margin-top: 0;
  margin-bottom: 0;

  @include breakpoint ($screen-md) {
    font-size: 60px;
  }
}

.stat-divider {
  margin-top: 80px;
  margin-bottom: 80px;
  border-top: 1px solid rgba(#63aaed, 0.47);
}

.stat-subtitle {
  font-size: 18px;

  @include breakpoint ($screen-sm) {
    font-size: 49px;
  }
}

.connect-section {
  padding-bottom: 0;
  margin-bottom: -80px;
}

.connect-subtitle {
  font-weight: 400;
  font-size: 28px;

  @include breakpoint ($screen-sm) {
    font-size: 50px;
  }
}

.connect-subtitle {
  font-size: 16px;
  margin-bottom: 20px;

  @include breakpoint ($screen-md) {
    font-size: 24px;
  }
}

.connect-img {
  padding-bottom: 0;
  margin-bottom: -80px;

  @include breakpoint ($screen-sm) {
    margin-left: auto;
  }
}

.consultancy-li {
  text-align: left !important;
  padding: 15px 15px 15px 25px;
  border-radius: 5px;
  margin-bottom: 20px;

  .icon {
    margin-right: 10px;
  }

  @include breakpoint ($screen-sm) {
    display: flex;
    align-items: center;
  }
}

.hero-primary-btn {
  margin-bottom: 20px;
  width: 100%;

  @include breakpoint ($screen-sm) {
    width: auto;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.stars-img {
  margin: 0 auto;
  width: 86px;
  height: 18px;
}

.hero-subtitle {
  font-size: 16px;
  font-weight: 400;

  @include breakpoint ($screen-sm) {
    font-size: 20px;
  }

  @include breakpoint ($screen-md) {
    font-size: 24px;
  }
}

.feature-title {
  font-weight: 600;
  font-size: 20px;

  @include breakpoint ($screen-sm) {
    font-size: 22px;
  }
}

.feature-subtitle {
  font-size: 16px;
  font-weight: 400;
}

.image-dots {
  position: absolute;
  right: -75px;
  width: 30%;
  opacity: 0.1;
}

.solution-card {
  padding: 20px;

  .card-text {
    padding-bottom: 20px;
  }

  @include breakpoint ($screen-sm) {
    padding: 40px;
  }
}
