body.default-campaign {
  .price-box {
    .strike {
      text-decoration: line-through;
      opacity: 0.8;
    }

    &.easter-campaign-premium {
      padding-bottom: 30px;
    }

    .price-feature-specific {
      padding-bottom: 15px;

      p {
        margin-bottom: 0;
      }

      ul li {
        display: block;
        font-weight: $font-weight-bold;
      }

      ul {
        padding-left: 0;
      }

      li {
        padding-top: 5px;
      }
    }

    img {
      height: 200px;
    }

    &.fade {
      opacity: 0.3;

      img {
        transition: filter 0.15s linear;
        filter: grayscale(0.4) hue-rotate(-156deg);
      }

      &:hover,
      &:focus {
        opacity: 1;

        img {
          filter: grayscale(0);
        }
      }
    }
  }
}
