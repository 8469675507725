/*
Corners Indicators
*/

$default-corner-size: 5px;

$corner-size-xs: 3px;
$corner-size-sm: $default-corner-size;
$corner-size-md: 10px;
$corner-size-lg: 15px;

$darkenEffetBorder: 5%;

.corner-indicator-container {
  position: relative;

  .corner-indicator {
    position: absolute;

    &.corner-top-left {
      top: 0;
      left: 0;
    }

    &.corner-bottom-left {
      bottom: 0;
      left: 0;
    }

    &.corner-top-right {
      top: 0;
      right: 0;
    }

    &.corner-bottom-right {
      bottom: 0;
      right: 0;
    }
  }
}

.corner-indicator {
  border: $corner-size-sm solid transparent;
  display: inline-block;
  height: 0;
  vertical-align: top;
  width: 0;

  &::after {
    border: ($corner-size-sm - 2px) solid transparent;
    content: " ";
    position: absolute;
  }

  &.corner-xs {
    border-width: $corner-size-xs;

    &::after {
      display: none;
    }
  }

  &.corner-md {
    border-width: $corner-size-md;

    &::after {
      border-width: ($corner-size-md - 2px);
    }
  }

  &.corner-lg {
    border-width: $corner-size-lg;

    &::after {
      border-width: ($corner-size-lg - 2px);
    }
  }

  &.corner-top-left {
    border-radius: $border-radius-base 0 0 0;

    &::after {
      border-radius: $border-radius-base 0 0 0;
      border: ($corner-size-sm - 2px) solid transparent;
      left: -($corner-size-sm - 1px);
      top: -($corner-size-sm - 1px);
    }

    @each $color, $colorValue in $colors {
      &.corner-#{$color} {
        border-left-color: darken($colorValue, $darkenEffetBorder);
        border-top-color: darken($colorValue, $darkenEffetBorder);

        &::after {
          border-left-color: $colorValue;
          border-top-color: $colorValue;
        }
      }
    }
  }

  &.corner-bottom-left {
    border-radius: 0 0 0 $border-radius-base;

    &::after {
      border-radius: 0 0 0 $border-radius-base;
      border: ($corner-size-sm - 2px) solid transparent;
      left: -($corner-size-sm - 1px);
      bottom: -($corner-size-sm - 1px);
    }

    @each $color, $colorValue in $colors {
      &.corner-#{$color} {
        border-left-color: darken($colorValue, $darkenEffetBorder);
        border-bottom-color: darken($colorValue, $darkenEffetBorder);
      }

      &.corner-#{$color}::after {
        border-left-color: $colorValue;
        border-bottom-color: $colorValue;
      }
    }
  }

  &.corner-top-right {
    border-radius: 0 $border-radius-base 0 0;

    &::after {
      border-radius: 0 $border-radius-base 0 0;
      border: ($corner-size-sm - 2px) solid transparent;
      right: -($corner-size-sm - 1px);
      top: -($corner-size-sm - 1px);
    }

    @each $color, $colorValue in $colors {
      &.corner-#{$color} {
        border-right-color: darken($colorValue, $darkenEffetBorder);
        border-top-color: darken($colorValue, $darkenEffetBorder);
      }

      &.corner-#{$color}::after {
        border-right-color: $colorValue;
        border-top-color: $colorValue;
      }
    }
  }

  &.corner-bottom-right {
    border-radius: 0 0 $border-radius-base 0;

    &::after {
      border-radius: 0 0 $border-radius-base 0;
      bottom: -($corner-size-sm - 1px);
      right: -($corner-size-sm - 1px);
    }

    @each $color, $colorValue in $colors {
      &.corner-#{$color} {
        border-right-color: darken($colorValue, $darkenEffetBorder);
        border-bottom-color: darken($colorValue, $darkenEffetBorder);
      }

      &.corner-#{$color}::after {
        border-right-color: $colorValue;
        border-bottom-color: $colorValue;
      }
    }
  }
}
