/*
Directions Labels
*/
a.direction-label,
a.direction-label:hover {
  text-decoration: none;
}

.direction-label {
  color: $grey-medium;
  display: inline-block;
  font-size: 15px;
  font-weight: $font-weight-normal;
  text-align: center;
  vertical-align: baseline;
  white-space: normal;
  word-break: break-all;

  &:active {
    color: $primary;
  }

  &:hover,
  &:focus {
    color: $primary-dark;
  }

  &:active,
  &:hover,
  &:focus {
    .icon {
      fill: $primary-dark;
    }
  }

  .icon {
    display: inline-block;
    height: 10px;
    width: 10px;
    fill: $grey;
  }

  .icon-arrow-up,
  .icon-arrow-down {
    display: none;
  }

  &.important {
    font-weight: $font-weight-bold;
  }

  &.up {
    .icon-arrow-up-down {
      display: none;
    }

    .icon-arrow-up {
      display: inline-block;
    }
  }

  &.down {
    .icon-arrow-up-down {
      display: none;
    }

    .icon-arrow-down {
      display: inline-block;
    }
  }
}
