/*
Blog Post
*/

body.blog-post {
  background-color: $white;

  .featured-img {
    clear: both;
    text-align: center;

    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .page-container {
    main {
      padding: 30px 0;
      font-weight: 400;
      font-size: 1.2em;
      display: block;
    }

    h2 {
      font-weight: $font-weight-bold;
    }

    ul {
      position: relative;
      padding-left: 0;

      li {
        list-style: none;
        display: block;
        margin: 0 0 0 30px;
      }

      li::before {
        position: absolute;
        color: $grey;
        content: "•";
        font-size: 15px;
        left: 10px;
      }

      ul {
        margin: 18px 0 18px 30px;

        li::before {
          content: "◦";
        }
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin: 30px auto;

      &.image-right {
        float: right !important; // pull-right class
        margin: 12px 0 0 12px;
      }

      &.image-left {
        float: left !important; // pull-left class
        margin: 12px 12px 0 0;
      }

      &.image-full {
        position: relative;
        width: 100vw;
        min-width: 100vw;
        margin-left: -50vw;
        left: 50%;
      }
    }

    .blog-article-author-picture {
      img {
        margin: auto;
      }
    }
  }
}
