.checklist {
  .checklist-header {
    display: flex;
    flex: 1 0 auto;
    align-items: center;

    .checklist-header-text {
      display: inline-flex;
      width: 100%;
    }

    .progress {
      float: right;
    }
  }

  .checklist-item {
    cursor: pointer;
    position: relative;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    line-height: 20px;
    padding: 0 12px;
    text-decoration: none !important;
    transition: background-color 300ms ease-out;

    .checklist-item-header {
      display: inline-flex;
      padding: 12px 0;

      .checklist-item-icon {
        margin-right: 6px;
      }

      .checklist-item-action {
        margin-right: auto;
      }
    }

    .checklist-content {
      display: none;
    }
  }
}

.checklist {
  .checklist-item {
    background-color: $white;

    .checklist-item-text {
      color: $grey-dark;
      font-weight: $font-weight-medium;
    }

    &:hover,
    &:focus {
      background-color: $grey-smoke;
    }
  }
}

.checklist {
  .checklist-item {
    &.checked {
      pointer-events: none;
      opacity: 0.7;

      .checklist-item-text {
        text-decoration: line-through;
        color: grey;
      }
    }
  }
}

// Icon interaction
.checklist {
  .checklist-item {
    .icon-box-check {
      display: none;
    }

    .icon-box {
      display: inline-block;
    }

    &.checked {
      .icon-box-check {
        display: inline-block;
      }

      .icon-box {
        display: none;
      }
    }
  }
}

.checklist {
  &.bordered {
    .checklist-item {
      border: 1px solid $grey-light;

      &:not(:last-child) {
        border-bottom: none;
      }
    }
  }
}
