/*
Modal
*/

.modal {
  &.modal-wide {
    .modal-dialog {
      @include breakpoint($screen-sm) {
        width: 90%;
        max-width: 890px;
      }
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 10px;
    padding: 10px;
    line-height: 0;
    opacity: 1;
  }

  .modal-header {
    border: none;
    padding: 20px 0 0;
  }

  .modal-cancel-dialog {
    max-width: 580px !important;
  }

  .modal-header-dialog {
    justify-content: center;
    margin-top: 32px;
  }

  .modal-title {
    text-align: center;
    font-size: 20px;
    font-weight: $font-weight-medium;
    color: $grey-darker;
  }

  .modal-footer {
    border: none;
    padding: 0 20px 20px;
  }

  .modal-body-dialog-one {
    margin: 0 28px;
    padding: 16px 16px 0;
  }

  .modal-body-dialog-two {
    background: $primary-smoke;
    margin: 0 28px 28px;
    padding: 16px;
  }

  .modal-body-dialog-text {
    font-weight: 600;
    padding-bottom: 8px;
  }

  .modal-footer-dialog {
    justify-content: center;
    border: none;
    padding: 0 20px 28px;
  }
}
