// Front hero banner specifics rules
$heroDefaultHeight: 358px;
$heroBigHeight: 560px;

.sitepoint-form-btn-container {
  padding-top: 8px;
}

.hero-banner {
  z-index: 0;

  &.wide-hero-banner {
    height: $heroDefaultHeight;

    @include breakpoint($screen-lg) {
      height: $heroBigHeight;
    }

    .hero-img {
      display: block;
      height: 100%;
      left: 50%;
      max-width: none;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: auto;
      z-index: 1;

      @include breakpoint($screen-sm) {
        height: auto;
        top: 15%;
        left: 0;
        transform: translateY(-15%);
        width: 100%;
      }
    }
  }
}

.fixed-vertical {
  margin-top: 20px;
  margin-bottom: 20px;
}

.hero-sitepoint-login-wrapper {
  background: $white;
  min-height: 500px;
  box-sizing: border-box;
  margin-top: 12px;

  .login-form-container {
    position: relative;
    margin-bottom: 0;
    min-height: 477px;
  }
}
