body.generic-content-page {
  background-color: $white;

  .page-header {
    padding-bottom: 40px;
  }

  .row img:not(.thumbs) {
    margin-bottom: 40px;
  }

  .inner-page-footer {
    margin-top: 30px;

    img {
      margin-bottom: -10px;
    }
  }

  .thumbnail {
    .h4 {
      margin-bottom: 0;
    }
  }

  .free-trial-btn-container {
    .btn-container {
      padding-bottom: 30px;
    }
  }

  .hero-banner {
    margin-bottom: 30px;

    h1 {
      margin: 0;
    }
  }
}
