/*
Page Footer
*/
#section-patterns-page-footer {
  .page-footer {
    width: 100%;
  }
}

.-footer {
  display: flex;
  flex: 0 0 auto; // footer flex
  flex-direction: column;
  margin-top: 60px;

  .footer-menu {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 auto;
    padding: 15px 0;
    border-top: 1px solid $footer-border-color;

    @include breakpoint ($screen-sm) {
      flex-direction: row;
    }

    .-menu {
      flex: 1 auto;
      text-align: center;

      @include breakpoint($screen-sm) {
        text-align: left;
      }
    }

    .-language {
      .dropup,
      .dropdown {
        color: $footer-font-color;
        display: inline-flex;
        padding: 0 15px;

        .icon-chevron-down,
        .icon-chevron-up {
          margin: 0 5px;
        }

        .dropdown-item {
          text-decoration: none;
        }
      }
    }
  }

  .footer-copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex: 1 auto;
    padding: 15px 0;
    border-top: 1px solid $footer-border-color;

    .brand {
      display: flex;
      max-width: 200px;
      padding: 0;
      height: auto;
      padding-right: 30px;
      align-items: center;
    }

    .-language {
      .dropup,
      .dropdown {
        color: $grey-darker;
        display: inline-flex;
        padding: 0 15px;

        .icon-chevron-down,
        .icon-chevron-up {
          margin: 0 5px;
        }

        .dropdown-item,
        .dropdown-toggle,
        a {
          text-decoration: none;
        }
      }
    }
  }

  .-nav {
    display: inline-flex;
    color: $footer-font-color;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    min-width: 100px;

    @include breakpoint ($screen-md) {
      min-width: 150px;
    }

    .-title {
      pointer-events: none;
      cursor: default;
      color: $footer-font-color;
      font-weight: $font-weight-bold;
      font-size: $footer-font-size;
      margin-top: 5px;
      max-width: 100px;
    }

    .-list {
      padding-left: 0;

      .-item {
        list-style-type: none;
        font-size: $footer-font-size;
        margin-bottom: 5px;
        max-width: 100px;
        word-break: break-word;

        a {
          color: $footer-font-color;
          font-weight: $font-weight-normal;
          text-decoration: none;

          &:hover {
            color: $footer-font-color-hover;
          }
        }
      }
    }
  }

  .-social {
    display: flex;
    overflow: hidden;
    cursor: default;

    .-list {
      padding-left: 0;
      margin: 0 auto;

      .-item {
        display: inline-block;
        list-style-type: none;
        font-size: 13px;

        a {
          text-decoration: none;
        }
      }
    }

    .-circle {
      .icon {
        fill: $grey-medium;
        width: 20px;
        height: 20px;
      }
    }
  }
}
