/*
Buttons
*/

.btn-container {
  padding-top: 30px;
  overflow: hidden;
  position: relative;
}

.btn {
  transition: background 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &.btn-primary {
    color: $white !important;
    background-color: $primary-fresh !important;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-light-fresh !important;
    }
  }

  &.btn-enterprise {
    color: $primary-fresh !important;
    background-color: $white !important;
    border: 1px solid $primary-fresh !important;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      background-color: $grey-smoke !important;
    }
  }

  &.btn-success {
    color: $white !important;
    background-color: $success-fresh !important;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      background-color: $success-light-fresh !important;
    }
  }

  &.btn-warning {
    color: $white !important;
    background-color: $warning-fresh !important;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      background-color: $warning-light-fresh !important;
    }
  }
}

.btn {
  font-weight: $font-weight-normal;
  white-space: normal;

  &.btn-dialog {
    margin: 0 18px;
  }

  &.btn-shadow {
    box-shadow: inset 0 -2px 0 0 rgba($black, 0.3);
  }

  @include breakpoint($screen-sm) {
    white-space: nowrap;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    pointer-events: none;
    opacity: 0.35;
  }

  &.busy,
  &[busy],
  fieldset[busy] & {
    @extend .disabled;
  }

  &:focus,
  &:hover,
  &:active,
  &.active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px rgba($white, 0.1);
  }

  &.btn-cancel {
    background-color: $white !important;
    color: $error !important;
    font-weight: 600 !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $error-dark !important;
    }
  }

  &.btn-error {
    background-color: $error;
    color: $white;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $error-dark;
    }
  }

  &.btn-link {
    &:active,
    &.active,
    &:focus {
      color: $primary-dark;
    }
  }

  &.btn-white-space {
    white-space: normal;
  }

  &.btn-primary,
  &.btn-success,
  &.btn-info,
  &.btn-warning,
  &.btn-error,
  &.btn-danger,
  &.btn-turquoise,
  &.btn-emerald,
  &.btn-amethyst {
    border: none;

    &:focus {
      outline: none;
    }
  }

  &.btn-default {
    &:hover,
    &:focus {
      color: $primary;
      border-color: $primary;
      background-color: $primary-smoke;
    }

    &:active,
    &.active {
      background-color: $primary-lighter;
      border-color: $primary-dark;
      color: $primary-dark;
      outline: none;
    }
  }

  &.btn-black {
    background-color: $black;
    color: $white;

    &:hover,
    &:focus {
      background-color: $grey-dark;
      color: $white;
      outline: none;
    }

    &:active,
    &.active {
      background-color: $grey-darker;
      color: $grey-lighter;
      outline: none;
    }
  }

  &.btn-centered {
    // display: block;
    margin: 0 auto;
    max-width: 100px;

    &.wide {
      max-width: 330px;
    }

    .icon {
      margin-right: 5px;
      fill: $white;
      vertical-align: inherit;
    }
  }

  &.btn-no-border {
    border: 1px solid transparent;
  }
}

.btn-grey {
  background-color: $grey-medium;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    background-color: $grey-dark;
    color: $white;
  }
}

.btn.btn-async {
  .icon {
    &.icon-loader {
      width: 10px;
      height: 10px;
    }
  }
}

.btn {
  &.btn-outline-dark {
    background-color: $white;

    &:hover,
    &:active,
    &:focus {
      color: $grey-darker;
      background-color: $grey-smoke;
    }
  }
}
