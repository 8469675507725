// Icons

.icon-text-left {
  margin-right: 6px;
}

.icon-container {
  min-width: 15px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.importance,
.solvability,
.rating-stars {
  .icon {
    fill: $primary-darker;
    opacity: 0.5;
    font-size: 0;
    margin-left: 2px;
    margin-right: 2px;

    &.icon:first-child {
      margin-left: 0;
    }

    &.icon:last-child {
      margin-right: 0;
    }
  }

  @for $i from 0 through 5 {
    &.rating-#{$i} {
      .icon:nth-child(-n+#{$i}) {
        opacity: 1;
      }
    }
  }

  &.rating--1 {
    .icon {
      fill: none;
      stroke: $primary-darker;
    }
  }
}
