/*
Accordion
*/

.panel-group {
  .panel-heading {
    padding: 0;
  }

  .panel-title {
    a {
      display: block;
      padding: 10px 15px;
      text-decoration: none;
    }
  }
}
