@charset "UTF-8";

/* Utils assets (Variables, Colors & mixins only) */
@import "styleguide/woorank-theme";
@import "utils/variables";
@import "utils/reset";

/* Woorank Front specific pages rules */
@import "pages/_index";

/* Woorank Front specific components rules */
@import "front-components/_index";

/* campaign */
@import "seasonal-events/default-campaign";

/* show javascript build errors in the page (browserify plugin) */
body > .errorify {
  z-index: 10000000000000000000;
  color: red;
  background-color: white;
  border: none;
  padding: 5px 10px;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
