/*
Tabs
*/

.nav-tabs {
  margin-bottom: 30px;

  > li {
    &.active a,
    &.active a:hover,
    &.active a:focus {
      background-color: $white;
      border: 1px solid $border-color;
      border-bottom-color: transparent;
    }
  }
}
