/*
Headings
*/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: $font-weight-medium;
  letter-spacing: -0.0415625em;
}

h5,
.h5 {
  font-size: 16px;
}

/*
Ordered List
*/

.ol {
  padding-left: 0;
  margin-left: 20px;
}

/*
Unordered List
*/

.ul {
  padding-left: 0;

  li {
    list-style: none;
  }

  li::before {
    color: $grey;
    content: "•";
    font-size: 15px;
    padding-right: 30px;
  }

  .ul &,
  .ol & {
    margin: 18px 0 18px 30px;

    li::before {
      content: "◦";
    }
  }
}

.logged {
  ul,
  ol {
    li {
      font-size: 13px;
    }
  }
}

/*
Unstyled List
*/

.list-unstyled,
.list-inline {
  li::before {
    padding-right: 0;
    content: "";
  }
}
