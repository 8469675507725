/*
Popover
*/

.popover {
  border: 1px solid $border-color;
  padding: 15px;

  &.popover-fluid {
    width: auto;
    max-width: none;
    min-width: auto;
  }

  &.top > .arrow {
    border-top-color: $border-color;
  }

  &.right > .arrow {
    border-right-color: $border-color;
  }

  &.bottom > .arrow {
    border-bottom-color: $border-color;
  }

  &.left > .arrow {
    border-left-color: $border-color;
  }

  .popover-title {
    padding: 0 0 15px;
    border-bottom: none;
    margin: 0 !important;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    line-height: 0;
    outline: none;

    &:focus,
    &:active {
      outline: none;
    }

    .icon {
      height: 13px;
      width: 13px;
    }
  }
}

$popoverContext: ('marketing-checklist');

@each $context in $popoverContext {
  .#{$context} .popover {
    border-color: $border-color;

    &.bottom {
      .arrow {
        border-bottom-color: $white;
        border-width: 0 5px 5px;
        margin-left: -5px;
        top: -5px;

        &::after {
          border-bottom-color: $border-color;
          border-width: 0 5px 5px;
          left: 0;
          margin-left: -5px;
          top: -1px;
        }
      }
    }

    .popover-content {
      color: $grey-darker;
      font-size: 13px;
      text-align: center;
    }

    .popover-note {
      font-size: 90%;
      margin: 12px 0 0 !important;
    }
  }
}
