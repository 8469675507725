// Variables

// Typography
$font-weight-normal       : 300;
$font-weight-medium       : 500;
$font-weight-bold         : 600;

$font-family-sans-serif   : "Open Sans", Helvetica, Arial, sans-serif;
$font-family-serif        : Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace    : Courier, "Courier New", monospace;
$font-family-base         : $font-family-sans-serif;

$font-size-int            : 15;
$font-size-base           : $font-size-int * 1px;
$font-size-large          : 18px;
$font-size-small          : 13px;

$line-height-base         : 24 / $font-size-int;
$line-height-computed     : floor($font-size-base * $line-height-base);

// Z-index master list
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
// Note: These variables are not generated into the Customizer.
$zindex-flying-menu       : 1000;
$zindex-dropdown          : 1010;
$zindex-popover           : 1020;

$zindex-tooltip           : 1040;

$zindex-navbar            : 1050;
$zindex-navbar-fixed      : 1060;

$zindex-modal             : 1070;
$zindex-modal-background  : $zindex-modal - 1;
$zindex-modal-loader      : $zindex-modal + 1;

/* Legacy responsiveness breakpoints */
$large-screen-threshold   : 1080px;
$medium-screen-threshold  : 805px;
$small-screen-threshold   : 600px;

// Media queries breakpoints

// Extra small screen / phone
$screen-xs                : 480px;
$screen-xs-min            : $screen-xs;
$screen-phone             : $screen-xs-min;

// Small screen / tablet
$screen-sm                : 768px;
$screen-sm-min            : $screen-sm;
$screen-tablet            : $screen-sm-min;

// Medium screen / desktop
$screen-md                : 992px;
$screen-md-min            : $screen-md;
$screen-desktop           : $screen-md-min;

// Large screen / wide desktop
$screen-lg                : 1200px;
$screen-lg-min            : $screen-lg;
$screen-lg-desktop        : $screen-lg-min;

// Extra large screen / HDTV
$screen-xl                : 1600px;
$screen-xl-min            : $screen-xl;
$screen-xl-desktop        : $screen-xl-min;

$border-radius            : 6px;

// Footer vars
$footer-font-size: $font-size-small;
$footer-font-color: $grey-dark;
$footer-font-color-hover: $primary;
$footer-border-color: $grey-light;
