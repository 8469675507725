/* Animations Utils */

$animationCurve: cubic-bezier(0.165, 0.84, 0.44, 1);

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  &.infinite {
    animation-iteration-count: infinite;
  }

  &.bounce {
    animation-name: bounce;
  }

  &.linear {
    animation-timing-function: linear !important;
  }

  &.fadeInUp {
    animation-name: fadeInUp;
  }

  &.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
  }

  &.shakingBox {
    animation-name: ShakingBox;
  }

  &.backgroundScrollToRight {
    animation-name: backgroundScrollToRight;
  }

  &.backgroundScrollToLeft {
    animation-name: backgroundScrollToLeft;
  }
}

.fadeout {
  animation-iteration-count: 1 !important;
  animation-name: fadeout !important;
  animation-duration: 500ms !important;
  animation-direction: normal !important;
  animation-timing-function: ease !important;
  animation-fill-mode: forwards !important;
  animation-delay: 0s !important;
}

@keyframes bounce {
  0% {
    transition-timing-function: cubic-bezier(0.53, 0.97, 0.35, 1);
    transform: translate3d(0, 0, 0) scale(1, 1);
  }

  40% {
    transition-timing-function: cubic-bezier(0.74, 0.01, 1, 0.13);
    transform: translate3d(0, 15px, 0) scale(1, 0.95);
  }

  43% {
    transition-timing-function: cubic-bezier(0.74, 0.01, 1, 0.13);
    transform: translate3d(0, 15px, 0) scale(1, 0.95);
  }

  60% {
    transition-timing-function: cubic-bezier(0.53, 0.97, 0.35, 1);
    transform: translate3d(0, 0, 0) scale(1, 1);
  }

  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, 7px, 0) scale(1, 1);
  }

  85% {
    transition-timing-function: cubic-bezier(0.53, 0.97, 0.35, 1);
    transform: translate3d(0, 0, 0) scale(1, 1);
  }

  90% {
    transform: translate3d(0, 2px, 0) scale(1, 1);
  }

  100% {
    transition-timing-function: cubic-bezier(0.53, 0.97, 0.35, 1);
    transform: translate3d(0, 0, 0) scale(1, 1);
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

.icon-loader {
  animation: spin 1s steps(12) infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

// Animations Utils
// Based on Daniel Eden works
// https://github.com/daneden/animate.css

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  &.linear {
    animation-timing-function: linear !important;
  }

  &.infinite {
    animation-iteration-count: infinite;
  }

  &.bounce {
    animation-name: bounce;
  }

  &.fadeInUp {
    animation-name: fadeInUp;
  }

  &.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
  }

  &.shakingBox {
    animation-name: ShakingBox;
  }

  &.backgroundScrollToRight {
    animation-name: backgroundScrollToRight;
  }

  &.backgroundScrollToLeft {
    animation-name: backgroundScrollToLeft;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes ShakingBox {
  10%,
  90% {
    transform: rotate(5deg) translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: rotate(-5deg) translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: rotate(5deg) translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: rotate(-5deg) translate3d(4px, 0, 0);
  }
}

// Custom Jobs page animation
@keyframes bubblesRise {
  100% { background-position: 0 -190px; }
}

@keyframes backgroundScrollToRight {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -800px 0;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes backgroundScrollToLeft {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 800px 0;
  }
}
