.pricing-faq {
  .pricing-faq-title {
    .icon {
      height: 16px;
      width: 16px;
      fill: $primary;
      transform: rotate(-180deg);
      transform-origin: center center;
      transition: transform 300ms ease-in-out;
    }

    &.collapsed {
      .icon {
        transform: rotate(0deg);
        fill: $grey;
      }
    }
  }

  .pricing-faq-content {
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}
