// bootstrap modals

.modal {
  .modal-title {
    font-size: 18px !important; // gracefully override #content h1

    @include breakpoint($screen-sm) {
      font-size: 24px !important; // gracefully override #content h1
    }

    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 15px;
    line-height: 1.6;
  }

  .modal-body {
    font-size: 15px;
  }

  .close {
    .icon-cross {
      height: 15px;
      width: 15px;
    }
  }

  .modal-overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: $white;
    z-index: $zindex-modal-loader;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -24px 0 0 -24px;
    }
  }

  &.busy {
    .modal-overlay {
      display: block;
    }
  }
}
