// Partner logos
.homepage-partners {
  margin-top: 70px;
  margin-bottom: 70px;

  .partners-title {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;
    text-align: center;
    color: $grey-dark;
  }

  .partner-logo {
    text-align: center;

    img {
      @include breakpoint ($screen-md) {
        width: 150px;
        margin: 0 auto;
      }
    }
  }
}
