.price-box {
  cursor: default;
  border-right: 0;
  padding: 20px;
  text-align: left;

  .price-enterprise-contact {
    margin-top: 33px;
    min-height: 92px;
    font-size: 14px;
    color: $grey-dark;
  }

  .btn-select-plan,
  .highlighted {
    width: 100%;
  }

  .highlighted {
    background-color: white;
    color: $grey-medium;
    border: 1px solid $grey-medium;
    min-height: 50px;

    .icon {
      height: 18px;
    }
  }

  .feature-list-all-container {
    margin-top: 20px;
  }

  .feature-list {
    padding-left: 0;

    li {
      line-height: 1.4;
      max-width: 100%;
      font-size: 14px;
      padding-bottom: 12px;

      &::before {
        padding-left: 20px !important;
        background-position: 0 1px !important;
      }
    }
  }

  .all-features {
    li {
      max-width: 100%;
      font-size: 15px !important;
      padding-bottom: 10px;
      display: list-item !important;
      list-style-type: disc !important;

      &::before {
        padding-left: 0 !important;
        background: none !important;
      }
    }
  }

  .all-features {
    margin-top: 30px;
    display: block;
  }

  .price-yearly-discount {
    display: block;
    font-size: 14px;
    color: $grey-dark;
    margin-top: 16px;
  }

  .feature-list-container {
    min-height: 500px;

    h3 {
      font-size: 21px;
    }

    h3.pricing-feature-title {
      color: $grey-darker;
      font-weight: 700;
      text-align: left;
      margin-top: 54px;
      margin-bottom: 32px;
    }
  }

  @include breakpoint($screen-sm) {
    border: solid 1px $grey-lighter;
    border-top: none;
    border-bottom: none;

    &:last-child {
      // border-radius: 0 6px 6px 0;
      border-right: none;
    }

    &:first-child {
      // border-radius: 6px 0 0 6px;
      border-left: none;
    }

    &:not(:last-child) {
      border-right: 0;
    }
  }

  &.price-box-hollow {
    color: $grey-dark;
    background-color: $white;

    .price-box-title {
      color: $grey-darker;
      font-size: 24px;
      font-weight: 700;
    }

    .price-box-subtitle {
      color: $grey-dark;
      font-size: 16px;
      margin-top: 5px;
      padding: 5px 0 0;
      min-height: 85px;
      font-style: normal;
    }
  }

  .price-amount {
    color: $grey-dark;
    font-size: 24px !important;
  }

  .btn,
  .highlighted {
    line-height: 1;
    white-space: normal;
    margin: 30px auto 20px;
  }

  .plan-illustration {
    display: block;
    max-width: 100%;
    height: 200px;
    transition: transform 0.1s linear;

    &:hover {
      transform: scale(1.1);
    }
  }

  .list-group {
    color: $grey-darker;
    background-color: $white;
    margin: 0 -10px -10px;
    border: 1px solid $border-color;
    padding-bottom: 0;

    strong,
    b {
      font-weight: $font-weight-bold;
      color: $black;
    }
  }

  .no-button .list-group {
    margin-top: 107px;
  }

  .price {
    position: relative;
    padding: 10px 0;
    line-height: 1;
    min-height: 125px;

    .amount {
      font-size: 60px;
      font-weight: $font-weight-bold;
    }

    .price-box-title {
      padding: 0;
    }

    .prefix-currency {
      display: block;
      min-height: 20px;
    }

    .price-timeframe {
      white-space: normal;
      display: block;
      margin-top: 5px;
    }
  }

  &.wide-price {
    margin-top: 30px;
    overflow: hidden;

    h2 {
      float: left;
      padding-left: 20px;
    }

    a.no-plan {
      float: right;
      line-height: 88px;
      margin-right: 20px;
    }
  }

  .bill-yearly-wrap {
    text-align: center;
    padding: 0;
  }

  .bill-yearly {
    display: inline-block;
    padding-top: 12px;

    input {
      margin: -2px 0 0;
      cursor: pointer;
    }

    label {
      margin-left: 4px;
      cursor: pointer;
    }
  }
}

.pricing-enterprise-banner {
  background: #f1f1f1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  h3 {
    font-size: 34px;
  }

  img {
    width: 33%;
    margin-left: 8%;
  }

  .pricing-enterprise-banner-content {
    width: 40%;
    font-size: 24px;
    margin-left: 15%;
  }

  .pricing-enterprise-banner-subtitle {
    margin-bottom: 20px;
  }
}

.feature-list {
  text-align: left;

  @include breakpoint($screen-sm, true) {
    padding-left: 1em;
  }

  li {
    display: table;
    list-style-type: none;
    font-size: 12px;
    max-width: 90%;
    padding-bottom: 10px;
    line-height: 1.4;

    @include breakpoint($screen-sm) {
      font-size: 14px;
    }

    @include breakpoint($screen-md) {
      font-size: 16px;
    }

    span {
      display: table-cell;

      &:last-child {
        padding-left: 10px;
      }
    }
  }
}
