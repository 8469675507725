/*
Position Label
*/
a.position-label,
a.position-label:hover {
  text-decoration: none;
}

.position-label {
  display: inline-block;
  color: $text-color;
  font-size: 15px;
  font-weight: $font-weight-medium;
  vertical-align: baseline;
  white-space: nowrap;

  .icon {
    display: none;
    height: 10px;
    width: 10px;
  }

  &.important {
    font-weight: $font-weight-bold;
  }

  &.up {
    color: $success;

    .icon-triangle-up {
      display: inline-block;
      fill: $success;
    }
  }

  &.down {
    color: $error;

    .icon-triangle-down {
      display: inline-block;
      fill: $error;
    }
  }
}
