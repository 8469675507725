.carousel {
  .carousel-control-prev {
    &.outer-controls {
      left: -100px;
    }
  }

  .carousel-control-next {
    &.outer-controls {
      right: -100px;
    }
  }
}
