/* Generics */
html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  display: flex; // footer flex
  flex-direction: column;
  min-height: 100%;
  transition: transform 0.2s linear;
  font-weight: $font-weight-normal;
  min-width: 320px;
  padding-top: 64px;
  letter-spacing: -0.015em;
  background-color: #edf1f5;
  margin: 0;
  font-family: $font-family-base;
  font-size: $font-size-base;
  color: $grey-darker;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  svg {
    shape-rendering: geometricPrecision;
  }

  &.no-scrolls {
    overflow: hidden;
  }

  &:not(.no-footer) {
    .flex-content {
      flex: 1 0 auto; // footer flex
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    font-weight: $font-weight-light;
  }
}

.container {
  &.page-narrow {
    max-width: 720px;
    margin: 1px auto 0;
    padding: 40px 15px;

    @include breakpoint($screen-sm) {
      padding: 40px 0;
    }
  }
}

/* Remove Firefox dotted outline */
a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: 0;
  outline: 0;
}

// hidden class deprecated in v4
.hidden {
  @extend .d-none;
}//

// Forms label weight modified to no weight in v4
label {
  font-weight: $font-weight-bold;
}

.checkbox,
.radio {
  label {
    font-weight: $font-weight-normal;
    cursor: pointer;
  }
} //

.tooltip {
  z-index: $zindex-tooltip !important;

  .tooltip-inner {
    max-width: 300px;

    @include breakpoint ($screen-md) {
      max-width: 400px;
    }
  }
}

// Legacy review over max - to be deleted on followup
.over-max-list {
  .over-max {
    display: none;
  }

  &.open {
    .over-max {
      display: block;
    }
  }
}//

// show-more show-less show-links
.show-links-custom,
.show-links {
  text-align: center;

  .show-more,
  .show-less,
  .fake-show-more {
    color: $grey-medium;
    border-top: 1px solid #ddd;
    padding: 2px 0;

    &:hover {
      color: $grey-dark;
      text-decoration: none;
      background-color: $grey-smoke;
    }
  }

  .fake-show-more {
    border-top: none;
  }

  .show-less {
    display: none;
  }

  .show-more,
  .fake-show-more {
    display: block;
  }

  &.open {
    .show-more {
      display: none;
    }

    .show-less {
      display: block;
    }
  }
}//

// Default tooltips
.tooltip {
  z-index: $zindex-tooltip !important;

  .tooltip-inner {
    max-width: 300px;

    @include breakpoint ($screen-md) {
      max-width: 400px;
    }
  }
} //
