// Register page

body.register,
body.update-billing-details {
  background-color: $primary-smoke;

  .subtitle {
    font-size: 18px;
    padding-top: 15px;
    font-weight: 800;
  }

  .register-change-plan {
    font-size: 14px;
    text-decoration: underline;
    color: $grey-medium;
    font-weight: normal;
  }

  .billing-total-due {
    font-size: 18px;
    color: $grey-medium;
  }

  .due-subtitle-light {
    color: $grey-medium;
    padding-top: 10px;
  }

  .due-subtitle-dark {
    font-weight: 600;
  }

  .payment-title {
    padding-bottom: 10px;
    font-weight: 600;
  }

  .row-price-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .col-due-today {
    align-self: flex-end;
  }

  .page-content {
    background-color: $white;
    border: 1px solid $grey;
    border-radius: $border-radius;
    overflow: hidden;

    .page-content-panel {
      padding: 15px;

      &:nth-child(2) {
        background-color: $grey-smoke;
        border-left: 1px solid $grey;
      }
    }
  }

  .terms-and-privacy {
    &.has-error {
      border: 1px solid $error;
      color: $error-dark;
      background-color: $error-smoke;
      padding: 0 20px;
    }

    label {
      display: block;
      margin: 20px 0;
      color: $grey-darker;
    }
  }

  .billing-container {
    background-color: $white;
    border-radius: $border-radius;
    color: $grey-dark;

    hr {
      border-top: 1px solid $grey;
      margin-left: -15px;
      margin-right: -15px;
    }

    .billing-header {
      .due-title {
        font-size: 28px;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
      }

      .due-title-price {
        font-size: 28px;
        align-self: flex-end;
        margin-bottom: 0;

        @include breakpoint($screen-sm) {
          text-align: right;
        }

        margin-top: 0;
      }

      .converted-plan-price {
        font-weight: bold;
      }
    }

    .billing-form {
      position: relative;

      .form-control {
        &.invalid {
          border-color: $error;
        }
      }

      .error {
        position: absolute;
        top: 0;
        right: 15px;
        font-size: $font-size-small;
        padding: 5px;
        color: $error;
        background-color: transparent;
        z-index: 1;
        border-radius: $border-radius;
      }
    }

    .billing_info {
      .btn-block {
        text-align: left;
        padding-left: 0;

        .icon {
          margin-right: 5px;
        }

        .close-text {
          display: none;
        }

        &.collapsed {
          .close-text {
            display: block;
          }

          .open-text {
            display: none;
          }
        }
      }

      .nav-tabs {
        // clear: both;
        // display: block;
        border-bottom: none;
        margin-bottom: 0;
      }

      .tab-content {
        background-color: $white;
        padding: 25px;
        border: 1px solid $border-color;
        overflow: visible;

        p:last-child {
          margin-bottom: 0;
        }
      }
    }

    .cc-field {
      padding-top: 0;

      div {
        height: 38px;
      }

      &.has-error {
        border: 1px solid $error;
        color: $error-dark;
        background-color: $error-smoke;
      }
    }

    .expires {
      margin-bottom: 15px;

      .month {
        &::after {
          content: "/";
          position: absolute;
          top: 0;
          right: -3%;
          font-size: $font-size-large;
          line-height: 36px;
          color: $grey;
        }
      }
    }

    .coupon {
      .alert {
        border-radius: $border-radius;
      }

      .error {
        padding: 10px;
        background-color: transparent;
      }
    }
  }

  .waiting_for_paypal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $white;
    height: 100%;
    width: 100%;

    $loadingSize: 64px;

    .icon-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      height: $loadingSize;
      width: $loadingSize;
      margin-top: -($loadingSize / 2);
      margin-left: -($loadingSize / 2);
    }
  }

  #three-d-secure-panel {
    width: 100%;
    height: 800px;

    div {
      height: 100%;
      display: block;
    }
  }

  .secure-payment {
    position: relative;
    overflow: hidden;

    .title {
      position: absolute;
      font-size: 15px;
      font-weight: bold;
      color: $grey-dark;
      margin: 20px 0 0 20px;
    }

    .payment-gateways {
      width: 100%;
      height: 100%;
    }

    .payment-faq {
      color: $grey-dark;

      h3 {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .trial-alert {
    margin-top: 15px;
  }
}
