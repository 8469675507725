/*
Sub Nav
*/

.container .sub-nav {
  margin: 0 -100% 20px;
  padding: 0 100%;
}

.sub-nav {
  background-color: $white;
  text-align: center;
  font-size: 0;

  .sub-nav-link {
    display: inline-block;
    padding: 20px 0;
    margin: 0 15px;
    font-size: 13px;
    color: $primary;
    border-bottom: 2px solid $white;
    text-decoration: none;
    transition: color 300ms;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: $primary-dark;
      border-bottom-color: $primary-light;
    }

    &:focus,
    &:hover,
    &:active {
      text-decoration: none !important;
    }

    &.active {
      border-bottom-color: $primary;
    }
  }
}
