/*
Featured Page
*/

body.featured-page {
  background-color: $white;

  .page-container {
    main {
      padding: 0;
      font-weight: 400;
      font-size: 1.2em;
      display: block;
    }

    blockquote {
      font-size: 1.2em;
    }

    h2 {
      font-weight: $font-weight-bold;
    }

    ul {
      position: relative;
      padding-left: 0;

      li {
        list-style: none;
        display: block;
        margin: 0 0 0 30px;
      }

      li::before {
        position: absolute;
        color: $grey;
        content: "•";
        font-size: 15px;
        left: 10px;
      }

      ul {
        margin: 18px 0 18px 30px;

        li::before {
          content: "◦";
        }
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto 30px;

      &.pull-left {
        width: auto;
        margin-right: 30px;
      }

      &.pull-right {
        width: auto;
        margin-left: 30px;
      }
    }
  }
}
