/*
Form
*/

.form-control {
  box-shadow: none;

  &:focus {
    box-shadow: 0 0 5px rgba($primary, 0.4);
    border-color: $primary;
  }
}

.form-group {
  .input-addon {
    display: inline-block;
    width: 100%;

    .addon {
      line-height: 36px;
    }
  }

  .error-msg {
    color: $error;
    float: right;
    visibility: hidden;
    font-size: 12px;
    margin-top: 2px;
  }

  &.has-error .error-msg {
    visibility: visible;
  }
}

.form-group {
  &.has-error {
    .control-feedback {
      color: $error;
    }
  }

  .control-feedback {
    float: right;
    cursor: default;
    text-align: right;
  }

  .input-addon {
    position: relative;

    .addon {
      position: absolute;
      top: 0;
      line-height: 34px;
      z-index: $zindex-input-addon;
      padding: 0 $padding-small-horizontal;

      .btn {
        padding-left: 0;
        padding-right: 0;
      }

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }

    &.addon-left {
      input {
        padding-left: 30px;
      }
    }

    &.addon-right {
      input {
        padding-right: 30px;
      }
    }
  }
}

.input-group {
  .addon {
    top: 50%;
    transform: translate(0, -50%);
  }
}

.form-centered {
  display: block;
  text-align: center;
  margin: 0 auto;
  max-width: 300px;
}
