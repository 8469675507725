.feature-container {
  &::before,
  &::after {
    content: '';
    height: 80px;
    display: block;
  }

  &::after {
    border-bottom: 1px solid $grey-lighter;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: $grey-dark;
    margin-bottom: 20px;

    .label {
      width: 61px;
      height: 27px;
      margin-right: 27px;
      color: $warning;
    }
  }

  .bullet {
    span {
      margin-right: 24px;
    }

    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: $grey-dark;
  }
}
