// Testimonials Styling

.homepage-testimonials {
  padding-top: 50px;
  padding-bottom: 50px;

  .testimonial-portrait {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;

    img {
      @include radius(50%);
    }
  }

  .testimonial-author-name {
    width: 310px;
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    color: $white;
  }

  .testimonial-author {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
  }

  .author-name {
    display: block;
    color: $white;
    font-weight: bold;
    line-height: 1.5;
    clear: both;
  }

  .author-worksfor {
    line-height: 1.43;

    a {
      text-decoration: none;
      color: $white;
    }
  }
}

.testimonial-dialog {
  p {
    background-color: $white;

    @include radius(6px);

    padding: 18px 30px 22px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: $grey-dark;
  }

  &::after {
    content: "";
    display: block;
    height: 20px;
    margin-left: 50px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/assets/img/home/testimonials/dialog-arrow.png');

    @include retina {
      background-image: url('/assets/img/home/testimonials/dialog-arrow@2x.png');
    }
  }
}
