// User Page

body.user {
  background-color: $white;

  .account-section {
    margin-bottom: 60px;
  }

  .account-section-sm {
    margin-bottom: 30px;
  }

  .account-section-xs {
    margin-bottom: 10px;
  }

  .alert {
    margin-top: 30px;
  }

  .form-group {
    .feedback {
      margin-left: 15px;

      .feedback-text {
        width: 100%;
        height: 80px;
      }
    }

    .reason {
      border: none !important;
    }
  }
}
