body.pricing {
  background-color: $white;

  .page-header {
    margin-bottom: 30px;
    color: $grey-darker;
  }

  .alert {
    margin-top: 30px;
  }

  .honey {
    display: none;
  }

  .pricing-list {
    li {
      &::before {
        display: table-cell;
        content: "";
        background: url("/assets/img/pages/pricing/pricing-check.svg") no-repeat;
        background-size: 13px 13px;
        padding-left: 30px;
        background-position: 0 5px;
      }

      ul {
        li {
          list-style-type: circle;
          display: list-item;

          &::before {
            content: none;
            background: none;
          }
        }
      }
    }
  }
}
