// // Homepage sequences
.page-hero {
  &.page-hero-backdrop-image {
    background-image: url('/assets/img/home/homepage-plant-laptop.jpg');
    background-size: cover;
    background-position: right top;
  }

  .generation-bar {
    .disabled {
      opacity: 1;
    }
  }
}

.homepage-features {
  position: relative;
  padding-bottom: 80px;

  .feature-background {
    position: absolute;
    right: 0;
    max-height: $screen-xs;
  }
}

.features-column {
  padding-left: 0;
  max-width: 320px;
  margin-top: 57px;
  text-align: left;

  .column-content,
  .column-icon {
    display: table-cell;
    vertical-align: top;
  }

  .column-icon {
    position: relative;
    padding-right: 20px;
  }

  .column-title {
    font-size: 20px;
    font-weight: bold;
    color: $grey-dark;
  }

  .column-content {
    width: 266px;
    font-size: 16px;
    line-height: 1.5;
    color: $grey-medium;
  }
}

.page-prefooter {
  margin-top: 0; // gracefully simulate overlap effect on page
}

.safe-no-header { // Only to be used in Custom Landing pages with Custom Header
  position: relative;
  height: 60px;
}

.homepage-hero {
  color: $white !important;

  @include breakpoint ($screen-sm) {
    min-height: 368px;
  }

  @include breakpoint ($screen-md) {
    min-height: 500px;
  }

  .title {
    font-size: 48px !important;

    @include breakpoint ($screen-sm) {
      font-size: 50px !important;
    }

    @include breakpoint ($screen-md) {
      font-size: 65px !important;
    }
  }

  .subtitle {
    font-size: 16px !important;

    @include breakpoint ($screen-sm) {
      font-size: 20px !important;
    }

    @include breakpoint ($screen-md) {
      font-size: 24px !important;
    }
  }
}
