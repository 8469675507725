/*
Page Prefooter
*/

.page-prefooter {
  position: relative;
  padding-top: 66px;
  padding-bottom: 66px;
  margin-top: 40px;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;

  img {
    position: absolute;
    bottom: 0;
    height: 220px;
    display: none;

    @include breakpoint($screen-sm) {
      display: block;
    }
  }

  .msg {
    display: none;
    text-align: left;
    width: 200px;
    margin-left: 30px;
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    color: $grey-dark;

    @include breakpoint ($screen-sm) {
      display: inline-block;
    }

    &.msg-mobile {
      display: block;
      position: relative;
      width: 200px;
      text-align: center;
      margin: 0 auto;
      font-size: 16px;
      line-height: 1;

      @include breakpoint ($screen-sm) {
        display: none;
      }
    }
  }
}
