// // Flexible navbar stuff
.-header {
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);

  .navigation {
    display: flex;
    align-items: center;
    flex: 1 0 0;

    .navigation-actions {
      display: flex;
      align-items: center;
    }

    .search {
      display: flex;
      align-items: center;
      flex: 1 0 0;
      padding: 0 12px;

      .toggle-genbar {
        margin-left: 12px;
        display: none;
      }
    }
  }

  .branding {
    flex: 0 0 0;
    text-align: center;
  }

  .user-actions {
    flex: 0 0 0;
    text-align: right;

    @include breakpoint($screen-sm) {
      flex: 1 0 0;
    }
  }
}

.-header {
  position: relative;

  .icon {
    fill: rgba(256, 256, 256, 0.9) !important;
  }

  .header-logo {
    .icon {
      height: 20px;
      width: 110px;
      margin-top: -6px;
      fill: rgba(256, 256, 256, 0.5) !important;
    }

    &:hover,
    &:active,
    &:focus {
      .icon {
        fill: rgba(256, 256, 256, 0.8) !important;
      }
    }
  }

  &._genbar-open_ {
    .navigation-actions,
    .user-actions {
      @include breakpoint($screen-sm - 1, true) {
        display: none;
      }
    }

    .branding {
      @include breakpoint($screen-md - 1, true) {
        display: none;
      }
    }

    .user-actions {
      @include breakpoint($screen-md - 1, true) {
        flex: 0 0 0;
      }
    }

    .search {
      @include breakpoint($screen-sm - 1, true) {
        padding: 0;
      }

      .toggle-genbar {
        @include breakpoint($screen-sm - 1, true) {
          display: inline-flex;
        }
      }
    }

    .generation-bar {
      max-width: none;  // ignore styleguide 640 width

      @include breakpoint($screen-sm) {
        max-width: 640px;
      }

      .input-group {
        input {
          padding-left: 34px;
          background-color: rgba(256, 256, 256, 0.9) !important;

          &:hover {
            background-color: rgba(256, 256, 256, 0.9) !important;
          }
        }
      }
    }
  }
}

.-header {
  .-circle {
    &:active,
    &:focus,
    &:hover {
      .icon {
        fill: rgba(256, 256, 256, 0.9) !important;
      }

      background-color: rgba($grey-smoke, 0.2) !important;
    }
  }
}

.-header {
  .generation-bar {
    position: relative; // avoid the absolute btn floats when resize
    flex: 1 1 auto;
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

    @include breakpoint ($screen-sm) {
      max-width: 200px;  // smaller on Mobile
      transition: max-width 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .input-group {
      display: inline-flex;

      input {
        font-weight: $font-weight-bold;
        height: 36px;
        background-color: rgba(256, 256, 256, 0.2);
        padding-left: 12px;
        border-radius: 2px !important; // Override border removal by hidden button and button-group

        &:hover {
          background-color: rgba(256, 256, 256, 0.3);
        }

        @include breakpoint ($screen-sm) {
          transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
      }

      .input-group-btn {
        display: none;
      }
    }

    .options-content {
      display: none;
    }
  }

  .dropdown {
    .dropdown-menu {
      margin-top: 10px;
    }
  }
}

.dropdown {
  .dropdown-menu {
    .dropdown-header {
      &.dropdown-item {
        cursor: default;
        font-style: italic;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        max-width: 280px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $grey-medium;
        font-size: $font-size-base - 1;

        &:hover,
        &:focus,
        &:active {
          color: $grey-medium;
        }
      }
    }

    .dropdown-pdf-v2-section {
      padding: 5px 21px;
      width: 240px;

      div {
        cursor: default;
        margin: 0 7px 8px;
        font-size: 12px;
      }

      a {
        font-size: 13px;
        white-space: normal;
      }
    }
  }
}
