// Experts in front

.expert-notification {
  background: $grey-smoke;
  border-radius: 5px;
  margin: 0 auto 12px;
  overflow: hidden;
  padding: 15px 35px 15px 15px;
  position: relative;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: ($zindex-flying-menu - 1);
  }

  .expert-notification-content {
    font-size: $font-size-small;
    line-height: 1.5;
    margin-left: 110px;
  }

  img {
    border-radius: 100px;
    float: left;
  }
}

.expert-notification-modal {
  font-size: $font-size-small;

  .close {
    opacity: 0.8;
    filter: alpha(opacity=80);
    position: absolute;
    top: 25px;
    right: 25px;
    height: 25px;
    width: 25px;
  }

  .icon-test {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 55px;
    height: 55px;
    position: relative;
    left: 14px;
    top: 9px;
  }

  h3 {
    margin-bottom: 27px;
    text-align: center;
    font-weight: $font-weight-normal;
  }

  .modal-backdrop.fade.in {
    z-index: 0;
  }

  .modal-body {
    max-height: 600px;
    padding: 20px 0 0;
  }

  .inner {
    padding: 0 20px;
  }

  .bottom {
    text-align: center;
    border-top: 1px solid #ddd;
    margin-top: 30px;
    padding: 20px;
  }
}
