/*
Dropdown
*/

.dropdown,
.dropup {
  .icon-chevron-down,
  .icon-chevron-up {
    fill: $grey-darker;
    height: 20px;
    width: 20px;
  }

  .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.15s ease-in-out;
    margin: 0;

    &.show {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
    }

    min-width: 200px;
    max-height: none;
    border-radius: $border-radius-base;
    border-color: transparent;

    .dropdown-item {
      color: $grey-darker;
      padding: 8px 20px;
      margin: 1px 0;
      text-decoration: none;
      font-size: $font-size-root + 3;
      transition: all 0.5s linear;

      &:hover,
      &:focus {
        color: $primary-fresh;
        background-color: transparent;

        .badge {
          background-color: $white;
        }
      }

      // Dropdown menu column titles
      &.dropdown-title {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: $font-weight-bold;
        color: $grey-medium;
        pointer-events: none;
      }//
    }
  }
}

.dropdown {
  .dropdown-menu {
    border-radius: 0 0 5px 5px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }
}

.dropup {
  .dropdown-menu {
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }
}

.header {
  .dropdown {
    .dropdown-menu {
      .dropdown-item {
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
