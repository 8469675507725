// Success & erros messages pages

body {
  &.message,
  &.exterror,
  &.forgot-password,
  &.recurly-success {
    background-color: $white;
  }
}

body.forgot-password {
  .forgot-password-form,
  .reset-password-form {
    max-width: 330px;
    padding: 15px 0;
    margin: 0 auto;
  }
}
