/*
Page Header
*/

.page-header {
  border-bottom: none;
  margin: 0;
  padding: 40px 0 10px;

  &.page-header-subtitle {
    h1,
    .h1 {
      padding-bottom: 10px;
    }
  }

  &.page-header-alone {
    text-align: center;
    padding: 80px 0 40px;

    h1,
    .h1 {
      padding: 0;
    }

    a {
      display: table;
      margin: 0 auto;
      padding: 5px 0;
      text-align: center;
    }
  }

  &.page-header-icon {
    display: inline-block;
    text-align: left;
    min-width: 50%;

    .icon {
      height: 90px;
      width: 90px;
      float: left;
      margin-right: 10px;
    }
  }

  h1,
  .h1 {
    color: $grey-darker;
    font-size: 38px;
    line-height: 1.2;
    margin: 0;
    padding: 0 0 20px;
    font-weight: $font-weight-medium;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $grey-medium;
    font-size: 24px;
    font-weight: $font-weight-medium;
    line-height: 1.25;
    margin: 0;
    padding: 0;
  }
}
