.featured-accordion {
  .nav-item {
    display: flex;
    margin-bottom: 15px;
    min-height: 130px;
    cursor: pointer;

    .title {
      color: $white;
      display: flex;
      transition: all 0.2s linear;
      font-size: 20px;

      @include breakpoint($screen-sm) {
        font-size: 22px;
      }
    }

    p.subtitle {
      color: $white;
      font-size: 16px;
    }

    .widget {
      display: flex;
      flex-shrink: 0;
      position: relative;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      width: 80px;
      height: 80px;
      background-color: #63aaed;
      color: $white;
      border-style: solid;
      border-width: 5px;
      border-color: transparent;
      border-radius: 50%;
      font-size: 40px;
    }

    &:not(:last-child) {
      .widget-container {
        &::after {
          content: '';
          display: inline-block;
          height: 100%;
          position: absolute;
          border: 1px solid $grey-smoke;
          border-width: 1px !important;
          min-height: 130px;
          border-color: $white;
          border-style: solid;
        }
      }
    }

    .widget-container {
      position: relative;
      display: block;
      float: left;
      text-align: center;
      margin-right: 42px;
    }

    &.active {
      .widget {
        background-color: $white;
        border-color: #e5f0fc;
        color: $primary-fresh;
      }
    }
  }
}
