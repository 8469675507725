body.sync-popup {
  .ga-profile-list {
    max-height: 250px;
    overflow-y: auto;

    .ga-profile-name {
      text-overflow: ellipsis;
      overflow: hidden;
      box-sizing: border-box;
    }

    .ga-profile-id {
      text-align: right;
      box-sizing: border-box;
    }
  }
}
