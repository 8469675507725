/*
Navbar Flex
*/

$item-link-icon-size: 40px;

.-header {
  transition: background-color 0.35s $animationCurve;

  .icon {
    transition: all 0.35s $animationCurve;
  }

  .generation-bar {
    input {
      border: 0;
    }
  }

  // Reset
  ul {
    padding-left: 0;

    li {
      list-style-type: none;

      a {
        text-decoration: none;
      }
    }
  }

  .-site {
    display: block;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    text-decoration: none;
    border-radius: $item-link-icon-size / 2;
    background-color: transparent;
    padding: 0 12px;
    transition: all 0.5s $animationCurve;
    overflow: hidden;
  }
}

.-logo {
  height: 32px; // Extra pixels from the K in WooRank
  padding: 0 12px;

  .icon {
    height: 20px;
    width: 110px;

    &.icon-woorank-bridgeline,
    &.icon-woorank-by-bridgeline {
      height: 40px;
      width: 140px;
    }
  }
}

.-right {
  justify-content: flex-end;
}

.-left {
  justify-content: flex-start;
}

.-circle {
  width: 40px;
  height: 40px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  transition: all 0.35s $animationCurve;
  padding: 8px;
  border-radius: 50%;
}

.-main {
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .-list {
    list-style: none;
    display: inline-flex;
    flex-flow: row nowrap;
    margin-bottom: 0;

    * {
      transition: all 0.1s $animationCurve;
    }

    .-item {
      line-height: 60px;
      padding: 0 4px;

      .-link {
        display: inline-block;
        padding: 0 8px;
        border-radius: $item-link-icon-size / 2;
        line-height: $item-link-icon-size;

        &.-icon {
          display: inline-block;
          text-align: center;
          width: $item-link-icon-size;
          height: $item-link-icon-size;
          padding: 0;
        }
      }

      .-btn {
        position: relative;
        display: block;
        padding: 0 12px;
        overflow: hidden;
      }
    }
  }
}

.-circle,
.-btn,
.-link {
  color: $grey-darker;

  .icon {
    fill: $grey-darker;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $grey-lighter;
    color: $grey-darker;

    .icon {
      fill: $grey-darker;
    }
  }
}

.-header {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  max-height: 60px;
  transition: all 0.35s $animationCurve, box-shadow $animationCurve 0.25s;
  background-color: $white;
  box-shadow: 0 1px 0 rgba(12, 13, 14, 0.15), 0 0 0 transparent, 0 0 0 transparent, 0 0 0 transparent;
  position: static;
  z-index: $zindex-navbar-fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  height: 60px;
  box-sizing: border-box;

  &.-fixed {
    position: fixed;
    min-width: auto;
    top: 0;
    left: 0;
    right: 0;
  }
}

// Theme color handling
.-header {
  @each $color, $colorValue in $basicColors {
    &.-header-#{$color} {
      background-color: get-variant-color($color, 'darker');

      .-circle,
      .-btn,
      .-link {
        color: $white;

        .icon {
          fill: $white;
        }

        &:hover,
        &:active,
        &:focus {
          @if ($color == 'grey' ) {
            background-color: get-variant-color($color, 'medium');
          }

          @else {
            background-color: get-variant-color($color, 'dark');
          }
        }
      }
    }
  }
}
