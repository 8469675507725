/*
Call to Action
*/

.call-to-action {
  display: block;
  padding: 30px;
  background-color: $white;
  margin: 0 0 30px;
  border-radius: $border-radius-base;
  text-align: center;

  .icon:not(:last-child) {
    margin-bottom: 15px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &.btn {
    white-space: normal;
  }

  .call-to-action-title {
    margin-top: 0;
    padding-top: 0;
  }
}
