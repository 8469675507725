
/*
Button Sizes
*/

.btn-xs,
.btn-group-xs > .btn {
  font-size: $font-size-xs;
}

.btn-lg,
.btn-group-lg > .btn {
  font-size: $font-size-large;
  padding: 15px 25px;
}

.btn {
  &.btn-primary,
  &.btn-success,
  &.btn-info,
  &.btn-warning,
  &.btn-danger {
    &.btn-xs {
      padding: 2px 5px;
    }

    &.btn-sm {
      padding: 6px 10px;
    }

    &.btn-lg {
      padding: 16px 25px;
    }
  }
}
