/*
Tables
*/

.table {
  td {
    input[type="checkbox"] {
      margin-top: 0;
    }
  }

  &.table-borderless {
    td {
      border-top: none;
    }
  }
}

.table-hover > tbody > tr:hover {
  background-color: $primary-smoke;
}

.table-thinhead > thead > tr > th {
  border-bottom-width: 1px;
}
