@charset "UTF-8";
/* Utils assets (Variables, Colors & mixins only) */
@import url(https://fonts.googleapis.com/css?display=swap&family=Open+Sans:300,400,500,600,700,300italic,400italic,500italic,600italic,700italic);
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #2286e6;
  --indigo: #428bca;
  --purple: #836990;
  --pink: #eca9a7;
  --red: #d9534f;
  --orange: #d3860c;
  --yellow: #f89d0e;
  --green: #4cd763;
  --teal: #4fceb9;
  --cyan: #539597;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2286e6;
  --secondary: #6c757d;
  --success: #4cd763;
  --info: #539597;
  --warning: #f89d0e;
  --danger: #d9534f;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 768px;
  --breakpoint-md: 992px;
  --breakpoint-nv: 1100px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1600px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2286e6;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #135fa8;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #eca9a7;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-nv {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 768px) {
  .container, .container-sm {
    max-width: 768px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md {
    max-width: 992px; } }

@media (min-width: 1100px) {
  .container, .container-sm, .container-md, .container-nv {
    max-width: 1050px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-nv-1, .col-nv-2, .col-nv-3, .col-nv-4, .col-nv-5, .col-nv-6, .col-nv-7, .col-nv-8, .col-nv-9, .col-nv-10, .col-nv-11, .col-nv-12, .col-nv,
.col-nv-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1100px) {
  .col-nv {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-nv-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-nv-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-nv-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-nv-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-nv-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-nv-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-nv-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-nv-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-nv-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-nv-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-nv-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-nv-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-nv-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-nv-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-nv-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-nv-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-nv-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-nv-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-nv-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-nv-first {
    order: -1; }
  .order-nv-last {
    order: 13; }
  .order-nv-0 {
    order: 0; }
  .order-nv-1 {
    order: 1; }
  .order-nv-2 {
    order: 2; }
  .order-nv-3 {
    order: 3; }
  .order-nv-4 {
    order: 4; }
  .order-nv-5 {
    order: 5; }
  .order-nv-6 {
    order: 6; }
  .order-nv-7 {
    order: 7; }
  .order-nv-8 {
    order: 8; }
  .order-nv-9 {
    order: 9; }
  .order-nv-10 {
    order: 10; }
  .order-nv-11 {
    order: 11; }
  .order-nv-12 {
    order: 12; }
  .offset-nv-0 {
    margin-left: 0; }
  .offset-nv-1 {
    margin-left: 8.33333%; }
  .offset-nv-2 {
    margin-left: 16.66667%; }
  .offset-nv-3 {
    margin-left: 25%; }
  .offset-nv-4 {
    margin-left: 33.33333%; }
  .offset-nv-5 {
    margin-left: 41.66667%; }
  .offset-nv-6 {
    margin-left: 50%; }
  .offset-nv-7 {
    margin-left: 58.33333%; }
  .offset-nv-8 {
    margin-left: 66.66667%; }
  .offset-nv-9 {
    margin-left: 75%; }
  .offset-nv-10 {
    margin-left: 83.33333%; }
  .offset-nv-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1ddf8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8cc0f2; }

.table-hover .table-primary:hover {
  background-color: #aad0f5; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #aad0f5; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cdf4d3; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a2eaae; }

.table-hover .table-success:hover {
  background-color: #b8efc1; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b8efc1; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #cfe1e2; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a6c8c9; }

.table-hover .table-info:hover {
  background-color: #bfd7d8; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #bfd7d8; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fde4bc; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fbcc82; }

.table-hover .table-warning:hover {
  background-color: #fcdaa3; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fcdaa3; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4cfce; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eba6a3; }

.table-hover .table-danger:hover {
  background-color: #efbbb9; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #efbbb9; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 767.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1099.98px) {
  .table-responsive-nv {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-nv > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1599.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .generation-bar input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .generation-bar input {
      transition: none; } }
  .form-control::-ms-expand, .generation-bar input::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring, .generation-bar input:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus, .generation-bar input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #95c5f3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 134, 230, 0.25); }
  .form-control::placeholder, .generation-bar input::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .generation-bar input:disabled, .form-control[readonly], .generation-bar input[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control, .generation-bar input[type="date"],
input[type="time"].form-control,
.generation-bar input[type="time"],
input[type="datetime-local"].form-control,
.generation-bar input[type="datetime-local"],
input[type="month"].form-control,
.generation-bar input[type="month"] {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4cd763; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(76, 215, 99, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .generation-bar input:valid, .generation-bar .was-validated input:valid, .form-control.is-valid, .generation-bar input.is-valid {
  border-color: #4cd763;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%234cd763' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .generation-bar input:valid:focus, .generation-bar .was-validated input:valid:focus, .form-control.is-valid:focus, .generation-bar input.is-valid:focus {
    border-color: #4cd763;
    box-shadow: 0 0 0 0.2rem rgba(76, 215, 99, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #4cd763;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%234cd763' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #4cd763;
    box-shadow: 0 0 0 0.2rem rgba(76, 215, 99, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4cd763; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4cd763; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #4cd763; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #76e087;
  background-color: #76e087; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(76, 215, 99, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4cd763; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4cd763; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #4cd763;
  box-shadow: 0 0 0 0.2rem rgba(76, 215, 99, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d9534f; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(217, 83, 79, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .generation-bar input:invalid, .generation-bar .was-validated input:invalid, .form-control.is-invalid, .generation-bar input.is-invalid {
  border-color: #d9534f;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .generation-bar input:invalid:focus, .generation-bar .was-validated input:invalid:focus, .form-control.is-invalid:focus, .generation-bar input.is-invalid:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d9534f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d9534f; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9534f; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d9534f; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e27c79;
  background-color: #e27c79; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d9534f; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9534f; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d9534f;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .generation-bar input, .generation-bar .form-inline input {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 134, 230, 0.25); }
  .btn.disabled, .btn.busy, .btn[busy],
  fieldset[busy] .btn, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]) {
    cursor: pointer; }

a.btn.disabled, a.btn.busy, a.btn[busy],
fieldset[busy] a.btn,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2286e6;
  border-color: #2286e6; }
  .btn-primary:hover {
    color: #fff;
    background-color: #1773cb;
    border-color: #166cbf; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #1773cb;
    border-color: #166cbf;
    box-shadow: 0 0 0 0.2rem rgba(67, 152, 234, 0.5); }
  .btn-primary.disabled, .btn-primary.btn.busy, .btn-primary.btn[busy],
  fieldset[busy] .btn-primary.btn, .btn-primary:disabled {
    color: #fff;
    background-color: #2286e6;
    border-color: #2286e6; }
  .btn-primary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-primary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #166cbf;
    border-color: #1466b4; }
    .btn-primary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-primary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 152, 234, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary.btn.busy, .btn-secondary.btn[busy],
  fieldset[busy] .btn-secondary.btn, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-secondary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-secondary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #212529;
  background-color: #4cd763;
  border-color: #4cd763; }
  .btn-success:hover {
    color: #fff;
    background-color: #2ecf49;
    border-color: #2cc445; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #2ecf49;
    border-color: #2cc445;
    box-shadow: 0 0 0 0.2rem rgba(70, 188, 90, 0.5); }
  .btn-success.disabled, .btn-success.btn.busy, .btn-success.btn[busy],
  fieldset[busy] .btn-success.btn, .btn-success:disabled {
    color: #212529;
    background-color: #4cd763;
    border-color: #4cd763; }
  .btn-success:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-success:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2cc445;
    border-color: #2aba41; }
    .btn-success:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-success:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(70, 188, 90, 0.5); }

.btn-info {
  color: #fff;
  background-color: #539597;
  border-color: #539597; }
  .btn-info:hover {
    color: #fff;
    background-color: #457d7e;
    border-color: #417576; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #457d7e;
    border-color: #417576;
    box-shadow: 0 0 0 0.2rem rgba(109, 165, 167, 0.5); }
  .btn-info.disabled, .btn-info.btn.busy, .btn-info.btn[busy],
  fieldset[busy] .btn-info.btn, .btn-info:disabled {
    color: #fff;
    background-color: #539597;
    border-color: #539597; }
  .btn-info:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-info:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #417576;
    border-color: #3c6c6e; }
    .btn-info:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-info:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(109, 165, 167, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f89d0e;
  border-color: #f89d0e; }
  .btn-warning:hover {
    color: #fff;
    background-color: #d98706;
    border-color: #cd8006; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #d98706;
    border-color: #cd8006;
    box-shadow: 0 0 0 0.2rem rgba(216, 139, 18, 0.5); }
  .btn-warning.disabled, .btn-warning.btn.busy, .btn-warning.btn[busy],
  fieldset[busy] .btn-warning.btn, .btn-warning:disabled {
    color: #212529;
    background-color: #f89d0e;
    border-color: #f89d0e; }
  .btn-warning:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-warning:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cd8006;
    border-color: #c17806; }
    .btn-warning:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-warning:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 139, 18, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f; }
  .btn-danger:hover {
    color: #fff;
    background-color: #d23430;
    border-color: #c9302c; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #d23430;
    border-color: #c9302c;
    box-shadow: 0 0 0 0.2rem rgba(223, 109, 105, 0.5); }
  .btn-danger.disabled, .btn-danger.btn.busy, .btn-danger.btn[busy],
  fieldset[busy] .btn-danger.btn, .btn-danger:disabled {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-danger:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-danger:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    border-color: #bf2e29; }
    .btn-danger:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-danger:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 109, 105, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light.btn.busy, .btn-light.btn[busy],
  fieldset[busy] .btn-light.btn, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-light:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-light:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark.btn.busy, .btn-dark.btn[busy],
  fieldset[busy] .btn-dark.btn, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-dark:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-dark:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #2286e6;
  border-color: #2286e6; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2286e6;
    border-color: #2286e6; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 134, 230, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary.btn.busy, .btn-outline-primary.btn[busy],
  fieldset[busy] .btn-outline-primary.btn, .btn-outline-primary:disabled {
    color: #2286e6;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-outline-primary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2286e6;
    border-color: #2286e6; }
    .btn-outline-primary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-outline-primary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 134, 230, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary.btn.busy, .btn-outline-secondary.btn[busy],
  fieldset[busy] .btn-outline-secondary.btn, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-outline-secondary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #4cd763;
  border-color: #4cd763; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #4cd763;
    border-color: #4cd763; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 215, 99, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success.btn.busy, .btn-outline-success.btn[busy],
  fieldset[busy] .btn-outline-success.btn, .btn-outline-success:disabled {
    color: #4cd763;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-outline-success:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #4cd763;
    border-color: #4cd763; }
    .btn-outline-success:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-outline-success:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 215, 99, 0.5); }

.btn-outline-info {
  color: #539597;
  border-color: #539597; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #539597;
    border-color: #539597; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(83, 149, 151, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info.btn.busy, .btn-outline-info.btn[busy],
  fieldset[busy] .btn-outline-info.btn, .btn-outline-info:disabled {
    color: #539597;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-outline-info:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #539597;
    border-color: #539597; }
    .btn-outline-info:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-outline-info:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(83, 149, 151, 0.5); }

.btn-outline-warning {
  color: #f89d0e;
  border-color: #f89d0e; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f89d0e;
    border-color: #f89d0e; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 157, 14, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning.btn.busy, .btn-outline-warning.btn[busy],
  fieldset[busy] .btn-outline-warning.btn, .btn-outline-warning:disabled {
    color: #f89d0e;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-outline-warning:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f89d0e;
    border-color: #f89d0e; }
    .btn-outline-warning:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-outline-warning:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 157, 14, 0.5); }

.btn-outline-danger {
  color: #d9534f;
  border-color: #d9534f; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger.btn.busy, .btn-outline-danger.btn[busy],
  fieldset[busy] .btn-outline-danger.btn, .btn-outline-danger:disabled {
    color: #d9534f;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-outline-danger:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
    .btn-outline-danger:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-outline-danger:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light.btn.busy, .btn-outline-light.btn[busy],
  fieldset[busy] .btn-outline-light.btn, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-outline-light:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-outline-light:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark.btn.busy, .btn-outline-dark.btn[busy],
  fieldset[busy] .btn-outline-dark.btn, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active, .btn-outline-dark:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):active:focus, .btn-outline-dark:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2286e6;
  text-decoration: none; }
  .btn-link:hover {
    color: #135fa8;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled, .btn-link.btn.busy, .btn-link.btn[busy],
  fieldset[busy] .btn-link.btn {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 768px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1100px) {
  .dropdown-menu-nv-left {
    right: auto;
    left: 0; }
  .dropdown-menu-nv-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1600px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2286e6; }
  .dropdown-item.disabled, .dropdown-item.btn.busy, .dropdown-item.btn[busy],
  fieldset[busy] .dropdown-item.btn, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .generation-bar .input-group > input,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .generation-bar .input-group > input + .form-control, .generation-bar .input-group > .form-control + input, .generation-bar .input-group > input + input,
    .input-group > .form-control + .custom-select,
    .generation-bar .input-group > input + .custom-select,
    .input-group > .form-control + .custom-file,
    .generation-bar .input-group > input + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .generation-bar .input-group > .form-control-plaintext + input,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .generation-bar .input-group > .custom-select + input,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .generation-bar .input-group > .custom-file + input,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .generation-bar .input-group > input:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child), .generation-bar .input-group > input:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child), .generation-bar .input-group:not(.has-validation) > input:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3), .generation-bar .input-group.has-validation > input:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .generation-bar .input-group-lg > input:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .generation-bar .input-group-lg > input,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .generation-bar .input-group-sm > input:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .generation-bar .input-group-sm > input,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2286e6;
    background-color: #2286e6; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(34, 134, 230, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #95c5f3; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #c2def8;
    border-color: #c2def8; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2286e6;
  background-color: #2286e6; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(34, 134, 230, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(34, 134, 230, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(34, 134, 230, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(34, 134, 230, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #95c5f3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 134, 230, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #95c5f3;
    box-shadow: 0 0 0 0.2rem rgba(34, 134, 230, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 134, 230, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 134, 230, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(34, 134, 230, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2286e6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #c2def8; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2286e6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #c2def8; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2286e6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #c2def8; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled, .nav-link.btn.busy, .nav-link.btn[busy],
  fieldset[busy] .nav-link.btn {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link.btn.busy, .nav-tabs .nav-link.btn[busy],
    .nav-tabs fieldset[busy] .nav-link.btn, fieldset[busy] .nav-tabs .nav-link.btn {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2286e6; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-nv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 767.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-nv {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-nv {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-nv {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-nv {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1099.98px) {
  .navbar-expand-nv > .container,
  .navbar-expand-nv > .container-fluid, .navbar-expand-nv > .container-sm, .navbar-expand-nv > .container-md, .navbar-expand-nv > .container-nv {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1100px) {
  .navbar-expand-nv {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-nv .navbar-nav {
      flex-direction: row; }
      .navbar-expand-nv .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-nv .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-nv > .container,
    .navbar-expand-nv > .container-fluid, .navbar-expand-nv > .container-sm, .navbar-expand-nv > .container-md, .navbar-expand-nv > .container-nv {
      flex-wrap: nowrap; }
    .navbar-expand-nv .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-nv .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-nv .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-nv {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-nv {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1599.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-nv {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1600px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-nv {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-nv {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-nv {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled, .navbar-light .navbar-nav .nav-link.btn.busy, .navbar-light .navbar-nav .nav-link.btn[busy],
  .navbar-light .navbar-nav fieldset[busy] .nav-link.btn, fieldset[busy] .navbar-light .navbar-nav .nav-link.btn {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled, .navbar-dark .navbar-nav .nav-link.btn.busy, .navbar-dark .navbar-nav .nav-link.btn[busy],
  .navbar-dark .navbar-nav fieldset[busy] .nav-link.btn, fieldset[busy] .navbar-dark .navbar-nav .nav-link.btn {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 768px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 768px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2286e6;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #135fa8;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 134, 230, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2286e6;
  border-color: #2286e6; }

.page-item.disabled .page-link, .page-item.btn.busy .page-link, .page-item.btn[busy] .page-link, fieldset[busy] .page-item.btn .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #2286e6; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #166cbf; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 134, 230, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #212529;
  background-color: #4cd763; }
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #2cc445; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(76, 215, 99, 0.5); }

.badge-info {
  color: #fff;
  background-color: #539597; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #417576; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(83, 149, 151, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #f89d0e; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cd8006; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 157, 14, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #d9534f; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #c9302c; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 768px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #124678;
  background-color: #d3e7fa;
  border-color: #c1ddf8; }
  .alert-primary hr {
    border-top-color: #aad0f5; }
  .alert-primary .alert-link {
    color: #0b2c4c; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #287033;
  background-color: #dbf7e0;
  border-color: #cdf4d3; }
  .alert-success hr {
    border-top-color: #b8efc1; }
  .alert-success .alert-link {
    color: #1b4a22; }

.alert-info {
  color: #2b4d4f;
  background-color: #ddeaea;
  border-color: #cfe1e2; }
  .alert-info hr {
    border-top-color: #bfd7d8; }
  .alert-info .alert-link {
    color: #192d2e; }

.alert-warning {
  color: #815207;
  background-color: #feebcf;
  border-color: #fde4bc; }
  .alert-warning hr {
    border-top-color: #fcdaa3; }
  .alert-warning .alert-link {
    color: #513304; }

.alert-danger {
  color: #712b29;
  background-color: #f7dddc;
  border-color: #f4cfce; }
  .alert-danger hr {
    border-top-color: #efbbb9; }
  .alert-danger .alert-link {
    color: #4c1d1b; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2286e6;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item.btn.busy, .list-group-item.btn[busy],
  fieldset[busy] .list-group-item.btn, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2286e6;
    border-color: #2286e6; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 768px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1100px) {
  .list-group-horizontal-nv {
    flex-direction: row; }
    .list-group-horizontal-nv > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-nv > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-nv > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-nv > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-nv > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1600px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #124678;
  background-color: #c1ddf8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #124678;
    background-color: #aad0f5; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #124678;
    border-color: #124678; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #287033;
  background-color: #cdf4d3; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #287033;
    background-color: #b8efc1; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #287033;
    border-color: #287033; }

.list-group-item-info {
  color: #2b4d4f;
  background-color: #cfe1e2; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #2b4d4f;
    background-color: #bfd7d8; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #2b4d4f;
    border-color: #2b4d4f; }

.list-group-item-warning {
  color: #815207;
  background-color: #fde4bc; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #815207;
    background-color: #fcdaa3; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #815207;
    border-color: #815207; }

.list-group-item-danger {
  color: #712b29;
  background-color: #f4cfce; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #712b29;
    background-color: #efbbb9; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #712b29;
    border-color: #712b29; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):hover, .close:not(:disabled):not(.disabled):not(.btn.busy):not(.btn[busy]):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled, a.close.btn.busy, a.close.btn[busy],
fieldset[busy] a.close.btn {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1600px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2286e6 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #166cbf !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #4cd763 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2cc445 !important; }

.bg-info {
  background-color: #539597 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #417576 !important; }

.bg-warning {
  background-color: #f89d0e !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cd8006 !important; }

.bg-danger {
  background-color: #d9534f !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c9302c !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2286e6 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #4cd763 !important; }

.border-info {
  border-color: #539597 !important; }

.border-warning {
  border-color: #f89d0e !important; }

.border-danger {
  border-color: #d9534f !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none, .hidden {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1100px) {
  .d-nv-none {
    display: none !important; }
  .d-nv-inline {
    display: inline !important; }
  .d-nv-inline-block {
    display: inline-block !important; }
  .d-nv-block {
    display: block !important; }
  .d-nv-table {
    display: table !important; }
  .d-nv-table-row {
    display: table-row !important; }
  .d-nv-table-cell {
    display: table-cell !important; }
  .d-nv-flex {
    display: flex !important; }
  .d-nv-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1100px) {
  .flex-nv-row {
    flex-direction: row !important; }
  .flex-nv-column {
    flex-direction: column !important; }
  .flex-nv-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-nv-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-nv-wrap {
    flex-wrap: wrap !important; }
  .flex-nv-nowrap {
    flex-wrap: nowrap !important; }
  .flex-nv-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-nv-fill {
    flex: 1 1 auto !important; }
  .flex-nv-grow-0 {
    flex-grow: 0 !important; }
  .flex-nv-grow-1 {
    flex-grow: 1 !important; }
  .flex-nv-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-nv-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-nv-start {
    justify-content: flex-start !important; }
  .justify-content-nv-end {
    justify-content: flex-end !important; }
  .justify-content-nv-center {
    justify-content: center !important; }
  .justify-content-nv-between {
    justify-content: space-between !important; }
  .justify-content-nv-around {
    justify-content: space-around !important; }
  .align-items-nv-start {
    align-items: flex-start !important; }
  .align-items-nv-end {
    align-items: flex-end !important; }
  .align-items-nv-center {
    align-items: center !important; }
  .align-items-nv-baseline {
    align-items: baseline !important; }
  .align-items-nv-stretch {
    align-items: stretch !important; }
  .align-content-nv-start {
    align-content: flex-start !important; }
  .align-content-nv-end {
    align-content: flex-end !important; }
  .align-content-nv-center {
    align-content: center !important; }
  .align-content-nv-between {
    align-content: space-between !important; }
  .align-content-nv-around {
    align-content: space-around !important; }
  .align-content-nv-stretch {
    align-content: stretch !important; }
  .align-self-nv-auto {
    align-self: auto !important; }
  .align-self-nv-start {
    align-self: flex-start !important; }
  .align-self-nv-end {
    align-self: flex-end !important; }
  .align-self-nv-center {
    align-self: center !important; }
  .align-self-nv-baseline {
    align-self: baseline !important; }
  .align-self-nv-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1100px) {
  .float-nv-left {
    float: left !important; }
  .float-nv-right {
    float: right !important; }
  .float-nv-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1100px) {
  .m-nv-0 {
    margin: 0 !important; }
  .mt-nv-0,
  .my-nv-0 {
    margin-top: 0 !important; }
  .mr-nv-0,
  .mx-nv-0 {
    margin-right: 0 !important; }
  .mb-nv-0,
  .my-nv-0 {
    margin-bottom: 0 !important; }
  .ml-nv-0,
  .mx-nv-0 {
    margin-left: 0 !important; }
  .m-nv-1 {
    margin: 0.25rem !important; }
  .mt-nv-1,
  .my-nv-1 {
    margin-top: 0.25rem !important; }
  .mr-nv-1,
  .mx-nv-1 {
    margin-right: 0.25rem !important; }
  .mb-nv-1,
  .my-nv-1 {
    margin-bottom: 0.25rem !important; }
  .ml-nv-1,
  .mx-nv-1 {
    margin-left: 0.25rem !important; }
  .m-nv-2 {
    margin: 0.5rem !important; }
  .mt-nv-2,
  .my-nv-2 {
    margin-top: 0.5rem !important; }
  .mr-nv-2,
  .mx-nv-2 {
    margin-right: 0.5rem !important; }
  .mb-nv-2,
  .my-nv-2 {
    margin-bottom: 0.5rem !important; }
  .ml-nv-2,
  .mx-nv-2 {
    margin-left: 0.5rem !important; }
  .m-nv-3 {
    margin: 1rem !important; }
  .mt-nv-3,
  .my-nv-3 {
    margin-top: 1rem !important; }
  .mr-nv-3,
  .mx-nv-3 {
    margin-right: 1rem !important; }
  .mb-nv-3,
  .my-nv-3 {
    margin-bottom: 1rem !important; }
  .ml-nv-3,
  .mx-nv-3 {
    margin-left: 1rem !important; }
  .m-nv-4 {
    margin: 1.5rem !important; }
  .mt-nv-4,
  .my-nv-4 {
    margin-top: 1.5rem !important; }
  .mr-nv-4,
  .mx-nv-4 {
    margin-right: 1.5rem !important; }
  .mb-nv-4,
  .my-nv-4 {
    margin-bottom: 1.5rem !important; }
  .ml-nv-4,
  .mx-nv-4 {
    margin-left: 1.5rem !important; }
  .m-nv-5 {
    margin: 3rem !important; }
  .mt-nv-5,
  .my-nv-5 {
    margin-top: 3rem !important; }
  .mr-nv-5,
  .mx-nv-5 {
    margin-right: 3rem !important; }
  .mb-nv-5,
  .my-nv-5 {
    margin-bottom: 3rem !important; }
  .ml-nv-5,
  .mx-nv-5 {
    margin-left: 3rem !important; }
  .p-nv-0 {
    padding: 0 !important; }
  .pt-nv-0,
  .py-nv-0 {
    padding-top: 0 !important; }
  .pr-nv-0,
  .px-nv-0 {
    padding-right: 0 !important; }
  .pb-nv-0,
  .py-nv-0 {
    padding-bottom: 0 !important; }
  .pl-nv-0,
  .px-nv-0 {
    padding-left: 0 !important; }
  .p-nv-1 {
    padding: 0.25rem !important; }
  .pt-nv-1,
  .py-nv-1 {
    padding-top: 0.25rem !important; }
  .pr-nv-1,
  .px-nv-1 {
    padding-right: 0.25rem !important; }
  .pb-nv-1,
  .py-nv-1 {
    padding-bottom: 0.25rem !important; }
  .pl-nv-1,
  .px-nv-1 {
    padding-left: 0.25rem !important; }
  .p-nv-2 {
    padding: 0.5rem !important; }
  .pt-nv-2,
  .py-nv-2 {
    padding-top: 0.5rem !important; }
  .pr-nv-2,
  .px-nv-2 {
    padding-right: 0.5rem !important; }
  .pb-nv-2,
  .py-nv-2 {
    padding-bottom: 0.5rem !important; }
  .pl-nv-2,
  .px-nv-2 {
    padding-left: 0.5rem !important; }
  .p-nv-3 {
    padding: 1rem !important; }
  .pt-nv-3,
  .py-nv-3 {
    padding-top: 1rem !important; }
  .pr-nv-3,
  .px-nv-3 {
    padding-right: 1rem !important; }
  .pb-nv-3,
  .py-nv-3 {
    padding-bottom: 1rem !important; }
  .pl-nv-3,
  .px-nv-3 {
    padding-left: 1rem !important; }
  .p-nv-4 {
    padding: 1.5rem !important; }
  .pt-nv-4,
  .py-nv-4 {
    padding-top: 1.5rem !important; }
  .pr-nv-4,
  .px-nv-4 {
    padding-right: 1.5rem !important; }
  .pb-nv-4,
  .py-nv-4 {
    padding-bottom: 1.5rem !important; }
  .pl-nv-4,
  .px-nv-4 {
    padding-left: 1.5rem !important; }
  .p-nv-5 {
    padding: 3rem !important; }
  .pt-nv-5,
  .py-nv-5 {
    padding-top: 3rem !important; }
  .pr-nv-5,
  .px-nv-5 {
    padding-right: 3rem !important; }
  .pb-nv-5,
  .py-nv-5 {
    padding-bottom: 3rem !important; }
  .pl-nv-5,
  .px-nv-5 {
    padding-left: 3rem !important; }
  .m-nv-n1 {
    margin: -0.25rem !important; }
  .mt-nv-n1,
  .my-nv-n1 {
    margin-top: -0.25rem !important; }
  .mr-nv-n1,
  .mx-nv-n1 {
    margin-right: -0.25rem !important; }
  .mb-nv-n1,
  .my-nv-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-nv-n1,
  .mx-nv-n1 {
    margin-left: -0.25rem !important; }
  .m-nv-n2 {
    margin: -0.5rem !important; }
  .mt-nv-n2,
  .my-nv-n2 {
    margin-top: -0.5rem !important; }
  .mr-nv-n2,
  .mx-nv-n2 {
    margin-right: -0.5rem !important; }
  .mb-nv-n2,
  .my-nv-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-nv-n2,
  .mx-nv-n2 {
    margin-left: -0.5rem !important; }
  .m-nv-n3 {
    margin: -1rem !important; }
  .mt-nv-n3,
  .my-nv-n3 {
    margin-top: -1rem !important; }
  .mr-nv-n3,
  .mx-nv-n3 {
    margin-right: -1rem !important; }
  .mb-nv-n3,
  .my-nv-n3 {
    margin-bottom: -1rem !important; }
  .ml-nv-n3,
  .mx-nv-n3 {
    margin-left: -1rem !important; }
  .m-nv-n4 {
    margin: -1.5rem !important; }
  .mt-nv-n4,
  .my-nv-n4 {
    margin-top: -1.5rem !important; }
  .mr-nv-n4,
  .mx-nv-n4 {
    margin-right: -1.5rem !important; }
  .mb-nv-n4,
  .my-nv-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-nv-n4,
  .mx-nv-n4 {
    margin-left: -1.5rem !important; }
  .m-nv-n5 {
    margin: -3rem !important; }
  .mt-nv-n5,
  .my-nv-n5 {
    margin-top: -3rem !important; }
  .mr-nv-n5,
  .mx-nv-n5 {
    margin-right: -3rem !important; }
  .mb-nv-n5,
  .my-nv-n5 {
    margin-bottom: -3rem !important; }
  .ml-nv-n5,
  .mx-nv-n5 {
    margin-left: -3rem !important; }
  .m-nv-auto {
    margin: auto !important; }
  .mt-nv-auto,
  .my-nv-auto {
    margin-top: auto !important; }
  .mr-nv-auto,
  .mx-nv-auto {
    margin-right: auto !important; }
  .mb-nv-auto,
  .my-nv-auto {
    margin-bottom: auto !important; }
  .ml-nv-auto,
  .mx-nv-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1100px) {
  .text-nv-left {
    text-align: left !important; }
  .text-nv-right {
    text-align: right !important; }
  .text-nv-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #2286e6 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #135fa8 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #4cd763 !important; }

a.text-success:hover, a.text-success:focus {
  color: #27af3e !important; }

.text-info {
  color: #539597 !important; }

a.text-info:hover, a.text-info:focus {
  color: #386466 !important; }

.text-warning {
  color: #f89d0e !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b47005 !important; }

.text-danger {
  color: #d9534f !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #b52b27 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1200px !important; }
  .container {
    min-width: 1200px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Percentile modifiers */
/* Colors values */
/* Flat Colors */
/* Colors Links */
/* Colors list */
.color-white {
  color: #fff; }

.fill-white {
  fill: #fff; }

.background-white {
  background-color: #fff; }

.banner-white {
  background-color: #fff; }

.btn-white {
  color: #353b48 !important;
  background-color: #fff !important; }
  .btn-white:hover, .btn-white:active, .btn-white:focus {
    color: #353b48 !important;
    background-color: #f2f2f2 !important; }
  .btn-white .icon {
    fill: #353b48 !important; }

.color-white-important {
  color: #fff !important; }

.fill-white-important {
  fill: #fff !important; }

.background-white-important {
  background-color: #fff !important; }

.btn-white-important {
  color: #fff;
  background-color: #fff; }

.color-black {
  color: #000; }

.fill-black {
  fill: #000; }

.background-black {
  background-color: #000; }

.banner-black {
  background-color: #000; }

.btn-black {
  color: #fff !important;
  background-color: #000 !important; }
  .btn-black:hover, .btn-black:active, .btn-black:focus {
    color: #fff !important;
    background-color: black !important; }
  .btn-black .icon {
    fill: #fff !important; }

.color-black-important {
  color: #000 !important; }

.fill-black-important {
  fill: #000 !important; }

.background-black-important {
  background-color: #000 !important; }

.btn-black-important {
  color: #fff;
  background-color: #000; }

.color-competitor-darker {
  color: #2f5455; }

.fill-competitor-darker {
  fill: #2f5455; }

.background-competitor-darker {
  background-color: #2f5455; }

.banner-competitor-darker {
  background-color: #2f5455; }

.btn-competitor-darker {
  color: #fff !important;
  background-color: #2f5455 !important; }
  .btn-competitor-darker:hover, .btn-competitor-darker:active, .btn-competitor-darker:focus {
    color: #fff !important;
    background-color: #264445 !important; }
  .btn-competitor-darker .icon {
    fill: #fff !important; }

.color-competitor-darker-important {
  color: #2f5455 !important; }

.fill-competitor-darker-important {
  fill: #2f5455 !important; }

.background-competitor-darker-important {
  background-color: #2f5455 !important; }

.btn-competitor-darker-important {
  color: #fff;
  background-color: #2f5455; }

.color-competitor-dark {
  color: #29494a; }

.fill-competitor-dark {
  fill: #29494a; }

.background-competitor-dark {
  background-color: #29494a; }

.banner-competitor-dark {
  background-color: #29494a; }

.btn-competitor-dark {
  color: #fff !important;
  background-color: #29494a !important; }
  .btn-competitor-dark:hover, .btn-competitor-dark:active, .btn-competitor-dark:focus {
    color: #fff !important;
    background-color: #20393a !important; }
  .btn-competitor-dark .icon {
    fill: #fff !important; }

.color-competitor-dark-important {
  color: #29494a !important; }

.fill-competitor-dark-important {
  fill: #29494a !important; }

.background-competitor-dark-important {
  background-color: #29494a !important; }

.btn-competitor-dark-important {
  color: #fff;
  background-color: #29494a; }

.color-competitor {
  color: #539597; }

.fill-competitor {
  fill: #539597; }

.background-competitor {
  background-color: #539597; }

.banner-competitor {
  background-color: #539597; }

.btn-competitor {
  color: #fff !important;
  background-color: #539597 !important; }
  .btn-competitor:hover, .btn-competitor:active, .btn-competitor:focus {
    color: #fff !important;
    background-color: #4a8587 !important; }
  .btn-competitor .icon {
    fill: #fff !important; }

.color-competitor-important {
  color: #539597 !important; }

.fill-competitor-important {
  fill: #539597 !important; }

.background-competitor-important {
  background-color: #539597 !important; }

.btn-competitor-important {
  color: #fff;
  background-color: #539597; }

.color-competitor-light {
  color: #afe5e7; }

.fill-competitor-light {
  fill: #afe5e7; }

.background-competitor-light {
  background-color: #afe5e7; }

.banner-competitor-light {
  background-color: #afe5e7; }

.btn-competitor-light {
  color: #353b48 !important;
  background-color: #afe5e7 !important; }
  .btn-competitor-light:hover, .btn-competitor-light:active, .btn-competitor-light:focus {
    color: #353b48 !important;
    background-color: #9bdfe1 !important; }
  .btn-competitor-light .icon {
    fill: #353b48 !important; }

.color-competitor-light-important {
  color: #afe5e7 !important; }

.fill-competitor-light-important {
  fill: #afe5e7 !important; }

.background-competitor-light-important {
  background-color: #afe5e7 !important; }

.btn-competitor-light-important {
  color: #fff;
  background-color: #afe5e7; }

.color-danger-smoke {
  color: #fbeded; }

.fill-danger-smoke {
  fill: #fbeded; }

.background-danger-smoke {
  background-color: #fbeded; }

.banner-danger-smoke {
  background-color: #fbeded; }

.btn-danger-smoke {
  color: #353b48 !important;
  background-color: #fbeded !important; }
  .btn-danger-smoke:hover, .btn-danger-smoke:active, .btn-danger-smoke:focus {
    color: #353b48 !important;
    background-color: #f6d8d8 !important; }
  .btn-danger-smoke .icon {
    fill: #353b48 !important; }

.color-danger-smoke-important {
  color: #fbeded !important; }

.fill-danger-smoke-important {
  fill: #fbeded !important; }

.background-danger-smoke-important {
  background-color: #fbeded !important; }

.btn-danger-smoke-important {
  color: #fff;
  background-color: #fbeded; }

.color-danger-lighter {
  color: #f6d4d4; }

.fill-danger-lighter {
  fill: #f6d4d4; }

.background-danger-lighter {
  background-color: #f6d4d4; }

.banner-danger-lighter {
  background-color: #f6d4d4; }

.btn-danger-lighter {
  color: #353b48 !important;
  background-color: #f6d4d4 !important; }
  .btn-danger-lighter:hover, .btn-danger-lighter:active, .btn-danger-lighter:focus {
    color: #353b48 !important;
    background-color: #f2bfbf !important; }
  .btn-danger-lighter .icon {
    fill: #353b48 !important; }

.color-danger-lighter-important {
  color: #f6d4d4 !important; }

.fill-danger-lighter-important {
  fill: #f6d4d4 !important; }

.background-danger-lighter-important {
  background-color: #f6d4d4 !important; }

.btn-danger-lighter-important {
  color: #fff;
  background-color: #f6d4d4; }

.color-danger-light {
  color: #eca9a7; }

.fill-danger-light {
  fill: #eca9a7; }

.background-danger-light {
  background-color: #eca9a7; }

.banner-danger-light {
  background-color: #eca9a7; }

.btn-danger-light {
  color: #353b48 !important;
  background-color: #eca9a7 !important; }
  .btn-danger-light:hover, .btn-danger-light:active, .btn-danger-light:focus {
    color: #353b48 !important;
    background-color: #e79592 !important; }
  .btn-danger-light .icon {
    fill: #353b48 !important; }

.color-danger-light-important {
  color: #eca9a7 !important; }

.fill-danger-light-important {
  fill: #eca9a7 !important; }

.background-danger-light-important {
  background-color: #eca9a7 !important; }

.btn-danger-light-important {
  color: #fff;
  background-color: #eca9a7; }

.color-danger {
  color: #d9534f; }

.fill-danger {
  fill: #d9534f; }

.background-danger {
  background-color: #d9534f; }

.banner-danger {
  background-color: #d9534f; }

.btn-danger {
  color: #fff !important;
  background-color: #d9534f !important; }
  .btn-danger:hover, .btn-danger:active, .btn-danger:focus {
    color: #fff !important;
    background-color: #d43f3a !important; }
  .btn-danger .icon {
    fill: #fff !important; }

.color-danger-important {
  color: #d9534f !important; }

.fill-danger-important {
  fill: #d9534f !important; }

.background-danger-important {
  background-color: #d9534f !important; }

.btn-danger-important {
  color: #fff;
  background-color: #d9534f; }

.color-danger-dark {
  color: #b94743; }

.fill-danger-dark {
  fill: #b94743; }

.background-danger-dark {
  background-color: #b94743; }

.banner-danger-dark {
  background-color: #b94743; }

.btn-danger-dark {
  color: #fff !important;
  background-color: #b94743 !important; }
  .btn-danger-dark:hover, .btn-danger-dark:active, .btn-danger-dark:focus {
    color: #fff !important;
    background-color: #a6403c !important; }
  .btn-danger-dark .icon {
    fill: #fff !important; }

.color-danger-dark-important {
  color: #b94743 !important; }

.fill-danger-dark-important {
  fill: #b94743 !important; }

.background-danger-dark-important {
  background-color: #b94743 !important; }

.btn-danger-dark-important {
  color: #fff;
  background-color: #b94743; }

.color-danger-darker {
  color: #a02622; }

.fill-danger-darker {
  fill: #a02622; }

.background-danger-darker {
  background-color: #a02622; }

.banner-danger-darker {
  background-color: #a02622; }

.btn-danger-darker {
  color: #fff !important;
  background-color: #a02622 !important; }
  .btn-danger-darker:hover, .btn-danger-darker:active, .btn-danger-darker:focus {
    color: #fff !important;
    background-color: #8b211e !important; }
  .btn-danger-darker .icon {
    fill: #fff !important; }

.color-danger-darker-important {
  color: #a02622 !important; }

.fill-danger-darker-important {
  fill: #a02622 !important; }

.background-danger-darker-important {
  background-color: #a02622 !important; }

.btn-danger-darker-important {
  color: #fff;
  background-color: #a02622; }

.color-error-smoke {
  color: #fbeded; }

.fill-error-smoke {
  fill: #fbeded; }

.background-error-smoke {
  background-color: #fbeded; }

.banner-error-smoke {
  background-color: #fbeded; }

.btn-error-smoke {
  color: #353b48 !important;
  background-color: #fbeded !important; }
  .btn-error-smoke:hover, .btn-error-smoke:active, .btn-error-smoke:focus {
    color: #353b48 !important;
    background-color: #f6d8d8 !important; }
  .btn-error-smoke .icon {
    fill: #353b48 !important; }

.color-error-smoke-important {
  color: #fbeded !important; }

.fill-error-smoke-important {
  fill: #fbeded !important; }

.background-error-smoke-important {
  background-color: #fbeded !important; }

.btn-error-smoke-important {
  color: #fff;
  background-color: #fbeded; }

.color-error-lighter {
  color: #f6d4d4; }

.fill-error-lighter {
  fill: #f6d4d4; }

.background-error-lighter {
  background-color: #f6d4d4; }

.banner-error-lighter {
  background-color: #f6d4d4; }

.btn-error-lighter {
  color: #353b48 !important;
  background-color: #f6d4d4 !important; }
  .btn-error-lighter:hover, .btn-error-lighter:active, .btn-error-lighter:focus {
    color: #353b48 !important;
    background-color: #f2bfbf !important; }
  .btn-error-lighter .icon {
    fill: #353b48 !important; }

.color-error-lighter-important {
  color: #f6d4d4 !important; }

.fill-error-lighter-important {
  fill: #f6d4d4 !important; }

.background-error-lighter-important {
  background-color: #f6d4d4 !important; }

.btn-error-lighter-important {
  color: #fff;
  background-color: #f6d4d4; }

.color-error-light {
  color: #eca9a7; }

.fill-error-light {
  fill: #eca9a7; }

.background-error-light {
  background-color: #eca9a7; }

.banner-error-light {
  background-color: #eca9a7; }

.btn-error-light {
  color: #353b48 !important;
  background-color: #eca9a7 !important; }
  .btn-error-light:hover, .btn-error-light:active, .btn-error-light:focus {
    color: #353b48 !important;
    background-color: #e79592 !important; }
  .btn-error-light .icon {
    fill: #353b48 !important; }

.color-error-light-important {
  color: #eca9a7 !important; }

.fill-error-light-important {
  fill: #eca9a7 !important; }

.background-error-light-important {
  background-color: #eca9a7 !important; }

.btn-error-light-important {
  color: #fff;
  background-color: #eca9a7; }

.color-error {
  color: #d9534f; }

.fill-error {
  fill: #d9534f; }

.background-error {
  background-color: #d9534f; }

.banner-error {
  background-color: #d9534f; }

.btn-error {
  color: #fff !important;
  background-color: #d9534f !important; }
  .btn-error:hover, .btn-error:active, .btn-error:focus {
    color: #fff !important;
    background-color: #d43f3a !important; }
  .btn-error .icon {
    fill: #fff !important; }

.color-error-important {
  color: #d9534f !important; }

.fill-error-important {
  fill: #d9534f !important; }

.background-error-important {
  background-color: #d9534f !important; }

.btn-error-important {
  color: #fff;
  background-color: #d9534f; }

.color-error-dark {
  color: #b94743; }

.fill-error-dark {
  fill: #b94743; }

.background-error-dark {
  background-color: #b94743; }

.banner-error-dark {
  background-color: #b94743; }

.btn-error-dark {
  color: #fff !important;
  background-color: #b94743 !important; }
  .btn-error-dark:hover, .btn-error-dark:active, .btn-error-dark:focus {
    color: #fff !important;
    background-color: #a6403c !important; }
  .btn-error-dark .icon {
    fill: #fff !important; }

.color-error-dark-important {
  color: #b94743 !important; }

.fill-error-dark-important {
  fill: #b94743 !important; }

.background-error-dark-important {
  background-color: #b94743 !important; }

.btn-error-dark-important {
  color: #fff;
  background-color: #b94743; }

.color-error-darker {
  color: #a02622; }

.fill-error-darker {
  fill: #a02622; }

.background-error-darker {
  background-color: #a02622; }

.banner-error-darker {
  background-color: #a02622; }

.btn-error-darker {
  color: #fff !important;
  background-color: #a02622 !important; }
  .btn-error-darker:hover, .btn-error-darker:active, .btn-error-darker:focus {
    color: #fff !important;
    background-color: #8b211e !important; }
  .btn-error-darker .icon {
    fill: #fff !important; }

.color-error-darker-important {
  color: #a02622 !important; }

.fill-error-darker-important {
  fill: #a02622 !important; }

.background-error-darker-important {
  background-color: #a02622 !important; }

.btn-error-darker-important {
  color: #fff;
  background-color: #a02622; }

.color-warning-smoke {
  color: #fef5e6; }

.fill-warning-smoke {
  fill: #fef5e6; }

.background-warning-smoke {
  background-color: #fef5e6; }

.banner-warning-smoke {
  background-color: #fef5e6; }

.btn-warning-smoke {
  color: #353b48 !important;
  background-color: #fef5e6 !important; }
  .btn-warning-smoke:hover, .btn-warning-smoke:active, .btn-warning-smoke:focus {
    color: #353b48 !important;
    background-color: #fdebcd !important; }
  .btn-warning-smoke .icon {
    fill: #353b48 !important; }

.color-warning-smoke-important {
  color: #fef5e6 !important; }

.fill-warning-smoke-important {
  fill: #fef5e6 !important; }

.background-warning-smoke-important {
  background-color: #fef5e6 !important; }

.btn-warning-smoke-important {
  color: #fff;
  background-color: #fef5e6; }

.color-warning-lighter {
  color: #fee6c8; }

.fill-warning-lighter {
  fill: #fee6c8; }

.background-warning-lighter {
  background-color: #fee6c8; }

.banner-warning-lighter {
  background-color: #fee6c8; }

.btn-warning-lighter {
  color: #353b48 !important;
  background-color: #fee6c8 !important; }
  .btn-warning-lighter:hover, .btn-warning-lighter:active, .btn-warning-lighter:focus {
    color: #353b48 !important;
    background-color: #fedbaf !important; }
  .btn-warning-lighter .icon {
    fill: #353b48 !important; }

.color-warning-lighter-important {
  color: #fee6c8 !important; }

.fill-warning-lighter-important {
  fill: #fee6c8 !important; }

.background-warning-lighter-important {
  background-color: #fee6c8 !important; }

.btn-warning-lighter-important {
  color: #fff;
  background-color: #fee6c8; }

.color-warning-light {
  color: #fbce86; }

.fill-warning-light {
  fill: #fbce86; }

.background-warning-light {
  background-color: #fbce86; }

.banner-warning-light {
  background-color: #fbce86; }

.btn-warning-light {
  color: #353b48 !important;
  background-color: #fbce86 !important; }
  .btn-warning-light:hover, .btn-warning-light:active, .btn-warning-light:focus {
    color: #353b48 !important;
    background-color: #fac46d !important; }
  .btn-warning-light .icon {
    fill: #353b48 !important; }

.color-warning-light-important {
  color: #fbce86 !important; }

.fill-warning-light-important {
  fill: #fbce86 !important; }

.background-warning-light-important {
  background-color: #fbce86 !important; }

.btn-warning-light-important {
  color: #fff;
  background-color: #fbce86; }

.color-warning {
  color: #f89d0e; }

.fill-warning {
  fill: #f89d0e; }

.background-warning {
  background-color: #f89d0e; }

.banner-warning {
  background-color: #f89d0e; }

.btn-warning {
  color: #fff !important;
  background-color: #f89d0e !important; }
  .btn-warning:hover, .btn-warning:active, .btn-warning:focus {
    color: #fff !important;
    background-color: #e68f07 !important; }
  .btn-warning .icon {
    fill: #fff !important; }

.color-warning-important {
  color: #f89d0e !important; }

.fill-warning-important {
  fill: #f89d0e !important; }

.background-warning-important {
  background-color: #f89d0e !important; }

.btn-warning-important {
  color: #fff;
  background-color: #f89d0e; }

.color-warning-dark {
  color: #d3860c; }

.fill-warning-dark {
  fill: #d3860c; }

.background-warning-dark {
  background-color: #d3860c; }

.banner-warning-dark {
  background-color: #d3860c; }

.btn-warning-dark {
  color: #fff !important;
  background-color: #d3860c !important; }
  .btn-warning-dark:hover, .btn-warning-dark:active, .btn-warning-dark:focus {
    color: #fff !important;
    background-color: #bb770b !important; }
  .btn-warning-dark .icon {
    fill: #fff !important; }

.color-warning-dark-important {
  color: #d3860c !important; }

.fill-warning-dark-important {
  fill: #d3860c !important; }

.background-warning-dark-important {
  background-color: #d3860c !important; }

.btn-warning-dark-important {
  color: #fff;
  background-color: #d3860c; }

.color-warning-darker {
  color: #9b6105; }

.fill-warning-darker {
  fill: #9b6105; }

.background-warning-darker {
  background-color: #9b6105; }

.banner-warning-darker {
  background-color: #9b6105; }

.btn-warning-darker {
  color: #fff !important;
  background-color: #9b6105 !important; }
  .btn-warning-darker:hover, .btn-warning-darker:active, .btn-warning-darker:focus {
    color: #fff !important;
    background-color: #835104 !important; }
  .btn-warning-darker .icon {
    fill: #fff !important; }

.color-warning-darker-important {
  color: #9b6105 !important; }

.fill-warning-darker-important {
  fill: #9b6105 !important; }

.background-warning-darker-important {
  background-color: #9b6105 !important; }

.btn-warning-darker-important {
  color: #fff;
  background-color: #9b6105; }

.color-success-smoke {
  color: #eef8ee; }

.fill-success-smoke {
  fill: #eef8ee; }

.background-success-smoke {
  background-color: #eef8ee; }

.banner-success-smoke {
  background-color: #eef8ee; }

.btn-success-smoke {
  color: #353b48 !important;
  background-color: #eef8ee !important; }
  .btn-success-smoke:hover, .btn-success-smoke:active, .btn-success-smoke:focus {
    color: #353b48 !important;
    background-color: #dcf1dc !important; }
  .btn-success-smoke .icon {
    fill: #353b48 !important; }

.color-success-smoke-important {
  color: #eef8ee !important; }

.fill-success-smoke-important {
  fill: #eef8ee !important; }

.background-success-smoke-important {
  background-color: #eef8ee !important; }

.btn-success-smoke-important {
  color: #fff;
  background-color: #eef8ee; }

.color-success-lighter {
  color: #d7edd6; }

.fill-success-lighter {
  fill: #d7edd6; }

.background-success-lighter {
  background-color: #d7edd6; }

.banner-success-lighter {
  background-color: #d7edd6; }

.btn-success-lighter {
  color: #353b48 !important;
  background-color: #d7edd6 !important; }
  .btn-success-lighter:hover, .btn-success-lighter:active, .btn-success-lighter:focus {
    color: #353b48 !important;
    background-color: #c6e5c4 !important; }
  .btn-success-lighter .icon {
    fill: #353b48 !important; }

.color-success-lighter-important {
  color: #d7edd6 !important; }

.fill-success-lighter-important {
  fill: #d7edd6 !important; }

.background-success-lighter-important {
  background-color: #d7edd6 !important; }

.btn-success-lighter-important {
  color: #fff;
  background-color: #d7edd6; }

.color-success-light {
  color: #4cd763; }

.fill-success-light {
  fill: #4cd763; }

.background-success-light {
  background-color: #4cd763; }

.banner-success-light {
  background-color: #4cd763; }

.btn-success-light {
  color: #353b48 !important;
  background-color: #4cd763 !important; }
  .btn-success-light:hover, .btn-success-light:active, .btn-success-light:focus {
    color: #353b48 !important;
    background-color: #37d251 !important; }
  .btn-success-light .icon {
    fill: #353b48 !important; }

.color-success-light-important {
  color: #4cd763 !important; }

.fill-success-light-important {
  fill: #4cd763 !important; }

.background-success-light-important {
  background-color: #4cd763 !important; }

.btn-success-light-important {
  color: #fff;
  background-color: #4cd763; }

.color-success {
  color: #00c622; }

.fill-success {
  fill: #00c622; }

.background-success {
  background-color: #00c622; }

.banner-success {
  background-color: #00c622; }

.btn-success {
  color: #fff !important;
  background-color: #00c622 !important; }
  .btn-success:hover, .btn-success:active, .btn-success:focus {
    color: #fff !important;
    background-color: #00ad1e !important; }
  .btn-success .icon {
    fill: #fff !important; }

.color-success-important {
  color: #00c622 !important; }

.fill-success-important {
  fill: #00c622 !important; }

.background-success-important {
  background-color: #00c622 !important; }

.btn-success-important {
  color: #fff;
  background-color: #00c622; }

.color-success-dark {
  color: #4e9d4e; }

.fill-success-dark {
  fill: #4e9d4e; }

.background-success-dark {
  background-color: #4e9d4e; }

.banner-success-dark {
  background-color: #4e9d4e; }

.btn-success-dark {
  color: #fff !important;
  background-color: #4e9d4e !important; }
  .btn-success-dark:hover, .btn-success-dark:active, .btn-success-dark:focus {
    color: #fff !important;
    background-color: #468c46 !important; }
  .btn-success-dark .icon {
    fill: #fff !important; }

.color-success-dark-important {
  color: #4e9d4e !important; }

.fill-success-dark-important {
  fill: #4e9d4e !important; }

.background-success-dark-important {
  background-color: #4e9d4e !important; }

.btn-success-dark-important {
  color: #fff;
  background-color: #4e9d4e; }

.color-success-darker {
  color: #357935; }

.fill-success-darker {
  fill: #357935; }

.background-success-darker {
  background-color: #357935; }

.banner-success-darker {
  background-color: #357935; }

.btn-success-darker {
  color: #fff !important;
  background-color: #357935 !important; }
  .btn-success-darker:hover, .btn-success-darker:active, .btn-success-darker:focus {
    color: #fff !important;
    background-color: #2d672d !important; }
  .btn-success-darker .icon {
    fill: #fff !important; }

.color-success-darker-important {
  color: #357935 !important; }

.fill-success-darker-important {
  fill: #357935 !important; }

.background-success-darker-important {
  background-color: #357935 !important; }

.btn-success-darker-important {
  color: #fff;
  background-color: #357935; }

.color-primary-smoke {
  color: #ecf3fa; }

.fill-primary-smoke {
  fill: #ecf3fa; }

.background-primary-smoke {
  background-color: #ecf3fa; }

.banner-primary-smoke {
  background-color: #ecf3fa; }

.btn-primary-smoke {
  color: #353b48 !important;
  background-color: #ecf3fa !important; }
  .btn-primary-smoke:hover, .btn-primary-smoke:active, .btn-primary-smoke:focus {
    color: #353b48 !important;
    background-color: #d8e6f5 !important; }
  .btn-primary-smoke .icon {
    fill: #353b48 !important; }

.color-primary-smoke-important {
  color: #ecf3fa !important; }

.fill-primary-smoke-important {
  fill: #ecf3fa !important; }

.background-primary-smoke-important {
  background-color: #ecf3fa !important; }

.btn-primary-smoke-important {
  color: #fff;
  background-color: #ecf3fa; }

.color-primary-lighter {
  color: #cfe2f2; }

.fill-primary-lighter {
  fill: #cfe2f2; }

.background-primary-lighter {
  background-color: #cfe2f2; }

.banner-primary-lighter {
  background-color: #cfe2f2; }

.btn-primary-lighter {
  color: #353b48 !important;
  background-color: #cfe2f2 !important; }
  .btn-primary-lighter:hover, .btn-primary-lighter:active, .btn-primary-lighter:focus {
    color: #353b48 !important;
    background-color: #bbd6ed !important; }
  .btn-primary-lighter .icon {
    fill: #353b48 !important; }

.color-primary-lighter-important {
  color: #cfe2f2 !important; }

.fill-primary-lighter-important {
  fill: #cfe2f2 !important; }

.background-primary-lighter-important {
  background-color: #cfe2f2 !important; }

.btn-primary-lighter-important {
  color: #fff;
  background-color: #cfe2f2; }

.color-primary-light {
  color: #a0c5e4; }

.fill-primary-light {
  fill: #a0c5e4; }

.background-primary-light {
  background-color: #a0c5e4; }

.banner-primary-light {
  background-color: #a0c5e4; }

.btn-primary-light {
  color: #353b48 !important;
  background-color: #a0c5e4 !important; }
  .btn-primary-light:hover, .btn-primary-light:active, .btn-primary-light:focus {
    color: #353b48 !important;
    background-color: #8cb9de !important; }
  .btn-primary-light .icon {
    fill: #353b48 !important; }

.color-primary-light-important {
  color: #a0c5e4 !important; }

.fill-primary-light-important {
  fill: #a0c5e4 !important; }

.background-primary-light-important {
  background-color: #a0c5e4 !important; }

.btn-primary-light-important {
  color: #fff;
  background-color: #a0c5e4; }

.color-primary {
  color: #428bca; }

.fill-primary {
  fill: #428bca; }

.background-primary {
  background-color: #428bca; }

.banner-primary {
  background-color: #428bca; }

.btn-primary {
  color: #fff !important;
  background-color: #428bca !important; }
  .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    color: #fff !important;
    background-color: #357ebd !important; }
  .btn-primary .icon {
    fill: #fff !important; }

.color-primary-important {
  color: #428bca !important; }

.fill-primary-important {
  fill: #428bca !important; }

.background-primary-important {
  background-color: #428bca !important; }

.btn-primary-important {
  color: #fff;
  background-color: #428bca; }

.color-primary-dark {
  color: #3876ac; }

.fill-primary-dark {
  fill: #3876ac; }

.background-primary-dark {
  background-color: #3876ac; }

.banner-primary-dark {
  background-color: #3876ac; }

.btn-primary-dark {
  color: #fff !important;
  background-color: #3876ac !important; }
  .btn-primary-dark:hover, .btn-primary-dark:active, .btn-primary-dark:focus {
    color: #fff !important;
    background-color: #326999 !important; }
  .btn-primary-dark .icon {
    fill: #fff !important; }

.color-primary-dark-important {
  color: #3876ac !important; }

.fill-primary-dark-important {
  fill: #3876ac !important; }

.background-primary-dark-important {
  background-color: #3876ac !important; }

.btn-primary-dark-important {
  color: #fff;
  background-color: #3876ac; }

.color-primary-darker {
  color: #2b5c86; }

.fill-primary-darker {
  fill: #2b5c86; }

.background-primary-darker {
  background-color: #2b5c86; }

.banner-primary-darker {
  background-color: #2b5c86; }

.btn-primary-darker {
  color: #fff !important;
  background-color: #2b5c86 !important; }
  .btn-primary-darker:hover, .btn-primary-darker:active, .btn-primary-darker:focus {
    color: #fff !important;
    background-color: #254f73 !important; }
  .btn-primary-darker .icon {
    fill: #fff !important; }

.color-primary-darker-important {
  color: #2b5c86 !important; }

.fill-primary-darker-important {
  fill: #2b5c86 !important; }

.background-primary-darker-important {
  background-color: #2b5c86 !important; }

.btn-primary-darker-important {
  color: #fff;
  background-color: #2b5c86; }

.color-grey-darker {
  color: #353b48; }

.fill-grey-darker {
  fill: #353b48; }

.background-grey-darker {
  background-color: #353b48; }

.banner-grey-darker {
  background-color: #353b48; }

.btn-grey-darker {
  color: #fff !important;
  background-color: #353b48 !important; }
  .btn-grey-darker:hover, .btn-grey-darker:active, .btn-grey-darker:focus {
    color: #fff !important;
    background-color: #2a2f39 !important; }
  .btn-grey-darker .icon {
    fill: #fff !important; }

.color-grey-darker-important {
  color: #353b48 !important; }

.fill-grey-darker-important {
  fill: #353b48 !important; }

.background-grey-darker-important {
  background-color: #353b48 !important; }

.btn-grey-darker-important {
  color: #fff;
  background-color: #353b48; }

.color-grey-dark {
  color: #434a54; }

.fill-grey-dark {
  fill: #434a54; }

.background-grey-dark {
  background-color: #434a54; }

.banner-grey-dark {
  background-color: #434a54; }

.btn-grey-dark {
  color: #fff !important;
  background-color: #434a54 !important; }
  .btn-grey-dark:hover, .btn-grey-dark:active, .btn-grey-dark:focus {
    color: #fff !important;
    background-color: #383e46 !important; }
  .btn-grey-dark .icon {
    fill: #fff !important; }

.color-grey-dark-important {
  color: #434a54 !important; }

.fill-grey-dark-important {
  fill: #434a54 !important; }

.background-grey-dark-important {
  background-color: #434a54 !important; }

.btn-grey-dark-important {
  color: #fff;
  background-color: #434a54; }

.color-grey-medium {
  color: #656d78; }

.fill-grey-medium {
  fill: #656d78; }

.background-grey-medium {
  background-color: #656d78; }

.banner-grey-medium {
  background-color: #656d78; }

.btn-grey-medium {
  color: #fff !important;
  background-color: #656d78 !important; }
  .btn-grey-medium:hover, .btn-grey-medium:active, .btn-grey-medium:focus {
    color: #fff !important;
    background-color: #59606a !important; }
  .btn-grey-medium .icon {
    fill: #fff !important; }

.color-grey-medium-important {
  color: #656d78 !important; }

.fill-grey-medium-important {
  fill: #656d78 !important; }

.background-grey-medium-important {
  background-color: #656d78 !important; }

.btn-grey-medium-important {
  color: #fff;
  background-color: #656d78; }

.color-grey {
  color: #aab2bd; }

.fill-grey {
  fill: #aab2bd; }

.background-grey {
  background-color: #aab2bd; }

.banner-grey {
  background-color: #aab2bd; }

.btn-grey {
  color: #fff !important;
  background-color: #aab2bd !important; }
  .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
    color: #fff !important;
    background-color: #9ca5b2 !important; }
  .btn-grey .icon {
    fill: #fff !important; }

.color-grey-important {
  color: #aab2bd !important; }

.fill-grey-important {
  fill: #aab2bd !important; }

.background-grey-important {
  background-color: #aab2bd !important; }

.btn-grey-important {
  color: #fff;
  background-color: #aab2bd; }

.color-grey-light {
  color: #ccd1d9; }

.fill-grey-light {
  fill: #ccd1d9; }

.background-grey-light {
  background-color: #ccd1d9; }

.banner-grey-light {
  background-color: #ccd1d9; }

.btn-grey-light {
  color: #353b48 !important;
  background-color: #ccd1d9 !important; }
  .btn-grey-light:hover, .btn-grey-light:active, .btn-grey-light:focus {
    color: #353b48 !important;
    background-color: #bdc4ce !important; }
  .btn-grey-light .icon {
    fill: #353b48 !important; }

.color-grey-light-important {
  color: #ccd1d9 !important; }

.fill-grey-light-important {
  fill: #ccd1d9 !important; }

.background-grey-light-important {
  background-color: #ccd1d9 !important; }

.btn-grey-light-important {
  color: #fff;
  background-color: #ccd1d9; }

.color-grey-lighter {
  color: #e6e9ed; }

.fill-grey-lighter {
  fill: #e6e9ed; }

.background-grey-lighter {
  background-color: #e6e9ed; }

.banner-grey-lighter {
  background-color: #e6e9ed; }

.btn-grey-lighter {
  color: #353b48 !important;
  background-color: #e6e9ed !important; }
  .btn-grey-lighter:hover, .btn-grey-lighter:active, .btn-grey-lighter:focus {
    color: #353b48 !important;
    background-color: #d7dce2 !important; }
  .btn-grey-lighter .icon {
    fill: #353b48 !important; }

.color-grey-lighter-important {
  color: #e6e9ed !important; }

.fill-grey-lighter-important {
  fill: #e6e9ed !important; }

.background-grey-lighter-important {
  background-color: #e6e9ed !important; }

.btn-grey-lighter-important {
  color: #fff;
  background-color: #e6e9ed; }

.color-grey-smoke {
  color: #f5f7fa; }

.fill-grey-smoke {
  fill: #f5f7fa; }

.background-grey-smoke {
  background-color: #f5f7fa; }

.banner-grey-smoke {
  background-color: #f5f7fa; }

.btn-grey-smoke {
  color: #353b48 !important;
  background-color: #f5f7fa !important; }
  .btn-grey-smoke:hover, .btn-grey-smoke:active, .btn-grey-smoke:focus {
    color: #353b48 !important;
    background-color: #e4e9f2 !important; }
  .btn-grey-smoke .icon {
    fill: #353b48 !important; }

.color-grey-smoke-important {
  color: #f5f7fa !important; }

.fill-grey-smoke-important {
  fill: #f5f7fa !important; }

.background-grey-smoke-important {
  background-color: #f5f7fa !important; }

.btn-grey-smoke-important {
  color: #fff;
  background-color: #f5f7fa; }

.color-turquoise-light {
  color: #9fe4d9; }

.fill-turquoise-light {
  fill: #9fe4d9; }

.background-turquoise-light {
  background-color: #9fe4d9; }

.banner-turquoise-light {
  background-color: #9fe4d9; }

.btn-turquoise-light {
  color: #353b48 !important;
  background-color: #9fe4d9 !important; }
  .btn-turquoise-light:hover, .btn-turquoise-light:active, .btn-turquoise-light:focus {
    color: #353b48 !important;
    background-color: #8bdfd1 !important; }
  .btn-turquoise-light .icon {
    fill: #353b48 !important; }

.color-turquoise-light-important {
  color: #9fe4d9 !important; }

.fill-turquoise-light-important {
  fill: #9fe4d9 !important; }

.background-turquoise-light-important {
  background-color: #9fe4d9 !important; }

.btn-turquoise-light-important {
  color: #fff;
  background-color: #9fe4d9; }

.color-turquoise-lighter {
  color: #effaf8; }

.fill-turquoise-lighter {
  fill: #effaf8; }

.background-turquoise-lighter {
  background-color: #effaf8; }

.banner-turquoise-lighter {
  background-color: #effaf8; }

.btn-turquoise-lighter {
  color: #353b48 !important;
  background-color: #effaf8 !important; }
  .btn-turquoise-lighter:hover, .btn-turquoise-lighter:active, .btn-turquoise-lighter:focus {
    color: #353b48 !important;
    background-color: #dbf5f1 !important; }
  .btn-turquoise-lighter .icon {
    fill: #353b48 !important; }

.color-turquoise-lighter-important {
  color: #effaf8 !important; }

.fill-turquoise-lighter-important {
  fill: #effaf8 !important; }

.background-turquoise-lighter-important {
  background-color: #effaf8 !important; }

.btn-turquoise-lighter-important {
  color: #fff;
  background-color: #effaf8; }

.color-turquoise {
  color: #4fceb9; }

.fill-turquoise {
  fill: #4fceb9; }

.background-turquoise {
  background-color: #4fceb9; }

.banner-turquoise {
  background-color: #4fceb9; }

.btn-turquoise {
  color: #fff !important;
  background-color: #4fceb9 !important; }
  .btn-turquoise:hover, .btn-turquoise:active, .btn-turquoise:focus {
    color: #fff !important;
    background-color: #3bc8b1 !important; }
  .btn-turquoise .icon {
    fill: #fff !important; }

.color-turquoise-important {
  color: #4fceb9 !important; }

.fill-turquoise-important {
  fill: #4fceb9 !important; }

.background-turquoise-important {
  background-color: #4fceb9 !important; }

.btn-turquoise-important {
  color: #fff;
  background-color: #4fceb9; }

.color-turquoise-dark {
  color: #34ad99; }

.fill-turquoise-dark {
  fill: #34ad99; }

.background-turquoise-dark {
  background-color: #34ad99; }

.banner-turquoise-dark {
  background-color: #34ad99; }

.btn-turquoise-dark {
  color: #fff !important;
  background-color: #34ad99 !important; }
  .btn-turquoise-dark:hover, .btn-turquoise-dark:active, .btn-turquoise-dark:focus {
    color: #fff !important;
    background-color: #2e9988 !important; }
  .btn-turquoise-dark .icon {
    fill: #fff !important; }

.color-turquoise-dark-important {
  color: #34ad99 !important; }

.fill-turquoise-dark-important {
  fill: #34ad99 !important; }

.background-turquoise-dark-important {
  background-color: #34ad99 !important; }

.btn-turquoise-dark-important {
  color: #fff;
  background-color: #34ad99; }

.color-turquoise-darker {
  color: #288f7e; }

.fill-turquoise-darker {
  fill: #288f7e; }

.background-turquoise-darker {
  background-color: #288f7e; }

.banner-turquoise-darker {
  background-color: #288f7e; }

.btn-turquoise-darker {
  color: #fff !important;
  background-color: #288f7e !important; }
  .btn-turquoise-darker:hover, .btn-turquoise-darker:active, .btn-turquoise-darker:focus {
    color: #fff !important;
    background-color: #227b6d !important; }
  .btn-turquoise-darker .icon {
    fill: #fff !important; }

.color-turquoise-darker-important {
  color: #288f7e !important; }

.fill-turquoise-darker-important {
  fill: #288f7e !important; }

.background-turquoise-darker-important {
  background-color: #288f7e !important; }

.btn-turquoise-darker-important {
  color: #fff;
  background-color: #288f7e; }

.color-emerald-light {
  color: #7ee2a8; }

.fill-emerald-light {
  fill: #7ee2a8; }

.background-emerald-light {
  background-color: #7ee2a8; }

.banner-emerald-light {
  background-color: #7ee2a8; }

.btn-emerald-light {
  color: #353b48 !important;
  background-color: #7ee2a8 !important; }
  .btn-emerald-light:hover, .btn-emerald-light:active, .btn-emerald-light:focus {
    color: #353b48 !important;
    background-color: #69dd9a !important; }
  .btn-emerald-light .icon {
    fill: #353b48 !important; }

.color-emerald-light-important {
  color: #7ee2a8 !important; }

.fill-emerald-light-important {
  fill: #7ee2a8 !important; }

.background-emerald-light-important {
  background-color: #7ee2a8 !important; }

.btn-emerald-light-important {
  color: #fff;
  background-color: #7ee2a8; }

.color-emerald-lighter {
  color: #d1f5e0; }

.fill-emerald-lighter {
  fill: #d1f5e0; }

.background-emerald-lighter {
  background-color: #d1f5e0; }

.banner-emerald-lighter {
  background-color: #d1f5e0; }

.btn-emerald-lighter {
  color: #353b48 !important;
  background-color: #d1f5e0 !important; }
  .btn-emerald-lighter:hover, .btn-emerald-lighter:active, .btn-emerald-lighter:focus {
    color: #353b48 !important;
    background-color: #bcf0d2 !important; }
  .btn-emerald-lighter .icon {
    fill: #353b48 !important; }

.color-emerald-lighter-important {
  color: #d1f5e0 !important; }

.fill-emerald-lighter-important {
  fill: #d1f5e0 !important; }

.background-emerald-lighter-important {
  background-color: #d1f5e0 !important; }

.btn-emerald-lighter-important {
  color: #fff;
  background-color: #d1f5e0; }

.color-emerald {
  color: #2ecc71; }

.fill-emerald {
  fill: #2ecc71; }

.background-emerald {
  background-color: #2ecc71; }

.banner-emerald {
  background-color: #2ecc71; }

.btn-emerald {
  color: #fff !important;
  background-color: #2ecc71 !important; }
  .btn-emerald:hover, .btn-emerald:active, .btn-emerald:focus {
    color: #fff !important;
    background-color: #29b765 !important; }
  .btn-emerald .icon {
    fill: #fff !important; }

.color-emerald-important {
  color: #2ecc71 !important; }

.fill-emerald-important {
  fill: #2ecc71 !important; }

.background-emerald-important {
  background-color: #2ecc71 !important; }

.btn-emerald-important {
  color: #fff;
  background-color: #2ecc71; }

.color-emerald-dark {
  color: #27ae60; }

.fill-emerald-dark {
  fill: #27ae60; }

.background-emerald-dark {
  background-color: #27ae60; }

.banner-emerald-dark {
  background-color: #27ae60; }

.btn-emerald-dark {
  color: #fff !important;
  background-color: #27ae60 !important; }
  .btn-emerald-dark:hover, .btn-emerald-dark:active, .btn-emerald-dark:focus {
    color: #fff !important;
    background-color: #229955 !important; }
  .btn-emerald-dark .icon {
    fill: #fff !important; }

.color-emerald-dark-important {
  color: #27ae60 !important; }

.fill-emerald-dark-important {
  fill: #27ae60 !important; }

.background-emerald-dark-important {
  background-color: #27ae60 !important; }

.btn-emerald-dark-important {
  color: #fff;
  background-color: #27ae60; }

.color-emerald-darker {
  color: #1b7943; }

.fill-emerald-darker {
  fill: #1b7943; }

.background-emerald-darker {
  background-color: #1b7943; }

.banner-emerald-darker {
  background-color: #1b7943; }

.btn-emerald-darker {
  color: #fff !important;
  background-color: #1b7943 !important; }
  .btn-emerald-darker:hover, .btn-emerald-darker:active, .btn-emerald-darker:focus {
    color: #fff !important;
    background-color: #176437 !important; }
  .btn-emerald-darker .icon {
    fill: #fff !important; }

.color-emerald-darker-important {
  color: #1b7943 !important; }

.fill-emerald-darker-important {
  fill: #1b7943 !important; }

.background-emerald-darker-important {
  background-color: #1b7943 !important; }

.btn-emerald-darker-important {
  color: #fff;
  background-color: #1b7943; }

.color-amethyst-light {
  color: #c4b3cc; }

.fill-amethyst-light {
  fill: #c4b3cc; }

.background-amethyst-light {
  background-color: #c4b3cc; }

.banner-amethyst-light {
  background-color: #c4b3cc; }

.btn-amethyst-light {
  color: #353b48 !important;
  background-color: #c4b3cc !important; }
  .btn-amethyst-light:hover, .btn-amethyst-light:active, .btn-amethyst-light:focus {
    color: #353b48 !important;
    background-color: #b8a4c2 !important; }
  .btn-amethyst-light .icon {
    fill: #353b48 !important; }

.color-amethyst-light-important {
  color: #c4b3cc !important; }

.fill-amethyst-light-important {
  fill: #c4b3cc !important; }

.background-amethyst-light-important {
  background-color: #c4b3cc !important; }

.btn-amethyst-light-important {
  color: #fff;
  background-color: #c4b3cc; }

.color-amethyst-lighter {
  color: #f3f0f5; }

.fill-amethyst-lighter {
  fill: #f3f0f5; }

.background-amethyst-lighter {
  background-color: #f3f0f5; }

.banner-amethyst-lighter {
  background-color: #f3f0f5; }

.btn-amethyst-lighter {
  color: #353b48 !important;
  background-color: #f3f0f5 !important; }
  .btn-amethyst-lighter:hover, .btn-amethyst-lighter:active, .btn-amethyst-lighter:focus {
    color: #353b48 !important;
    background-color: #e7e1eb !important; }
  .btn-amethyst-lighter .icon {
    fill: #353b48 !important; }

.color-amethyst-lighter-important {
  color: #f3f0f5 !important; }

.fill-amethyst-lighter-important {
  fill: #f3f0f5 !important; }

.background-amethyst-lighter-important {
  background-color: #f3f0f5 !important; }

.btn-amethyst-lighter-important {
  color: #fff;
  background-color: #f3f0f5; }

.color-amethyst {
  color: #9476a3; }

.fill-amethyst {
  fill: #9476a3; }

.background-amethyst {
  background-color: #9476a3; }

.banner-amethyst {
  background-color: #9476a3; }

.btn-amethyst {
  color: #fff !important;
  background-color: #9476a3 !important; }
  .btn-amethyst:hover, .btn-amethyst:active, .btn-amethyst:focus {
    color: #fff !important;
    background-color: #886799 !important; }
  .btn-amethyst .icon {
    fill: #fff !important; }

.color-amethyst-important {
  color: #9476a3 !important; }

.fill-amethyst-important {
  fill: #9476a3 !important; }

.background-amethyst-important {
  background-color: #9476a3 !important; }

.btn-amethyst-important {
  color: #fff;
  background-color: #9476a3; }

.color-amethyst-dark {
  color: #836990; }

.fill-amethyst-dark {
  fill: #836990; }

.background-amethyst-dark {
  background-color: #836990; }

.banner-amethyst-dark {
  background-color: #836990; }

.btn-amethyst-dark {
  color: #fff !important;
  background-color: #836990 !important; }
  .btn-amethyst-dark:hover, .btn-amethyst-dark:active, .btn-amethyst-dark:focus {
    color: #fff !important;
    background-color: #765e81 !important; }
  .btn-amethyst-dark .icon {
    fill: #fff !important; }

.color-amethyst-dark-important {
  color: #836990 !important; }

.fill-amethyst-dark-important {
  fill: #836990 !important; }

.background-amethyst-dark-important {
  background-color: #836990 !important; }

.btn-amethyst-dark-important {
  color: #fff;
  background-color: #836990; }

.color-amethyst-darker {
  color: #5f486b; }

.fill-amethyst-darker {
  fill: #5f486b; }

.background-amethyst-darker {
  background-color: #5f486b; }

.banner-amethyst-darker {
  background-color: #5f486b; }

.btn-amethyst-darker {
  color: #fff !important;
  background-color: #5f486b !important; }
  .btn-amethyst-darker:hover, .btn-amethyst-darker:active, .btn-amethyst-darker:focus {
    color: #fff !important;
    background-color: #523e5c !important; }
  .btn-amethyst-darker .icon {
    fill: #fff !important; }

.color-amethyst-darker-important {
  color: #5f486b !important; }

.fill-amethyst-darker-important {
  fill: #5f486b !important; }

.background-amethyst-darker-important {
  background-color: #5f486b !important; }

.btn-amethyst-darker-important {
  color: #fff;
  background-color: #5f486b; }

.color-primary-fresh {
  color: #2286e6 !important; }

.background-primary-fresh {
  background-color: #2286e6; }

.btn-contained-primary {
  color: #2286e6;
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(24, 94, 161, 0.44);
  border-radius: 5px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-contained-primary:hover, .btn-contained-primary:active, .btn-contained-primary:focus {
    color: #2286e6; }

.btn-outline-primary {
  color: #2286e6;
  background-color: white;
  border-color: #2286e6;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus {
    color: #2286e6;
    background-color: rgba(34, 134, 230, 0.1); }

.btn-inverted-primary {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 8px 50px; }
  .btn-inverted-primary:hover, .btn-inverted-primary:active, .btn-inverted-primary:focus {
    color: #2286e6;
    background-color: #fff; }

.color-grey-fresh {
  color: #434a54 !important; }

.background-grey-fresh {
  background-color: #434a54; }

.btn-contained-grey {
  color: #434a54;
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(24, 94, 161, 0.44);
  border-radius: 5px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-contained-grey:hover, .btn-contained-grey:active, .btn-contained-grey:focus {
    color: #434a54; }

.btn-outline-grey {
  color: #434a54;
  background-color: white;
  border-color: #434a54;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-outline-grey:hover, .btn-outline-grey:active, .btn-outline-grey:focus {
    color: #434a54;
    background-color: rgba(67, 74, 84, 0.1); }

.btn-inverted-grey {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 8px 50px; }
  .btn-inverted-grey:hover, .btn-inverted-grey:active, .btn-inverted-grey:focus {
    color: #434a54;
    background-color: #fff; }

.color-primary-light-fresh {
  color: #e8f3fd !important; }

.background-primary-light-fresh {
  background-color: #e8f3fd; }

.btn-contained-primary-light {
  color: #e8f3fd;
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(24, 94, 161, 0.44);
  border-radius: 5px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-contained-primary-light:hover, .btn-contained-primary-light:active, .btn-contained-primary-light:focus {
    color: #e8f3fd; }

.btn-outline-primary-light {
  color: #e8f3fd;
  background-color: white;
  border-color: #e8f3fd;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-outline-primary-light:hover, .btn-outline-primary-light:active, .btn-outline-primary-light:focus {
    color: #e8f3fd;
    background-color: rgba(232, 243, 253, 0.1); }

.btn-inverted-primary-light {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 8px 50px; }
  .btn-inverted-primary-light:hover, .btn-inverted-primary-light:active, .btn-inverted-primary-light:focus {
    color: #e8f3fd;
    background-color: #fff; }

.color-warning-fresh {
  color: #ffa700 !important; }

.background-warning-fresh {
  background-color: #ffa700; }

.btn-contained-warning {
  color: #ffa700;
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(24, 94, 161, 0.44);
  border-radius: 5px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-contained-warning:hover, .btn-contained-warning:active, .btn-contained-warning:focus {
    color: #ffa700; }

.btn-outline-warning {
  color: #ffa700;
  background-color: white;
  border-color: #ffa700;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-outline-warning:hover, .btn-outline-warning:active, .btn-outline-warning:focus {
    color: #ffa700;
    background-color: rgba(255, 167, 0, 0.1); }

.btn-inverted-warning {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 8px 50px; }
  .btn-inverted-warning:hover, .btn-inverted-warning:active, .btn-inverted-warning:focus {
    color: #ffa700;
    background-color: #fff; }

.color-success-fresh {
  color: #00c622 !important; }

.background-success-fresh {
  background-color: #00c622; }

.btn-contained-success {
  color: #00c622;
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(24, 94, 161, 0.44);
  border-radius: 5px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-contained-success:hover, .btn-contained-success:active, .btn-contained-success:focus {
    color: #00c622; }

.btn-outline-success {
  color: #00c622;
  background-color: white;
  border-color: #00c622;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 10px 50px; }
  .btn-outline-success:hover, .btn-outline-success:active, .btn-outline-success:focus {
    color: #00c622;
    background-color: rgba(0, 198, 34, 0.1); }

.btn-inverted-success {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  padding: 8px 50px; }
  .btn-inverted-success:hover, .btn-inverted-success:active, .btn-inverted-success:focus {
    color: #00c622;
    background-color: #fff; }

/* Animations Utils */
.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }
  .animated.infinite {
    animation-iteration-count: infinite; }
  .animated.bounce {
    animation-name: bounce; }
  .animated.linear {
    animation-timing-function: linear !important; }
  .animated.fadeInUp {
    animation-name: fadeInUp; }
  .animated.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX; }
  .animated.shakingBox {
    animation-name: ShakingBox; }
  .animated.backgroundScrollToRight {
    animation-name: backgroundScrollToRight; }
  .animated.backgroundScrollToLeft {
    animation-name: backgroundScrollToLeft; }

.fadeout {
  animation-iteration-count: 1 !important;
  animation-name: fadeout !important;
  animation-duration: 500ms !important;
  animation-direction: normal !important;
  animation-timing-function: ease !important;
  animation-fill-mode: forwards !important;
  animation-delay: 0s !important; }

@keyframes bounce {
  0% {
    transition-timing-function: cubic-bezier(0.53, 0.97, 0.35, 1);
    transform: translate3d(0, 0, 0) scale(1, 1); }
  40% {
    transition-timing-function: cubic-bezier(0.74, 0.01, 1, 0.13);
    transform: translate3d(0, 15px, 0) scale(1, 0.95); }
  43% {
    transition-timing-function: cubic-bezier(0.74, 0.01, 1, 0.13);
    transform: translate3d(0, 15px, 0) scale(1, 0.95); }
  60% {
    transition-timing-function: cubic-bezier(0.53, 0.97, 0.35, 1);
    transform: translate3d(0, 0, 0) scale(1, 1); }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, 7px, 0) scale(1, 1); }
  85% {
    transition-timing-function: cubic-bezier(0.53, 0.97, 0.35, 1);
    transform: translate3d(0, 0, 0) scale(1, 1); }
  90% {
    transform: translate3d(0, 2px, 0) scale(1, 1); }
  100% {
    transition-timing-function: cubic-bezier(0.53, 0.97, 0.35, 1);
    transform: translate3d(0, 0, 0) scale(1, 1); } }

@keyframes fadeout {
  0% {
    opacity: 1; }
  99% {
    opacity: 0; }
  100% {
    opacity: 0;
    display: none;
    visibility: hidden; } }

.icon-loader {
  animation: spin 1s steps(12) infinite; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }
  .animated.linear {
    animation-timing-function: linear !important; }
  .animated.infinite {
    animation-iteration-count: infinite; }
  .animated.bounce {
    animation-name: bounce; }
  .animated.fadeInUp {
    animation-name: fadeInUp; }
  .animated.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX; }
  .animated.shakingBox {
    animation-name: ShakingBox; }
  .animated.backgroundScrollToRight {
    animation-name: backgroundScrollToRight; }
  .animated.backgroundScrollToLeft {
    animation-name: backgroundScrollToLeft; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes ShakingBox {
  10%,
  90% {
    transform: rotate(5deg) translate3d(-1px, 0, 0); }
  20%,
  80% {
    transform: rotate(-5deg) translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    transform: rotate(5deg) translate3d(-4px, 0, 0); }
  40%,
  60% {
    transform: rotate(-5deg) translate3d(4px, 0, 0); } }

@keyframes bubblesRise {
  100% {
    background-position: 0 -190px; } }

@keyframes backgroundScrollToRight {
  from {
    background-position: 0 0; }
  to {
    background-position: -800px 0; } }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@keyframes backgroundScrollToLeft {
  from {
    background-position: 0 0; }
  to {
    background-position: 800px 0; } }

/* Generics */
html,
body {
  min-height: 100%;
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  transition: transform 0.2s linear;
  font-weight: 400;
  min-width: 320px;
  padding-top: 64px;
  letter-spacing: -0.015em;
  background-color: #edf1f5;
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #353b48;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }
  body svg {
    shape-rendering: geometricPrecision; }
  body.no-scrolls {
    overflow: hidden; }
  body:not(.no-footer) .flex-content {
    flex: 1 0 auto; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    body {
      font-weight: 300; } }

.container.page-narrow {
  max-width: 720px;
  margin: 1px auto 0;
  padding: 40px 15px; }
  @media screen and (min-width: 768px) {
    .container.page-narrow {
      padding: 40px 0; } }

/* Remove Firefox dotted outline */
a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: 0;
  outline: 0; }

label {
  font-weight: 600; }

.checkbox label,
.radio label {
  font-weight: 400;
  cursor: pointer; }

.tooltip {
  z-index: 1050 !important; }
  .tooltip .tooltip-inner {
    max-width: 300px; }
    @media screen and (min-width: 992px) {
      .tooltip .tooltip-inner {
        max-width: 400px; } }

.over-max-list .over-max {
  display: none; }

.over-max-list.open .over-max {
  display: block; }

.show-links-custom,
.show-links {
  text-align: center; }
  .show-links-custom .show-more,
  .show-links-custom .show-less,
  .show-links-custom .fake-show-more,
  .show-links .show-more,
  .show-links .show-less,
  .show-links .fake-show-more {
    color: #656d78;
    border-top: 1px solid #ddd;
    padding: 2px 0; }
    .show-links-custom .show-more:hover,
    .show-links-custom .show-less:hover,
    .show-links-custom .fake-show-more:hover,
    .show-links .show-more:hover,
    .show-links .show-less:hover,
    .show-links .fake-show-more:hover {
      color: #434a54;
      text-decoration: none;
      background-color: #f5f7fa; }
  .show-links-custom .fake-show-more,
  .show-links .fake-show-more {
    border-top: none; }
  .show-links-custom .show-less,
  .show-links .show-less {
    display: none; }
  .show-links-custom .show-more,
  .show-links-custom .fake-show-more,
  .show-links .show-more,
  .show-links .fake-show-more {
    display: block; }
  .show-links-custom.open .show-more,
  .show-links.open .show-more {
    display: none; }
  .show-links-custom.open .show-less,
  .show-links.open .show-less {
    display: block; }

.tooltip {
  z-index: 1050 !important; }
  .tooltip .tooltip-inner {
    max-width: 300px; }
    @media screen and (min-width: 992px) {
      .tooltip .tooltip-inner {
        max-width: 400px; } }

/*
Headings
*/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 500;
  letter-spacing: -0.0415625em; }

h5, .h5 {
  font-size: 16px; }

/*
Ordered List
*/
.ol {
  padding-left: 0;
  margin-left: 20px; }

/*
Unordered List
*/
.ul {
  padding-left: 0; }
  .ul li {
    list-style: none; }
  .ul li::before {
    color: #aab2bd;
    content: "•";
    font-size: 15px;
    padding-right: 30px; }
  .ul .ul,
  .ol .ul {
    margin: 18px 0 18px 30px; }
    .ul .ul li::before,
    .ol .ul li::before {
      content: "◦"; }

.logged ul li,
.logged ol li {
  font-size: 13px; }

/*
Unstyled List
*/
.list-unstyled li::before,
.list-inline li::before {
  padding-right: 0;
  content: ""; }

/* Icons manager: */
.icon {
  background-image: none;
  background-size: contain;
  display: inline-block;
  height: 24px;
  vertical-align: middle;
  width: 24px; }
  .icon.icon-xxs {
    height: 10px;
    width: 10px; }
  .icon.icon-xs {
    height: 13px;
    width: 13px; }
  .icon.icon-sm {
    height: 16px;
    width: 16px; }
  .icon.icon-md {
    height: 20px;
    width: 20px; }
  .icon.icon-lg {
    height: 32px;
    width: 32px; }
  .icon.icon-2x {
    height: 48px;
    width: 48px; }
  .icon.icon-3x {
    height: 64px;
    width: 64px; }
  .icon.icon-4x {
    height: 128px;
    width: 128px; }
  .icon.icon-hidden {
    display: none; }

.icon-text {
  margin-left: 6px;
  vertical-align: middle; }

/* Components manager: */
/*
Accordion
*/
.panel-group .panel-heading {
  padding: 0; }

.panel-group .panel-title a {
  display: block;
  padding: 10px 15px;
  text-decoration: none; }

/*
Alert
*/
.alert {
  position: relative; }
  .alert.alert-competitor {
    border-color: #29494a;
    color: #29494a; }
    .alert.alert-competitor .icon {
      fill: #29494a; }
  .alert.alert-danger {
    background-color: #fbeded;
    border-color: #b94743;
    color: #b94743; }
    .alert.alert-danger .icon {
      fill: #b94743; }
  .alert.alert-error {
    background-color: #fbeded;
    border-color: #b94743;
    color: #b94743; }
    .alert.alert-error .icon {
      fill: #b94743; }
  .alert.alert-warning {
    background-color: #fef5e6;
    border-color: #d3860c;
    color: #d3860c; }
    .alert.alert-warning .icon {
      fill: #d3860c; }
  .alert.alert-success {
    background-color: #eef8ee;
    border-color: #4e9d4e;
    color: #4e9d4e; }
    .alert.alert-success .icon {
      fill: #4e9d4e; }
  .alert.alert-primary {
    background-color: #ecf3fa;
    border-color: #3876ac;
    color: #3876ac; }
    .alert.alert-primary .icon {
      fill: #3876ac; }
  .alert.alert-grey {
    background-color: #f5f7fa;
    border-color: #434a54;
    color: #434a54; }
    .alert.alert-grey .icon {
      fill: #434a54; }
  .alert.alert-turquoise {
    border-color: #34ad99;
    color: #34ad99; }
    .alert.alert-turquoise .icon {
      fill: #34ad99; }
  .alert.alert-emerald {
    border-color: #27ae60;
    color: #27ae60; }
    .alert.alert-emerald .icon {
      fill: #27ae60; }
  .alert.alert-amethyst {
    border-color: #836990;
    color: #836990; }
    .alert.alert-amethyst .icon {
      fill: #836990; }
  .alert:not([class*="alert-"]) {
    background-color: #f5f7fa;
    border-color: #ccd1d9; }
    .alert:not([class*="alert-"]) .icon {
      fill: #353b48; }
  .alert .alert-link {
    color: #000;
    font-weight: 400; }
  .alert .close {
    line-height: 0;
    opacity: 1; }
    .alert .close .icon {
      height: 10px;
      width: 10px; }

/*
Breadcrumb
*/
.badge {
  font-size: 13px;
  font-weight: 400; }
  .badge.badge-light {
    color: #353b48;
    background-color: #f5f7fa; }

/*
Breadcrumb
*/
.breadcrumb {
  font-size: 13px; }
  .breadcrumb li + li::before {
    content: "/";
    color: #aab2bd; }

/*
Business Card
*/
.business-card {
  overflow: hidden;
  font-size: 12px;
  line-height: 18px; }
  .business-card .photo-wrapper {
    width: 65px;
    height: 65px;
    float: left;
    border-radius: 32.5px;
    overflow: hidden;
    margin-right: 15px; }
    .business-card .photo-wrapper.no-photos {
      background-color: #cfe2f2; }
  .business-card .photo {
    display: block;
    width: 100%;
    height: auto; }
  .business-card .name,
  .business-card .address,
  .business-card .locality,
  .business-card .region,
  .business-card .country-name,
  .business-card .link-all {
    display: block;
    padding-right: 5px;
    text-overflow: ellipsis;
    overflow: hidden; }
  .business-card .name,
  .business-card .link-all {
    padding-top: 5px; }

/*
Button Circle
*/
.btn.btn-circle {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid #ccd1d9;
  text-align: center;
  vertical-align: middle;
  line-height: 0;
  font-size: 18px;
  font-weight: 600;
  color: #656d78;
  padding: 0;
  letter-spacing: -0.1em; }
  .btn.btn-circle .icon {
    fill: #656d78;
    height: 10px;
    width: 10px; }
  .btn.btn-circle.active, .btn.btn-circle:active, .btn.btn-circle:focus:active, .btn.btn-circle:focus {
    outline: none;
    box-shadow: none; }

/*
Button Sizes
*/
.btn-xs,
.btn-group-xs > .btn {
  font-size: 11px; }

.btn-lg, .btn-group-lg > .btn,
.btn-group-lg > .btn {
  font-size: 18px;
  padding: 15px 25px; }

.btn.btn-primary.btn-xs, .btn.btn-success.btn-xs, .btn.btn-info.btn-xs, .btn.btn-warning.btn-xs, .btn.btn-danger.btn-xs {
  padding: 2px 5px; }

.btn.btn-primary.btn-sm, .btn-group-sm > .btn.btn-primary, .btn.btn-success.btn-sm, .btn-group-sm > .btn.btn-success, .btn.btn-info.btn-sm, .btn-group-sm > .btn.btn-info, .btn.btn-warning.btn-sm, .btn-group-sm > .btn.btn-warning, .btn.btn-danger.btn-sm, .btn-group-sm > .btn.btn-danger {
  padding: 6px 10px; }

.btn.btn-primary.btn-lg, .btn-group-lg > .btn.btn-primary, .btn.btn-success.btn-lg, .btn-group-lg > .btn.btn-success, .btn.btn-info.btn-lg, .btn-group-lg > .btn.btn-info, .btn.btn-warning.btn-lg, .btn-group-lg > .btn.btn-warning, .btn.btn-danger.btn-lg, .btn-group-lg > .btn.btn-danger {
  padding: 16px 25px; }

/*
Buttons
*/
.btn-container {
  padding-top: 30px;
  overflow: hidden;
  position: relative; }

.btn {
  transition: background 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .btn.btn-primary {
    color: #fff !important;
    background-color: #2286e6 !important;
    font-weight: 600; }
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active {
      background-color: #258df2 !important; }
  .btn.btn-enterprise {
    color: #2286e6 !important;
    background-color: #fff !important;
    border: 1px solid #2286e6 !important;
    font-weight: 600; }
    .btn.btn-enterprise:hover, .btn.btn-enterprise:focus, .btn.btn-enterprise:active {
      background-color: #f5f7fa !important; }
  .btn.btn-success {
    color: #fff !important;
    background-color: #00c622 !important;
    font-weight: 600; }
    .btn.btn-success:hover, .btn.btn-success:focus, .btn.btn-success:active {
      background-color: #4cd763 !important; }
  .btn.btn-warning {
    color: #fff !important;
    background-color: #ffa700 !important;
    font-weight: 600; }
    .btn.btn-warning:hover, .btn.btn-warning:focus, .btn.btn-warning:active {
      background-color: #ffdc99 !important; }

.btn {
  font-weight: 400;
  white-space: normal; }
  .btn.btn-dialog {
    margin: 0 18px; }
  .btn.btn-shadow {
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.3); }
  @media screen and (min-width: 768px) {
    .btn {
      white-space: nowrap; } }
  .btn.disabled, .btn.busy, .btn[busy],
  fieldset[busy] .btn, .btn[disabled],
  fieldset[disabled] .btn {
    pointer-events: none;
    opacity: 0.35; }
  .btn:focus, .btn:hover, .btn:active, .btn.active {
    outline: none;
    box-shadow: none; }
  .btn:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1); }
  .btn.btn-cancel {
    background-color: #fff !important;
    color: #d9534f !important;
    font-weight: 600 !important; }
    .btn.btn-cancel:hover, .btn.btn-cancel:focus, .btn.btn-cancel:active, .btn.btn-cancel.active {
      color: #b94743 !important; }
  .btn.btn-error {
    background-color: #d9534f;
    color: #fff; }
    .btn.btn-error:hover, .btn.btn-error:focus, .btn.btn-error:active, .btn.btn-error.active {
      background-color: #b94743; }
  .btn.btn-link:active, .btn.btn-link.active, .btn.btn-link:focus {
    color: #3876ac; }
  .btn.btn-white-space {
    white-space: normal; }
  .btn.btn-primary, .btn.btn-success, .btn.btn-info, .btn.btn-warning, .btn.btn-error, .btn.btn-danger, .btn.btn-turquoise, .btn.btn-emerald, .btn.btn-amethyst {
    border: none; }
    .btn.btn-primary:focus, .btn.btn-success:focus, .btn.btn-info:focus, .btn.btn-warning:focus, .btn.btn-error:focus, .btn.btn-danger:focus, .btn.btn-turquoise:focus, .btn.btn-emerald:focus, .btn.btn-amethyst:focus {
      outline: none; }
  .btn.btn-default:hover, .btn.btn-default:focus {
    color: #428bca;
    border-color: #428bca;
    background-color: #ecf3fa; }
  .btn.btn-default:active, .btn.btn-default.active {
    background-color: #cfe2f2;
    border-color: #3876ac;
    color: #3876ac;
    outline: none; }
  .btn.btn-black {
    background-color: #000;
    color: #fff; }
    .btn.btn-black:hover, .btn.btn-black:focus {
      background-color: #434a54;
      color: #fff;
      outline: none; }
    .btn.btn-black:active, .btn.btn-black.active {
      background-color: #353b48;
      color: #e6e9ed;
      outline: none; }
  .btn.btn-centered {
    margin: 0 auto;
    max-width: 100px; }
    .btn.btn-centered.wide {
      max-width: 330px; }
    .btn.btn-centered .icon {
      margin-right: 5px;
      fill: #fff;
      vertical-align: inherit; }
  .btn.btn-no-border {
    border: 1px solid transparent; }

.btn-grey {
  background-color: #656d78;
  color: #fff; }
  .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
    background-color: #434a54;
    color: #fff; }

.btn.btn-async .icon.icon-loader {
  width: 10px;
  height: 10px; }

.btn.btn-outline-dark {
  background-color: #fff; }
  .btn.btn-outline-dark:hover, .btn.btn-outline-dark:active, .btn.btn-outline-dark:focus {
    color: #353b48;
    background-color: #f5f7fa; }

/*
Button Async
*/
.btn .icon-loader {
  height: 13px;
  width: 13px; }

.btn.btn-async .icon {
  height: 20px;
  width: 20px; }

.btn.btn-async .icon-loader,
.btn.btn-async .icon-check,
.btn.btn-async .icon-cross {
  display: none; }

.btn.btn-async.busy .icon-text, .btn.btn-async.success .icon-text, .btn.btn-async.error .icon-text {
  margin-left: 6px; }

.btn.btn-async.busy.btn-sm .icon-text, .btn-group-sm > .btn.btn-async.busy .icon-text, .btn.btn-async.busy.btn-xs .icon-text, .btn.btn-async.success.btn-sm .icon-text, .btn-group-sm > .btn.btn-async.success .icon-text, .btn.btn-async.success.btn-xs .icon-text, .btn.btn-async.error.btn-sm .icon-text, .btn-group-sm > .btn.btn-async.error .icon-text, .btn.btn-async.error.btn-xs .icon-text {
  margin-left: 3px; }

.btn.btn-async.busy.btn-link, .btn.btn-async.success.btn-link, .btn.btn-async.error.btn-link {
  background-color: transparent;
  border-color: transparent;
  color: #428bca; }
  .btn.btn-async.busy.btn-link:hover, .btn.btn-async.busy.btn-link:focus, .btn.btn-async.busy.btn-link:active, .btn.btn-async.success.btn-link:hover, .btn.btn-async.success.btn-link:focus, .btn.btn-async.success.btn-link:active, .btn.btn-async.error.btn-link:hover, .btn.btn-async.error.btn-link:focus, .btn.btn-async.error.btn-link:active {
    color: #3876ac; }

.btn.btn-async.busy .icon-loader {
  display: inline-block; }

.btn.btn-async.busy .icon:not(.icon-loader) {
  display: none; }

.btn.btn-async.success .icon-check {
  display: inline-block; }

.btn.btn-async.success .icon:not(.icon-check) {
  display: none; }

.btn.btn-async.error .icon-cross {
  display: inline-block; }

.btn.btn-async.error .icon:not(.icon-cross) {
  display: none; }

.btn.btn-async .icon-text {
  margin-left: 0; }

.btn.btn-async.btn-link .icon-loader,
.btn.btn-async.btn-default .icon-loader {
  fill: #428bca; }

.btn.btn-async.btn-link {
  text-decoration: none; }
  .btn.btn-async.btn-link:hover .icon-text, .btn.btn-async.btn-link:focus .icon-text, .btn.btn-async.btn-link:active .icon-text {
    text-decoration: underline; }

.btn.btn-async.btn-sm .icon, .btn-group-sm > .btn.btn-async .icon {
  height: 15px;
  width: 15px; }

.btn.btn-async.btn-xs .icon {
  height: 10px;
  width: 10px; }

.btn.btn-async.btn-async-loading .icon-text, .btn.btn-async.btn-async-error .icon-text, .btn.btn-async.btn-async-success .icon-text {
  margin-left: 6px; }

.btn.btn-async.btn-async-loading.btn-sm .icon-text, .btn-group-sm > .btn.btn-async.btn-async-loading .icon-text, .btn.btn-async.btn-async-loading.btn-xs .icon-text, .btn.btn-async.btn-async-error.btn-sm .icon-text, .btn-group-sm > .btn.btn-async.btn-async-error .icon-text, .btn.btn-async.btn-async-error.btn-xs .icon-text, .btn.btn-async.btn-async-success.btn-sm .icon-text, .btn-group-sm > .btn.btn-async.btn-async-success .icon-text, .btn.btn-async.btn-async-success.btn-xs .icon-text {
  margin-left: 3px; }

.btn.btn-async.btn-async-loading.btn-link, .btn.btn-async.btn-async-error.btn-link, .btn.btn-async.btn-async-success.btn-link {
  background-color: transparent;
  border-color: transparent;
  color: #428bca; }
  .btn.btn-async.btn-async-loading.btn-link:hover, .btn.btn-async.btn-async-loading.btn-link:focus, .btn.btn-async.btn-async-loading.btn-link:active, .btn.btn-async.btn-async-error.btn-link:hover, .btn.btn-async.btn-async-error.btn-link:focus, .btn.btn-async.btn-async-error.btn-link:active, .btn.btn-async.btn-async-success.btn-link:hover, .btn.btn-async.btn-async-success.btn-link:focus, .btn.btn-async.btn-async-success.btn-link:active {
    color: #3876ac; }

.btn.btn-async.btn-async-loading .icon-loader {
  display: inline-block; }

.btn.btn-async.btn-async-success .icon-check {
  display: inline-block; }

.btn.btn-async.btn-async-error .icon-cross {
  display: inline-block; }

.btn.btn-async.btn-async-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #fff; }
  .btn.btn-async.btn-async-success.btn-link .icon {
    fill: #5cb85c; }

.btn.btn-async.btn-async-error {
  background-color: #d9534f;
  border-color: #d9534f;
  color: #fff; }
  .btn.btn-async.btn-async-error.btn-link .icon {
    fill: #d9534f; }

/*
Carousel
*/
.carousel .carousel-indicators {
  bottom: 10px; }
  .carousel .carousel-indicators li {
    border-color: #aab2bd;
    height: 12px;
    margin: 0 10px;
    width: 12px; }
    .carousel .carousel-indicators li.active {
      background-color: #428bca;
      border-color: #428bca;
      height: 12px;
      margin: 0 10px;
      width: 12px; }

.carousel .carousel-control {
  background-color: #434a54;
  background-color: rgba(67, 74, 84, 0.8);
  background-image: none;
  min-width: 50px;
  opacity: 1;
  width: 5%; }
  .carousel .carousel-control:hover, .carousel .carousel-control:focus, .carousel .carousel-control:active {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.8); }
    .carousel .carousel-control:hover .icon, .carousel .carousel-control:focus .icon, .carousel .carousel-control:active .icon {
      fill: #428bca; }
  .carousel .carousel-control .icon {
    fill: #fff;
    height: 20px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 20px; }

/*
Checkbox
*/
.checkbox-container {
  display: inline-block;
  vertical-align: middle; }
  .checkbox-container button.checkbox {
    vertical-align: middle;
    margin-right: 5px; }
  .checkbox-container .checkbox-label {
    display: inline-block;
    vertical-align: middle; }

button.checkbox {
  background-color: #f5f7fa;
  border-radius: 2px;
  border: 1px solid #aab2bd;
  box-shadow: inset 0 0 1px 1px #fff;
  display: inline-block;
  height: 18px;
  margin: 0;
  padding: 0;
  position: relative;
  transition: all 150ms ease-in;
  width: 18px; }
  button.checkbox.checkbox-sm {
    height: 12px;
    width: 12px; }
  button.checkbox input[type=checkbox] {
    visibility: hidden; }
  button.checkbox::before, button.checkbox::after {
    content: "";
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform-origin: 0 0;
    transform: translateY(-50%) translateX(-50%);
    transition: all 150ms ease-in; }
  button.checkbox:hover, button.checkbox.hovered {
    background-color: #fff;
    box-shadow: inset 0 0 1px 1px #f5f7fa; }
    button.checkbox:hover::before, button.checkbox:hover::after, button.checkbox.hovered::before, button.checkbox.hovered::after {
      background-color: #428bca;
      border-radius: 50%;
      height: 0;
      width: 0;
      padding: 2px; }
  button.checkbox.remove {
    background-color: #fff;
    box-shadow: inset 0 0 1px 1px #f5f7fa; }
    button.checkbox.remove::before, button.checkbox.remove::after {
      background-color: #428bca;
      border-radius: 1px;
      height: 2px;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 6px;
      padding: 0; }
  button.checkbox.checked {
    background-color: #428bca;
    border-color: #428bca;
    box-shadow: inset 0 0 1px 0 #428bca; }
    button.checkbox.checked:focus, button.checkbox.checked.focused {
      box-shadow: inset 0 0 1px 0 #428bca, 0 0 3px #e6e9ed; }
    button.checkbox.checked::before, button.checkbox.checked::after {
      background-color: #fff;
      border-radius: 0;
      height: 2px;
      padding: 0; }
    button.checkbox.checked::before {
      left: 4px;
      top: 6px;
      transform: rotate(45deg);
      width: 6px; }
    button.checkbox.checked::after {
      left: 6px;
      top: 11px;
      transform: rotate(-45deg);
      width: 10px; }
    button.checkbox.checked.checkbox-sm::before {
      left: 2px;
      top: 3px;
      width: 4px; }
    button.checkbox.checked.checkbox-sm::after {
      left: 3px;
      top: 7px;
      width: 7px; }
  button.checkbox:focus, button.checkbox.focused {
    border-color: #428bca;
    box-shadow: inset 0 0 1px 1px #f5f7fa, 0 0 3px #e6e9ed; }
  button.checkbox:disabled, button.checkbox.disabled, button.checkbox.btn.busy, button.checkbox.btn[busy],
  fieldset[busy] button.checkbox.btn {
    background-color: #f5f7fa;
    border-color: #ccd1d9;
    box-shadow: inset 0 0 1px 1px #fff;
    cursor: not-allowed; }
    button.checkbox:disabled::before, button.checkbox:disabled::after, button.checkbox.disabled::before, button.checkbox.btn.busy::before, button.checkbox.btn[busy]::before,
    fieldset[busy] button.checkbox.btn::before, button.checkbox.disabled::after, button.checkbox.btn.busy::after, button.checkbox.btn[busy]::after,
    fieldset[busy] button.checkbox.btn::after {
      opacity: 0; }
    button.checkbox:disabled:hover::before, button.checkbox:disabled:hover::after, button.checkbox:disabled.hovered::before, button.checkbox:disabled.hovered::after, button.checkbox:disabled:focus::before, button.checkbox:disabled:focus::after, button.checkbox:disabled.focused::before, button.checkbox:disabled.focused::after, button.checkbox.disabled:hover::before, button.checkbox.btn.busy:hover::before, button.checkbox.btn[busy]:hover::before,
    fieldset[busy] button.checkbox.btn:hover::before, button.checkbox.disabled:hover::after, button.checkbox.btn.busy:hover::after, button.checkbox.btn[busy]:hover::after,
    fieldset[busy] button.checkbox.btn:hover::after, button.checkbox.disabled.hovered::before, button.checkbox.hovered.btn.busy::before, button.checkbox.hovered.btn[busy]::before,
    fieldset[busy] button.checkbox.hovered.btn::before, button.checkbox.disabled.hovered::after, button.checkbox.hovered.btn.busy::after, button.checkbox.hovered.btn[busy]::after,
    fieldset[busy] button.checkbox.hovered.btn::after, button.checkbox.disabled:focus::before, button.checkbox.btn.busy:focus::before, button.checkbox.btn[busy]:focus::before,
    fieldset[busy] button.checkbox.btn:focus::before, button.checkbox.disabled:focus::after, button.checkbox.btn.busy:focus::after, button.checkbox.btn[busy]:focus::after,
    fieldset[busy] button.checkbox.btn:focus::after, button.checkbox.disabled.focused::before, button.checkbox.focused.btn.busy::before, button.checkbox.focused.btn[busy]::before,
    fieldset[busy] button.checkbox.focused.btn::before, button.checkbox.disabled.focused::after, button.checkbox.focused.btn.busy::after, button.checkbox.focused.btn[busy]::after,
    fieldset[busy] button.checkbox.focused.btn::after {
      background-color: #ccd1d9;
      border-radius: 0;
      height: 2px;
      left: 50%;
      opacity: 1;
      top: 50%;
      width: 16px;
      padding: 0; }
    button.checkbox:disabled:hover::before, button.checkbox:disabled.hovered::before, button.checkbox:disabled:focus::before, button.checkbox:disabled.focused::before, button.checkbox.disabled:hover::before, button.checkbox.btn.busy:hover::before, button.checkbox.btn[busy]:hover::before,
    fieldset[busy] button.checkbox.btn:hover::before, button.checkbox.disabled.hovered::before, button.checkbox.hovered.btn.busy::before, button.checkbox.hovered.btn[busy]::before,
    fieldset[busy] button.checkbox.hovered.btn::before, button.checkbox.disabled:focus::before, button.checkbox.btn.busy:focus::before, button.checkbox.btn[busy]:focus::before,
    fieldset[busy] button.checkbox.btn:focus::before, button.checkbox.disabled.focused::before, button.checkbox.focused.btn.busy::before, button.checkbox.focused.btn[busy]::before,
    fieldset[busy] button.checkbox.focused.btn::before {
      transform: rotate(45deg) translateX(-50%) translateY(-50%); }
    button.checkbox:disabled:hover::after, button.checkbox:disabled.hovered::after, button.checkbox:disabled:focus::after, button.checkbox:disabled.focused::after, button.checkbox.disabled:hover::after, button.checkbox.btn.busy:hover::after, button.checkbox.btn[busy]:hover::after,
    fieldset[busy] button.checkbox.btn:hover::after, button.checkbox.disabled.hovered::after, button.checkbox.hovered.btn.busy::after, button.checkbox.hovered.btn[busy]::after,
    fieldset[busy] button.checkbox.hovered.btn::after, button.checkbox.disabled:focus::after, button.checkbox.btn.busy:focus::after, button.checkbox.btn[busy]:focus::after,
    fieldset[busy] button.checkbox.btn:focus::after, button.checkbox.disabled.focused::after, button.checkbox.focused.btn.busy::after, button.checkbox.focused.btn[busy]::after,
    fieldset[busy] button.checkbox.focused.btn::after {
      transform: rotate(-45deg) translateX(-50%) translateY(-50%); }
    button.checkbox:disabled.checkbox-sm::before, button.checkbox:disabled.checkbox-sm::after, button.checkbox.disabled.checkbox-sm::before, button.checkbox.checkbox-sm.btn.busy::before, button.checkbox.checkbox-sm.btn[busy]::before,
    fieldset[busy] button.checkbox.checkbox-sm.btn::before, button.checkbox.disabled.checkbox-sm::after, button.checkbox.checkbox-sm.btn.busy::after, button.checkbox.checkbox-sm.btn[busy]::after,
    fieldset[busy] button.checkbox.checkbox-sm.btn::after {
      width: 9px; }
  button.checkbox.not-allowed {
    background-color: #f5f7fa;
    border-color: #ccd1d9;
    box-shadow: inset 0 0 1px 1px #fff;
    cursor: not-allowed; }
    button.checkbox.not-allowed.checkbox-sm::before, button.checkbox.not-allowed.checkbox-sm::after {
      width: 9px; }
    button.checkbox.not-allowed::before, button.checkbox.not-allowed::after {
      background-color: #ccd1d9;
      border-radius: 0;
      height: 2px;
      left: 50%;
      opacity: 1;
      top: 50%;
      width: 15px;
      padding: 0; }
    button.checkbox.not-allowed::before {
      transform: rotate(45deg) translateX(-50%) translateY(-50%); }
    button.checkbox.not-allowed::after {
      transform: rotate(-45deg) translateX(-50%) translateY(-50%); }

/*
Corners Indicators
*/
.corner-indicator-container {
  position: relative; }
  .corner-indicator-container .corner-indicator {
    position: absolute; }
    .corner-indicator-container .corner-indicator.corner-top-left {
      top: 0;
      left: 0; }
    .corner-indicator-container .corner-indicator.corner-bottom-left {
      bottom: 0;
      left: 0; }
    .corner-indicator-container .corner-indicator.corner-top-right {
      top: 0;
      right: 0; }
    .corner-indicator-container .corner-indicator.corner-bottom-right {
      bottom: 0;
      right: 0; }

.corner-indicator {
  border: 5px solid transparent;
  display: inline-block;
  height: 0;
  vertical-align: top;
  width: 0; }
  .corner-indicator::after {
    border: 3px solid transparent;
    content: " ";
    position: absolute; }
  .corner-indicator.corner-xs {
    border-width: 3px; }
    .corner-indicator.corner-xs::after {
      display: none; }
  .corner-indicator.corner-md {
    border-width: 10px; }
    .corner-indicator.corner-md::after {
      border-width: 8px; }
  .corner-indicator.corner-lg {
    border-width: 15px; }
    .corner-indicator.corner-lg::after {
      border-width: 13px; }
  .corner-indicator.corner-top-left {
    border-radius: 2px 0 0 0; }
    .corner-indicator.corner-top-left::after {
      border-radius: 2px 0 0 0;
      border: 3px solid transparent;
      left: -4px;
      top: -4px; }
    .corner-indicator.corner-top-left.corner-white {
      border-left-color: #f2f2f2;
      border-top-color: #f2f2f2; }
      .corner-indicator.corner-top-left.corner-white::after {
        border-left-color: #fff;
        border-top-color: #fff; }
    .corner-indicator.corner-top-left.corner-black {
      border-left-color: black;
      border-top-color: black; }
      .corner-indicator.corner-top-left.corner-black::after {
        border-left-color: #000;
        border-top-color: #000; }
    .corner-indicator.corner-top-left.corner-competitor-darker {
      border-left-color: #264445;
      border-top-color: #264445; }
      .corner-indicator.corner-top-left.corner-competitor-darker::after {
        border-left-color: #2f5455;
        border-top-color: #2f5455; }
    .corner-indicator.corner-top-left.corner-competitor-dark {
      border-left-color: #20393a;
      border-top-color: #20393a; }
      .corner-indicator.corner-top-left.corner-competitor-dark::after {
        border-left-color: #29494a;
        border-top-color: #29494a; }
    .corner-indicator.corner-top-left.corner-competitor {
      border-left-color: #4a8587;
      border-top-color: #4a8587; }
      .corner-indicator.corner-top-left.corner-competitor::after {
        border-left-color: #539597;
        border-top-color: #539597; }
    .corner-indicator.corner-top-left.corner-competitor-light {
      border-left-color: #9bdfe1;
      border-top-color: #9bdfe1; }
      .corner-indicator.corner-top-left.corner-competitor-light::after {
        border-left-color: #afe5e7;
        border-top-color: #afe5e7; }
    .corner-indicator.corner-top-left.corner-danger-smoke {
      border-left-color: #f6d8d8;
      border-top-color: #f6d8d8; }
      .corner-indicator.corner-top-left.corner-danger-smoke::after {
        border-left-color: #fbeded;
        border-top-color: #fbeded; }
    .corner-indicator.corner-top-left.corner-danger-lighter {
      border-left-color: #f2bfbf;
      border-top-color: #f2bfbf; }
      .corner-indicator.corner-top-left.corner-danger-lighter::after {
        border-left-color: #f6d4d4;
        border-top-color: #f6d4d4; }
    .corner-indicator.corner-top-left.corner-danger-light {
      border-left-color: #e79592;
      border-top-color: #e79592; }
      .corner-indicator.corner-top-left.corner-danger-light::after {
        border-left-color: #eca9a7;
        border-top-color: #eca9a7; }
    .corner-indicator.corner-top-left.corner-danger {
      border-left-color: #d43f3a;
      border-top-color: #d43f3a; }
      .corner-indicator.corner-top-left.corner-danger::after {
        border-left-color: #d9534f;
        border-top-color: #d9534f; }
    .corner-indicator.corner-top-left.corner-danger-dark {
      border-left-color: #a6403c;
      border-top-color: #a6403c; }
      .corner-indicator.corner-top-left.corner-danger-dark::after {
        border-left-color: #b94743;
        border-top-color: #b94743; }
    .corner-indicator.corner-top-left.corner-danger-darker {
      border-left-color: #8b211e;
      border-top-color: #8b211e; }
      .corner-indicator.corner-top-left.corner-danger-darker::after {
        border-left-color: #a02622;
        border-top-color: #a02622; }
    .corner-indicator.corner-top-left.corner-error-smoke {
      border-left-color: #f6d8d8;
      border-top-color: #f6d8d8; }
      .corner-indicator.corner-top-left.corner-error-smoke::after {
        border-left-color: #fbeded;
        border-top-color: #fbeded; }
    .corner-indicator.corner-top-left.corner-error-lighter {
      border-left-color: #f2bfbf;
      border-top-color: #f2bfbf; }
      .corner-indicator.corner-top-left.corner-error-lighter::after {
        border-left-color: #f6d4d4;
        border-top-color: #f6d4d4; }
    .corner-indicator.corner-top-left.corner-error-light {
      border-left-color: #e79592;
      border-top-color: #e79592; }
      .corner-indicator.corner-top-left.corner-error-light::after {
        border-left-color: #eca9a7;
        border-top-color: #eca9a7; }
    .corner-indicator.corner-top-left.corner-error {
      border-left-color: #d43f3a;
      border-top-color: #d43f3a; }
      .corner-indicator.corner-top-left.corner-error::after {
        border-left-color: #d9534f;
        border-top-color: #d9534f; }
    .corner-indicator.corner-top-left.corner-error-dark {
      border-left-color: #a6403c;
      border-top-color: #a6403c; }
      .corner-indicator.corner-top-left.corner-error-dark::after {
        border-left-color: #b94743;
        border-top-color: #b94743; }
    .corner-indicator.corner-top-left.corner-error-darker {
      border-left-color: #8b211e;
      border-top-color: #8b211e; }
      .corner-indicator.corner-top-left.corner-error-darker::after {
        border-left-color: #a02622;
        border-top-color: #a02622; }
    .corner-indicator.corner-top-left.corner-warning-smoke {
      border-left-color: #fdebcd;
      border-top-color: #fdebcd; }
      .corner-indicator.corner-top-left.corner-warning-smoke::after {
        border-left-color: #fef5e6;
        border-top-color: #fef5e6; }
    .corner-indicator.corner-top-left.corner-warning-lighter {
      border-left-color: #fedbaf;
      border-top-color: #fedbaf; }
      .corner-indicator.corner-top-left.corner-warning-lighter::after {
        border-left-color: #fee6c8;
        border-top-color: #fee6c8; }
    .corner-indicator.corner-top-left.corner-warning-light {
      border-left-color: #fac46d;
      border-top-color: #fac46d; }
      .corner-indicator.corner-top-left.corner-warning-light::after {
        border-left-color: #fbce86;
        border-top-color: #fbce86; }
    .corner-indicator.corner-top-left.corner-warning {
      border-left-color: #e68f07;
      border-top-color: #e68f07; }
      .corner-indicator.corner-top-left.corner-warning::after {
        border-left-color: #f89d0e;
        border-top-color: #f89d0e; }
    .corner-indicator.corner-top-left.corner-warning-dark {
      border-left-color: #bb770b;
      border-top-color: #bb770b; }
      .corner-indicator.corner-top-left.corner-warning-dark::after {
        border-left-color: #d3860c;
        border-top-color: #d3860c; }
    .corner-indicator.corner-top-left.corner-warning-darker {
      border-left-color: #835104;
      border-top-color: #835104; }
      .corner-indicator.corner-top-left.corner-warning-darker::after {
        border-left-color: #9b6105;
        border-top-color: #9b6105; }
    .corner-indicator.corner-top-left.corner-success-smoke {
      border-left-color: #dcf1dc;
      border-top-color: #dcf1dc; }
      .corner-indicator.corner-top-left.corner-success-smoke::after {
        border-left-color: #eef8ee;
        border-top-color: #eef8ee; }
    .corner-indicator.corner-top-left.corner-success-lighter {
      border-left-color: #c6e5c4;
      border-top-color: #c6e5c4; }
      .corner-indicator.corner-top-left.corner-success-lighter::after {
        border-left-color: #d7edd6;
        border-top-color: #d7edd6; }
    .corner-indicator.corner-top-left.corner-success-light {
      border-left-color: #37d251;
      border-top-color: #37d251; }
      .corner-indicator.corner-top-left.corner-success-light::after {
        border-left-color: #4cd763;
        border-top-color: #4cd763; }
    .corner-indicator.corner-top-left.corner-success {
      border-left-color: #00ad1e;
      border-top-color: #00ad1e; }
      .corner-indicator.corner-top-left.corner-success::after {
        border-left-color: #00c622;
        border-top-color: #00c622; }
    .corner-indicator.corner-top-left.corner-success-dark {
      border-left-color: #468c46;
      border-top-color: #468c46; }
      .corner-indicator.corner-top-left.corner-success-dark::after {
        border-left-color: #4e9d4e;
        border-top-color: #4e9d4e; }
    .corner-indicator.corner-top-left.corner-success-darker {
      border-left-color: #2d672d;
      border-top-color: #2d672d; }
      .corner-indicator.corner-top-left.corner-success-darker::after {
        border-left-color: #357935;
        border-top-color: #357935; }
    .corner-indicator.corner-top-left.corner-primary-smoke {
      border-left-color: #d8e6f5;
      border-top-color: #d8e6f5; }
      .corner-indicator.corner-top-left.corner-primary-smoke::after {
        border-left-color: #ecf3fa;
        border-top-color: #ecf3fa; }
    .corner-indicator.corner-top-left.corner-primary-lighter {
      border-left-color: #bbd6ed;
      border-top-color: #bbd6ed; }
      .corner-indicator.corner-top-left.corner-primary-lighter::after {
        border-left-color: #cfe2f2;
        border-top-color: #cfe2f2; }
    .corner-indicator.corner-top-left.corner-primary-light {
      border-left-color: #8cb9de;
      border-top-color: #8cb9de; }
      .corner-indicator.corner-top-left.corner-primary-light::after {
        border-left-color: #a0c5e4;
        border-top-color: #a0c5e4; }
    .corner-indicator.corner-top-left.corner-primary {
      border-left-color: #357ebd;
      border-top-color: #357ebd; }
      .corner-indicator.corner-top-left.corner-primary::after {
        border-left-color: #428bca;
        border-top-color: #428bca; }
    .corner-indicator.corner-top-left.corner-primary-dark {
      border-left-color: #326999;
      border-top-color: #326999; }
      .corner-indicator.corner-top-left.corner-primary-dark::after {
        border-left-color: #3876ac;
        border-top-color: #3876ac; }
    .corner-indicator.corner-top-left.corner-primary-darker {
      border-left-color: #254f73;
      border-top-color: #254f73; }
      .corner-indicator.corner-top-left.corner-primary-darker::after {
        border-left-color: #2b5c86;
        border-top-color: #2b5c86; }
    .corner-indicator.corner-top-left.corner-grey-darker {
      border-left-color: #2a2f39;
      border-top-color: #2a2f39; }
      .corner-indicator.corner-top-left.corner-grey-darker::after {
        border-left-color: #353b48;
        border-top-color: #353b48; }
    .corner-indicator.corner-top-left.corner-grey-dark {
      border-left-color: #383e46;
      border-top-color: #383e46; }
      .corner-indicator.corner-top-left.corner-grey-dark::after {
        border-left-color: #434a54;
        border-top-color: #434a54; }
    .corner-indicator.corner-top-left.corner-grey-medium {
      border-left-color: #59606a;
      border-top-color: #59606a; }
      .corner-indicator.corner-top-left.corner-grey-medium::after {
        border-left-color: #656d78;
        border-top-color: #656d78; }
    .corner-indicator.corner-top-left.corner-grey {
      border-left-color: #9ca5b2;
      border-top-color: #9ca5b2; }
      .corner-indicator.corner-top-left.corner-grey::after {
        border-left-color: #aab2bd;
        border-top-color: #aab2bd; }
    .corner-indicator.corner-top-left.corner-grey-light {
      border-left-color: #bdc4ce;
      border-top-color: #bdc4ce; }
      .corner-indicator.corner-top-left.corner-grey-light::after {
        border-left-color: #ccd1d9;
        border-top-color: #ccd1d9; }
    .corner-indicator.corner-top-left.corner-grey-lighter {
      border-left-color: #d7dce2;
      border-top-color: #d7dce2; }
      .corner-indicator.corner-top-left.corner-grey-lighter::after {
        border-left-color: #e6e9ed;
        border-top-color: #e6e9ed; }
    .corner-indicator.corner-top-left.corner-grey-smoke {
      border-left-color: #e4e9f2;
      border-top-color: #e4e9f2; }
      .corner-indicator.corner-top-left.corner-grey-smoke::after {
        border-left-color: #f5f7fa;
        border-top-color: #f5f7fa; }
    .corner-indicator.corner-top-left.corner-turquoise-light {
      border-left-color: #8bdfd1;
      border-top-color: #8bdfd1; }
      .corner-indicator.corner-top-left.corner-turquoise-light::after {
        border-left-color: #9fe4d9;
        border-top-color: #9fe4d9; }
    .corner-indicator.corner-top-left.corner-turquoise-lighter {
      border-left-color: #dbf5f1;
      border-top-color: #dbf5f1; }
      .corner-indicator.corner-top-left.corner-turquoise-lighter::after {
        border-left-color: #effaf8;
        border-top-color: #effaf8; }
    .corner-indicator.corner-top-left.corner-turquoise {
      border-left-color: #3bc8b1;
      border-top-color: #3bc8b1; }
      .corner-indicator.corner-top-left.corner-turquoise::after {
        border-left-color: #4fceb9;
        border-top-color: #4fceb9; }
    .corner-indicator.corner-top-left.corner-turquoise-dark {
      border-left-color: #2e9988;
      border-top-color: #2e9988; }
      .corner-indicator.corner-top-left.corner-turquoise-dark::after {
        border-left-color: #34ad99;
        border-top-color: #34ad99; }
    .corner-indicator.corner-top-left.corner-turquoise-darker {
      border-left-color: #227b6d;
      border-top-color: #227b6d; }
      .corner-indicator.corner-top-left.corner-turquoise-darker::after {
        border-left-color: #288f7e;
        border-top-color: #288f7e; }
    .corner-indicator.corner-top-left.corner-emerald-light {
      border-left-color: #69dd9a;
      border-top-color: #69dd9a; }
      .corner-indicator.corner-top-left.corner-emerald-light::after {
        border-left-color: #7ee2a8;
        border-top-color: #7ee2a8; }
    .corner-indicator.corner-top-left.corner-emerald-lighter {
      border-left-color: #bcf0d2;
      border-top-color: #bcf0d2; }
      .corner-indicator.corner-top-left.corner-emerald-lighter::after {
        border-left-color: #d1f5e0;
        border-top-color: #d1f5e0; }
    .corner-indicator.corner-top-left.corner-emerald {
      border-left-color: #29b765;
      border-top-color: #29b765; }
      .corner-indicator.corner-top-left.corner-emerald::after {
        border-left-color: #2ecc71;
        border-top-color: #2ecc71; }
    .corner-indicator.corner-top-left.corner-emerald-dark {
      border-left-color: #229955;
      border-top-color: #229955; }
      .corner-indicator.corner-top-left.corner-emerald-dark::after {
        border-left-color: #27ae60;
        border-top-color: #27ae60; }
    .corner-indicator.corner-top-left.corner-emerald-darker {
      border-left-color: #176437;
      border-top-color: #176437; }
      .corner-indicator.corner-top-left.corner-emerald-darker::after {
        border-left-color: #1b7943;
        border-top-color: #1b7943; }
    .corner-indicator.corner-top-left.corner-amethyst-light {
      border-left-color: #b8a4c2;
      border-top-color: #b8a4c2; }
      .corner-indicator.corner-top-left.corner-amethyst-light::after {
        border-left-color: #c4b3cc;
        border-top-color: #c4b3cc; }
    .corner-indicator.corner-top-left.corner-amethyst-lighter {
      border-left-color: #e7e1eb;
      border-top-color: #e7e1eb; }
      .corner-indicator.corner-top-left.corner-amethyst-lighter::after {
        border-left-color: #f3f0f5;
        border-top-color: #f3f0f5; }
    .corner-indicator.corner-top-left.corner-amethyst {
      border-left-color: #886799;
      border-top-color: #886799; }
      .corner-indicator.corner-top-left.corner-amethyst::after {
        border-left-color: #9476a3;
        border-top-color: #9476a3; }
    .corner-indicator.corner-top-left.corner-amethyst-dark {
      border-left-color: #765e81;
      border-top-color: #765e81; }
      .corner-indicator.corner-top-left.corner-amethyst-dark::after {
        border-left-color: #836990;
        border-top-color: #836990; }
    .corner-indicator.corner-top-left.corner-amethyst-darker {
      border-left-color: #523e5c;
      border-top-color: #523e5c; }
      .corner-indicator.corner-top-left.corner-amethyst-darker::after {
        border-left-color: #5f486b;
        border-top-color: #5f486b; }
  .corner-indicator.corner-bottom-left {
    border-radius: 0 0 0 2px; }
    .corner-indicator.corner-bottom-left::after {
      border-radius: 0 0 0 2px;
      border: 3px solid transparent;
      left: -4px;
      bottom: -4px; }
    .corner-indicator.corner-bottom-left.corner-white {
      border-left-color: #f2f2f2;
      border-bottom-color: #f2f2f2; }
    .corner-indicator.corner-bottom-left.corner-white::after {
      border-left-color: #fff;
      border-bottom-color: #fff; }
    .corner-indicator.corner-bottom-left.corner-black {
      border-left-color: black;
      border-bottom-color: black; }
    .corner-indicator.corner-bottom-left.corner-black::after {
      border-left-color: #000;
      border-bottom-color: #000; }
    .corner-indicator.corner-bottom-left.corner-competitor-darker {
      border-left-color: #264445;
      border-bottom-color: #264445; }
    .corner-indicator.corner-bottom-left.corner-competitor-darker::after {
      border-left-color: #2f5455;
      border-bottom-color: #2f5455; }
    .corner-indicator.corner-bottom-left.corner-competitor-dark {
      border-left-color: #20393a;
      border-bottom-color: #20393a; }
    .corner-indicator.corner-bottom-left.corner-competitor-dark::after {
      border-left-color: #29494a;
      border-bottom-color: #29494a; }
    .corner-indicator.corner-bottom-left.corner-competitor {
      border-left-color: #4a8587;
      border-bottom-color: #4a8587; }
    .corner-indicator.corner-bottom-left.corner-competitor::after {
      border-left-color: #539597;
      border-bottom-color: #539597; }
    .corner-indicator.corner-bottom-left.corner-competitor-light {
      border-left-color: #9bdfe1;
      border-bottom-color: #9bdfe1; }
    .corner-indicator.corner-bottom-left.corner-competitor-light::after {
      border-left-color: #afe5e7;
      border-bottom-color: #afe5e7; }
    .corner-indicator.corner-bottom-left.corner-danger-smoke {
      border-left-color: #f6d8d8;
      border-bottom-color: #f6d8d8; }
    .corner-indicator.corner-bottom-left.corner-danger-smoke::after {
      border-left-color: #fbeded;
      border-bottom-color: #fbeded; }
    .corner-indicator.corner-bottom-left.corner-danger-lighter {
      border-left-color: #f2bfbf;
      border-bottom-color: #f2bfbf; }
    .corner-indicator.corner-bottom-left.corner-danger-lighter::after {
      border-left-color: #f6d4d4;
      border-bottom-color: #f6d4d4; }
    .corner-indicator.corner-bottom-left.corner-danger-light {
      border-left-color: #e79592;
      border-bottom-color: #e79592; }
    .corner-indicator.corner-bottom-left.corner-danger-light::after {
      border-left-color: #eca9a7;
      border-bottom-color: #eca9a7; }
    .corner-indicator.corner-bottom-left.corner-danger {
      border-left-color: #d43f3a;
      border-bottom-color: #d43f3a; }
    .corner-indicator.corner-bottom-left.corner-danger::after {
      border-left-color: #d9534f;
      border-bottom-color: #d9534f; }
    .corner-indicator.corner-bottom-left.corner-danger-dark {
      border-left-color: #a6403c;
      border-bottom-color: #a6403c; }
    .corner-indicator.corner-bottom-left.corner-danger-dark::after {
      border-left-color: #b94743;
      border-bottom-color: #b94743; }
    .corner-indicator.corner-bottom-left.corner-danger-darker {
      border-left-color: #8b211e;
      border-bottom-color: #8b211e; }
    .corner-indicator.corner-bottom-left.corner-danger-darker::after {
      border-left-color: #a02622;
      border-bottom-color: #a02622; }
    .corner-indicator.corner-bottom-left.corner-error-smoke {
      border-left-color: #f6d8d8;
      border-bottom-color: #f6d8d8; }
    .corner-indicator.corner-bottom-left.corner-error-smoke::after {
      border-left-color: #fbeded;
      border-bottom-color: #fbeded; }
    .corner-indicator.corner-bottom-left.corner-error-lighter {
      border-left-color: #f2bfbf;
      border-bottom-color: #f2bfbf; }
    .corner-indicator.corner-bottom-left.corner-error-lighter::after {
      border-left-color: #f6d4d4;
      border-bottom-color: #f6d4d4; }
    .corner-indicator.corner-bottom-left.corner-error-light {
      border-left-color: #e79592;
      border-bottom-color: #e79592; }
    .corner-indicator.corner-bottom-left.corner-error-light::after {
      border-left-color: #eca9a7;
      border-bottom-color: #eca9a7; }
    .corner-indicator.corner-bottom-left.corner-error {
      border-left-color: #d43f3a;
      border-bottom-color: #d43f3a; }
    .corner-indicator.corner-bottom-left.corner-error::after {
      border-left-color: #d9534f;
      border-bottom-color: #d9534f; }
    .corner-indicator.corner-bottom-left.corner-error-dark {
      border-left-color: #a6403c;
      border-bottom-color: #a6403c; }
    .corner-indicator.corner-bottom-left.corner-error-dark::after {
      border-left-color: #b94743;
      border-bottom-color: #b94743; }
    .corner-indicator.corner-bottom-left.corner-error-darker {
      border-left-color: #8b211e;
      border-bottom-color: #8b211e; }
    .corner-indicator.corner-bottom-left.corner-error-darker::after {
      border-left-color: #a02622;
      border-bottom-color: #a02622; }
    .corner-indicator.corner-bottom-left.corner-warning-smoke {
      border-left-color: #fdebcd;
      border-bottom-color: #fdebcd; }
    .corner-indicator.corner-bottom-left.corner-warning-smoke::after {
      border-left-color: #fef5e6;
      border-bottom-color: #fef5e6; }
    .corner-indicator.corner-bottom-left.corner-warning-lighter {
      border-left-color: #fedbaf;
      border-bottom-color: #fedbaf; }
    .corner-indicator.corner-bottom-left.corner-warning-lighter::after {
      border-left-color: #fee6c8;
      border-bottom-color: #fee6c8; }
    .corner-indicator.corner-bottom-left.corner-warning-light {
      border-left-color: #fac46d;
      border-bottom-color: #fac46d; }
    .corner-indicator.corner-bottom-left.corner-warning-light::after {
      border-left-color: #fbce86;
      border-bottom-color: #fbce86; }
    .corner-indicator.corner-bottom-left.corner-warning {
      border-left-color: #e68f07;
      border-bottom-color: #e68f07; }
    .corner-indicator.corner-bottom-left.corner-warning::after {
      border-left-color: #f89d0e;
      border-bottom-color: #f89d0e; }
    .corner-indicator.corner-bottom-left.corner-warning-dark {
      border-left-color: #bb770b;
      border-bottom-color: #bb770b; }
    .corner-indicator.corner-bottom-left.corner-warning-dark::after {
      border-left-color: #d3860c;
      border-bottom-color: #d3860c; }
    .corner-indicator.corner-bottom-left.corner-warning-darker {
      border-left-color: #835104;
      border-bottom-color: #835104; }
    .corner-indicator.corner-bottom-left.corner-warning-darker::after {
      border-left-color: #9b6105;
      border-bottom-color: #9b6105; }
    .corner-indicator.corner-bottom-left.corner-success-smoke {
      border-left-color: #dcf1dc;
      border-bottom-color: #dcf1dc; }
    .corner-indicator.corner-bottom-left.corner-success-smoke::after {
      border-left-color: #eef8ee;
      border-bottom-color: #eef8ee; }
    .corner-indicator.corner-bottom-left.corner-success-lighter {
      border-left-color: #c6e5c4;
      border-bottom-color: #c6e5c4; }
    .corner-indicator.corner-bottom-left.corner-success-lighter::after {
      border-left-color: #d7edd6;
      border-bottom-color: #d7edd6; }
    .corner-indicator.corner-bottom-left.corner-success-light {
      border-left-color: #37d251;
      border-bottom-color: #37d251; }
    .corner-indicator.corner-bottom-left.corner-success-light::after {
      border-left-color: #4cd763;
      border-bottom-color: #4cd763; }
    .corner-indicator.corner-bottom-left.corner-success {
      border-left-color: #00ad1e;
      border-bottom-color: #00ad1e; }
    .corner-indicator.corner-bottom-left.corner-success::after {
      border-left-color: #00c622;
      border-bottom-color: #00c622; }
    .corner-indicator.corner-bottom-left.corner-success-dark {
      border-left-color: #468c46;
      border-bottom-color: #468c46; }
    .corner-indicator.corner-bottom-left.corner-success-dark::after {
      border-left-color: #4e9d4e;
      border-bottom-color: #4e9d4e; }
    .corner-indicator.corner-bottom-left.corner-success-darker {
      border-left-color: #2d672d;
      border-bottom-color: #2d672d; }
    .corner-indicator.corner-bottom-left.corner-success-darker::after {
      border-left-color: #357935;
      border-bottom-color: #357935; }
    .corner-indicator.corner-bottom-left.corner-primary-smoke {
      border-left-color: #d8e6f5;
      border-bottom-color: #d8e6f5; }
    .corner-indicator.corner-bottom-left.corner-primary-smoke::after {
      border-left-color: #ecf3fa;
      border-bottom-color: #ecf3fa; }
    .corner-indicator.corner-bottom-left.corner-primary-lighter {
      border-left-color: #bbd6ed;
      border-bottom-color: #bbd6ed; }
    .corner-indicator.corner-bottom-left.corner-primary-lighter::after {
      border-left-color: #cfe2f2;
      border-bottom-color: #cfe2f2; }
    .corner-indicator.corner-bottom-left.corner-primary-light {
      border-left-color: #8cb9de;
      border-bottom-color: #8cb9de; }
    .corner-indicator.corner-bottom-left.corner-primary-light::after {
      border-left-color: #a0c5e4;
      border-bottom-color: #a0c5e4; }
    .corner-indicator.corner-bottom-left.corner-primary {
      border-left-color: #357ebd;
      border-bottom-color: #357ebd; }
    .corner-indicator.corner-bottom-left.corner-primary::after {
      border-left-color: #428bca;
      border-bottom-color: #428bca; }
    .corner-indicator.corner-bottom-left.corner-primary-dark {
      border-left-color: #326999;
      border-bottom-color: #326999; }
    .corner-indicator.corner-bottom-left.corner-primary-dark::after {
      border-left-color: #3876ac;
      border-bottom-color: #3876ac; }
    .corner-indicator.corner-bottom-left.corner-primary-darker {
      border-left-color: #254f73;
      border-bottom-color: #254f73; }
    .corner-indicator.corner-bottom-left.corner-primary-darker::after {
      border-left-color: #2b5c86;
      border-bottom-color: #2b5c86; }
    .corner-indicator.corner-bottom-left.corner-grey-darker {
      border-left-color: #2a2f39;
      border-bottom-color: #2a2f39; }
    .corner-indicator.corner-bottom-left.corner-grey-darker::after {
      border-left-color: #353b48;
      border-bottom-color: #353b48; }
    .corner-indicator.corner-bottom-left.corner-grey-dark {
      border-left-color: #383e46;
      border-bottom-color: #383e46; }
    .corner-indicator.corner-bottom-left.corner-grey-dark::after {
      border-left-color: #434a54;
      border-bottom-color: #434a54; }
    .corner-indicator.corner-bottom-left.corner-grey-medium {
      border-left-color: #59606a;
      border-bottom-color: #59606a; }
    .corner-indicator.corner-bottom-left.corner-grey-medium::after {
      border-left-color: #656d78;
      border-bottom-color: #656d78; }
    .corner-indicator.corner-bottom-left.corner-grey {
      border-left-color: #9ca5b2;
      border-bottom-color: #9ca5b2; }
    .corner-indicator.corner-bottom-left.corner-grey::after {
      border-left-color: #aab2bd;
      border-bottom-color: #aab2bd; }
    .corner-indicator.corner-bottom-left.corner-grey-light {
      border-left-color: #bdc4ce;
      border-bottom-color: #bdc4ce; }
    .corner-indicator.corner-bottom-left.corner-grey-light::after {
      border-left-color: #ccd1d9;
      border-bottom-color: #ccd1d9; }
    .corner-indicator.corner-bottom-left.corner-grey-lighter {
      border-left-color: #d7dce2;
      border-bottom-color: #d7dce2; }
    .corner-indicator.corner-bottom-left.corner-grey-lighter::after {
      border-left-color: #e6e9ed;
      border-bottom-color: #e6e9ed; }
    .corner-indicator.corner-bottom-left.corner-grey-smoke {
      border-left-color: #e4e9f2;
      border-bottom-color: #e4e9f2; }
    .corner-indicator.corner-bottom-left.corner-grey-smoke::after {
      border-left-color: #f5f7fa;
      border-bottom-color: #f5f7fa; }
    .corner-indicator.corner-bottom-left.corner-turquoise-light {
      border-left-color: #8bdfd1;
      border-bottom-color: #8bdfd1; }
    .corner-indicator.corner-bottom-left.corner-turquoise-light::after {
      border-left-color: #9fe4d9;
      border-bottom-color: #9fe4d9; }
    .corner-indicator.corner-bottom-left.corner-turquoise-lighter {
      border-left-color: #dbf5f1;
      border-bottom-color: #dbf5f1; }
    .corner-indicator.corner-bottom-left.corner-turquoise-lighter::after {
      border-left-color: #effaf8;
      border-bottom-color: #effaf8; }
    .corner-indicator.corner-bottom-left.corner-turquoise {
      border-left-color: #3bc8b1;
      border-bottom-color: #3bc8b1; }
    .corner-indicator.corner-bottom-left.corner-turquoise::after {
      border-left-color: #4fceb9;
      border-bottom-color: #4fceb9; }
    .corner-indicator.corner-bottom-left.corner-turquoise-dark {
      border-left-color: #2e9988;
      border-bottom-color: #2e9988; }
    .corner-indicator.corner-bottom-left.corner-turquoise-dark::after {
      border-left-color: #34ad99;
      border-bottom-color: #34ad99; }
    .corner-indicator.corner-bottom-left.corner-turquoise-darker {
      border-left-color: #227b6d;
      border-bottom-color: #227b6d; }
    .corner-indicator.corner-bottom-left.corner-turquoise-darker::after {
      border-left-color: #288f7e;
      border-bottom-color: #288f7e; }
    .corner-indicator.corner-bottom-left.corner-emerald-light {
      border-left-color: #69dd9a;
      border-bottom-color: #69dd9a; }
    .corner-indicator.corner-bottom-left.corner-emerald-light::after {
      border-left-color: #7ee2a8;
      border-bottom-color: #7ee2a8; }
    .corner-indicator.corner-bottom-left.corner-emerald-lighter {
      border-left-color: #bcf0d2;
      border-bottom-color: #bcf0d2; }
    .corner-indicator.corner-bottom-left.corner-emerald-lighter::after {
      border-left-color: #d1f5e0;
      border-bottom-color: #d1f5e0; }
    .corner-indicator.corner-bottom-left.corner-emerald {
      border-left-color: #29b765;
      border-bottom-color: #29b765; }
    .corner-indicator.corner-bottom-left.corner-emerald::after {
      border-left-color: #2ecc71;
      border-bottom-color: #2ecc71; }
    .corner-indicator.corner-bottom-left.corner-emerald-dark {
      border-left-color: #229955;
      border-bottom-color: #229955; }
    .corner-indicator.corner-bottom-left.corner-emerald-dark::after {
      border-left-color: #27ae60;
      border-bottom-color: #27ae60; }
    .corner-indicator.corner-bottom-left.corner-emerald-darker {
      border-left-color: #176437;
      border-bottom-color: #176437; }
    .corner-indicator.corner-bottom-left.corner-emerald-darker::after {
      border-left-color: #1b7943;
      border-bottom-color: #1b7943; }
    .corner-indicator.corner-bottom-left.corner-amethyst-light {
      border-left-color: #b8a4c2;
      border-bottom-color: #b8a4c2; }
    .corner-indicator.corner-bottom-left.corner-amethyst-light::after {
      border-left-color: #c4b3cc;
      border-bottom-color: #c4b3cc; }
    .corner-indicator.corner-bottom-left.corner-amethyst-lighter {
      border-left-color: #e7e1eb;
      border-bottom-color: #e7e1eb; }
    .corner-indicator.corner-bottom-left.corner-amethyst-lighter::after {
      border-left-color: #f3f0f5;
      border-bottom-color: #f3f0f5; }
    .corner-indicator.corner-bottom-left.corner-amethyst {
      border-left-color: #886799;
      border-bottom-color: #886799; }
    .corner-indicator.corner-bottom-left.corner-amethyst::after {
      border-left-color: #9476a3;
      border-bottom-color: #9476a3; }
    .corner-indicator.corner-bottom-left.corner-amethyst-dark {
      border-left-color: #765e81;
      border-bottom-color: #765e81; }
    .corner-indicator.corner-bottom-left.corner-amethyst-dark::after {
      border-left-color: #836990;
      border-bottom-color: #836990; }
    .corner-indicator.corner-bottom-left.corner-amethyst-darker {
      border-left-color: #523e5c;
      border-bottom-color: #523e5c; }
    .corner-indicator.corner-bottom-left.corner-amethyst-darker::after {
      border-left-color: #5f486b;
      border-bottom-color: #5f486b; }
  .corner-indicator.corner-top-right {
    border-radius: 0 2px 0 0; }
    .corner-indicator.corner-top-right::after {
      border-radius: 0 2px 0 0;
      border: 3px solid transparent;
      right: -4px;
      top: -4px; }
    .corner-indicator.corner-top-right.corner-white {
      border-right-color: #f2f2f2;
      border-top-color: #f2f2f2; }
    .corner-indicator.corner-top-right.corner-white::after {
      border-right-color: #fff;
      border-top-color: #fff; }
    .corner-indicator.corner-top-right.corner-black {
      border-right-color: black;
      border-top-color: black; }
    .corner-indicator.corner-top-right.corner-black::after {
      border-right-color: #000;
      border-top-color: #000; }
    .corner-indicator.corner-top-right.corner-competitor-darker {
      border-right-color: #264445;
      border-top-color: #264445; }
    .corner-indicator.corner-top-right.corner-competitor-darker::after {
      border-right-color: #2f5455;
      border-top-color: #2f5455; }
    .corner-indicator.corner-top-right.corner-competitor-dark {
      border-right-color: #20393a;
      border-top-color: #20393a; }
    .corner-indicator.corner-top-right.corner-competitor-dark::after {
      border-right-color: #29494a;
      border-top-color: #29494a; }
    .corner-indicator.corner-top-right.corner-competitor {
      border-right-color: #4a8587;
      border-top-color: #4a8587; }
    .corner-indicator.corner-top-right.corner-competitor::after {
      border-right-color: #539597;
      border-top-color: #539597; }
    .corner-indicator.corner-top-right.corner-competitor-light {
      border-right-color: #9bdfe1;
      border-top-color: #9bdfe1; }
    .corner-indicator.corner-top-right.corner-competitor-light::after {
      border-right-color: #afe5e7;
      border-top-color: #afe5e7; }
    .corner-indicator.corner-top-right.corner-danger-smoke {
      border-right-color: #f6d8d8;
      border-top-color: #f6d8d8; }
    .corner-indicator.corner-top-right.corner-danger-smoke::after {
      border-right-color: #fbeded;
      border-top-color: #fbeded; }
    .corner-indicator.corner-top-right.corner-danger-lighter {
      border-right-color: #f2bfbf;
      border-top-color: #f2bfbf; }
    .corner-indicator.corner-top-right.corner-danger-lighter::after {
      border-right-color: #f6d4d4;
      border-top-color: #f6d4d4; }
    .corner-indicator.corner-top-right.corner-danger-light {
      border-right-color: #e79592;
      border-top-color: #e79592; }
    .corner-indicator.corner-top-right.corner-danger-light::after {
      border-right-color: #eca9a7;
      border-top-color: #eca9a7; }
    .corner-indicator.corner-top-right.corner-danger {
      border-right-color: #d43f3a;
      border-top-color: #d43f3a; }
    .corner-indicator.corner-top-right.corner-danger::after {
      border-right-color: #d9534f;
      border-top-color: #d9534f; }
    .corner-indicator.corner-top-right.corner-danger-dark {
      border-right-color: #a6403c;
      border-top-color: #a6403c; }
    .corner-indicator.corner-top-right.corner-danger-dark::after {
      border-right-color: #b94743;
      border-top-color: #b94743; }
    .corner-indicator.corner-top-right.corner-danger-darker {
      border-right-color: #8b211e;
      border-top-color: #8b211e; }
    .corner-indicator.corner-top-right.corner-danger-darker::after {
      border-right-color: #a02622;
      border-top-color: #a02622; }
    .corner-indicator.corner-top-right.corner-error-smoke {
      border-right-color: #f6d8d8;
      border-top-color: #f6d8d8; }
    .corner-indicator.corner-top-right.corner-error-smoke::after {
      border-right-color: #fbeded;
      border-top-color: #fbeded; }
    .corner-indicator.corner-top-right.corner-error-lighter {
      border-right-color: #f2bfbf;
      border-top-color: #f2bfbf; }
    .corner-indicator.corner-top-right.corner-error-lighter::after {
      border-right-color: #f6d4d4;
      border-top-color: #f6d4d4; }
    .corner-indicator.corner-top-right.corner-error-light {
      border-right-color: #e79592;
      border-top-color: #e79592; }
    .corner-indicator.corner-top-right.corner-error-light::after {
      border-right-color: #eca9a7;
      border-top-color: #eca9a7; }
    .corner-indicator.corner-top-right.corner-error {
      border-right-color: #d43f3a;
      border-top-color: #d43f3a; }
    .corner-indicator.corner-top-right.corner-error::after {
      border-right-color: #d9534f;
      border-top-color: #d9534f; }
    .corner-indicator.corner-top-right.corner-error-dark {
      border-right-color: #a6403c;
      border-top-color: #a6403c; }
    .corner-indicator.corner-top-right.corner-error-dark::after {
      border-right-color: #b94743;
      border-top-color: #b94743; }
    .corner-indicator.corner-top-right.corner-error-darker {
      border-right-color: #8b211e;
      border-top-color: #8b211e; }
    .corner-indicator.corner-top-right.corner-error-darker::after {
      border-right-color: #a02622;
      border-top-color: #a02622; }
    .corner-indicator.corner-top-right.corner-warning-smoke {
      border-right-color: #fdebcd;
      border-top-color: #fdebcd; }
    .corner-indicator.corner-top-right.corner-warning-smoke::after {
      border-right-color: #fef5e6;
      border-top-color: #fef5e6; }
    .corner-indicator.corner-top-right.corner-warning-lighter {
      border-right-color: #fedbaf;
      border-top-color: #fedbaf; }
    .corner-indicator.corner-top-right.corner-warning-lighter::after {
      border-right-color: #fee6c8;
      border-top-color: #fee6c8; }
    .corner-indicator.corner-top-right.corner-warning-light {
      border-right-color: #fac46d;
      border-top-color: #fac46d; }
    .corner-indicator.corner-top-right.corner-warning-light::after {
      border-right-color: #fbce86;
      border-top-color: #fbce86; }
    .corner-indicator.corner-top-right.corner-warning {
      border-right-color: #e68f07;
      border-top-color: #e68f07; }
    .corner-indicator.corner-top-right.corner-warning::after {
      border-right-color: #f89d0e;
      border-top-color: #f89d0e; }
    .corner-indicator.corner-top-right.corner-warning-dark {
      border-right-color: #bb770b;
      border-top-color: #bb770b; }
    .corner-indicator.corner-top-right.corner-warning-dark::after {
      border-right-color: #d3860c;
      border-top-color: #d3860c; }
    .corner-indicator.corner-top-right.corner-warning-darker {
      border-right-color: #835104;
      border-top-color: #835104; }
    .corner-indicator.corner-top-right.corner-warning-darker::after {
      border-right-color: #9b6105;
      border-top-color: #9b6105; }
    .corner-indicator.corner-top-right.corner-success-smoke {
      border-right-color: #dcf1dc;
      border-top-color: #dcf1dc; }
    .corner-indicator.corner-top-right.corner-success-smoke::after {
      border-right-color: #eef8ee;
      border-top-color: #eef8ee; }
    .corner-indicator.corner-top-right.corner-success-lighter {
      border-right-color: #c6e5c4;
      border-top-color: #c6e5c4; }
    .corner-indicator.corner-top-right.corner-success-lighter::after {
      border-right-color: #d7edd6;
      border-top-color: #d7edd6; }
    .corner-indicator.corner-top-right.corner-success-light {
      border-right-color: #37d251;
      border-top-color: #37d251; }
    .corner-indicator.corner-top-right.corner-success-light::after {
      border-right-color: #4cd763;
      border-top-color: #4cd763; }
    .corner-indicator.corner-top-right.corner-success {
      border-right-color: #00ad1e;
      border-top-color: #00ad1e; }
    .corner-indicator.corner-top-right.corner-success::after {
      border-right-color: #00c622;
      border-top-color: #00c622; }
    .corner-indicator.corner-top-right.corner-success-dark {
      border-right-color: #468c46;
      border-top-color: #468c46; }
    .corner-indicator.corner-top-right.corner-success-dark::after {
      border-right-color: #4e9d4e;
      border-top-color: #4e9d4e; }
    .corner-indicator.corner-top-right.corner-success-darker {
      border-right-color: #2d672d;
      border-top-color: #2d672d; }
    .corner-indicator.corner-top-right.corner-success-darker::after {
      border-right-color: #357935;
      border-top-color: #357935; }
    .corner-indicator.corner-top-right.corner-primary-smoke {
      border-right-color: #d8e6f5;
      border-top-color: #d8e6f5; }
    .corner-indicator.corner-top-right.corner-primary-smoke::after {
      border-right-color: #ecf3fa;
      border-top-color: #ecf3fa; }
    .corner-indicator.corner-top-right.corner-primary-lighter {
      border-right-color: #bbd6ed;
      border-top-color: #bbd6ed; }
    .corner-indicator.corner-top-right.corner-primary-lighter::after {
      border-right-color: #cfe2f2;
      border-top-color: #cfe2f2; }
    .corner-indicator.corner-top-right.corner-primary-light {
      border-right-color: #8cb9de;
      border-top-color: #8cb9de; }
    .corner-indicator.corner-top-right.corner-primary-light::after {
      border-right-color: #a0c5e4;
      border-top-color: #a0c5e4; }
    .corner-indicator.corner-top-right.corner-primary {
      border-right-color: #357ebd;
      border-top-color: #357ebd; }
    .corner-indicator.corner-top-right.corner-primary::after {
      border-right-color: #428bca;
      border-top-color: #428bca; }
    .corner-indicator.corner-top-right.corner-primary-dark {
      border-right-color: #326999;
      border-top-color: #326999; }
    .corner-indicator.corner-top-right.corner-primary-dark::after {
      border-right-color: #3876ac;
      border-top-color: #3876ac; }
    .corner-indicator.corner-top-right.corner-primary-darker {
      border-right-color: #254f73;
      border-top-color: #254f73; }
    .corner-indicator.corner-top-right.corner-primary-darker::after {
      border-right-color: #2b5c86;
      border-top-color: #2b5c86; }
    .corner-indicator.corner-top-right.corner-grey-darker {
      border-right-color: #2a2f39;
      border-top-color: #2a2f39; }
    .corner-indicator.corner-top-right.corner-grey-darker::after {
      border-right-color: #353b48;
      border-top-color: #353b48; }
    .corner-indicator.corner-top-right.corner-grey-dark {
      border-right-color: #383e46;
      border-top-color: #383e46; }
    .corner-indicator.corner-top-right.corner-grey-dark::after {
      border-right-color: #434a54;
      border-top-color: #434a54; }
    .corner-indicator.corner-top-right.corner-grey-medium {
      border-right-color: #59606a;
      border-top-color: #59606a; }
    .corner-indicator.corner-top-right.corner-grey-medium::after {
      border-right-color: #656d78;
      border-top-color: #656d78; }
    .corner-indicator.corner-top-right.corner-grey {
      border-right-color: #9ca5b2;
      border-top-color: #9ca5b2; }
    .corner-indicator.corner-top-right.corner-grey::after {
      border-right-color: #aab2bd;
      border-top-color: #aab2bd; }
    .corner-indicator.corner-top-right.corner-grey-light {
      border-right-color: #bdc4ce;
      border-top-color: #bdc4ce; }
    .corner-indicator.corner-top-right.corner-grey-light::after {
      border-right-color: #ccd1d9;
      border-top-color: #ccd1d9; }
    .corner-indicator.corner-top-right.corner-grey-lighter {
      border-right-color: #d7dce2;
      border-top-color: #d7dce2; }
    .corner-indicator.corner-top-right.corner-grey-lighter::after {
      border-right-color: #e6e9ed;
      border-top-color: #e6e9ed; }
    .corner-indicator.corner-top-right.corner-grey-smoke {
      border-right-color: #e4e9f2;
      border-top-color: #e4e9f2; }
    .corner-indicator.corner-top-right.corner-grey-smoke::after {
      border-right-color: #f5f7fa;
      border-top-color: #f5f7fa; }
    .corner-indicator.corner-top-right.corner-turquoise-light {
      border-right-color: #8bdfd1;
      border-top-color: #8bdfd1; }
    .corner-indicator.corner-top-right.corner-turquoise-light::after {
      border-right-color: #9fe4d9;
      border-top-color: #9fe4d9; }
    .corner-indicator.corner-top-right.corner-turquoise-lighter {
      border-right-color: #dbf5f1;
      border-top-color: #dbf5f1; }
    .corner-indicator.corner-top-right.corner-turquoise-lighter::after {
      border-right-color: #effaf8;
      border-top-color: #effaf8; }
    .corner-indicator.corner-top-right.corner-turquoise {
      border-right-color: #3bc8b1;
      border-top-color: #3bc8b1; }
    .corner-indicator.corner-top-right.corner-turquoise::after {
      border-right-color: #4fceb9;
      border-top-color: #4fceb9; }
    .corner-indicator.corner-top-right.corner-turquoise-dark {
      border-right-color: #2e9988;
      border-top-color: #2e9988; }
    .corner-indicator.corner-top-right.corner-turquoise-dark::after {
      border-right-color: #34ad99;
      border-top-color: #34ad99; }
    .corner-indicator.corner-top-right.corner-turquoise-darker {
      border-right-color: #227b6d;
      border-top-color: #227b6d; }
    .corner-indicator.corner-top-right.corner-turquoise-darker::after {
      border-right-color: #288f7e;
      border-top-color: #288f7e; }
    .corner-indicator.corner-top-right.corner-emerald-light {
      border-right-color: #69dd9a;
      border-top-color: #69dd9a; }
    .corner-indicator.corner-top-right.corner-emerald-light::after {
      border-right-color: #7ee2a8;
      border-top-color: #7ee2a8; }
    .corner-indicator.corner-top-right.corner-emerald-lighter {
      border-right-color: #bcf0d2;
      border-top-color: #bcf0d2; }
    .corner-indicator.corner-top-right.corner-emerald-lighter::after {
      border-right-color: #d1f5e0;
      border-top-color: #d1f5e0; }
    .corner-indicator.corner-top-right.corner-emerald {
      border-right-color: #29b765;
      border-top-color: #29b765; }
    .corner-indicator.corner-top-right.corner-emerald::after {
      border-right-color: #2ecc71;
      border-top-color: #2ecc71; }
    .corner-indicator.corner-top-right.corner-emerald-dark {
      border-right-color: #229955;
      border-top-color: #229955; }
    .corner-indicator.corner-top-right.corner-emerald-dark::after {
      border-right-color: #27ae60;
      border-top-color: #27ae60; }
    .corner-indicator.corner-top-right.corner-emerald-darker {
      border-right-color: #176437;
      border-top-color: #176437; }
    .corner-indicator.corner-top-right.corner-emerald-darker::after {
      border-right-color: #1b7943;
      border-top-color: #1b7943; }
    .corner-indicator.corner-top-right.corner-amethyst-light {
      border-right-color: #b8a4c2;
      border-top-color: #b8a4c2; }
    .corner-indicator.corner-top-right.corner-amethyst-light::after {
      border-right-color: #c4b3cc;
      border-top-color: #c4b3cc; }
    .corner-indicator.corner-top-right.corner-amethyst-lighter {
      border-right-color: #e7e1eb;
      border-top-color: #e7e1eb; }
    .corner-indicator.corner-top-right.corner-amethyst-lighter::after {
      border-right-color: #f3f0f5;
      border-top-color: #f3f0f5; }
    .corner-indicator.corner-top-right.corner-amethyst {
      border-right-color: #886799;
      border-top-color: #886799; }
    .corner-indicator.corner-top-right.corner-amethyst::after {
      border-right-color: #9476a3;
      border-top-color: #9476a3; }
    .corner-indicator.corner-top-right.corner-amethyst-dark {
      border-right-color: #765e81;
      border-top-color: #765e81; }
    .corner-indicator.corner-top-right.corner-amethyst-dark::after {
      border-right-color: #836990;
      border-top-color: #836990; }
    .corner-indicator.corner-top-right.corner-amethyst-darker {
      border-right-color: #523e5c;
      border-top-color: #523e5c; }
    .corner-indicator.corner-top-right.corner-amethyst-darker::after {
      border-right-color: #5f486b;
      border-top-color: #5f486b; }
  .corner-indicator.corner-bottom-right {
    border-radius: 0 0 2px 0; }
    .corner-indicator.corner-bottom-right::after {
      border-radius: 0 0 2px 0;
      bottom: -4px;
      right: -4px; }
    .corner-indicator.corner-bottom-right.corner-white {
      border-right-color: #f2f2f2;
      border-bottom-color: #f2f2f2; }
    .corner-indicator.corner-bottom-right.corner-white::after {
      border-right-color: #fff;
      border-bottom-color: #fff; }
    .corner-indicator.corner-bottom-right.corner-black {
      border-right-color: black;
      border-bottom-color: black; }
    .corner-indicator.corner-bottom-right.corner-black::after {
      border-right-color: #000;
      border-bottom-color: #000; }
    .corner-indicator.corner-bottom-right.corner-competitor-darker {
      border-right-color: #264445;
      border-bottom-color: #264445; }
    .corner-indicator.corner-bottom-right.corner-competitor-darker::after {
      border-right-color: #2f5455;
      border-bottom-color: #2f5455; }
    .corner-indicator.corner-bottom-right.corner-competitor-dark {
      border-right-color: #20393a;
      border-bottom-color: #20393a; }
    .corner-indicator.corner-bottom-right.corner-competitor-dark::after {
      border-right-color: #29494a;
      border-bottom-color: #29494a; }
    .corner-indicator.corner-bottom-right.corner-competitor {
      border-right-color: #4a8587;
      border-bottom-color: #4a8587; }
    .corner-indicator.corner-bottom-right.corner-competitor::after {
      border-right-color: #539597;
      border-bottom-color: #539597; }
    .corner-indicator.corner-bottom-right.corner-competitor-light {
      border-right-color: #9bdfe1;
      border-bottom-color: #9bdfe1; }
    .corner-indicator.corner-bottom-right.corner-competitor-light::after {
      border-right-color: #afe5e7;
      border-bottom-color: #afe5e7; }
    .corner-indicator.corner-bottom-right.corner-danger-smoke {
      border-right-color: #f6d8d8;
      border-bottom-color: #f6d8d8; }
    .corner-indicator.corner-bottom-right.corner-danger-smoke::after {
      border-right-color: #fbeded;
      border-bottom-color: #fbeded; }
    .corner-indicator.corner-bottom-right.corner-danger-lighter {
      border-right-color: #f2bfbf;
      border-bottom-color: #f2bfbf; }
    .corner-indicator.corner-bottom-right.corner-danger-lighter::after {
      border-right-color: #f6d4d4;
      border-bottom-color: #f6d4d4; }
    .corner-indicator.corner-bottom-right.corner-danger-light {
      border-right-color: #e79592;
      border-bottom-color: #e79592; }
    .corner-indicator.corner-bottom-right.corner-danger-light::after {
      border-right-color: #eca9a7;
      border-bottom-color: #eca9a7; }
    .corner-indicator.corner-bottom-right.corner-danger {
      border-right-color: #d43f3a;
      border-bottom-color: #d43f3a; }
    .corner-indicator.corner-bottom-right.corner-danger::after {
      border-right-color: #d9534f;
      border-bottom-color: #d9534f; }
    .corner-indicator.corner-bottom-right.corner-danger-dark {
      border-right-color: #a6403c;
      border-bottom-color: #a6403c; }
    .corner-indicator.corner-bottom-right.corner-danger-dark::after {
      border-right-color: #b94743;
      border-bottom-color: #b94743; }
    .corner-indicator.corner-bottom-right.corner-danger-darker {
      border-right-color: #8b211e;
      border-bottom-color: #8b211e; }
    .corner-indicator.corner-bottom-right.corner-danger-darker::after {
      border-right-color: #a02622;
      border-bottom-color: #a02622; }
    .corner-indicator.corner-bottom-right.corner-error-smoke {
      border-right-color: #f6d8d8;
      border-bottom-color: #f6d8d8; }
    .corner-indicator.corner-bottom-right.corner-error-smoke::after {
      border-right-color: #fbeded;
      border-bottom-color: #fbeded; }
    .corner-indicator.corner-bottom-right.corner-error-lighter {
      border-right-color: #f2bfbf;
      border-bottom-color: #f2bfbf; }
    .corner-indicator.corner-bottom-right.corner-error-lighter::after {
      border-right-color: #f6d4d4;
      border-bottom-color: #f6d4d4; }
    .corner-indicator.corner-bottom-right.corner-error-light {
      border-right-color: #e79592;
      border-bottom-color: #e79592; }
    .corner-indicator.corner-bottom-right.corner-error-light::after {
      border-right-color: #eca9a7;
      border-bottom-color: #eca9a7; }
    .corner-indicator.corner-bottom-right.corner-error {
      border-right-color: #d43f3a;
      border-bottom-color: #d43f3a; }
    .corner-indicator.corner-bottom-right.corner-error::after {
      border-right-color: #d9534f;
      border-bottom-color: #d9534f; }
    .corner-indicator.corner-bottom-right.corner-error-dark {
      border-right-color: #a6403c;
      border-bottom-color: #a6403c; }
    .corner-indicator.corner-bottom-right.corner-error-dark::after {
      border-right-color: #b94743;
      border-bottom-color: #b94743; }
    .corner-indicator.corner-bottom-right.corner-error-darker {
      border-right-color: #8b211e;
      border-bottom-color: #8b211e; }
    .corner-indicator.corner-bottom-right.corner-error-darker::after {
      border-right-color: #a02622;
      border-bottom-color: #a02622; }
    .corner-indicator.corner-bottom-right.corner-warning-smoke {
      border-right-color: #fdebcd;
      border-bottom-color: #fdebcd; }
    .corner-indicator.corner-bottom-right.corner-warning-smoke::after {
      border-right-color: #fef5e6;
      border-bottom-color: #fef5e6; }
    .corner-indicator.corner-bottom-right.corner-warning-lighter {
      border-right-color: #fedbaf;
      border-bottom-color: #fedbaf; }
    .corner-indicator.corner-bottom-right.corner-warning-lighter::after {
      border-right-color: #fee6c8;
      border-bottom-color: #fee6c8; }
    .corner-indicator.corner-bottom-right.corner-warning-light {
      border-right-color: #fac46d;
      border-bottom-color: #fac46d; }
    .corner-indicator.corner-bottom-right.corner-warning-light::after {
      border-right-color: #fbce86;
      border-bottom-color: #fbce86; }
    .corner-indicator.corner-bottom-right.corner-warning {
      border-right-color: #e68f07;
      border-bottom-color: #e68f07; }
    .corner-indicator.corner-bottom-right.corner-warning::after {
      border-right-color: #f89d0e;
      border-bottom-color: #f89d0e; }
    .corner-indicator.corner-bottom-right.corner-warning-dark {
      border-right-color: #bb770b;
      border-bottom-color: #bb770b; }
    .corner-indicator.corner-bottom-right.corner-warning-dark::after {
      border-right-color: #d3860c;
      border-bottom-color: #d3860c; }
    .corner-indicator.corner-bottom-right.corner-warning-darker {
      border-right-color: #835104;
      border-bottom-color: #835104; }
    .corner-indicator.corner-bottom-right.corner-warning-darker::after {
      border-right-color: #9b6105;
      border-bottom-color: #9b6105; }
    .corner-indicator.corner-bottom-right.corner-success-smoke {
      border-right-color: #dcf1dc;
      border-bottom-color: #dcf1dc; }
    .corner-indicator.corner-bottom-right.corner-success-smoke::after {
      border-right-color: #eef8ee;
      border-bottom-color: #eef8ee; }
    .corner-indicator.corner-bottom-right.corner-success-lighter {
      border-right-color: #c6e5c4;
      border-bottom-color: #c6e5c4; }
    .corner-indicator.corner-bottom-right.corner-success-lighter::after {
      border-right-color: #d7edd6;
      border-bottom-color: #d7edd6; }
    .corner-indicator.corner-bottom-right.corner-success-light {
      border-right-color: #37d251;
      border-bottom-color: #37d251; }
    .corner-indicator.corner-bottom-right.corner-success-light::after {
      border-right-color: #4cd763;
      border-bottom-color: #4cd763; }
    .corner-indicator.corner-bottom-right.corner-success {
      border-right-color: #00ad1e;
      border-bottom-color: #00ad1e; }
    .corner-indicator.corner-bottom-right.corner-success::after {
      border-right-color: #00c622;
      border-bottom-color: #00c622; }
    .corner-indicator.corner-bottom-right.corner-success-dark {
      border-right-color: #468c46;
      border-bottom-color: #468c46; }
    .corner-indicator.corner-bottom-right.corner-success-dark::after {
      border-right-color: #4e9d4e;
      border-bottom-color: #4e9d4e; }
    .corner-indicator.corner-bottom-right.corner-success-darker {
      border-right-color: #2d672d;
      border-bottom-color: #2d672d; }
    .corner-indicator.corner-bottom-right.corner-success-darker::after {
      border-right-color: #357935;
      border-bottom-color: #357935; }
    .corner-indicator.corner-bottom-right.corner-primary-smoke {
      border-right-color: #d8e6f5;
      border-bottom-color: #d8e6f5; }
    .corner-indicator.corner-bottom-right.corner-primary-smoke::after {
      border-right-color: #ecf3fa;
      border-bottom-color: #ecf3fa; }
    .corner-indicator.corner-bottom-right.corner-primary-lighter {
      border-right-color: #bbd6ed;
      border-bottom-color: #bbd6ed; }
    .corner-indicator.corner-bottom-right.corner-primary-lighter::after {
      border-right-color: #cfe2f2;
      border-bottom-color: #cfe2f2; }
    .corner-indicator.corner-bottom-right.corner-primary-light {
      border-right-color: #8cb9de;
      border-bottom-color: #8cb9de; }
    .corner-indicator.corner-bottom-right.corner-primary-light::after {
      border-right-color: #a0c5e4;
      border-bottom-color: #a0c5e4; }
    .corner-indicator.corner-bottom-right.corner-primary {
      border-right-color: #357ebd;
      border-bottom-color: #357ebd; }
    .corner-indicator.corner-bottom-right.corner-primary::after {
      border-right-color: #428bca;
      border-bottom-color: #428bca; }
    .corner-indicator.corner-bottom-right.corner-primary-dark {
      border-right-color: #326999;
      border-bottom-color: #326999; }
    .corner-indicator.corner-bottom-right.corner-primary-dark::after {
      border-right-color: #3876ac;
      border-bottom-color: #3876ac; }
    .corner-indicator.corner-bottom-right.corner-primary-darker {
      border-right-color: #254f73;
      border-bottom-color: #254f73; }
    .corner-indicator.corner-bottom-right.corner-primary-darker::after {
      border-right-color: #2b5c86;
      border-bottom-color: #2b5c86; }
    .corner-indicator.corner-bottom-right.corner-grey-darker {
      border-right-color: #2a2f39;
      border-bottom-color: #2a2f39; }
    .corner-indicator.corner-bottom-right.corner-grey-darker::after {
      border-right-color: #353b48;
      border-bottom-color: #353b48; }
    .corner-indicator.corner-bottom-right.corner-grey-dark {
      border-right-color: #383e46;
      border-bottom-color: #383e46; }
    .corner-indicator.corner-bottom-right.corner-grey-dark::after {
      border-right-color: #434a54;
      border-bottom-color: #434a54; }
    .corner-indicator.corner-bottom-right.corner-grey-medium {
      border-right-color: #59606a;
      border-bottom-color: #59606a; }
    .corner-indicator.corner-bottom-right.corner-grey-medium::after {
      border-right-color: #656d78;
      border-bottom-color: #656d78; }
    .corner-indicator.corner-bottom-right.corner-grey {
      border-right-color: #9ca5b2;
      border-bottom-color: #9ca5b2; }
    .corner-indicator.corner-bottom-right.corner-grey::after {
      border-right-color: #aab2bd;
      border-bottom-color: #aab2bd; }
    .corner-indicator.corner-bottom-right.corner-grey-light {
      border-right-color: #bdc4ce;
      border-bottom-color: #bdc4ce; }
    .corner-indicator.corner-bottom-right.corner-grey-light::after {
      border-right-color: #ccd1d9;
      border-bottom-color: #ccd1d9; }
    .corner-indicator.corner-bottom-right.corner-grey-lighter {
      border-right-color: #d7dce2;
      border-bottom-color: #d7dce2; }
    .corner-indicator.corner-bottom-right.corner-grey-lighter::after {
      border-right-color: #e6e9ed;
      border-bottom-color: #e6e9ed; }
    .corner-indicator.corner-bottom-right.corner-grey-smoke {
      border-right-color: #e4e9f2;
      border-bottom-color: #e4e9f2; }
    .corner-indicator.corner-bottom-right.corner-grey-smoke::after {
      border-right-color: #f5f7fa;
      border-bottom-color: #f5f7fa; }
    .corner-indicator.corner-bottom-right.corner-turquoise-light {
      border-right-color: #8bdfd1;
      border-bottom-color: #8bdfd1; }
    .corner-indicator.corner-bottom-right.corner-turquoise-light::after {
      border-right-color: #9fe4d9;
      border-bottom-color: #9fe4d9; }
    .corner-indicator.corner-bottom-right.corner-turquoise-lighter {
      border-right-color: #dbf5f1;
      border-bottom-color: #dbf5f1; }
    .corner-indicator.corner-bottom-right.corner-turquoise-lighter::after {
      border-right-color: #effaf8;
      border-bottom-color: #effaf8; }
    .corner-indicator.corner-bottom-right.corner-turquoise {
      border-right-color: #3bc8b1;
      border-bottom-color: #3bc8b1; }
    .corner-indicator.corner-bottom-right.corner-turquoise::after {
      border-right-color: #4fceb9;
      border-bottom-color: #4fceb9; }
    .corner-indicator.corner-bottom-right.corner-turquoise-dark {
      border-right-color: #2e9988;
      border-bottom-color: #2e9988; }
    .corner-indicator.corner-bottom-right.corner-turquoise-dark::after {
      border-right-color: #34ad99;
      border-bottom-color: #34ad99; }
    .corner-indicator.corner-bottom-right.corner-turquoise-darker {
      border-right-color: #227b6d;
      border-bottom-color: #227b6d; }
    .corner-indicator.corner-bottom-right.corner-turquoise-darker::after {
      border-right-color: #288f7e;
      border-bottom-color: #288f7e; }
    .corner-indicator.corner-bottom-right.corner-emerald-light {
      border-right-color: #69dd9a;
      border-bottom-color: #69dd9a; }
    .corner-indicator.corner-bottom-right.corner-emerald-light::after {
      border-right-color: #7ee2a8;
      border-bottom-color: #7ee2a8; }
    .corner-indicator.corner-bottom-right.corner-emerald-lighter {
      border-right-color: #bcf0d2;
      border-bottom-color: #bcf0d2; }
    .corner-indicator.corner-bottom-right.corner-emerald-lighter::after {
      border-right-color: #d1f5e0;
      border-bottom-color: #d1f5e0; }
    .corner-indicator.corner-bottom-right.corner-emerald {
      border-right-color: #29b765;
      border-bottom-color: #29b765; }
    .corner-indicator.corner-bottom-right.corner-emerald::after {
      border-right-color: #2ecc71;
      border-bottom-color: #2ecc71; }
    .corner-indicator.corner-bottom-right.corner-emerald-dark {
      border-right-color: #229955;
      border-bottom-color: #229955; }
    .corner-indicator.corner-bottom-right.corner-emerald-dark::after {
      border-right-color: #27ae60;
      border-bottom-color: #27ae60; }
    .corner-indicator.corner-bottom-right.corner-emerald-darker {
      border-right-color: #176437;
      border-bottom-color: #176437; }
    .corner-indicator.corner-bottom-right.corner-emerald-darker::after {
      border-right-color: #1b7943;
      border-bottom-color: #1b7943; }
    .corner-indicator.corner-bottom-right.corner-amethyst-light {
      border-right-color: #b8a4c2;
      border-bottom-color: #b8a4c2; }
    .corner-indicator.corner-bottom-right.corner-amethyst-light::after {
      border-right-color: #c4b3cc;
      border-bottom-color: #c4b3cc; }
    .corner-indicator.corner-bottom-right.corner-amethyst-lighter {
      border-right-color: #e7e1eb;
      border-bottom-color: #e7e1eb; }
    .corner-indicator.corner-bottom-right.corner-amethyst-lighter::after {
      border-right-color: #f3f0f5;
      border-bottom-color: #f3f0f5; }
    .corner-indicator.corner-bottom-right.corner-amethyst {
      border-right-color: #886799;
      border-bottom-color: #886799; }
    .corner-indicator.corner-bottom-right.corner-amethyst::after {
      border-right-color: #9476a3;
      border-bottom-color: #9476a3; }
    .corner-indicator.corner-bottom-right.corner-amethyst-dark {
      border-right-color: #765e81;
      border-bottom-color: #765e81; }
    .corner-indicator.corner-bottom-right.corner-amethyst-dark::after {
      border-right-color: #836990;
      border-bottom-color: #836990; }
    .corner-indicator.corner-bottom-right.corner-amethyst-darker {
      border-right-color: #523e5c;
      border-bottom-color: #523e5c; }
    .corner-indicator.corner-bottom-right.corner-amethyst-darker::after {
      border-right-color: #5f486b;
      border-bottom-color: #5f486b; }

/*
Directions Labels
*/
a.direction-label,
a.direction-label:hover {
  text-decoration: none; }

.direction-label {
  color: #656d78;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  vertical-align: baseline;
  white-space: normal;
  word-break: break-all; }
  .direction-label:active {
    color: #428bca; }
  .direction-label:hover, .direction-label:focus {
    color: #3876ac; }
  .direction-label:active .icon, .direction-label:hover .icon, .direction-label:focus .icon {
    fill: #3876ac; }
  .direction-label .icon {
    display: inline-block;
    height: 10px;
    width: 10px;
    fill: #aab2bd; }
  .direction-label .icon-arrow-up,
  .direction-label .icon-arrow-down {
    display: none; }
  .direction-label.important {
    font-weight: 600; }
  .direction-label.up .icon-arrow-up-down {
    display: none; }
  .direction-label.up .icon-arrow-up {
    display: inline-block; }
  .direction-label.down .icon-arrow-up-down {
    display: none; }
  .direction-label.down .icon-arrow-down {
    display: inline-block; }

/*
Dot
*/
.dot {
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ccd1d9;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  height: 13px;
  letter-spacing: -1px;
  text-align: center;
  vertical-align: middle;
  width: 13px; }
  .dot.dot-extra-small, .dot.dot-xs {
    height: 5px;
    width: 5px; }
  .dot.dot-small, .dot.dot-sm {
    height: 10px;
    line-height: 10px;
    width: 10px; }
  .dot.dot-medium, .dot.dot-md {
    height: 15px;
    line-height: 15px;
    width: 15px; }
  .dot.dot-large, .dot.dot-lg {
    height: 18px;
    line-height: 18px;
    width: 18px; }
  .dot.dot-xxl {
    font-size: 18px;
    height: 30px;
    line-height: 28px;
    width: 30px; }
  .dot.dot-white {
    background-color: #fff;
    border-color: #fff; }
    .dot.dot-white.active {
      background-color: #fff;
      color: #fff;
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.3); }
  .dot.dot-black {
    background-color: #000;
    border-color: #000; }
    .dot.dot-black.active {
      background-color: #fff;
      color: #000;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .dot.dot-competitor-darker {
    background-color: #2f5455;
    border-color: #2f5455; }
    .dot.dot-competitor-darker.active {
      background-color: #fff;
      color: #2f5455;
      box-shadow: inset 0 0 5px rgba(47, 84, 85, 0.3); }
  .dot.dot-competitor-dark {
    background-color: #29494a;
    border-color: #29494a; }
    .dot.dot-competitor-dark.active {
      background-color: #fff;
      color: #29494a;
      box-shadow: inset 0 0 5px rgba(41, 73, 74, 0.3); }
  .dot.dot-competitor {
    background-color: #539597;
    border-color: #539597; }
    .dot.dot-competitor.active {
      background-color: #fff;
      color: #539597;
      box-shadow: inset 0 0 5px rgba(83, 149, 151, 0.3); }
  .dot.dot-competitor-light {
    background-color: #afe5e7;
    border-color: #afe5e7; }
    .dot.dot-competitor-light.active {
      background-color: #fff;
      color: #afe5e7;
      box-shadow: inset 0 0 5px rgba(175, 229, 231, 0.3); }
  .dot.dot-danger-smoke {
    background-color: #fbeded;
    border-color: #fbeded; }
    .dot.dot-danger-smoke.active {
      background-color: #fff;
      color: #fbeded;
      box-shadow: inset 0 0 5px rgba(251, 237, 237, 0.3); }
  .dot.dot-danger-lighter {
    background-color: #f6d4d4;
    border-color: #f6d4d4; }
    .dot.dot-danger-lighter.active {
      background-color: #fff;
      color: #f6d4d4;
      box-shadow: inset 0 0 5px rgba(246, 212, 212, 0.3); }
  .dot.dot-danger-light {
    background-color: #eca9a7;
    border-color: #eca9a7; }
    .dot.dot-danger-light.active {
      background-color: #fff;
      color: #eca9a7;
      box-shadow: inset 0 0 5px rgba(236, 169, 167, 0.3); }
  .dot.dot-danger {
    background-color: #d9534f;
    border-color: #d9534f; }
    .dot.dot-danger.active {
      background-color: #fff;
      color: #d9534f;
      box-shadow: inset 0 0 5px rgba(217, 83, 79, 0.3); }
  .dot.dot-danger-dark {
    background-color: #b94743;
    border-color: #b94743; }
    .dot.dot-danger-dark.active {
      background-color: #fff;
      color: #b94743;
      box-shadow: inset 0 0 5px rgba(185, 71, 67, 0.3); }
  .dot.dot-danger-darker {
    background-color: #a02622;
    border-color: #a02622; }
    .dot.dot-danger-darker.active {
      background-color: #fff;
      color: #a02622;
      box-shadow: inset 0 0 5px rgba(160, 38, 34, 0.3); }
  .dot.dot-error-smoke {
    background-color: #fbeded;
    border-color: #fbeded; }
    .dot.dot-error-smoke.active {
      background-color: #fff;
      color: #fbeded;
      box-shadow: inset 0 0 5px rgba(251, 237, 237, 0.3); }
  .dot.dot-error-lighter {
    background-color: #f6d4d4;
    border-color: #f6d4d4; }
    .dot.dot-error-lighter.active {
      background-color: #fff;
      color: #f6d4d4;
      box-shadow: inset 0 0 5px rgba(246, 212, 212, 0.3); }
  .dot.dot-error-light {
    background-color: #eca9a7;
    border-color: #eca9a7; }
    .dot.dot-error-light.active {
      background-color: #fff;
      color: #eca9a7;
      box-shadow: inset 0 0 5px rgba(236, 169, 167, 0.3); }
  .dot.dot-error {
    background-color: #d9534f;
    border-color: #d9534f; }
    .dot.dot-error.active {
      background-color: #fff;
      color: #d9534f;
      box-shadow: inset 0 0 5px rgba(217, 83, 79, 0.3); }
  .dot.dot-error-dark {
    background-color: #b94743;
    border-color: #b94743; }
    .dot.dot-error-dark.active {
      background-color: #fff;
      color: #b94743;
      box-shadow: inset 0 0 5px rgba(185, 71, 67, 0.3); }
  .dot.dot-error-darker {
    background-color: #a02622;
    border-color: #a02622; }
    .dot.dot-error-darker.active {
      background-color: #fff;
      color: #a02622;
      box-shadow: inset 0 0 5px rgba(160, 38, 34, 0.3); }
  .dot.dot-warning-smoke {
    background-color: #fef5e6;
    border-color: #fef5e6; }
    .dot.dot-warning-smoke.active {
      background-color: #fff;
      color: #fef5e6;
      box-shadow: inset 0 0 5px rgba(254, 245, 230, 0.3); }
  .dot.dot-warning-lighter {
    background-color: #fee6c8;
    border-color: #fee6c8; }
    .dot.dot-warning-lighter.active {
      background-color: #fff;
      color: #fee6c8;
      box-shadow: inset 0 0 5px rgba(254, 230, 200, 0.3); }
  .dot.dot-warning-light {
    background-color: #fbce86;
    border-color: #fbce86; }
    .dot.dot-warning-light.active {
      background-color: #fff;
      color: #fbce86;
      box-shadow: inset 0 0 5px rgba(251, 206, 134, 0.3); }
  .dot.dot-warning {
    background-color: #f89d0e;
    border-color: #f89d0e; }
    .dot.dot-warning.active {
      background-color: #fff;
      color: #f89d0e;
      box-shadow: inset 0 0 5px rgba(248, 157, 14, 0.3); }
  .dot.dot-warning-dark {
    background-color: #d3860c;
    border-color: #d3860c; }
    .dot.dot-warning-dark.active {
      background-color: #fff;
      color: #d3860c;
      box-shadow: inset 0 0 5px rgba(211, 134, 12, 0.3); }
  .dot.dot-warning-darker {
    background-color: #9b6105;
    border-color: #9b6105; }
    .dot.dot-warning-darker.active {
      background-color: #fff;
      color: #9b6105;
      box-shadow: inset 0 0 5px rgba(155, 97, 5, 0.3); }
  .dot.dot-success-smoke {
    background-color: #eef8ee;
    border-color: #eef8ee; }
    .dot.dot-success-smoke.active {
      background-color: #fff;
      color: #eef8ee;
      box-shadow: inset 0 0 5px rgba(238, 248, 238, 0.3); }
  .dot.dot-success-lighter {
    background-color: #d7edd6;
    border-color: #d7edd6; }
    .dot.dot-success-lighter.active {
      background-color: #fff;
      color: #d7edd6;
      box-shadow: inset 0 0 5px rgba(215, 237, 214, 0.3); }
  .dot.dot-success-light {
    background-color: #4cd763;
    border-color: #4cd763; }
    .dot.dot-success-light.active {
      background-color: #fff;
      color: #4cd763;
      box-shadow: inset 0 0 5px rgba(76, 215, 99, 0.3); }
  .dot.dot-success {
    background-color: #00c622;
    border-color: #00c622; }
    .dot.dot-success.active {
      background-color: #fff;
      color: #00c622;
      box-shadow: inset 0 0 5px rgba(0, 198, 34, 0.3); }
  .dot.dot-success-dark {
    background-color: #4e9d4e;
    border-color: #4e9d4e; }
    .dot.dot-success-dark.active {
      background-color: #fff;
      color: #4e9d4e;
      box-shadow: inset 0 0 5px rgba(78, 157, 78, 0.3); }
  .dot.dot-success-darker {
    background-color: #357935;
    border-color: #357935; }
    .dot.dot-success-darker.active {
      background-color: #fff;
      color: #357935;
      box-shadow: inset 0 0 5px rgba(53, 121, 53, 0.3); }
  .dot.dot-primary-smoke {
    background-color: #ecf3fa;
    border-color: #ecf3fa; }
    .dot.dot-primary-smoke.active {
      background-color: #fff;
      color: #ecf3fa;
      box-shadow: inset 0 0 5px rgba(236, 243, 250, 0.3); }
  .dot.dot-primary-lighter {
    background-color: #cfe2f2;
    border-color: #cfe2f2; }
    .dot.dot-primary-lighter.active {
      background-color: #fff;
      color: #cfe2f2;
      box-shadow: inset 0 0 5px rgba(207, 226, 242, 0.3); }
  .dot.dot-primary-light {
    background-color: #a0c5e4;
    border-color: #a0c5e4; }
    .dot.dot-primary-light.active {
      background-color: #fff;
      color: #a0c5e4;
      box-shadow: inset 0 0 5px rgba(160, 197, 228, 0.3); }
  .dot.dot-primary {
    background-color: #428bca;
    border-color: #428bca; }
    .dot.dot-primary.active {
      background-color: #fff;
      color: #428bca;
      box-shadow: inset 0 0 5px rgba(66, 139, 202, 0.3); }
  .dot.dot-primary-dark {
    background-color: #3876ac;
    border-color: #3876ac; }
    .dot.dot-primary-dark.active {
      background-color: #fff;
      color: #3876ac;
      box-shadow: inset 0 0 5px rgba(56, 118, 172, 0.3); }
  .dot.dot-primary-darker {
    background-color: #2b5c86;
    border-color: #2b5c86; }
    .dot.dot-primary-darker.active {
      background-color: #fff;
      color: #2b5c86;
      box-shadow: inset 0 0 5px rgba(43, 92, 134, 0.3); }
  .dot.dot-grey-darker {
    background-color: #353b48;
    border-color: #353b48; }
    .dot.dot-grey-darker.active {
      background-color: #fff;
      color: #353b48;
      box-shadow: inset 0 0 5px rgba(53, 59, 72, 0.3); }
  .dot.dot-grey-dark {
    background-color: #434a54;
    border-color: #434a54; }
    .dot.dot-grey-dark.active {
      background-color: #fff;
      color: #434a54;
      box-shadow: inset 0 0 5px rgba(67, 74, 84, 0.3); }
  .dot.dot-grey-medium {
    background-color: #656d78;
    border-color: #656d78; }
    .dot.dot-grey-medium.active {
      background-color: #fff;
      color: #656d78;
      box-shadow: inset 0 0 5px rgba(101, 109, 120, 0.3); }
  .dot.dot-grey {
    background-color: #aab2bd;
    border-color: #aab2bd; }
    .dot.dot-grey.active {
      background-color: #fff;
      color: #aab2bd;
      box-shadow: inset 0 0 5px rgba(170, 178, 189, 0.3); }
  .dot.dot-grey-light {
    background-color: #ccd1d9;
    border-color: #ccd1d9; }
    .dot.dot-grey-light.active {
      background-color: #fff;
      color: #ccd1d9;
      box-shadow: inset 0 0 5px rgba(204, 209, 217, 0.3); }
  .dot.dot-grey-lighter {
    background-color: #e6e9ed;
    border-color: #e6e9ed; }
    .dot.dot-grey-lighter.active {
      background-color: #fff;
      color: #e6e9ed;
      box-shadow: inset 0 0 5px rgba(230, 233, 237, 0.3); }
  .dot.dot-grey-smoke {
    background-color: #f5f7fa;
    border-color: #f5f7fa; }
    .dot.dot-grey-smoke.active {
      background-color: #fff;
      color: #f5f7fa;
      box-shadow: inset 0 0 5px rgba(245, 247, 250, 0.3); }
  .dot.dot-turquoise-light {
    background-color: #9fe4d9;
    border-color: #9fe4d9; }
    .dot.dot-turquoise-light.active {
      background-color: #fff;
      color: #9fe4d9;
      box-shadow: inset 0 0 5px rgba(159, 228, 217, 0.3); }
  .dot.dot-turquoise-lighter {
    background-color: #effaf8;
    border-color: #effaf8; }
    .dot.dot-turquoise-lighter.active {
      background-color: #fff;
      color: #effaf8;
      box-shadow: inset 0 0 5px rgba(239, 250, 248, 0.3); }
  .dot.dot-turquoise {
    background-color: #4fceb9;
    border-color: #4fceb9; }
    .dot.dot-turquoise.active {
      background-color: #fff;
      color: #4fceb9;
      box-shadow: inset 0 0 5px rgba(79, 206, 185, 0.3); }
  .dot.dot-turquoise-dark {
    background-color: #34ad99;
    border-color: #34ad99; }
    .dot.dot-turquoise-dark.active {
      background-color: #fff;
      color: #34ad99;
      box-shadow: inset 0 0 5px rgba(52, 173, 153, 0.3); }
  .dot.dot-turquoise-darker {
    background-color: #288f7e;
    border-color: #288f7e; }
    .dot.dot-turquoise-darker.active {
      background-color: #fff;
      color: #288f7e;
      box-shadow: inset 0 0 5px rgba(40, 143, 126, 0.3); }
  .dot.dot-emerald-light {
    background-color: #7ee2a8;
    border-color: #7ee2a8; }
    .dot.dot-emerald-light.active {
      background-color: #fff;
      color: #7ee2a8;
      box-shadow: inset 0 0 5px rgba(126, 226, 168, 0.3); }
  .dot.dot-emerald-lighter {
    background-color: #d1f5e0;
    border-color: #d1f5e0; }
    .dot.dot-emerald-lighter.active {
      background-color: #fff;
      color: #d1f5e0;
      box-shadow: inset 0 0 5px rgba(209, 245, 224, 0.3); }
  .dot.dot-emerald {
    background-color: #2ecc71;
    border-color: #2ecc71; }
    .dot.dot-emerald.active {
      background-color: #fff;
      color: #2ecc71;
      box-shadow: inset 0 0 5px rgba(46, 204, 113, 0.3); }
  .dot.dot-emerald-dark {
    background-color: #27ae60;
    border-color: #27ae60; }
    .dot.dot-emerald-dark.active {
      background-color: #fff;
      color: #27ae60;
      box-shadow: inset 0 0 5px rgba(39, 174, 96, 0.3); }
  .dot.dot-emerald-darker {
    background-color: #1b7943;
    border-color: #1b7943; }
    .dot.dot-emerald-darker.active {
      background-color: #fff;
      color: #1b7943;
      box-shadow: inset 0 0 5px rgba(27, 121, 67, 0.3); }
  .dot.dot-amethyst-light {
    background-color: #c4b3cc;
    border-color: #c4b3cc; }
    .dot.dot-amethyst-light.active {
      background-color: #fff;
      color: #c4b3cc;
      box-shadow: inset 0 0 5px rgba(196, 179, 204, 0.3); }
  .dot.dot-amethyst-lighter {
    background-color: #f3f0f5;
    border-color: #f3f0f5; }
    .dot.dot-amethyst-lighter.active {
      background-color: #fff;
      color: #f3f0f5;
      box-shadow: inset 0 0 5px rgba(243, 240, 245, 0.3); }
  .dot.dot-amethyst {
    background-color: #9476a3;
    border-color: #9476a3; }
    .dot.dot-amethyst.active {
      background-color: #fff;
      color: #9476a3;
      box-shadow: inset 0 0 5px rgba(148, 118, 163, 0.3); }
  .dot.dot-amethyst-dark {
    background-color: #836990;
    border-color: #836990; }
    .dot.dot-amethyst-dark.active {
      background-color: #fff;
      color: #836990;
      box-shadow: inset 0 0 5px rgba(131, 105, 144, 0.3); }
  .dot.dot-amethyst-darker {
    background-color: #5f486b;
    border-color: #5f486b; }
    .dot.dot-amethyst-darker.active {
      background-color: #fff;
      color: #5f486b;
      box-shadow: inset 0 0 5px rgba(95, 72, 107, 0.3); }
  .dot.dot-border-white {
    border-color: #fff; }
  .dot.dot-border-black {
    border-color: #000; }
  .dot.dot-border-competitor-darker {
    border-color: #2f5455; }
  .dot.dot-border-competitor-dark {
    border-color: #29494a; }
  .dot.dot-border-competitor {
    border-color: #539597; }
  .dot.dot-border-competitor-light {
    border-color: #afe5e7; }
  .dot.dot-border-danger-smoke {
    border-color: #fbeded; }
  .dot.dot-border-danger-lighter {
    border-color: #f6d4d4; }
  .dot.dot-border-danger-light {
    border-color: #eca9a7; }
  .dot.dot-border-danger {
    border-color: #d9534f; }
  .dot.dot-border-danger-dark {
    border-color: #b94743; }
  .dot.dot-border-danger-darker {
    border-color: #a02622; }
  .dot.dot-border-error-smoke {
    border-color: #fbeded; }
  .dot.dot-border-error-lighter {
    border-color: #f6d4d4; }
  .dot.dot-border-error-light {
    border-color: #eca9a7; }
  .dot.dot-border-error {
    border-color: #d9534f; }
  .dot.dot-border-error-dark {
    border-color: #b94743; }
  .dot.dot-border-error-darker {
    border-color: #a02622; }
  .dot.dot-border-warning-smoke {
    border-color: #fef5e6; }
  .dot.dot-border-warning-lighter {
    border-color: #fee6c8; }
  .dot.dot-border-warning-light {
    border-color: #fbce86; }
  .dot.dot-border-warning {
    border-color: #f89d0e; }
  .dot.dot-border-warning-dark {
    border-color: #d3860c; }
  .dot.dot-border-warning-darker {
    border-color: #9b6105; }
  .dot.dot-border-success-smoke {
    border-color: #eef8ee; }
  .dot.dot-border-success-lighter {
    border-color: #d7edd6; }
  .dot.dot-border-success-light {
    border-color: #4cd763; }
  .dot.dot-border-success {
    border-color: #00c622; }
  .dot.dot-border-success-dark {
    border-color: #4e9d4e; }
  .dot.dot-border-success-darker {
    border-color: #357935; }
  .dot.dot-border-primary-smoke {
    border-color: #ecf3fa; }
  .dot.dot-border-primary-lighter {
    border-color: #cfe2f2; }
  .dot.dot-border-primary-light {
    border-color: #a0c5e4; }
  .dot.dot-border-primary {
    border-color: #428bca; }
  .dot.dot-border-primary-dark {
    border-color: #3876ac; }
  .dot.dot-border-primary-darker {
    border-color: #2b5c86; }
  .dot.dot-border-grey-darker {
    border-color: #353b48; }
  .dot.dot-border-grey-dark {
    border-color: #434a54; }
  .dot.dot-border-grey-medium {
    border-color: #656d78; }
  .dot.dot-border-grey {
    border-color: #aab2bd; }
  .dot.dot-border-grey-light {
    border-color: #ccd1d9; }
  .dot.dot-border-grey-lighter {
    border-color: #e6e9ed; }
  .dot.dot-border-grey-smoke {
    border-color: #f5f7fa; }
  .dot.dot-border-turquoise-light {
    border-color: #9fe4d9; }
  .dot.dot-border-turquoise-lighter {
    border-color: #effaf8; }
  .dot.dot-border-turquoise {
    border-color: #4fceb9; }
  .dot.dot-border-turquoise-dark {
    border-color: #34ad99; }
  .dot.dot-border-turquoise-darker {
    border-color: #288f7e; }
  .dot.dot-border-emerald-light {
    border-color: #7ee2a8; }
  .dot.dot-border-emerald-lighter {
    border-color: #d1f5e0; }
  .dot.dot-border-emerald {
    border-color: #2ecc71; }
  .dot.dot-border-emerald-dark {
    border-color: #27ae60; }
  .dot.dot-border-emerald-darker {
    border-color: #1b7943; }
  .dot.dot-border-amethyst-light {
    border-color: #c4b3cc; }
  .dot.dot-border-amethyst-lighter {
    border-color: #f3f0f5; }
  .dot.dot-border-amethyst {
    border-color: #9476a3; }
  .dot.dot-border-amethyst-dark {
    border-color: #836990; }
  .dot.dot-border-amethyst-darker {
    border-color: #5f486b; }

/*
Dropdown
*/
.dropdown .icon-chevron-down,
.dropdown .icon-chevron-up,
.dropup .icon-chevron-down,
.dropup .icon-chevron-up {
  fill: #353b48;
  height: 20px;
  width: 20px; }

.dropdown .dropdown-menu,
.dropup .dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  margin: 0;
  min-width: 200px;
  max-height: none;
  border-radius: 2px;
  border-color: transparent; }
  .dropdown .dropdown-menu.show,
  .dropup .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    pointer-events: all; }
  .dropdown .dropdown-menu .dropdown-item,
  .dropup .dropdown-menu .dropdown-item {
    color: #353b48;
    padding: 8px 20px;
    margin: 1px 0;
    text-decoration: none;
    font-size: 19px;
    transition: all 0.5s linear; }
    .dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:focus,
    .dropup .dropdown-menu .dropdown-item:hover,
    .dropup .dropdown-menu .dropdown-item:focus {
      color: #2286e6;
      background-color: transparent; }
      .dropdown .dropdown-menu .dropdown-item:hover .badge, .dropdown .dropdown-menu .dropdown-item:focus .badge,
      .dropup .dropdown-menu .dropdown-item:hover .badge,
      .dropup .dropdown-menu .dropdown-item:focus .badge {
        background-color: #fff; }
    .dropdown .dropdown-menu .dropdown-item.dropdown-title,
    .dropup .dropdown-menu .dropdown-item.dropdown-title {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      color: #656d78;
      pointer-events: none; }

.dropdown .dropdown-menu {
  border-radius: 0 0 5px 5px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1); }

.dropup .dropdown-menu {
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); }

.header .dropdown .dropdown-menu .dropdown-item:hover, .header .dropdown .dropdown-menu .dropdown-item:focus {
  text-decoration: underline; }

/*
Form
*/
.form-control, .generation-bar input {
  box-shadow: none; }
  .form-control:focus, .generation-bar input:focus {
    box-shadow: 0 0 5px rgba(66, 139, 202, 0.4);
    border-color: #428bca; }

.form-group .input-addon {
  display: inline-block;
  width: 100%; }
  .form-group .input-addon .addon {
    line-height: 36px; }

.form-group .error-msg {
  color: #d9534f;
  float: right;
  visibility: hidden;
  font-size: 12px;
  margin-top: 2px; }

.form-group.has-error .error-msg {
  visibility: visible; }

.form-group.has-error .control-feedback {
  color: #d9534f; }

.form-group .control-feedback {
  float: right;
  cursor: default;
  text-align: right; }

.form-group .input-addon {
  position: relative; }
  .form-group .input-addon .addon {
    position: absolute;
    top: 0;
    line-height: 34px;
    z-index: 99;
    padding: 0 10px; }
    .form-group .input-addon .addon .btn {
      padding-left: 0;
      padding-right: 0; }
    .form-group .input-addon .addon:first-child {
      left: 0; }
    .form-group .input-addon .addon:last-child {
      right: 0; }
  .form-group .input-addon.addon-left input {
    padding-left: 30px; }
  .form-group .input-addon.addon-right input {
    padding-right: 30px; }

.input-group .addon {
  top: 50%;
  transform: translate(0, -50%); }

.form-centered {
  display: block;
  text-align: center;
  margin: 0 auto;
  max-width: 300px; }

/*
Hero Banner
*/
.hero-banner {
  clear: both;
  overflow: hidden;
  position: relative; }
  .hero-banner h1,
  .hero-banner h2,
  .hero-banner h3,
  .hero-banner h4,
  .hero-banner h5,
  .hero-banner h6,
  .hero-banner .h1,
  .hero-banner .h2,
  .hero-banner .h3,
  .hero-banner .h4,
  .hero-banner .h5,
  .hero-banner .h6 {
    color: #fff;
    text-align: center; }
  .hero-banner h1,
  .hero-banner .h1 {
    font-size: 56px; }
  .hero-banner .container {
    padding: 60px 0; }
  .hero-banner .hero-img {
    display: block;
    height: auto;
    max-width: none;
    width: 100%; }
  .hero-banner.hero-banner-preview {
    background-color: #428bca; }
    .hero-banner.hero-banner-preview::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(66, 139, 202, 0.7);
      z-index: 1; }
    .hero-banner.hero-banner-preview .hero-img {
      filter: blur(3px); }

/*
Labels
*/
.label {
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.1em 0.4em 0.2em;
  position: relative;
  top: -0.1em; }
  .label.label-default {
    background-color: #f5f7fa;
    color: #aab2bd; }
  .label.label-primary {
    background-color: #ecf3fa;
    color: #3876ac; }
  .label.label-success {
    background-color: #eef8ee;
    color: #4e9d4e; }
  .label.label-warning {
    background-color: #fef5e6;
    color: #d3860c; }
  .label.label-danger {
    background-color: #fbeded;
    color: #b94743; }

/*
List Group
*/
.list-group .btn-block + .btn-block {
  margin-top: 0; }

.list-group .list-group-item {
  color: #434a54; }
  .list-group .list-group-item:hover, .list-group .list-group-item:focus {
    text-decoration: none;
    background-color: #ecf3fa;
    color: #2286e6; }

/*
Modal
*/
@media screen and (min-width: 768px) {
  .modal.modal-wide .modal-dialog {
    width: 90%;
    max-width: 890px; } }

.modal .close {
  position: absolute;
  top: 15px;
  right: 10px;
  padding: 10px;
  line-height: 0;
  opacity: 1; }

.modal .modal-header {
  border: none;
  padding: 20px 0 0; }

.modal .modal-cancel-dialog {
  max-width: 580px !important; }

.modal .modal-header-dialog {
  justify-content: center;
  margin-top: 32px; }

.modal .modal-title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #353b48; }

.modal .modal-footer {
  border: none;
  padding: 0 20px 20px; }

.modal .modal-body-dialog-one {
  margin: 0 28px;
  padding: 16px 16px 0; }

.modal .modal-body-dialog-two {
  background: #ecf3fa;
  margin: 0 28px 28px;
  padding: 16px; }

.modal .modal-body-dialog-text {
  font-weight: 600;
  padding-bottom: 8px; }

.modal .modal-footer-dialog {
  justify-content: center;
  border: none;
  padding: 0 20px 28px; }

/*
Page Header
*/
.page-header {
  border-bottom: none;
  margin: 0;
  padding: 40px 0 10px; }
  .page-header.page-header-subtitle h1,
  .page-header.page-header-subtitle .h1 {
    padding-bottom: 10px; }
  .page-header.page-header-alone {
    text-align: center;
    padding: 80px 0 40px; }
    .page-header.page-header-alone h1,
    .page-header.page-header-alone .h1 {
      padding: 0; }
    .page-header.page-header-alone a {
      display: table;
      margin: 0 auto;
      padding: 5px 0;
      text-align: center; }
  .page-header.page-header-icon {
    display: inline-block;
    text-align: left;
    min-width: 50%; }
    .page-header.page-header-icon .icon {
      height: 90px;
      width: 90px;
      float: left;
      margin-right: 10px; }
  .page-header h1,
  .page-header .h1 {
    color: #353b48;
    font-size: 38px;
    line-height: 1.2;
    margin: 0;
    padding: 0 0 20px;
    font-weight: 500; }
  .page-header h2,
  .page-header h3,
  .page-header h4,
  .page-header h5,
  .page-header h6,
  .page-header .h2,
  .page-header .h3,
  .page-header .h4,
  .page-header .h5,
  .page-header .h6 {
    color: #656d78;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    margin: 0;
    padding: 0; }

/*
Panels
*/
.panel .panel-title {
  font-weight: 400; }

/*
Popover
*/
.popover {
  border: 1px solid #ccd1d9;
  padding: 15px; }
  .popover.popover-fluid {
    width: auto;
    max-width: none;
    min-width: auto; }
  .popover.top > .arrow {
    border-top-color: #ccd1d9; }
  .popover.right > .arrow {
    border-right-color: #ccd1d9; }
  .popover.bottom > .arrow {
    border-bottom-color: #ccd1d9; }
  .popover.left > .arrow {
    border-left-color: #ccd1d9; }
  .popover .popover-title {
    padding: 0 0 15px;
    border-bottom: none;
    margin: 0 !important; }
  .popover .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    line-height: 0;
    outline: none; }
    .popover .close:focus, .popover .close:active {
      outline: none; }
    .popover .close .icon {
      height: 13px;
      width: 13px; }

.marketing-checklist .popover {
  border-color: #ccd1d9; }
  .marketing-checklist .popover.bottom .arrow {
    border-bottom-color: #fff;
    border-width: 0 5px 5px;
    margin-left: -5px;
    top: -5px; }
    .marketing-checklist .popover.bottom .arrow::after {
      border-bottom-color: #ccd1d9;
      border-width: 0 5px 5px;
      left: 0;
      margin-left: -5px;
      top: -1px; }
  .marketing-checklist .popover .popover-content {
    color: #353b48;
    font-size: 13px;
    text-align: center; }
  .marketing-checklist .popover .popover-note {
    font-size: 90%;
    margin: 12px 0 0 !important; }

/*
Position Label
*/
a.position-label,
a.position-label:hover {
  text-decoration: none; }

.position-label {
  display: inline-block;
  color: #656d78;
  font-size: 15px;
  font-weight: 500;
  vertical-align: baseline;
  white-space: nowrap; }
  .position-label .icon {
    display: none;
    height: 10px;
    width: 10px; }
  .position-label.important {
    font-weight: 600; }
  .position-label.up {
    color: #5cb85c; }
    .position-label.up .icon-triangle-up {
      display: inline-block;
      fill: #5cb85c; }
  .position-label.down {
    color: #d9534f; }
    .position-label.down .icon-triangle-down {
      display: inline-block;
      fill: #d9534f; }

/*
Progress Bar
*/
.progress-label,
.progress {
  border-radius: 6px;
  box-shadow: none;
  display: inline-block;
  vertical-align: middle;
  width: 100%; }

.progress-label {
  color: #aab2bd;
  font-size: 13px;
  font-weight: 400;
  padding-left: 20px;
  position: relative; }
  .progress-label .dot {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  .progress-label.text-right {
    padding-left: 0;
    padding-right: 20px; }
    .progress-label.text-right .dot {
      left: auto;
      right: 0; }

.progress {
  box-shadow: none;
  margin-bottom: 0;
  background-color: #f5f7fa;
  border: 1px solid #e6e9ed;
  overflow: visible;
  height: 22px; }
  .progress .progress-bar {
    background-color: #cfe2f2;
    border-radius: 6px;
    border: 1px solid #428bca;
    box-shadow: none;
    height: 22px;
    line-height: 20px;
    margin: -1px;
    min-width: 3em;
    width: 0%; }
  .progress .progress-value {
    display: inline; }
  .progress.progress-xxs {
    height: 1px; }
    .progress.progress-xxs .progress-bar {
      height: 0px;
      border-radius: 0;
      position: relative;
      border-top: none; }
    .progress.progress-xxs .progress-value {
      display: none; }
  .progress.progress-xs {
    height: 8px; }
    .progress.progress-xs .progress-bar {
      height: 8px; }
    .progress.progress-xs .progress-value {
      display: none; }
  .progress.progress-sm {
    height: 13px; }
    .progress.progress-sm .progress-bar {
      height: 13px; }
    .progress.progress-sm .progress-bar {
      line-height: 11px;
      font-size: 11px; }
  .progress.progress-md {
    height: 18px; }
    .progress.progress-md .progress-bar {
      height: 18px; }
    .progress.progress-md .progress-bar {
      line-height: 16px;
      font-size: 12px; }
  .progress.progress-outise-value .progress-bar {
    position: relative;
    overflow: visible; }
  .progress.progress-outise-value .progress-value {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    width: 1px;
    text-align: right;
    padding: 2px 0; }
  .progress.progress-white .progress-bar {
    border-color: #fff; }
  .progress.progress-black .progress-bar {
    border-color: #000; }
  .progress.progress-competitor-darker .progress-bar {
    border-color: #2f5455; }
  .progress.progress-competitor-dark .progress-bar {
    border-color: #29494a; }
  .progress.progress-competitor .progress-bar {
    border-color: #539597;
    color: #29494a; }
  .progress.progress-competitor-light .progress-bar {
    border-color: #afe5e7; }
  .progress.progress-danger-smoke .progress-bar {
    border-color: #fbeded; }
  .progress.progress-danger-lighter .progress-bar {
    border-color: #f6d4d4; }
  .progress.progress-danger-light .progress-bar {
    border-color: #eca9a7; }
  .progress.progress-danger .progress-bar {
    background-color: #fbeded;
    border-color: #d9534f;
    color: #b94743; }
  .progress.progress-danger-dark .progress-bar {
    border-color: #b94743; }
  .progress.progress-danger-darker .progress-bar {
    border-color: #a02622; }
  .progress.progress-error-smoke .progress-bar {
    border-color: #fbeded; }
  .progress.progress-error-lighter .progress-bar {
    border-color: #f6d4d4; }
  .progress.progress-error-light .progress-bar {
    border-color: #eca9a7; }
  .progress.progress-error .progress-bar {
    background-color: #fbeded;
    border-color: #d9534f;
    color: #b94743; }
  .progress.progress-error-dark .progress-bar {
    border-color: #b94743; }
  .progress.progress-error-darker .progress-bar {
    border-color: #a02622; }
  .progress.progress-warning-smoke .progress-bar {
    border-color: #fef5e6; }
  .progress.progress-warning-lighter .progress-bar {
    border-color: #fee6c8; }
  .progress.progress-warning-light .progress-bar {
    border-color: #fbce86; }
  .progress.progress-warning .progress-bar {
    background-color: #fef5e6;
    border-color: #f89d0e;
    color: #d3860c; }
  .progress.progress-warning-dark .progress-bar {
    border-color: #d3860c; }
  .progress.progress-warning-darker .progress-bar {
    border-color: #9b6105; }
  .progress.progress-success-smoke .progress-bar {
    border-color: #eef8ee; }
  .progress.progress-success-lighter .progress-bar {
    border-color: #d7edd6; }
  .progress.progress-success-light .progress-bar {
    border-color: #4cd763; }
  .progress.progress-success .progress-bar {
    background-color: #eef8ee;
    border-color: #00c622;
    color: #4e9d4e; }
  .progress.progress-success-dark .progress-bar {
    border-color: #4e9d4e; }
  .progress.progress-success-darker .progress-bar {
    border-color: #357935; }
  .progress.progress-primary-smoke .progress-bar {
    border-color: #ecf3fa; }
  .progress.progress-primary-lighter .progress-bar {
    border-color: #cfe2f2; }
  .progress.progress-primary-light .progress-bar {
    border-color: #a0c5e4; }
  .progress.progress-primary .progress-bar {
    background-color: #ecf3fa;
    border-color: #428bca;
    color: #3876ac; }
  .progress.progress-primary-dark .progress-bar {
    border-color: #3876ac; }
  .progress.progress-primary-darker .progress-bar {
    border-color: #2b5c86; }
  .progress.progress-grey-darker .progress-bar {
    border-color: #353b48; }
  .progress.progress-grey-dark .progress-bar {
    border-color: #434a54; }
  .progress.progress-grey-medium .progress-bar {
    border-color: #656d78; }
  .progress.progress-grey .progress-bar {
    background-color: #f5f7fa;
    border-color: #aab2bd;
    color: #434a54; }
  .progress.progress-grey-light .progress-bar {
    border-color: #ccd1d9; }
  .progress.progress-grey-lighter .progress-bar {
    border-color: #e6e9ed; }
  .progress.progress-grey-smoke .progress-bar {
    border-color: #f5f7fa; }
  .progress.progress-turquoise-light .progress-bar {
    border-color: #9fe4d9; }
  .progress.progress-turquoise-lighter .progress-bar {
    border-color: #effaf8; }
  .progress.progress-turquoise .progress-bar {
    border-color: #4fceb9;
    color: #34ad99; }
  .progress.progress-turquoise-dark .progress-bar {
    border-color: #34ad99; }
  .progress.progress-turquoise-darker .progress-bar {
    border-color: #288f7e; }
  .progress.progress-emerald-light .progress-bar {
    border-color: #7ee2a8; }
  .progress.progress-emerald-lighter .progress-bar {
    border-color: #d1f5e0; }
  .progress.progress-emerald .progress-bar {
    border-color: #2ecc71;
    color: #27ae60; }
  .progress.progress-emerald-dark .progress-bar {
    border-color: #27ae60; }
  .progress.progress-emerald-darker .progress-bar {
    border-color: #1b7943; }
  .progress.progress-amethyst-light .progress-bar {
    border-color: #c4b3cc; }
  .progress.progress-amethyst-lighter .progress-bar {
    border-color: #f3f0f5; }
  .progress.progress-amethyst .progress-bar {
    border-color: #9476a3;
    color: #836990; }
  .progress.progress-amethyst-dark .progress-bar {
    border-color: #836990; }
  .progress.progress-amethyst-darker .progress-bar {
    border-color: #5f486b; }
  .progress.progress-competitor-light .progress-bar {
    background-color: #d6f2f3;
    border-color: #afe5e7;
    color: #74d2d5; }
  .progress.progress-competitor .progress-bar {
    background-color: #8fc0c1;
    border-color: #539597;
    color: #417576; }
  .progress.progress-competitor-dark .progress-bar {
    background-color: #63a7a9;
    border-color: #29494a;
    color: #20393a; }

.progress-container {
  display: inline-block;
  width: 100%;
  font-size: 0;
  padding-bottom: 5px; }
  .progress-container .progress {
    width: 70%; }
  .progress-container .dot {
    display: inline-block;
    position: static;
    transform: translateY(2px);
    margin-right: 5px; }
  .progress-container .progress-label {
    width: 30%;
    text-align: right;
    padding-right: 15px; }
  .progress-container .progress + .progress-label {
    width: 30%;
    padding-left: 15px;
    text-align: left; }

/*
Sub Nav
*/
.container .sub-nav {
  margin: 0 -100% 20px;
  padding: 0 100%; }

.sub-nav {
  background-color: #fff;
  text-align: center;
  font-size: 0; }
  .sub-nav .sub-nav-link {
    display: inline-block;
    padding: 20px 0;
    margin: 0 15px;
    font-size: 13px;
    color: #428bca;
    border-bottom: 2px solid #fff;
    text-decoration: none;
    transition: color 300ms;
    text-transform: uppercase; }
    .sub-nav .sub-nav-link:hover, .sub-nav .sub-nav-link:focus {
      color: #3876ac;
      border-bottom-color: #a0c5e4; }
    .sub-nav .sub-nav-link:focus, .sub-nav .sub-nav-link:hover, .sub-nav .sub-nav-link:active {
      text-decoration: none !important; }
    .sub-nav .sub-nav-link.active {
      border-bottom-color: #428bca; }

/*
Tables
*/
.table td input[type="checkbox"] {
  margin-top: 0; }

.table.table-borderless td {
  border-top: none; }

.table-hover > tbody > tr:hover {
  background-color: #ecf3fa; }

.table-thinhead > thead > tr > th {
  border-bottom-width: 1px; }

/*
Tags
*/
.tag {
  background-color: #f5f7fa;
  border-radius: 2px;
  border: 1px solid #ccd1d9;
  color: #434a54;
  display: inline-block;
  font-size: 0;
  line-height: 1.6;
  margin-top: -1px;
  min-height: 24px;
  min-width: 40px;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap; }
  .tag div,
  .tag span {
    font-size: 13px; }
  @media screen and (min-width: 992px) {
    .tag {
      width: auto; } }
  .tag.tag-sm {
    min-height: 0;
    line-height: 0;
    min-width: 0; }
  .tag.tag-dismissible {
    padding-right: 15px; }
  .tag .icon {
    opacity: 0.5;
    height: 10px;
    width: 10px;
    fill: #aab2bd; }
  .tag:hover .icon, .tag:focus .icon {
    opacity: 1; }
  .tag.tag-primary {
    background-color: #ecf3fa;
    border-color: #3876ac;
    color: #3876ac; }
    .tag.tag-primary .tag-number {
      color: #fff;
      background-color: #3876ac; }
    .tag.tag-primary .icon {
      fill: #3876ac; }
  .tag.tag-success {
    background-color: #eef8ee;
    border-color: #4e9d4e;
    color: #4e9d4e; }
    .tag.tag-success .tag-number {
      color: #fff;
      background-color: #4e9d4e; }
    .tag.tag-success .icon {
      fill: #4e9d4e; }
  .tag.tag-warning {
    background-color: #fef5e6;
    border-color: #d3860c;
    color: #d3860c; }
    .tag.tag-warning .tag-number {
      color: #fff;
      background-color: #d3860c; }
    .tag.tag-warning .icon {
      fill: #d3860c; }
  .tag.tag-error {
    background-color: #fbeded;
    border-color: #b94743;
    color: #b94743; }
    .tag.tag-error .tag-number {
      color: #fff;
      background-color: #b94743; }
    .tag.tag-error .icon {
      fill: #b94743; }
  .tag.tag-turquoise {
    border-color: #34ad99;
    color: #34ad99; }
    .tag.tag-turquoise .tag-number {
      color: #fff;
      background-color: #34ad99; }
    .tag.tag-turquoise .icon {
      fill: #34ad99; }
  .tag.tag-emerald {
    border-color: #27ae60;
    color: #27ae60; }
    .tag.tag-emerald .tag-number {
      color: #fff;
      background-color: #27ae60; }
    .tag.tag-emerald .icon {
      fill: #27ae60; }
  .tag.tag-amethyst {
    border-color: #836990;
    color: #836990; }
    .tag.tag-amethyst .tag-number {
      color: #fff;
      background-color: #836990; }
    .tag.tag-amethyst .icon {
      fill: #836990; }
  .tag.tag-corner {
    padding-left: 10px; }
    .tag.tag-corner.corner-right {
      padding-left: 5px;
      padding-right: 10px; }
      .tag.tag-corner.corner-right.tag-dismissible {
        padding-right: 20px; }
  .tag .tag-text,
  .tag .tag-number {
    white-space: nowrap;
    padding: 3px 5px; }
  .tag .tag-number {
    background-color: #ccd1d9; }
  .tag .corner-indicator.corner-top-left {
    margin-top: -1px;
    margin-left: -1px; }
  .tag .corner-indicator.corner-top-right {
    margin-top: -1px;
    margin-right: -1px; }
  .tag .corner-indicator.corner-bottom-left {
    margin-bottom: -1px;
    margin-left: -1px; }
  .tag .corner-indicator.corner-bottom-right {
    margin-bottom: -1px;
    margin-right: -1px; }
  .tag .btn-close {
    background: none;
    height: 20px;
    line-height: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px; }

/*
Thumbnails
*/
a.thumbnail {
  text-decoration: none; }

/*
Tooltip
*/
.tooltip {
  display: inline-block;
  z-index: 1039;
  margin-top: 3px;
  padding: 5px 0; }
  .tooltip .tooltip-btn-close,
  .tooltip .close {
    float: none;
    font-size: 12px;
    line-height: 0;
    padding: 5px;
    display: inline-block;
    cursor: pointer; }
    .tooltip .tooltip-btn-close .icon,
    .tooltip .close .icon {
      fill: #fff;
      height: 10px;
      width: 10px; }
  .tooltip .tooltip-inner {
    background-color: #434a54; }
  .tooltip .tooltip-title {
    display: block;
    clear: both;
    border-bottom: 1px solid #ccd1d9;
    padding: 0 0 5px;
    margin: 0 0 10px;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase; }
  .tooltip .tooltip-btn-close {
    color: #ccd1d9; }
  .tooltip .tooltip-arrow::after {
    border: 5px solid rgba(255, 255, 255, 0);
    content: "";
    position: absolute;
    top: 0;
    left: 0; }
  .tooltip.left .tooltip-btn-close,
  .tooltip.left .close {
    margin-right: -3px; }
  .tooltip.left .tooltip-arrow::after {
    margin-top: -5px;
    margin-left: -6px; }
  .tooltip.right .tooltip-arrow::after {
    margin-top: -5px;
    margin-left: -4px; }
  .tooltip.top .tooltip-arrow::after {
    margin-top: -6px;
    margin-left: -5px; }
  .tooltip.bottom .tooltip-arrow::after {
    margin-top: -4px;
    margin-left: -5px; }
  .tooltip.bottom-left .tooltip-btn-close,
  .tooltip.bottom-left .close, .tooltip.top-left .tooltip-btn-close,
  .tooltip.top-left .close {
    margin-right: -3px; }
  .tooltip.bottom-left .tooltip-arrow, .tooltip.top-left .tooltip-arrow {
    left: 20px; }
  .tooltip.bottom-right .tooltip-arrow, .tooltip.top-right .tooltip-arrow {
    left: auto;
    right: 20px; }
  .tooltip.bottom-left .tooltip-arrow, .tooltip.bottom-right .tooltip-arrow {
    margin-top: 0; }
    .tooltip.bottom-left .tooltip-arrow::after, .tooltip.bottom-right .tooltip-arrow::after {
      margin-top: -4px;
      margin-left: -5px; }
  .tooltip.top-left .tooltip-arrow, .tooltip.top-right .tooltip-arrow {
    margin-bottom: 0; }
    .tooltip.top-left .tooltip-arrow::after, .tooltip.top-right .tooltip-arrow::after {
      margin-top: -6px;
      margin-left: -5px; }
  .tooltip.left-top .tooltip-arrow {
    top: 20px;
    left: -10px;
    border-width: 5px; }
    .tooltip.left-top .tooltip-arrow::after {
      margin-top: -5px;
      margin-left: -4px; }

.tooltip.tooltip-white .tooltip-inner {
  border: 1px solid;
  color: #fff; }

.tooltip.tooltip-white .tooltip-title {
  color: #fff; }

.tooltip.tooltip-black .tooltip-inner {
  border: 1px solid;
  color: #000; }

.tooltip.tooltip-black .tooltip-title {
  color: #000; }

.tooltip.tooltip-competitor-darker .tooltip-inner {
  border: 1px solid;
  color: #2f5455; }

.tooltip.tooltip-competitor-darker .tooltip-title {
  color: #2f5455; }

.tooltip.tooltip-competitor-dark .tooltip-inner {
  border: 1px solid;
  color: #29494a; }

.tooltip.tooltip-competitor-dark .tooltip-title {
  color: #29494a; }

.tooltip.tooltip-competitor .tooltip-inner {
  border: 1px solid #afe5e7;
  color: #539597; }
  .tooltip.tooltip-competitor .tooltip-inner .close .icon {
    fill: #29494a; }

.tooltip.tooltip-competitor .tooltip-title {
  color: #539597;
  border-bottom-color: #afe5e7; }

.tooltip.tooltip-competitor.bottom .tooltip-arrow, .tooltip.tooltip-competitor.bottom-left .tooltip-arrow, .tooltip.tooltip-competitor.bottom-right .tooltip-arrow {
  border-bottom-color: #afe5e7; }

.tooltip.tooltip-competitor.top .tooltip-arrow, .tooltip.tooltip-competitor.top-left .tooltip-arrow, .tooltip.tooltip-competitor.top-right .tooltip-arrow {
  border-top-color: #afe5e7; }

.tooltip.tooltip-competitor.left .tooltip-arrow {
  border-left-color: #afe5e7; }

.tooltip.tooltip-competitor.right .tooltip-arrow, .tooltip.tooltip-competitor.left-top .tooltip-arrow {
  border-right-color: #afe5e7; }

.tooltip.tooltip-competitor .tooltip-btn-close {
  color: #afe5e7; }

.tooltip.tooltip-competitor-light .tooltip-inner {
  border: 1px solid;
  color: #afe5e7; }

.tooltip.tooltip-competitor-light .tooltip-title {
  color: #afe5e7; }

.tooltip.tooltip-danger-smoke .tooltip-inner {
  border: 1px solid;
  color: #fbeded; }

.tooltip.tooltip-danger-smoke .tooltip-title {
  color: #fbeded; }

.tooltip.tooltip-danger-lighter .tooltip-inner {
  border: 1px solid;
  color: #f6d4d4; }

.tooltip.tooltip-danger-lighter .tooltip-title {
  color: #f6d4d4; }

.tooltip.tooltip-danger-light .tooltip-inner {
  border: 1px solid;
  color: #eca9a7; }

.tooltip.tooltip-danger-light .tooltip-title {
  color: #eca9a7; }

.tooltip.tooltip-danger .tooltip-inner {
  border: 1px solid #eca9a7;
  background-color: #fbeded;
  color: #d9534f; }
  .tooltip.tooltip-danger .tooltip-inner .close .icon {
    fill: #b94743; }

.tooltip.tooltip-danger .tooltip-title {
  color: #d9534f;
  border-bottom-color: #eca9a7; }

.tooltip.tooltip-danger.bottom .tooltip-arrow, .tooltip.tooltip-danger.bottom-left .tooltip-arrow, .tooltip.tooltip-danger.bottom-right .tooltip-arrow {
  border-bottom-color: #eca9a7; }
  .tooltip.tooltip-danger.bottom .tooltip-arrow::after, .tooltip.tooltip-danger.bottom-left .tooltip-arrow::after, .tooltip.tooltip-danger.bottom-right .tooltip-arrow::after {
    border-bottom-color: #fbeded; }

.tooltip.tooltip-danger.top .tooltip-arrow, .tooltip.tooltip-danger.top-left .tooltip-arrow, .tooltip.tooltip-danger.top-right .tooltip-arrow {
  border-top-color: #eca9a7; }
  .tooltip.tooltip-danger.top .tooltip-arrow::after, .tooltip.tooltip-danger.top-left .tooltip-arrow::after, .tooltip.tooltip-danger.top-right .tooltip-arrow::after {
    border-top-color: #fbeded; }

.tooltip.tooltip-danger.left .tooltip-arrow {
  border-left-color: #eca9a7; }
  .tooltip.tooltip-danger.left .tooltip-arrow::after {
    border-left-color: #fbeded; }

.tooltip.tooltip-danger.right .tooltip-arrow, .tooltip.tooltip-danger.left-top .tooltip-arrow {
  border-right-color: #eca9a7; }
  .tooltip.tooltip-danger.right .tooltip-arrow::after, .tooltip.tooltip-danger.left-top .tooltip-arrow::after {
    border-right-color: #fbeded; }

.tooltip.tooltip-danger .tooltip-btn-close {
  color: #eca9a7; }

.tooltip.tooltip-danger-dark .tooltip-inner {
  border: 1px solid;
  color: #b94743; }

.tooltip.tooltip-danger-dark .tooltip-title {
  color: #b94743; }

.tooltip.tooltip-danger-darker .tooltip-inner {
  border: 1px solid;
  color: #a02622; }

.tooltip.tooltip-danger-darker .tooltip-title {
  color: #a02622; }

.tooltip.tooltip-error-smoke .tooltip-inner {
  border: 1px solid;
  color: #fbeded; }

.tooltip.tooltip-error-smoke .tooltip-title {
  color: #fbeded; }

.tooltip.tooltip-error-lighter .tooltip-inner {
  border: 1px solid;
  color: #f6d4d4; }

.tooltip.tooltip-error-lighter .tooltip-title {
  color: #f6d4d4; }

.tooltip.tooltip-error-light .tooltip-inner {
  border: 1px solid;
  color: #eca9a7; }

.tooltip.tooltip-error-light .tooltip-title {
  color: #eca9a7; }

.tooltip.tooltip-error .tooltip-inner {
  border: 1px solid #eca9a7;
  background-color: #fbeded;
  color: #d9534f; }
  .tooltip.tooltip-error .tooltip-inner .close .icon {
    fill: #b94743; }

.tooltip.tooltip-error .tooltip-title {
  color: #d9534f;
  border-bottom-color: #eca9a7; }

.tooltip.tooltip-error.bottom .tooltip-arrow, .tooltip.tooltip-error.bottom-left .tooltip-arrow, .tooltip.tooltip-error.bottom-right .tooltip-arrow {
  border-bottom-color: #eca9a7; }
  .tooltip.tooltip-error.bottom .tooltip-arrow::after, .tooltip.tooltip-error.bottom-left .tooltip-arrow::after, .tooltip.tooltip-error.bottom-right .tooltip-arrow::after {
    border-bottom-color: #fbeded; }

.tooltip.tooltip-error.top .tooltip-arrow, .tooltip.tooltip-error.top-left .tooltip-arrow, .tooltip.tooltip-error.top-right .tooltip-arrow {
  border-top-color: #eca9a7; }
  .tooltip.tooltip-error.top .tooltip-arrow::after, .tooltip.tooltip-error.top-left .tooltip-arrow::after, .tooltip.tooltip-error.top-right .tooltip-arrow::after {
    border-top-color: #fbeded; }

.tooltip.tooltip-error.left .tooltip-arrow {
  border-left-color: #eca9a7; }
  .tooltip.tooltip-error.left .tooltip-arrow::after {
    border-left-color: #fbeded; }

.tooltip.tooltip-error.right .tooltip-arrow, .tooltip.tooltip-error.left-top .tooltip-arrow {
  border-right-color: #eca9a7; }
  .tooltip.tooltip-error.right .tooltip-arrow::after, .tooltip.tooltip-error.left-top .tooltip-arrow::after {
    border-right-color: #fbeded; }

.tooltip.tooltip-error .tooltip-btn-close {
  color: #eca9a7; }

.tooltip.tooltip-error-dark .tooltip-inner {
  border: 1px solid;
  color: #b94743; }

.tooltip.tooltip-error-dark .tooltip-title {
  color: #b94743; }

.tooltip.tooltip-error-darker .tooltip-inner {
  border: 1px solid;
  color: #a02622; }

.tooltip.tooltip-error-darker .tooltip-title {
  color: #a02622; }

.tooltip.tooltip-warning-smoke .tooltip-inner {
  border: 1px solid;
  color: #fef5e6; }

.tooltip.tooltip-warning-smoke .tooltip-title {
  color: #fef5e6; }

.tooltip.tooltip-warning-lighter .tooltip-inner {
  border: 1px solid;
  color: #fee6c8; }

.tooltip.tooltip-warning-lighter .tooltip-title {
  color: #fee6c8; }

.tooltip.tooltip-warning-light .tooltip-inner {
  border: 1px solid;
  color: #fbce86; }

.tooltip.tooltip-warning-light .tooltip-title {
  color: #fbce86; }

.tooltip.tooltip-warning .tooltip-inner {
  border: 1px solid #fbce86;
  background-color: #fef5e6;
  color: #f89d0e; }
  .tooltip.tooltip-warning .tooltip-inner .close .icon {
    fill: #d3860c; }

.tooltip.tooltip-warning .tooltip-title {
  color: #f89d0e;
  border-bottom-color: #fbce86; }

.tooltip.tooltip-warning.bottom .tooltip-arrow, .tooltip.tooltip-warning.bottom-left .tooltip-arrow, .tooltip.tooltip-warning.bottom-right .tooltip-arrow {
  border-bottom-color: #fbce86; }
  .tooltip.tooltip-warning.bottom .tooltip-arrow::after, .tooltip.tooltip-warning.bottom-left .tooltip-arrow::after, .tooltip.tooltip-warning.bottom-right .tooltip-arrow::after {
    border-bottom-color: #fef5e6; }

.tooltip.tooltip-warning.top .tooltip-arrow, .tooltip.tooltip-warning.top-left .tooltip-arrow, .tooltip.tooltip-warning.top-right .tooltip-arrow {
  border-top-color: #fbce86; }
  .tooltip.tooltip-warning.top .tooltip-arrow::after, .tooltip.tooltip-warning.top-left .tooltip-arrow::after, .tooltip.tooltip-warning.top-right .tooltip-arrow::after {
    border-top-color: #fef5e6; }

.tooltip.tooltip-warning.left .tooltip-arrow {
  border-left-color: #fbce86; }
  .tooltip.tooltip-warning.left .tooltip-arrow::after {
    border-left-color: #fef5e6; }

.tooltip.tooltip-warning.right .tooltip-arrow, .tooltip.tooltip-warning.left-top .tooltip-arrow {
  border-right-color: #fbce86; }
  .tooltip.tooltip-warning.right .tooltip-arrow::after, .tooltip.tooltip-warning.left-top .tooltip-arrow::after {
    border-right-color: #fef5e6; }

.tooltip.tooltip-warning .tooltip-btn-close {
  color: #fbce86; }

.tooltip.tooltip-warning-dark .tooltip-inner {
  border: 1px solid;
  color: #d3860c; }

.tooltip.tooltip-warning-dark .tooltip-title {
  color: #d3860c; }

.tooltip.tooltip-warning-darker .tooltip-inner {
  border: 1px solid;
  color: #9b6105; }

.tooltip.tooltip-warning-darker .tooltip-title {
  color: #9b6105; }

.tooltip.tooltip-success-smoke .tooltip-inner {
  border: 1px solid;
  color: #eef8ee; }

.tooltip.tooltip-success-smoke .tooltip-title {
  color: #eef8ee; }

.tooltip.tooltip-success-lighter .tooltip-inner {
  border: 1px solid;
  color: #d7edd6; }

.tooltip.tooltip-success-lighter .tooltip-title {
  color: #d7edd6; }

.tooltip.tooltip-success-light .tooltip-inner {
  border: 1px solid;
  color: #4cd763; }

.tooltip.tooltip-success-light .tooltip-title {
  color: #4cd763; }

.tooltip.tooltip-success .tooltip-inner {
  border: 1px solid #4cd763;
  background-color: #eef8ee;
  color: #00c622; }
  .tooltip.tooltip-success .tooltip-inner .close .icon {
    fill: #4e9d4e; }

.tooltip.tooltip-success .tooltip-title {
  color: #00c622;
  border-bottom-color: #4cd763; }

.tooltip.tooltip-success.bottom .tooltip-arrow, .tooltip.tooltip-success.bottom-left .tooltip-arrow, .tooltip.tooltip-success.bottom-right .tooltip-arrow {
  border-bottom-color: #4cd763; }
  .tooltip.tooltip-success.bottom .tooltip-arrow::after, .tooltip.tooltip-success.bottom-left .tooltip-arrow::after, .tooltip.tooltip-success.bottom-right .tooltip-arrow::after {
    border-bottom-color: #eef8ee; }

.tooltip.tooltip-success.top .tooltip-arrow, .tooltip.tooltip-success.top-left .tooltip-arrow, .tooltip.tooltip-success.top-right .tooltip-arrow {
  border-top-color: #4cd763; }
  .tooltip.tooltip-success.top .tooltip-arrow::after, .tooltip.tooltip-success.top-left .tooltip-arrow::after, .tooltip.tooltip-success.top-right .tooltip-arrow::after {
    border-top-color: #eef8ee; }

.tooltip.tooltip-success.left .tooltip-arrow {
  border-left-color: #4cd763; }
  .tooltip.tooltip-success.left .tooltip-arrow::after {
    border-left-color: #eef8ee; }

.tooltip.tooltip-success.right .tooltip-arrow, .tooltip.tooltip-success.left-top .tooltip-arrow {
  border-right-color: #4cd763; }
  .tooltip.tooltip-success.right .tooltip-arrow::after, .tooltip.tooltip-success.left-top .tooltip-arrow::after {
    border-right-color: #eef8ee; }

.tooltip.tooltip-success .tooltip-btn-close {
  color: #4cd763; }

.tooltip.tooltip-success-dark .tooltip-inner {
  border: 1px solid;
  color: #4e9d4e; }

.tooltip.tooltip-success-dark .tooltip-title {
  color: #4e9d4e; }

.tooltip.tooltip-success-darker .tooltip-inner {
  border: 1px solid;
  color: #357935; }

.tooltip.tooltip-success-darker .tooltip-title {
  color: #357935; }

.tooltip.tooltip-primary-smoke .tooltip-inner {
  border: 1px solid;
  color: #ecf3fa; }

.tooltip.tooltip-primary-smoke .tooltip-title {
  color: #ecf3fa; }

.tooltip.tooltip-primary-lighter .tooltip-inner {
  border: 1px solid;
  color: #cfe2f2; }

.tooltip.tooltip-primary-lighter .tooltip-title {
  color: #cfe2f2; }

.tooltip.tooltip-primary-light .tooltip-inner {
  border: 1px solid;
  color: #a0c5e4; }

.tooltip.tooltip-primary-light .tooltip-title {
  color: #a0c5e4; }

.tooltip.tooltip-primary .tooltip-inner {
  border: 1px solid #a0c5e4;
  background-color: #ecf3fa;
  color: #428bca; }
  .tooltip.tooltip-primary .tooltip-inner .close .icon {
    fill: #3876ac; }

.tooltip.tooltip-primary .tooltip-title {
  color: #428bca;
  border-bottom-color: #a0c5e4; }

.tooltip.tooltip-primary.bottom .tooltip-arrow, .tooltip.tooltip-primary.bottom-left .tooltip-arrow, .tooltip.tooltip-primary.bottom-right .tooltip-arrow {
  border-bottom-color: #a0c5e4; }
  .tooltip.tooltip-primary.bottom .tooltip-arrow::after, .tooltip.tooltip-primary.bottom-left .tooltip-arrow::after, .tooltip.tooltip-primary.bottom-right .tooltip-arrow::after {
    border-bottom-color: #ecf3fa; }

.tooltip.tooltip-primary.top .tooltip-arrow, .tooltip.tooltip-primary.top-left .tooltip-arrow, .tooltip.tooltip-primary.top-right .tooltip-arrow {
  border-top-color: #a0c5e4; }
  .tooltip.tooltip-primary.top .tooltip-arrow::after, .tooltip.tooltip-primary.top-left .tooltip-arrow::after, .tooltip.tooltip-primary.top-right .tooltip-arrow::after {
    border-top-color: #ecf3fa; }

.tooltip.tooltip-primary.left .tooltip-arrow {
  border-left-color: #a0c5e4; }
  .tooltip.tooltip-primary.left .tooltip-arrow::after {
    border-left-color: #ecf3fa; }

.tooltip.tooltip-primary.right .tooltip-arrow, .tooltip.tooltip-primary.left-top .tooltip-arrow {
  border-right-color: #a0c5e4; }
  .tooltip.tooltip-primary.right .tooltip-arrow::after, .tooltip.tooltip-primary.left-top .tooltip-arrow::after {
    border-right-color: #ecf3fa; }

.tooltip.tooltip-primary .tooltip-btn-close {
  color: #a0c5e4; }

.tooltip.tooltip-primary-dark .tooltip-inner {
  border: 1px solid;
  color: #3876ac; }

.tooltip.tooltip-primary-dark .tooltip-title {
  color: #3876ac; }

.tooltip.tooltip-primary-darker .tooltip-inner {
  border: 1px solid;
  color: #2b5c86; }

.tooltip.tooltip-primary-darker .tooltip-title {
  color: #2b5c86; }

.tooltip.tooltip-grey-darker .tooltip-inner {
  border: 1px solid;
  color: #353b48; }

.tooltip.tooltip-grey-darker .tooltip-title {
  color: #353b48; }

.tooltip.tooltip-grey-dark .tooltip-inner {
  border: 1px solid;
  color: #434a54; }

.tooltip.tooltip-grey-dark .tooltip-title {
  color: #434a54; }

.tooltip.tooltip-grey-medium .tooltip-inner {
  border: 1px solid;
  color: #656d78; }

.tooltip.tooltip-grey-medium .tooltip-title {
  color: #656d78; }

.tooltip.tooltip-grey .tooltip-inner {
  border: 1px solid #ccd1d9;
  background-color: #f5f7fa;
  color: #aab2bd; }
  .tooltip.tooltip-grey .tooltip-inner .close .icon {
    fill: #434a54; }

.tooltip.tooltip-grey .tooltip-title {
  color: #aab2bd;
  border-bottom-color: #ccd1d9; }

.tooltip.tooltip-grey.bottom .tooltip-arrow, .tooltip.tooltip-grey.bottom-left .tooltip-arrow, .tooltip.tooltip-grey.bottom-right .tooltip-arrow {
  border-bottom-color: #ccd1d9; }
  .tooltip.tooltip-grey.bottom .tooltip-arrow::after, .tooltip.tooltip-grey.bottom-left .tooltip-arrow::after, .tooltip.tooltip-grey.bottom-right .tooltip-arrow::after {
    border-bottom-color: #f5f7fa; }

.tooltip.tooltip-grey.top .tooltip-arrow, .tooltip.tooltip-grey.top-left .tooltip-arrow, .tooltip.tooltip-grey.top-right .tooltip-arrow {
  border-top-color: #ccd1d9; }
  .tooltip.tooltip-grey.top .tooltip-arrow::after, .tooltip.tooltip-grey.top-left .tooltip-arrow::after, .tooltip.tooltip-grey.top-right .tooltip-arrow::after {
    border-top-color: #f5f7fa; }

.tooltip.tooltip-grey.left .tooltip-arrow {
  border-left-color: #ccd1d9; }
  .tooltip.tooltip-grey.left .tooltip-arrow::after {
    border-left-color: #f5f7fa; }

.tooltip.tooltip-grey.right .tooltip-arrow, .tooltip.tooltip-grey.left-top .tooltip-arrow {
  border-right-color: #ccd1d9; }
  .tooltip.tooltip-grey.right .tooltip-arrow::after, .tooltip.tooltip-grey.left-top .tooltip-arrow::after {
    border-right-color: #f5f7fa; }

.tooltip.tooltip-grey .tooltip-btn-close {
  color: #ccd1d9; }

.tooltip.tooltip-grey-light .tooltip-inner {
  border: 1px solid;
  color: #ccd1d9; }

.tooltip.tooltip-grey-light .tooltip-title {
  color: #ccd1d9; }

.tooltip.tooltip-grey-lighter .tooltip-inner {
  border: 1px solid;
  color: #e6e9ed; }

.tooltip.tooltip-grey-lighter .tooltip-title {
  color: #e6e9ed; }

.tooltip.tooltip-grey-smoke .tooltip-inner {
  border: 1px solid;
  color: #f5f7fa; }

.tooltip.tooltip-grey-smoke .tooltip-title {
  color: #f5f7fa; }

.tooltip.tooltip-turquoise-light .tooltip-inner {
  border: 1px solid;
  color: #9fe4d9; }

.tooltip.tooltip-turquoise-light .tooltip-title {
  color: #9fe4d9; }

.tooltip.tooltip-turquoise-lighter .tooltip-inner {
  border: 1px solid;
  color: #effaf8; }

.tooltip.tooltip-turquoise-lighter .tooltip-title {
  color: #effaf8; }

.tooltip.tooltip-turquoise .tooltip-inner {
  border: 1px solid #9fe4d9;
  color: #4fceb9; }
  .tooltip.tooltip-turquoise .tooltip-inner .close .icon {
    fill: #34ad99; }

.tooltip.tooltip-turquoise .tooltip-title {
  color: #4fceb9;
  border-bottom-color: #9fe4d9; }

.tooltip.tooltip-turquoise.bottom .tooltip-arrow, .tooltip.tooltip-turquoise.bottom-left .tooltip-arrow, .tooltip.tooltip-turquoise.bottom-right .tooltip-arrow {
  border-bottom-color: #9fe4d9; }

.tooltip.tooltip-turquoise.top .tooltip-arrow, .tooltip.tooltip-turquoise.top-left .tooltip-arrow, .tooltip.tooltip-turquoise.top-right .tooltip-arrow {
  border-top-color: #9fe4d9; }

.tooltip.tooltip-turquoise.left .tooltip-arrow {
  border-left-color: #9fe4d9; }

.tooltip.tooltip-turquoise.right .tooltip-arrow, .tooltip.tooltip-turquoise.left-top .tooltip-arrow {
  border-right-color: #9fe4d9; }

.tooltip.tooltip-turquoise .tooltip-btn-close {
  color: #9fe4d9; }

.tooltip.tooltip-turquoise-dark .tooltip-inner {
  border: 1px solid;
  color: #34ad99; }

.tooltip.tooltip-turquoise-dark .tooltip-title {
  color: #34ad99; }

.tooltip.tooltip-turquoise-darker .tooltip-inner {
  border: 1px solid;
  color: #288f7e; }

.tooltip.tooltip-turquoise-darker .tooltip-title {
  color: #288f7e; }

.tooltip.tooltip-emerald-light .tooltip-inner {
  border: 1px solid;
  color: #7ee2a8; }

.tooltip.tooltip-emerald-light .tooltip-title {
  color: #7ee2a8; }

.tooltip.tooltip-emerald-lighter .tooltip-inner {
  border: 1px solid;
  color: #d1f5e0; }

.tooltip.tooltip-emerald-lighter .tooltip-title {
  color: #d1f5e0; }

.tooltip.tooltip-emerald .tooltip-inner {
  border: 1px solid #7ee2a8;
  color: #2ecc71; }
  .tooltip.tooltip-emerald .tooltip-inner .close .icon {
    fill: #27ae60; }

.tooltip.tooltip-emerald .tooltip-title {
  color: #2ecc71;
  border-bottom-color: #7ee2a8; }

.tooltip.tooltip-emerald.bottom .tooltip-arrow, .tooltip.tooltip-emerald.bottom-left .tooltip-arrow, .tooltip.tooltip-emerald.bottom-right .tooltip-arrow {
  border-bottom-color: #7ee2a8; }

.tooltip.tooltip-emerald.top .tooltip-arrow, .tooltip.tooltip-emerald.top-left .tooltip-arrow, .tooltip.tooltip-emerald.top-right .tooltip-arrow {
  border-top-color: #7ee2a8; }

.tooltip.tooltip-emerald.left .tooltip-arrow {
  border-left-color: #7ee2a8; }

.tooltip.tooltip-emerald.right .tooltip-arrow, .tooltip.tooltip-emerald.left-top .tooltip-arrow {
  border-right-color: #7ee2a8; }

.tooltip.tooltip-emerald .tooltip-btn-close {
  color: #7ee2a8; }

.tooltip.tooltip-emerald-dark .tooltip-inner {
  border: 1px solid;
  color: #27ae60; }

.tooltip.tooltip-emerald-dark .tooltip-title {
  color: #27ae60; }

.tooltip.tooltip-emerald-darker .tooltip-inner {
  border: 1px solid;
  color: #1b7943; }

.tooltip.tooltip-emerald-darker .tooltip-title {
  color: #1b7943; }

.tooltip.tooltip-amethyst-light .tooltip-inner {
  border: 1px solid;
  color: #c4b3cc; }

.tooltip.tooltip-amethyst-light .tooltip-title {
  color: #c4b3cc; }

.tooltip.tooltip-amethyst-lighter .tooltip-inner {
  border: 1px solid;
  color: #f3f0f5; }

.tooltip.tooltip-amethyst-lighter .tooltip-title {
  color: #f3f0f5; }

.tooltip.tooltip-amethyst .tooltip-inner {
  border: 1px solid #c4b3cc;
  color: #9476a3; }
  .tooltip.tooltip-amethyst .tooltip-inner .close .icon {
    fill: #836990; }

.tooltip.tooltip-amethyst .tooltip-title {
  color: #9476a3;
  border-bottom-color: #c4b3cc; }

.tooltip.tooltip-amethyst.bottom .tooltip-arrow, .tooltip.tooltip-amethyst.bottom-left .tooltip-arrow, .tooltip.tooltip-amethyst.bottom-right .tooltip-arrow {
  border-bottom-color: #c4b3cc; }

.tooltip.tooltip-amethyst.top .tooltip-arrow, .tooltip.tooltip-amethyst.top-left .tooltip-arrow, .tooltip.tooltip-amethyst.top-right .tooltip-arrow {
  border-top-color: #c4b3cc; }

.tooltip.tooltip-amethyst.left .tooltip-arrow {
  border-left-color: #c4b3cc; }

.tooltip.tooltip-amethyst.right .tooltip-arrow, .tooltip.tooltip-amethyst.left-top .tooltip-arrow {
  border-right-color: #c4b3cc; }

.tooltip.tooltip-amethyst .tooltip-btn-close {
  color: #c4b3cc; }

.tooltip.tooltip-amethyst-dark .tooltip-inner {
  border: 1px solid;
  color: #836990; }

.tooltip.tooltip-amethyst-dark .tooltip-title {
  color: #836990; }

.tooltip.tooltip-amethyst-darker .tooltip-inner {
  border: 1px solid;
  color: #5f486b; }

.tooltip.tooltip-amethyst-darker .tooltip-title {
  color: #5f486b; }

.tooltip.tooltip-white .tooltip-inner {
  border: 1px solid #ccd1d9;
  background-color: #fff;
  color: #434a54; }

.tooltip.tooltip-white.top .tooltip-arrow {
  border-top-color: #ccd1d9; }
  .tooltip.tooltip-white.top .tooltip-arrow::after {
    border-top-color: #fff; }

.tooltip.tooltip-white.bottom .tooltip-arrow {
  border-bottom-color: #ccd1d9; }
  .tooltip.tooltip-white.bottom .tooltip-arrow::after {
    border-bottom-color: #fff; }

.tooltip.tooltip-white.left .tooltip-arrow {
  border-left-color: #ccd1d9; }
  .tooltip.tooltip-white.left .tooltip-arrow::after {
    border-left-color: #fff; }

.tooltip.tooltip-white.right .tooltip-arrow {
  border-right-color: #ccd1d9; }
  .tooltip.tooltip-white.right .tooltip-arrow::after {
    border-right-color: #fff; }

.tooltip.tooltip-white.bottom .tooltip-arrow, .tooltip.tooltip-white.bottom-left .tooltip-arrow, .tooltip.tooltip-white.bottom-right .tooltip-arrow {
  border-bottom-color: #ccd1d9; }
  .tooltip.tooltip-white.bottom .tooltip-arrow::after, .tooltip.tooltip-white.bottom-left .tooltip-arrow::after, .tooltip.tooltip-white.bottom-right .tooltip-arrow::after {
    border-bottom-color: #fff; }

.tooltip.tooltip-white.top .tooltip-arrow, .tooltip.tooltip-white.top-left .tooltip-arrow, .tooltip.tooltip-white.top-right .tooltip-arrow {
  border-top-color: #ccd1d9; }
  .tooltip.tooltip-white.top .tooltip-arrow::after, .tooltip.tooltip-white.top-left .tooltip-arrow::after, .tooltip.tooltip-white.top-right .tooltip-arrow::after {
    border-top-color: #fff; }

.tooltip.tooltip-white.left-top .tooltip-arrow {
  border-right-color: #ccd1d9; }
  .tooltip.tooltip-white.left-top .tooltip-arrow::after {
    border-right-color: #fff; }

.tooltip.tooltip-visible {
  opacity: 1; }

/* Patterns manager: */
/*
Blog Article
*/
.blog-article {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 0 30px 30px;
  border-radius: 2px;
  overflow: hidden; }
  .blog-article .blog-article-img {
    margin-left: -30px;
    margin-right: -30px; }

/*
Blog Article Meta
*/
.blog-article-meta {
  clear: both; }
  .blog-article-meta .blog-article-author-picture {
    overflow: hidden;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 25px;
    height: 50px;
    width: 50px; }
    .blog-article-meta .blog-article-author-picture img {
      width: 100%;
      height: auto;
      display: block; }
  .blog-article-meta .blog-article-author {
    padding-right: 10px; }
  .blog-article-meta .blog-article-author,
  .blog-article-meta .blog-article-date {
    display: block; }

/*
Call to Action
*/
.call-to-action {
  display: block;
  padding: 30px;
  background-color: #fff;
  margin: 0 0 30px;
  border-radius: 2px;
  text-align: center; }
  .call-to-action .icon:not(:last-child) {
    margin-bottom: 15px; }
  .call-to-action p:last-child {
    margin-bottom: 0; }
  .call-to-action.btn {
    white-space: normal; }
  .call-to-action .call-to-action-title {
    margin-top: 0;
    padding-top: 0; }

/*
Generation Bar
*/
.generation-bar .input-group {
  width: 100%; }
  .generation-bar .input-group input {
    border-radius: 0 !important; }

.generation-bar input,
.generation-bar button {
  height: 50px;
  font-size: 15px; }

.generation-bar input:focus {
  background-color: #f5f7fa; }

.generation-bar button {
  font-weight: 600;
  border-radius: 5px;
  flex-shrink: 0;
  border: 1px solid transparent !important; }
  .generation-bar button .addon .icon {
    fill: #656d78 !important; }
    .generation-bar button .addon .icon .icon-loader {
      width: 1em;
      height: 1em; }

.generation-bar .error-container {
  height: 25px;
  justify-content: flex-start;
  width: 100%; }
  .generation-bar .error-container .error-msg {
    display: none;
    visibility: hidden;
    color: #d9534f !important;
    font-size: 14px;
    padding-left: 2.3em;
    padding-top: 4px; }

.generation-bar {
  position: relative;
  margin: 0 auto;
  max-width: 640px; }
  .generation-bar .addon .icon-loader {
    display: none; }
  .generation-bar input {
    padding-left: 2em; }

.generation-bar.disabled, .generation-bar.btn.busy, .generation-bar.btn[busy],
fieldset[busy] .generation-bar.btn {
  pointer-events: none;
  cursor: not-allowed; }
  .generation-bar.disabled button, .generation-bar.btn.busy button, .generation-bar.btn[busy] button, fieldset[busy] .generation-bar.btn button {
    background-color: #ccd1d9 !important; }

.generation-bar.busy {
  pointer-events: none; }
  .generation-bar.busy input {
    opacity: 0.8 !important; }
  .generation-bar.busy button {
    background-color: #656d78 !important;
    background-color: #ccd1d9 !important; }
    .generation-bar.busy button .addon .icon.icon-loader {
      display: flex !important; }
    .generation-bar.busy button .submit-text {
      display: none; }

.generation-bar.error .error-container {
  display: flex;
  visibility: visible; }
  .generation-bar.error .error-container.small {
    height: 14px !important; }
  .generation-bar.error .error-container .error-msg {
    display: flex;
    visibility: visible; }

.generation-bar.error input {
  border-color: #d9534f !important;
  color: #d9534f !important; }

.new-design .generation-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 1140px;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  border-style: solid;
  border-width: 1.3px;
  border-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 40px;
  padding: 30px 30px 4px;
  z-index: 1; }
  .new-design .generation-bar.small {
    padding: 10px 16px 0; }
    .new-design .generation-bar.small .input-group input {
      margin-left: 30px; }
    .new-design .generation-bar.small:hover {
      border-color: transparent !important; }
    .new-design .generation-bar.small .error-container {
      height: 10px !important; }
  .new-design .generation-bar:hover {
    border-style: solid;
    border-width: 1.3px;
    border-color: #2286e6; }
  .new-design .generation-bar .input-group input {
    margin-right: 30px;
    border-left: none;
    border-right: none;
    border-top: none;
    box-shadow: none; }
    .new-design .generation-bar .input-group input:focus {
      background-color: transparent;
      border-color: #2286e6;
      box-shadow: none; }
  .new-design .generation-bar .input-group input,
  .new-design .generation-bar .input-group button {
    font-size: 18px; }
  .new-design .generation-bar .input-group button {
    padding-left: 50px;
    padding-right: 50px; }
    .new-design .generation-bar .input-group button.disabled, .new-design .generation-bar .input-group button.btn.busy, .new-design .generation-bar .input-group button.btn[busy],
    .new-design .generation-bar .input-group fieldset[busy] button.btn, fieldset[busy] .new-design .generation-bar .input-group button.btn {
      opacity: 1 !important;
      color: #656d78 !important;
      background-color: #ccd1d9 !important; }
    .new-design .generation-bar .input-group button .icon.icon-loader {
      height: 24px;
      width: 24px; }

/*
Input Groups
*/
.input-group .input-group-addon {
  padding-top: 0;
  padding-bottom: 0; }
  .input-group .input-group-addon .icon {
    max-height: 18px; }

.input-group.input-group-sm .input-group-addon {
  padding-top: 0;
  padding-bottom: 0; }
  .input-group.input-group-sm .input-group-addon .icon {
    margin-top: -5px;
    transform: translateY(2px);
    max-height: 16px; }

.input-group .btn .icon-text,
.input-group .btn .icon {
  line-height: 0;
  vertical-align: initial; }

.input-group .input-group-addon.white {
  border-color: #fff;
  background-color: #fff; }

.input-group .input-group-addon.black {
  border-color: #000;
  background-color: #000; }

.input-group .input-group-addon.competitor-darker {
  border-color: #2f5455;
  background-color: #2f5455; }

.input-group .input-group-addon.competitor-dark {
  border-color: #29494a;
  background-color: #29494a; }

.input-group .input-group-addon.competitor {
  border-color: #539597;
  background-color: #539597; }

.input-group .input-group-addon.competitor-light {
  border-color: #afe5e7;
  background-color: #afe5e7; }

.input-group .input-group-addon.danger-smoke {
  border-color: #fbeded;
  background-color: #fbeded; }

.input-group .input-group-addon.danger-lighter {
  border-color: #f6d4d4;
  background-color: #f6d4d4; }

.input-group .input-group-addon.danger-light {
  border-color: #eca9a7;
  background-color: #eca9a7; }

.input-group .input-group-addon.danger {
  border-color: #d9534f;
  background-color: #d9534f; }

.input-group .input-group-addon.danger-dark {
  border-color: #b94743;
  background-color: #b94743; }

.input-group .input-group-addon.danger-darker {
  border-color: #a02622;
  background-color: #a02622; }

.input-group .input-group-addon.error-smoke {
  border-color: #fbeded;
  background-color: #fbeded; }

.input-group .input-group-addon.error-lighter {
  border-color: #f6d4d4;
  background-color: #f6d4d4; }

.input-group .input-group-addon.error-light {
  border-color: #eca9a7;
  background-color: #eca9a7; }

.input-group .input-group-addon.error {
  border-color: #d9534f;
  background-color: #d9534f; }

.input-group .input-group-addon.error-dark {
  border-color: #b94743;
  background-color: #b94743; }

.input-group .input-group-addon.error-darker {
  border-color: #a02622;
  background-color: #a02622; }

.input-group .input-group-addon.warning-smoke {
  border-color: #fef5e6;
  background-color: #fef5e6; }

.input-group .input-group-addon.warning-lighter {
  border-color: #fee6c8;
  background-color: #fee6c8; }

.input-group .input-group-addon.warning-light {
  border-color: #fbce86;
  background-color: #fbce86; }

.input-group .input-group-addon.warning {
  border-color: #f89d0e;
  background-color: #f89d0e; }

.input-group .input-group-addon.warning-dark {
  border-color: #d3860c;
  background-color: #d3860c; }

.input-group .input-group-addon.warning-darker {
  border-color: #9b6105;
  background-color: #9b6105; }

.input-group .input-group-addon.success-smoke {
  border-color: #eef8ee;
  background-color: #eef8ee; }

.input-group .input-group-addon.success-lighter {
  border-color: #d7edd6;
  background-color: #d7edd6; }

.input-group .input-group-addon.success-light {
  border-color: #4cd763;
  background-color: #4cd763; }

.input-group .input-group-addon.success {
  border-color: #00c622;
  background-color: #00c622; }

.input-group .input-group-addon.success-dark {
  border-color: #4e9d4e;
  background-color: #4e9d4e; }

.input-group .input-group-addon.success-darker {
  border-color: #357935;
  background-color: #357935; }

.input-group .input-group-addon.primary-smoke {
  border-color: #ecf3fa;
  background-color: #ecf3fa; }

.input-group .input-group-addon.primary-lighter {
  border-color: #cfe2f2;
  background-color: #cfe2f2; }

.input-group .input-group-addon.primary-light {
  border-color: #a0c5e4;
  background-color: #a0c5e4; }

.input-group .input-group-addon.primary {
  border-color: #428bca;
  background-color: #428bca; }

.input-group .input-group-addon.primary-dark {
  border-color: #3876ac;
  background-color: #3876ac; }

.input-group .input-group-addon.primary-darker {
  border-color: #2b5c86;
  background-color: #2b5c86; }

.input-group .input-group-addon.grey-darker {
  border-color: #353b48;
  background-color: #353b48; }

.input-group .input-group-addon.grey-dark {
  border-color: #434a54;
  background-color: #434a54; }

.input-group .input-group-addon.grey-medium {
  border-color: #656d78;
  background-color: #656d78; }

.input-group .input-group-addon.grey {
  border-color: #aab2bd;
  background-color: #aab2bd; }

.input-group .input-group-addon.grey-light {
  border-color: #ccd1d9;
  background-color: #ccd1d9; }

.input-group .input-group-addon.grey-lighter {
  border-color: #e6e9ed;
  background-color: #e6e9ed; }

.input-group .input-group-addon.grey-smoke {
  border-color: #f5f7fa;
  background-color: #f5f7fa; }

.input-group .input-group-addon.turquoise-light {
  border-color: #9fe4d9;
  background-color: #9fe4d9; }

.input-group .input-group-addon.turquoise-lighter {
  border-color: #effaf8;
  background-color: #effaf8; }

.input-group .input-group-addon.turquoise {
  border-color: #4fceb9;
  background-color: #4fceb9; }

.input-group .input-group-addon.turquoise-dark {
  border-color: #34ad99;
  background-color: #34ad99; }

.input-group .input-group-addon.turquoise-darker {
  border-color: #288f7e;
  background-color: #288f7e; }

.input-group .input-group-addon.emerald-light {
  border-color: #7ee2a8;
  background-color: #7ee2a8; }

.input-group .input-group-addon.emerald-lighter {
  border-color: #d1f5e0;
  background-color: #d1f5e0; }

.input-group .input-group-addon.emerald {
  border-color: #2ecc71;
  background-color: #2ecc71; }

.input-group .input-group-addon.emerald-dark {
  border-color: #27ae60;
  background-color: #27ae60; }

.input-group .input-group-addon.emerald-darker {
  border-color: #1b7943;
  background-color: #1b7943; }

.input-group .input-group-addon.amethyst-light {
  border-color: #c4b3cc;
  background-color: #c4b3cc; }

.input-group .input-group-addon.amethyst-lighter {
  border-color: #f3f0f5;
  background-color: #f3f0f5; }

.input-group .input-group-addon.amethyst {
  border-color: #9476a3;
  background-color: #9476a3; }

.input-group .input-group-addon.amethyst-dark {
  border-color: #836990;
  background-color: #836990; }

.input-group .input-group-addon.amethyst-darker {
  border-color: #5f486b;
  background-color: #5f486b; }

/*
Modal
*/
.modal .modal-header .close {
  z-index: 1; }

.modal .modal-header .icon-cross {
  height: 13px;
  width: 13px; }

/*
Navbar
*/
@media screen and (min-width: 992px) {
  .navbar .container {
    max-width: 1050px; } }

@media screen and (min-width: 992px) {
  .navbar .container {
    max-width: 1100px; } }

@media screen and (min-width: 1200px) {
  .navbar .container {
    max-width: 1200px; } }

@media screen and (min-width: 1600px) {
  .navbar .container {
    max-width: 1600px; } }

body.navbar-open {
  overflow: hidden; }

.navbar {
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
  max-height: 64px;
  height: 64px;
  transition: all 0.5s linear; }
  .navbar.fixed-top {
    z-index: 1050; }
  .navbar .navbar-nav .nav-item {
    margin-left: 15px; }
    .navbar .navbar-nav .nav-item .nav-link {
      transition: all 0.2s ease-in-out;
      color: #353b48;
      font-size: 16px;
      font-weight: 400;
      border-bottom: 2px solid transparent; }
      .navbar .navbar-nav .nav-item .nav-link:hover {
        border-color: #2286e6; }
  .navbar .navbar-nav.navbar-action {
    margin-left: auto; }
    @media screen and (min-width: 768px) {
      .navbar .navbar-nav.navbar-action {
        flex-flow: row nowrap; }
        .navbar .navbar-nav.navbar-action .btn {
          flex-grow: 1;
          flex-shrink: 0; } }
  @media screen and (min-width: 768px) {
    .navbar.navbar-expand-sm .navbar-nav .nav-link {
      padding: 5px 10px; } }
  @media screen and (min-width: 768px) {
    .navbar.navbar-expand-sm .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-column {
      flex-direction: column;
      flex-basis: 250px;
      margin: 0 10px; }
    .navbar.navbar-expand-sm .navbar-collapse .dropdown .dropdown-menu.dropdown-menu-full-container {
      position: fixed;
      min-height: 0;
      top: 54px;
      left: 0;
      width: 100%;
      width: 100vw;
      border-radius: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding: 30px 0 80px; }
    .navbar.navbar-expand-sm .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full {
      display: flex;
      margin: 0 auto;
      justify-content: space-around;
      max-width: 846px; } }
  @media screen and (min-width: 768px) and (min-width: 1200px) {
    .navbar.navbar-expand-sm .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full {
      max-width: 950px; } }
  @media screen and (min-width: 768px) and (min-width: 1600px) {
    .navbar.navbar-expand-sm .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full {
      max-width: 1140px; } }
  @media screen and (min-width: 768px) {
      .navbar.navbar-expand-sm .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full .dropdown-menu-column .dropdown-divider {
        display: none; } }
  @media screen and (max-width: 767px) {
    .navbar.navbar-expand-sm .navbar-collapse {
      background-color: #fff;
      backface-visibility: hidden;
      bottom: 0;
      padding: 20px;
      position: fixed;
      left: 0;
      top: 63px;
      visibility: hidden;
      width: 100%;
      min-width: 250px;
      z-index: 1050; }
      .navbar.navbar-expand-sm .navbar-collapse.in, .navbar.navbar-expand-sm .navbar-collapse.show, .navbar.navbar-expand-sm .navbar-collapse.collapsing {
        visibility: visible;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .navbar.navbar-expand-sm .navbar-collapse .navbar-menu {
        overflow-y: auto;
        overflow-x: hidden;
        flex-wrap: nowrap;
        flex-grow: 1; }
      .navbar.navbar-expand-sm .navbar-collapse .navbar-action {
        border-top: 1px solid #f5f7fa; }
      .navbar.navbar-expand-sm .navbar-collapse .navbar-nav {
        margin: 0;
        width: 100%; }
        .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .nav-item {
          margin-left: 0; }
          .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .nav-item .nav-link {
            padding: 15px; }
            .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .nav-item .nav-link:hover, .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .nav-item .nav-link:focus {
              border-color: transparent; }
        .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown .dropdown-item {
          background-color: #fff;
          padding: 8px 15px; }
        .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown .nav-link {
          display: flex;
          align-items: center; }
          .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown .nav-link:hover, .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown .nav-link:focus {
            background-color: #f5f7fa; }
          .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown .nav-link .icon {
            margin-left: auto; }
            .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown .nav-link .icon.icon-chevron-up, .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown .nav-link .icon.icon-chevron-down {
              transition: all 0.1s linear;
              transform: rotate(0deg); }
        .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown.show .nav-link {
          background-color: #f5f7fa; }
          .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown.show .nav-link .icon.icon-chevron-up, .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown.show .nav-link .icon.icon-chevron-down {
            transform: rotate(-180deg); }
        .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu {
          padding: 0;
          display: none;
          padding-top: 10px;
          padding-bottom: 10px; }
          .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full {
            flex-direction: column; }
            .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full .dropdown-menu-column:not(:first-child) {
              padding-top: 15px; }
            .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full .dropdown-menu-column:not(:last-child) {
              padding-bottom: 15px; }
            .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full .dropdown-divider {
              margin: 0 10px; }
          .navbar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu.show {
            display: block;
            margin-bottom: 10px; } }
  @media screen and (min-width: 992px) {
    .navbar.navbar-expand-md .navbar-nav .nav-link {
      padding: 5px 10px; } }
  @media screen and (min-width: 992px) {
    .navbar.navbar-expand-md .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-column {
      flex-direction: column;
      flex-basis: 250px;
      margin: 0 10px; }
    .navbar.navbar-expand-md .navbar-collapse .dropdown .dropdown-menu.dropdown-menu-full-container {
      position: fixed;
      min-height: 0;
      top: 54px;
      left: 0;
      width: 100%;
      width: 100vw;
      border-radius: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding: 30px 0 80px; }
    .navbar.navbar-expand-md .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full {
      display: flex;
      margin: 0 auto;
      justify-content: space-around;
      max-width: 846px; } }
  @media screen and (min-width: 992px) and (min-width: 1200px) {
    .navbar.navbar-expand-md .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full {
      max-width: 950px; } }
  @media screen and (min-width: 992px) and (min-width: 1600px) {
    .navbar.navbar-expand-md .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full {
      max-width: 1140px; } }
  @media screen and (min-width: 992px) {
      .navbar.navbar-expand-md .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full .dropdown-menu-column .dropdown-divider {
        display: none; } }
  @media screen and (max-width: 991px) {
    .navbar.navbar-expand-md .navbar-collapse {
      background-color: #fff;
      backface-visibility: hidden;
      bottom: 0;
      padding: 20px;
      position: fixed;
      left: 0;
      top: 63px;
      visibility: hidden;
      width: 100%;
      min-width: 250px;
      z-index: 1050; }
      .navbar.navbar-expand-md .navbar-collapse.in, .navbar.navbar-expand-md .navbar-collapse.show, .navbar.navbar-expand-md .navbar-collapse.collapsing {
        visibility: visible;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .navbar.navbar-expand-md .navbar-collapse .navbar-menu {
        overflow-y: auto;
        overflow-x: hidden;
        flex-wrap: nowrap;
        flex-grow: 1; }
      .navbar.navbar-expand-md .navbar-collapse .navbar-action {
        border-top: 1px solid #f5f7fa; }
      .navbar.navbar-expand-md .navbar-collapse .navbar-nav {
        margin: 0;
        width: 100%; }
        .navbar.navbar-expand-md .navbar-collapse .navbar-nav .nav-item {
          margin-left: 0; }
          .navbar.navbar-expand-md .navbar-collapse .navbar-nav .nav-item .nav-link {
            padding: 15px; }
            .navbar.navbar-expand-md .navbar-collapse .navbar-nav .nav-item .nav-link:hover, .navbar.navbar-expand-md .navbar-collapse .navbar-nav .nav-item .nav-link:focus {
              border-color: transparent; }
        .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown .dropdown-item {
          background-color: #fff;
          padding: 8px 15px; }
        .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown .nav-link {
          display: flex;
          align-items: center; }
          .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown .nav-link:hover, .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown .nav-link:focus {
            background-color: #f5f7fa; }
          .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown .nav-link .icon {
            margin-left: auto; }
            .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown .nav-link .icon.icon-chevron-up, .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown .nav-link .icon.icon-chevron-down {
              transition: all 0.1s linear;
              transform: rotate(0deg); }
        .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown.show .nav-link {
          background-color: #f5f7fa; }
          .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown.show .nav-link .icon.icon-chevron-up, .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown.show .nav-link .icon.icon-chevron-down {
            transform: rotate(-180deg); }
        .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu {
          padding: 0;
          display: none;
          padding-top: 10px;
          padding-bottom: 10px; }
          .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full {
            flex-direction: column; }
            .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full .dropdown-menu-column:not(:first-child) {
              padding-top: 15px; }
            .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full .dropdown-menu-column:not(:last-child) {
              padding-bottom: 15px; }
            .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full .dropdown-divider {
              margin: 0 10px; }
          .navbar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu.show {
            display: block;
            margin-bottom: 10px; } }
  @media screen and (min-width: 1100px) {
    .navbar.navbar-expand-nv .navbar-nav .nav-link {
      padding: 10px; } }
  @media screen and (min-width: 1100px) {
    .navbar.navbar-expand-nv .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-column {
      flex-direction: column;
      flex-basis: 250px;
      margin: 0 10px; }
    .navbar.navbar-expand-nv .navbar-collapse .dropdown .dropdown-menu.dropdown-menu-full-container {
      position: fixed;
      min-height: 0;
      top: 54px;
      left: 0;
      width: 100%;
      width: 100vw;
      border-radius: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding: 30px 0 80px; }
    .navbar.navbar-expand-nv .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full {
      display: flex;
      margin: 0 auto;
      justify-content: space-around;
      max-width: 846px; } }
  @media screen and (min-width: 1100px) and (min-width: 1200px) {
    .navbar.navbar-expand-nv .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full {
      max-width: 950px; } }
  @media screen and (min-width: 1100px) and (min-width: 1600px) {
    .navbar.navbar-expand-nv .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full {
      max-width: 1140px; } }
  @media screen and (min-width: 1100px) {
      .navbar.navbar-expand-nv .navbar-collapse .dropdown .dropdown-menu .dropdown-menu-full .dropdown-menu-column .dropdown-divider {
        display: none; } }
  @media screen and (max-width: 1099px) {
    .navbar.navbar-expand-nv .navbar-collapse {
      background-color: #fff;
      backface-visibility: hidden;
      bottom: 0;
      padding: 20px;
      position: fixed;
      left: 0;
      top: 63px;
      visibility: hidden;
      width: 100%;
      min-width: 250px;
      z-index: 1050; }
      .navbar.navbar-expand-nv .navbar-collapse.in, .navbar.navbar-expand-nv .navbar-collapse.show, .navbar.navbar-expand-nv .navbar-collapse.collapsing {
        visibility: visible;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .navbar.navbar-expand-nv .navbar-collapse .navbar-menu {
        overflow-y: auto;
        overflow-x: hidden;
        flex-wrap: nowrap;
        flex-grow: 1; }
      .navbar.navbar-expand-nv .navbar-collapse .navbar-action {
        border-top: 1px solid #f5f7fa; }
      .navbar.navbar-expand-nv .navbar-collapse .navbar-nav {
        margin: 0;
        width: 100%; }
        .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .nav-item {
          margin-left: 0; }
          .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .nav-item .nav-link {
            padding: 15px; }
            .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .nav-item .nav-link:hover, .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .nav-item .nav-link:focus {
              border-color: transparent; }
        .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown .dropdown-item {
          background-color: #fff;
          padding: 8px 15px; }
        .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown .nav-link {
          display: flex;
          align-items: center; }
          .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown .nav-link:hover, .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown .nav-link:focus {
            background-color: #f5f7fa; }
          .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown .nav-link .icon {
            margin-left: auto; }
            .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown .nav-link .icon.icon-chevron-up, .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown .nav-link .icon.icon-chevron-down {
              transition: all 0.1s linear;
              transform: rotate(0deg); }
        .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown.show .nav-link {
          background-color: #f5f7fa; }
          .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown.show .nav-link .icon.icon-chevron-up, .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown.show .nav-link .icon.icon-chevron-down {
            transform: rotate(-180deg); }
        .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown-menu {
          padding: 0;
          display: none;
          padding-top: 10px;
          padding-bottom: 10px; }
          .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full {
            flex-direction: column; }
            .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full .dropdown-menu-column:not(:first-child) {
              padding-top: 15px; }
            .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full .dropdown-menu-column:not(:last-child) {
              padding-bottom: 15px; }
            .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown-menu .dropdown-menu-full .dropdown-divider {
              margin: 0 10px; }
          .navbar.navbar-expand-nv .navbar-collapse .navbar-nav .dropdown-menu.show {
            display: block;
            margin-bottom: 10px; } }

.navbar-brand {
  height: 38px;
  line-height: 20px;
  max-width: 125px; }
  .navbar-brand .icon-logo {
    height: 24px;
    width: 126px; }

.contact-support .icon-loader {
  display: none; }

.contact-support.busy .icon:not(.icon-loader) {
  display: none; }

.contact-support.busy .icon-loader {
  display: inline-block !important; }

/*
Navbar Flex
*/
.-header {
  transition: background-color 0.35s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .-header .icon {
    transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .-header .generation-bar input {
    border: 0; }
  .-header ul {
    padding-left: 0; }
    .-header ul li {
      list-style-type: none; }
      .-header ul li a {
        text-decoration: none; }
  .-header .-site {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    border-radius: 20px;
    background-color: transparent;
    padding: 0 12px;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: hidden; }

.-logo {
  height: 32px;
  padding: 0 12px; }
  .-logo .icon {
    height: 20px;
    width: 110px; }
    .-logo .icon.icon-woorank-bridgeline, .-logo .icon.icon-woorank-by-bridgeline {
      height: 40px;
      width: 140px; }

.-right {
  justify-content: flex-end; }

.-left {
  justify-content: flex-start; }

.-circle {
  width: 40px;
  height: 40px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  padding: 8px;
  border-radius: 50%; }

.-main {
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between; }
  .-main .-list {
    list-style: none;
    display: inline-flex;
    flex-flow: row nowrap;
    margin-bottom: 0; }
    .-main .-list * {
      transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }
    .-main .-list .-item {
      line-height: 60px;
      padding: 0 4px; }
      .-main .-list .-item .-link {
        display: inline-block;
        padding: 0 8px;
        border-radius: 20px;
        line-height: 40px; }
        .-main .-list .-item .-link.-icon {
          display: inline-block;
          text-align: center;
          width: 40px;
          height: 40px;
          padding: 0; }
      .-main .-list .-item .-btn {
        position: relative;
        display: block;
        padding: 0 12px;
        overflow: hidden; }

.-circle,
.-btn,
.-link {
  color: #353b48; }
  .-circle .icon,
  .-btn .icon,
  .-link .icon {
    fill: #353b48; }
  .-circle:hover, .-circle:active, .-circle:focus,
  .-btn:hover,
  .-btn:active,
  .-btn:focus,
  .-link:hover,
  .-link:active,
  .-link:focus {
    background-color: #e6e9ed;
    color: #353b48; }
    .-circle:hover .icon, .-circle:active .icon, .-circle:focus .icon,
    .-btn:hover .icon,
    .-btn:active .icon,
    .-btn:focus .icon,
    .-link:hover .icon,
    .-link:active .icon,
    .-link:focus .icon {
      fill: #353b48; }

.-header {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  max-height: 60px;
  transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow cubic-bezier(0.165, 0.84, 0.44, 1) 0.25s;
  background-color: #fff;
  box-shadow: 0 1px 0 rgba(12, 13, 14, 0.15), 0 0 0 transparent, 0 0 0 transparent, 0 0 0 transparent;
  position: static;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  height: 60px;
  box-sizing: border-box; }
  .-header.-fixed {
    position: fixed;
    min-width: auto;
    top: 0;
    left: 0;
    right: 0; }

.-header.-header-primary {
  background-color: #2b5c86; }
  .-header.-header-primary .-circle,
  .-header.-header-primary .-btn,
  .-header.-header-primary .-link {
    color: #fff; }
    .-header.-header-primary .-circle .icon,
    .-header.-header-primary .-btn .icon,
    .-header.-header-primary .-link .icon {
      fill: #fff; }
    .-header.-header-primary .-circle:hover, .-header.-header-primary .-circle:active, .-header.-header-primary .-circle:focus,
    .-header.-header-primary .-btn:hover,
    .-header.-header-primary .-btn:active,
    .-header.-header-primary .-btn:focus,
    .-header.-header-primary .-link:hover,
    .-header.-header-primary .-link:active,
    .-header.-header-primary .-link:focus {
      background-color: #3876ac; }

.-header.-header-success {
  background-color: #357935; }
  .-header.-header-success .-circle,
  .-header.-header-success .-btn,
  .-header.-header-success .-link {
    color: #fff; }
    .-header.-header-success .-circle .icon,
    .-header.-header-success .-btn .icon,
    .-header.-header-success .-link .icon {
      fill: #fff; }
    .-header.-header-success .-circle:hover, .-header.-header-success .-circle:active, .-header.-header-success .-circle:focus,
    .-header.-header-success .-btn:hover,
    .-header.-header-success .-btn:active,
    .-header.-header-success .-btn:focus,
    .-header.-header-success .-link:hover,
    .-header.-header-success .-link:active,
    .-header.-header-success .-link:focus {
      background-color: #4e9d4e; }

.-header.-header-warning {
  background-color: #9b6105; }
  .-header.-header-warning .-circle,
  .-header.-header-warning .-btn,
  .-header.-header-warning .-link {
    color: #fff; }
    .-header.-header-warning .-circle .icon,
    .-header.-header-warning .-btn .icon,
    .-header.-header-warning .-link .icon {
      fill: #fff; }
    .-header.-header-warning .-circle:hover, .-header.-header-warning .-circle:active, .-header.-header-warning .-circle:focus,
    .-header.-header-warning .-btn:hover,
    .-header.-header-warning .-btn:active,
    .-header.-header-warning .-btn:focus,
    .-header.-header-warning .-link:hover,
    .-header.-header-warning .-link:active,
    .-header.-header-warning .-link:focus {
      background-color: #d3860c; }

.-header.-header-error {
  background-color: #a02622; }
  .-header.-header-error .-circle,
  .-header.-header-error .-btn,
  .-header.-header-error .-link {
    color: #fff; }
    .-header.-header-error .-circle .icon,
    .-header.-header-error .-btn .icon,
    .-header.-header-error .-link .icon {
      fill: #fff; }
    .-header.-header-error .-circle:hover, .-header.-header-error .-circle:active, .-header.-header-error .-circle:focus,
    .-header.-header-error .-btn:hover,
    .-header.-header-error .-btn:active,
    .-header.-header-error .-btn:focus,
    .-header.-header-error .-link:hover,
    .-header.-header-error .-link:active,
    .-header.-header-error .-link:focus {
      background-color: #b94743; }

.-header.-header-turquoise {
  background-color: #288f7e; }
  .-header.-header-turquoise .-circle,
  .-header.-header-turquoise .-btn,
  .-header.-header-turquoise .-link {
    color: #fff; }
    .-header.-header-turquoise .-circle .icon,
    .-header.-header-turquoise .-btn .icon,
    .-header.-header-turquoise .-link .icon {
      fill: #fff; }
    .-header.-header-turquoise .-circle:hover, .-header.-header-turquoise .-circle:active, .-header.-header-turquoise .-circle:focus,
    .-header.-header-turquoise .-btn:hover,
    .-header.-header-turquoise .-btn:active,
    .-header.-header-turquoise .-btn:focus,
    .-header.-header-turquoise .-link:hover,
    .-header.-header-turquoise .-link:active,
    .-header.-header-turquoise .-link:focus {
      background-color: #34ad99; }

.-header.-header-emerald {
  background-color: #1b7943; }
  .-header.-header-emerald .-circle,
  .-header.-header-emerald .-btn,
  .-header.-header-emerald .-link {
    color: #fff; }
    .-header.-header-emerald .-circle .icon,
    .-header.-header-emerald .-btn .icon,
    .-header.-header-emerald .-link .icon {
      fill: #fff; }
    .-header.-header-emerald .-circle:hover, .-header.-header-emerald .-circle:active, .-header.-header-emerald .-circle:focus,
    .-header.-header-emerald .-btn:hover,
    .-header.-header-emerald .-btn:active,
    .-header.-header-emerald .-btn:focus,
    .-header.-header-emerald .-link:hover,
    .-header.-header-emerald .-link:active,
    .-header.-header-emerald .-link:focus {
      background-color: #27ae60; }

.-header.-header-amethyst {
  background-color: #5f486b; }
  .-header.-header-amethyst .-circle,
  .-header.-header-amethyst .-btn,
  .-header.-header-amethyst .-link {
    color: #fff; }
    .-header.-header-amethyst .-circle .icon,
    .-header.-header-amethyst .-btn .icon,
    .-header.-header-amethyst .-link .icon {
      fill: #fff; }
    .-header.-header-amethyst .-circle:hover, .-header.-header-amethyst .-circle:active, .-header.-header-amethyst .-circle:focus,
    .-header.-header-amethyst .-btn:hover,
    .-header.-header-amethyst .-btn:active,
    .-header.-header-amethyst .-btn:focus,
    .-header.-header-amethyst .-link:hover,
    .-header.-header-amethyst .-link:active,
    .-header.-header-amethyst .-link:focus {
      background-color: #836990; }

/*
Page
*/
.background-full::before, .background-full::after {
  content: " ";
  display: table; }

.background-full::after {
  clear: both; }

.page-hero {
  position: relative;
  clear: both;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s linear;
  cursor: default; }
  .page-hero.page-hero-full {
    min-height: 450px;
    height: 70vh; }
    @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
      .page-hero.page-hero-full {
        height: 717px; } }
    @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
      .page-hero.page-hero-full {
        height: 538px; } }
    @media screen and (device-aspect-ratio: 40 / 71) {
      .page-hero.page-hero-full {
        height: 350px; } }
    @media screen and (device-width: 320px) and (device-aspect-ratio: 2 / 3) and (orientation: portrait) {
      .page-hero.page-hero-full {
        height: 336px; } }
    @media screen and (min-width: 768px) {
      .page-hero.page-hero-full {
        height: calc(100vh - 120px); } }
  @media screen and (min-width: 768px) and (device-aspect-ratio: 40 / 71) {
    .page-hero.page-hero-full {
      height: 380px; } }
  @media screen and (min-width: 768px) and (device-width: 320px) and (device-aspect-ratio: 2 / 3) and (orientation: portrait) {
    .page-hero.page-hero-full {
      height: 360px; } }
    .page-hero.page-hero-full .page-hero-content {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0;
      width: 90%; }
      .page-hero.page-hero-full .page-hero-content .title {
        margin-top: 0; }
        @media screen and (min-width: 768px) {
          .page-hero.page-hero-full .page-hero-content .title {
            font-size: 40px; } }
        @media screen and (min-width: 992px) {
          .page-hero.page-hero-full .page-hero-content .title {
            font-size: 52px; } }
        @media screen and (min-width: 1200px) {
          .page-hero.page-hero-full .page-hero-content .title {
            font-size: 58px; } }
      @media screen and (min-width: 992px) {
        .page-hero.page-hero-full .page-hero-content .subtitle {
          font-size: 24px; } }
      @media screen and (min-width: 1200px) {
        .page-hero.page-hero-full .page-hero-content .subtitle {
          font-size: 30px; } }
  .page-hero .page-hero-content {
    display: block;
    position: relative;
    margin-top: 20px;
    z-index: 1000; }
    @media screen and (min-width: 768px) {
      .page-hero .page-hero-content {
        margin-top: 50px; } }
    .page-hero .page-hero-content .title {
      font-size: 28px;
      line-height: 1;
      letter-spacing: -1.5px;
      text-align: center;
      color: #fff;
      text-shadow: 0 2px 0 rgba(52, 58, 72, 0.5);
      margin-bottom: 28px; }
      @media screen and (min-width: 992px) {
        .page-hero .page-hero-content .title {
          font-size: 38px; } }
      @media screen and (min-width: 1200px) {
        .page-hero .page-hero-content .title {
          font-size: 48px; } }
    .page-hero .page-hero-content .subtitle {
      font-size: 20px;
      line-height: 1;
      text-align: center;
      color: #fff;
      text-shadow: 0 2px 0 rgba(52, 58, 72, 0.5);
      margin-bottom: 52px; }
      @media screen and (min-width: 1200px) {
        .page-hero .page-hero-content .subtitle {
          font-size: 24px; } }
  .page-hero .page-hero-bottom-margin {
    pointer-events: none;
    min-width: 768px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (min-width: 992px) {
      .page-hero .page-hero-bottom-margin {
        min-width: 1050px; } }
    @media screen and (min-width: 1200px) {
      .page-hero .page-hero-bottom-margin {
        width: 100%;
        height: auto; } }
    .page-hero .page-hero-bottom-margin.banner-fixed {
      max-width: 1200px; }

.page-hero-footer {
  position: relative;
  display: block;
  height: 100px;
  width: 100%;
  text-align: center;
  background-color: #fff; }

/*
Page Prefooter
*/
.page-prefooter {
  position: relative;
  padding-top: 66px;
  padding-bottom: 66px;
  margin-top: 40px;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain; }
  .page-prefooter img {
    position: absolute;
    bottom: 0;
    height: 220px;
    display: none; }
    @media screen and (min-width: 768px) {
      .page-prefooter img {
        display: block; } }
  .page-prefooter .msg {
    display: none;
    text-align: left;
    width: 200px;
    margin-left: 30px;
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    color: #434a54; }
    @media screen and (min-width: 768px) {
      .page-prefooter .msg {
        display: inline-block; } }
    .page-prefooter .msg.msg-mobile {
      display: block;
      position: relative;
      width: 200px;
      text-align: center;
      margin: 0 auto;
      font-size: 16px;
      line-height: 1; }
      @media screen and (min-width: 768px) {
        .page-prefooter .msg.msg-mobile {
          display: none; } }

/*
Page Footer
*/
#section-patterns-page-footer .page-footer {
  width: 100%; }

.-footer {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-top: 60px; }
  .-footer .footer-menu {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 auto;
    padding: 15px 0;
    border-top: 1px solid #ccd1d9; }
    @media screen and (min-width: 768px) {
      .-footer .footer-menu {
        flex-direction: row; } }
    .-footer .footer-menu .-menu {
      flex: 1 auto;
      text-align: center; }
      @media screen and (min-width: 768px) {
        .-footer .footer-menu .-menu {
          text-align: left; } }
    .-footer .footer-menu .-language .dropup,
    .-footer .footer-menu .-language .dropdown {
      color: #434a54;
      display: inline-flex;
      padding: 0 15px; }
      .-footer .footer-menu .-language .dropup .icon-chevron-down,
      .-footer .footer-menu .-language .dropup .icon-chevron-up,
      .-footer .footer-menu .-language .dropdown .icon-chevron-down,
      .-footer .footer-menu .-language .dropdown .icon-chevron-up {
        margin: 0 5px; }
      .-footer .footer-menu .-language .dropup .dropdown-item,
      .-footer .footer-menu .-language .dropdown .dropdown-item {
        text-decoration: none; }
  .-footer .footer-copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex: 1 auto;
    padding: 15px 0;
    border-top: 1px solid #ccd1d9; }
    .-footer .footer-copyright .brand {
      display: flex;
      max-width: 200px;
      padding: 0;
      height: auto;
      padding-right: 30px;
      align-items: center; }
    .-footer .footer-copyright .-language .dropup,
    .-footer .footer-copyright .-language .dropdown {
      color: #353b48;
      display: inline-flex;
      padding: 0 15px; }
      .-footer .footer-copyright .-language .dropup .icon-chevron-down,
      .-footer .footer-copyright .-language .dropup .icon-chevron-up,
      .-footer .footer-copyright .-language .dropdown .icon-chevron-down,
      .-footer .footer-copyright .-language .dropdown .icon-chevron-up {
        margin: 0 5px; }
      .-footer .footer-copyright .-language .dropup .dropdown-item,
      .-footer .footer-copyright .-language .dropup .dropdown-toggle,
      .-footer .footer-copyright .-language .dropup a,
      .-footer .footer-copyright .-language .dropdown .dropdown-item,
      .-footer .footer-copyright .-language .dropdown .dropdown-toggle,
      .-footer .footer-copyright .-language .dropdown a {
        text-decoration: none; }
  .-footer .-nav {
    display: inline-flex;
    color: #434a54;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    min-width: 100px; }
    @media screen and (min-width: 992px) {
      .-footer .-nav {
        min-width: 150px; } }
    .-footer .-nav .-title {
      pointer-events: none;
      cursor: default;
      color: #434a54;
      font-weight: 600;
      font-size: 13px;
      margin-top: 5px;
      max-width: 100px; }
    .-footer .-nav .-list {
      padding-left: 0; }
      .-footer .-nav .-list .-item {
        list-style-type: none;
        font-size: 13px;
        margin-bottom: 5px;
        max-width: 100px;
        word-break: break-word; }
        .-footer .-nav .-list .-item a {
          color: #434a54;
          font-weight: 400;
          text-decoration: none; }
          .-footer .-nav .-list .-item a:hover {
            color: #428bca; }
  .-footer .-social {
    display: flex;
    overflow: hidden;
    cursor: default; }
    .-footer .-social .-list {
      padding-left: 0;
      margin: 0 auto; }
      .-footer .-social .-list .-item {
        display: inline-block;
        list-style-type: none;
        font-size: 13px; }
        .-footer .-social .-list .-item a {
          text-decoration: none; }
    .-footer .-social .-circle .icon {
      fill: #656d78;
      width: 20px;
      height: 20px; }

/*
Price Box
*/
.price-box {
  padding: 10px;
  color: #fff;
  text-align: center;
  margin-bottom: 30px; }
  .price-box .price-box-title {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    padding: 24px 0 0;
    margin: 0; }
  .price-box .price-box-subtitle {
    color: #fff;
    font-style: italic;
    padding: 0 0 12px;
    margin: 0; }
  .price-box .price-tag {
    clear: both;
    line-height: 1;
    white-space: nowrap; }
    .price-box .price-tag .price-currency {
      font-size: 18px;
      line-height: 1.9;
      vertical-align: bottom; }
    .price-box .price-tag .price-amount {
      font-size: 60px;
      font-weight: 600; }
  .price-box img {
    display: block;
    margin: 15px auto; }
  .price-box .btn-black {
    margin: 30px auto 20px;
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.3);
    width: 90%; }
  .price-box.background-white .btn-black:hover, .price-box.background-white .btn-black:focus {
    background-color: #fff;
    box-shadow: none; }
  .price-box.background-black .btn-black:hover, .price-box.background-black .btn-black:focus {
    background-color: #000;
    box-shadow: none; }
  .price-box.background-competitor-darker .btn-black:hover, .price-box.background-competitor-darker .btn-black:focus {
    background-color: #2f5455;
    box-shadow: none; }
  .price-box.background-competitor-dark .btn-black:hover, .price-box.background-competitor-dark .btn-black:focus {
    background-color: #29494a;
    box-shadow: none; }
  .price-box.background-competitor .btn-black {
    background-color: #29494a; }
    .price-box.background-competitor .btn-black:hover, .price-box.background-competitor .btn-black:focus {
      background-color: #539597;
      border-color: #29494a;
      box-shadow: none; }
  .price-box.background-competitor-light .btn-black:hover, .price-box.background-competitor-light .btn-black:focus {
    background-color: #afe5e7;
    box-shadow: none; }
  .price-box.background-danger-smoke .btn-black:hover, .price-box.background-danger-smoke .btn-black:focus {
    background-color: #fbeded;
    box-shadow: none; }
  .price-box.background-danger-lighter .btn-black:hover, .price-box.background-danger-lighter .btn-black:focus {
    background-color: #f6d4d4;
    box-shadow: none; }
  .price-box.background-danger-light .btn-black:hover, .price-box.background-danger-light .btn-black:focus {
    background-color: #eca9a7;
    box-shadow: none; }
  .price-box.background-danger .btn-black {
    background-color: #b94743; }
    .price-box.background-danger .btn-black:hover, .price-box.background-danger .btn-black:focus {
      background-color: #d9534f;
      border-color: #b94743;
      box-shadow: none; }
  .price-box.background-danger-dark .btn-black:hover, .price-box.background-danger-dark .btn-black:focus {
    background-color: #b94743;
    box-shadow: none; }
  .price-box.background-danger-darker .btn-black:hover, .price-box.background-danger-darker .btn-black:focus {
    background-color: #a02622;
    box-shadow: none; }
  .price-box.background-error-smoke .btn-black:hover, .price-box.background-error-smoke .btn-black:focus {
    background-color: #fbeded;
    box-shadow: none; }
  .price-box.background-error-lighter .btn-black:hover, .price-box.background-error-lighter .btn-black:focus {
    background-color: #f6d4d4;
    box-shadow: none; }
  .price-box.background-error-light .btn-black:hover, .price-box.background-error-light .btn-black:focus {
    background-color: #eca9a7;
    box-shadow: none; }
  .price-box.background-error .btn-black {
    background-color: #b94743; }
    .price-box.background-error .btn-black:hover, .price-box.background-error .btn-black:focus {
      background-color: #d9534f;
      border-color: #b94743;
      box-shadow: none; }
  .price-box.background-error-dark .btn-black:hover, .price-box.background-error-dark .btn-black:focus {
    background-color: #b94743;
    box-shadow: none; }
  .price-box.background-error-darker .btn-black:hover, .price-box.background-error-darker .btn-black:focus {
    background-color: #a02622;
    box-shadow: none; }
  .price-box.background-warning-smoke .btn-black:hover, .price-box.background-warning-smoke .btn-black:focus {
    background-color: #fef5e6;
    box-shadow: none; }
  .price-box.background-warning-lighter .btn-black:hover, .price-box.background-warning-lighter .btn-black:focus {
    background-color: #fee6c8;
    box-shadow: none; }
  .price-box.background-warning-light .btn-black:hover, .price-box.background-warning-light .btn-black:focus {
    background-color: #fbce86;
    box-shadow: none; }
  .price-box.background-warning .btn-black {
    background-color: #d3860c; }
    .price-box.background-warning .btn-black:hover, .price-box.background-warning .btn-black:focus {
      background-color: #f89d0e;
      border-color: #d3860c;
      box-shadow: none; }
  .price-box.background-warning-dark .btn-black:hover, .price-box.background-warning-dark .btn-black:focus {
    background-color: #d3860c;
    box-shadow: none; }
  .price-box.background-warning-darker .btn-black:hover, .price-box.background-warning-darker .btn-black:focus {
    background-color: #9b6105;
    box-shadow: none; }
  .price-box.background-success-smoke .btn-black:hover, .price-box.background-success-smoke .btn-black:focus {
    background-color: #eef8ee;
    box-shadow: none; }
  .price-box.background-success-lighter .btn-black:hover, .price-box.background-success-lighter .btn-black:focus {
    background-color: #d7edd6;
    box-shadow: none; }
  .price-box.background-success-light .btn-black:hover, .price-box.background-success-light .btn-black:focus {
    background-color: #4cd763;
    box-shadow: none; }
  .price-box.background-success .btn-black {
    background-color: #4e9d4e; }
    .price-box.background-success .btn-black:hover, .price-box.background-success .btn-black:focus {
      background-color: #00c622;
      border-color: #4e9d4e;
      box-shadow: none; }
  .price-box.background-success-dark .btn-black:hover, .price-box.background-success-dark .btn-black:focus {
    background-color: #4e9d4e;
    box-shadow: none; }
  .price-box.background-success-darker .btn-black:hover, .price-box.background-success-darker .btn-black:focus {
    background-color: #357935;
    box-shadow: none; }
  .price-box.background-primary-smoke .btn-black:hover, .price-box.background-primary-smoke .btn-black:focus {
    background-color: #ecf3fa;
    box-shadow: none; }
  .price-box.background-primary-lighter .btn-black:hover, .price-box.background-primary-lighter .btn-black:focus {
    background-color: #cfe2f2;
    box-shadow: none; }
  .price-box.background-primary-light .btn-black:hover, .price-box.background-primary-light .btn-black:focus {
    background-color: #a0c5e4;
    box-shadow: none; }
  .price-box.background-primary .btn-black {
    background-color: #3876ac; }
    .price-box.background-primary .btn-black:hover, .price-box.background-primary .btn-black:focus {
      background-color: #428bca;
      border-color: #3876ac;
      box-shadow: none; }
  .price-box.background-primary-dark .btn-black:hover, .price-box.background-primary-dark .btn-black:focus {
    background-color: #3876ac;
    box-shadow: none; }
  .price-box.background-primary-darker .btn-black:hover, .price-box.background-primary-darker .btn-black:focus {
    background-color: #2b5c86;
    box-shadow: none; }
  .price-box.background-grey-darker .btn-black:hover, .price-box.background-grey-darker .btn-black:focus {
    background-color: #353b48;
    box-shadow: none; }
  .price-box.background-grey-dark .btn-black:hover, .price-box.background-grey-dark .btn-black:focus {
    background-color: #434a54;
    box-shadow: none; }
  .price-box.background-grey-medium .btn-black:hover, .price-box.background-grey-medium .btn-black:focus {
    background-color: #656d78;
    box-shadow: none; }
  .price-box.background-grey .btn-black {
    background-color: #434a54; }
    .price-box.background-grey .btn-black:hover, .price-box.background-grey .btn-black:focus {
      background-color: #aab2bd;
      border-color: #434a54;
      box-shadow: none; }
  .price-box.background-grey-light .btn-black:hover, .price-box.background-grey-light .btn-black:focus {
    background-color: #ccd1d9;
    box-shadow: none; }
  .price-box.background-grey-lighter .btn-black:hover, .price-box.background-grey-lighter .btn-black:focus {
    background-color: #e6e9ed;
    box-shadow: none; }
  .price-box.background-grey-smoke .btn-black:hover, .price-box.background-grey-smoke .btn-black:focus {
    background-color: #f5f7fa;
    box-shadow: none; }
  .price-box.background-turquoise-light .btn-black:hover, .price-box.background-turquoise-light .btn-black:focus {
    background-color: #9fe4d9;
    box-shadow: none; }
  .price-box.background-turquoise-lighter .btn-black:hover, .price-box.background-turquoise-lighter .btn-black:focus {
    background-color: #effaf8;
    box-shadow: none; }
  .price-box.background-turquoise .btn-black {
    background-color: #34ad99; }
    .price-box.background-turquoise .btn-black:hover, .price-box.background-turquoise .btn-black:focus {
      background-color: #4fceb9;
      border-color: #34ad99;
      box-shadow: none; }
  .price-box.background-turquoise-dark .btn-black:hover, .price-box.background-turquoise-dark .btn-black:focus {
    background-color: #34ad99;
    box-shadow: none; }
  .price-box.background-turquoise-darker .btn-black:hover, .price-box.background-turquoise-darker .btn-black:focus {
    background-color: #288f7e;
    box-shadow: none; }
  .price-box.background-emerald-light .btn-black:hover, .price-box.background-emerald-light .btn-black:focus {
    background-color: #7ee2a8;
    box-shadow: none; }
  .price-box.background-emerald-lighter .btn-black:hover, .price-box.background-emerald-lighter .btn-black:focus {
    background-color: #d1f5e0;
    box-shadow: none; }
  .price-box.background-emerald .btn-black {
    background-color: #27ae60; }
    .price-box.background-emerald .btn-black:hover, .price-box.background-emerald .btn-black:focus {
      background-color: #2ecc71;
      border-color: #27ae60;
      box-shadow: none; }
  .price-box.background-emerald-dark .btn-black:hover, .price-box.background-emerald-dark .btn-black:focus {
    background-color: #27ae60;
    box-shadow: none; }
  .price-box.background-emerald-darker .btn-black:hover, .price-box.background-emerald-darker .btn-black:focus {
    background-color: #1b7943;
    box-shadow: none; }
  .price-box.background-amethyst-light .btn-black:hover, .price-box.background-amethyst-light .btn-black:focus {
    background-color: #c4b3cc;
    box-shadow: none; }
  .price-box.background-amethyst-lighter .btn-black:hover, .price-box.background-amethyst-lighter .btn-black:focus {
    background-color: #f3f0f5;
    box-shadow: none; }
  .price-box.background-amethyst .btn-black {
    background-color: #836990; }
    .price-box.background-amethyst .btn-black:hover, .price-box.background-amethyst .btn-black:focus {
      background-color: #9476a3;
      border-color: #836990;
      box-shadow: none; }
  .price-box.background-amethyst-dark .btn-black:hover, .price-box.background-amethyst-dark .btn-black:focus {
    background-color: #836990;
    box-shadow: none; }
  .price-box.background-amethyst-darker .btn-black:hover, .price-box.background-amethyst-darker .btn-black:focus {
    background-color: #5f486b;
    box-shadow: none; }

/*
Tabs
*/
.nav-tabs {
  margin-bottom: 30px; }
  .nav-tabs > li.active a,
  .nav-tabs > li.active a:hover,
  .nav-tabs > li.active a:focus {
    background-color: #fff;
    border: 1px solid #ccd1d9;
    border-bottom-color: transparent; }

/*
Testimonials
*/
.testimonials {
  clear: both;
  padding: 50px 0; }
  @media screen and (min-width: 768px) {
    .testimonials .testimonial {
      min-height: 180px; } }
  .testimonials .testimonial-protrait {
    float: left; }
    .testimonials .testimonial-protrait img {
      border-radius: 50%;
      display: block;
      width: 100%;
      width: 70px;
      height: 70px; }
      @media screen and (min-width: 768px) {
        .testimonials .testimonial-protrait img {
          width: 80px;
          height: 80px; } }
      @media screen and (min-width: 992px) {
        .testimonials .testimonial-protrait img {
          width: 100px;
          height: 100px; } }
  .testimonials .testimonial-blockquote {
    position: relative;
    border-left: none;
    padding-top: 0;
    padding-left: 10px;
    margin-left: 80px; }
    @media screen and (min-width: 768px) {
      .testimonials .testimonial-blockquote {
        margin-left: 90px; } }
    @media screen and (min-width: 992px) {
      .testimonials .testimonial-blockquote {
        margin-left: 110px; } }
    .testimonials .testimonial-blockquote::before {
      position: absolute;
      top: -2px;
      left: 0;
      content: "\"";
      color: #3876ac;
      font-size: 15px; }
    .testimonials .testimonial-blockquote p {
      font-size: 15px;
      line-height: 1.6;
      color: #656d78; }
  .testimonials .testimonial-author {
    font-size: 13px;
    margin: 0;
    padding: 0;
    border-top: none;
    width: auto; }
    .testimonials .testimonial-author::before {
      display: none; }
    .testimonials .testimonial-author .author-name {
      display: block;
      color: #3876ac; }
    .testimonials .testimonial-author .author-worksfor {
      display: block;
      color: #aab2bd; }

/*
Tool Interactive
*/
.tooltip-interactive {
  opacity: 1;
  position: relative;
  vertical-align: top;
  width: 100%; }
  .tooltip-interactive.tooltip-with-dot .tooltip-inner {
    padding-left: 50px; }
  .tooltip-interactive.tooltip-with-dot .dot {
    position: absolute;
    top: 15px;
    left: 15px; }
  .tooltip-interactive.tooltip-white .tooltip-title {
    color: #434a54; }
  .tooltip-interactive .tooltip-inner {
    padding: 15px 20% 15px 30px;
    text-align: left;
    max-width: none; }
  .tooltip-interactive .tooltip-right-btn {
    border-radius: 0 2px 2px 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    max-width: 20%; }
    .tooltip-interactive .tooltip-right-btn::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%; }
    .tooltip-interactive .tooltip-right-btn .tooltip-info-btn {
      display: inline-block;
      vertical-align: bottom;
      font-size: 13px;
      margin: 0 -5px; }
    .tooltip-interactive .tooltip-right-btn .icon {
      fill: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%); }
    .tooltip-interactive .tooltip-right-btn.btn-default .icon {
      fill: #428bca; }
  .tooltip-interactive p:last-child {
    margin-bottom: 0;
    padding-bottom: 0; }

/* Structure manager: */
/*
Blog Post
*/
body.blog-post {
  background-color: #fff; }
  body.blog-post .featured-img {
    clear: both;
    text-align: center; }
    body.blog-post .featured-img img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 100%;
      margin: 0 auto; }
  body.blog-post .page-container main {
    padding: 30px 0;
    font-weight: 400;
    font-size: 1.2em;
    display: block; }
  body.blog-post .page-container h2 {
    font-weight: 600; }
  body.blog-post .page-container ul {
    position: relative;
    padding-left: 0; }
    body.blog-post .page-container ul li {
      list-style: none;
      display: block;
      margin: 0 0 0 30px; }
    body.blog-post .page-container ul li::before {
      position: absolute;
      color: #aab2bd;
      content: "•";
      font-size: 15px;
      left: 10px; }
    body.blog-post .page-container ul ul {
      margin: 18px 0 18px 30px; }
      body.blog-post .page-container ul ul li::before {
        content: "◦"; }
  body.blog-post .page-container img {
    display: block;
    max-width: 100%;
    margin: 30px auto; }
    body.blog-post .page-container img.image-right {
      float: right !important;
      margin: 12px 0 0 12px; }
    body.blog-post .page-container img.image-left {
      float: left !important;
      margin: 12px 12px 0 0; }
    body.blog-post .page-container img.image-full {
      position: relative;
      width: 100vw;
      min-width: 100vw;
      margin-left: -50vw;
      left: 50%; }
  body.blog-post .page-container .blog-article-author-picture img {
    margin: auto; }

/*
Blogroll
*/
body.blogroll {
  background-color: #f5f7fa; }

/*
Featured Page
*/
body.featured-page {
  background-color: #fff; }
  body.featured-page .page-container main {
    padding: 0;
    font-weight: 400;
    font-size: 1.2em;
    display: block; }
  body.featured-page .page-container blockquote {
    font-size: 1.2em; }
  body.featured-page .page-container h2 {
    font-weight: 600; }
  body.featured-page .page-container ul {
    position: relative;
    padding-left: 0; }
    body.featured-page .page-container ul li {
      list-style: none;
      display: block;
      margin: 0 0 0 30px; }
    body.featured-page .page-container ul li::before {
      position: absolute;
      color: #aab2bd;
      content: "•";
      font-size: 15px;
      left: 10px; }
    body.featured-page .page-container ul ul {
      margin: 18px 0 18px 30px; }
      body.featured-page .page-container ul ul li::before {
        content: "◦"; }
  body.featured-page .page-container img {
    display: block;
    max-width: 100%;
    margin: 0 auto 30px; }
    body.featured-page .page-container img.pull-left {
      width: auto;
      margin-right: 30px; }
    body.featured-page .page-container img.pull-right {
      width: auto;
      margin-left: 30px; }

/* Legacy responsiveness breakpoints */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:hover, a:active, a:focus,
input:hover,
input:active,
input:focus,
button:hover,
button:active,
button:focus,
textarea:hover,
textarea:active,
textarea:focus,
*:hover,
*:active,
*:focus {
  outline: 0; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic; }

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
  font-family: inherit; }

button,
input {
  *overflow: visible;
  line-height: normal; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="search"] {
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }

/* Woorank Front specific pages rules */
body.basic-pwd {
  background-color: #fff; }
  body.basic-pwd #basicpwd-form .form-control, body.basic-pwd #basicpwd-form .generation-bar input, .generation-bar body.basic-pwd #basicpwd-form input {
    display: inline-block;
    width: auto; }
  body.basic-pwd #basicpwd-form .btn-submit {
    float: none;
    left: -5px;
    padding-top: 7px;
    padding-bottom: 7px; }

body.gaprofiles .dropdown-menu {
  overflow: auto; }

body.generic-content-page {
  background-color: #fff; }
  body.generic-content-page .page-header {
    padding-bottom: 40px; }
  body.generic-content-page .row img:not(.thumbs) {
    margin-bottom: 40px; }
  body.generic-content-page .inner-page-footer {
    margin-top: 30px; }
    body.generic-content-page .inner-page-footer img {
      margin-bottom: -10px; }
  body.generic-content-page .thumbnail .h4 {
    margin-bottom: 0; }
  body.generic-content-page .free-trial-btn-container .btn-container {
    padding-bottom: 30px; }
  body.generic-content-page .hero-banner {
    margin-bottom: 30px; }
    body.generic-content-page .hero-banner h1 {
      margin: 0; }

body.home {
  background-color: #f5f7fa; }
  body.home .page-header {
    margin-top: 40px;
    padding-bottom: 40px; }
    body.home .page-header .subtitle {
      font-weight: 300; }
  @media screen and (min-width: 1200px) {
    body.home .hero-banner {
      height: 358px; } }
  body.home .testimonial:nth-child(odd) {
    clear: both; }

.testimonial-small .what {
  font-size: 26px;
  font-weight: 600;
  color: #2286e6; }

.testimonial-small .who {
  color: #353b48;
  font-size: 18px;
  font-weight: 400; }

.enterprise-rating-logo {
  display: inline;
  height: 200px; }

.container-fluid.max-width-fixed, .max-width-fixed.container-sm, .max-width-fixed.container-md, .max-width-fixed.container-nv {
  max-width: 1140px; }

body.solutions:not(.transparent),
body.enterprise:not(.transparent),
.new-design:not(.transparent) {
  background-color: #f5f7fa; }

body.solutions .container-fluid, body.solutions .container-sm, body.solutions .container-md, body.solutions .container-nv,
body.enterprise .container-fluid,
body.enterprise .container-sm,
body.enterprise .container-md,
body.enterprise .container-nv,
.new-design .container-fluid,
.new-design .container-sm,
.new-design .container-md,
.new-design .container-nv {
  max-width: 1140px; }

body.solutions h1.title,
body.enterprise h1.title,
.new-design h1.title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    body.solutions h1.title,
    body.enterprise h1.title,
    .new-design h1.title {
      font-size: 44px; } }
  @media screen and (min-width: 1200px) {
    body.solutions h1.title,
    body.enterprise h1.title,
    .new-design h1.title {
      font-size: 54px; } }

body.solutions h2.testimonial,
body.enterprise h2.testimonial,
.new-design h2.testimonial {
  font-size: 26px; }
  @media screen and (min-width: 768px) {
    body.solutions h2.testimonial,
    body.enterprise h2.testimonial,
    .new-design h2.testimonial {
      font-size: 36px; } }

body.solutions h2.title,
body.enterprise h2.title,
.new-design h2.title {
  font-size: 28px;
  font-weight: 600; }
  @media screen and (min-width: 768px) {
    body.solutions h2.title,
    body.enterprise h2.title,
    .new-design h2.title {
      font-size: 40px; } }
  @media screen and (min-width: 1200px) {
    body.solutions h2.title,
    body.enterprise h2.title,
    .new-design h2.title {
      font-size: 50px; } }
  body.solutions h2.title.large,
  body.enterprise h2.title.large,
  .new-design h2.title.large {
    font-size: 48px;
    margin-bottom: 20px; }
    @media screen and (min-width: 768px) {
      body.solutions h2.title.large,
      body.enterprise h2.title.large,
      .new-design h2.title.large {
        font-size: 50px !important; } }
    @media screen and (min-width: 992px) {
      body.solutions h2.title.large,
      body.enterprise h2.title.large,
      .new-design h2.title.large {
        font-size: 84px !important; } }

body.solutions h2.subtitle,
body.enterprise h2.subtitle,
.new-design h2.subtitle {
  font-size: 36px;
  font-weight: 600; }

body.solutions h3.title,
body.enterprise h3.title,
.new-design h3.title {
  font-size: 24px;
  font-weight: 600; }

body.solutions h3.subtitle,
body.enterprise h3.subtitle,
.new-design h3.subtitle {
  font-size: 22px;
  font-weight: 600; }

body.solutions p.title,
body.enterprise p.title,
.new-design p.title {
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 1em; }
  @media screen and (min-width: 768px) {
    body.solutions p.title,
    body.enterprise p.title,
    .new-design p.title {
      font-size: 20px; } }
  @media screen and (min-width: 768px) {
    body.solutions p.title,
    body.enterprise p.title,
    .new-design p.title {
      font-size: 22px; } }

body.solutions p.subtitle,
body.enterprise p.subtitle,
.new-design p.subtitle {
  font-size: 18px; }

.bg-cut {
  width: 100%;
  margin-bottom: -2px;
  display: none; }
  @media screen and (min-width: 768px) {
    .bg-cut {
      display: block; } }

.background {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover; }
  .background.background-primary {
    background-image: url("/assets/img/pages/enterprise/wave_wave-hero-mobile.svg"); }
    @media screen and (min-width: 768px) {
      .background.background-primary {
        background-image: url("/assets/img/pages/solutions/wave_wave-hero-11.svg"); } }
  .background.background-primary-dark {
    background-image: url("/assets/img/pages/enterprise/wave_wave-hero-enterprise-1.svg"); }
  .background.background-white-grey {
    background: #fff; }
    @media screen and (min-width: 768px) {
      .background.background-white-grey {
        background: linear-gradient(90deg, #fff 0%, #fff 50%, #e8f3fd 50%, #e8f3fd 100%); } }
  .background.background-grey-white {
    background: #fff; }
    @media screen and (min-width: 768px) {
      .background.background-grey-white {
        background: linear-gradient(270deg, #fff 0%, #fff 50%, #e8f3fd 50%, #e8f3fd 100%); } }
  .background.background-white-grey-vertical {
    background: #fff;
    background: linear-gradient(180deg, white 0%, #e7f2fc 100%); }

.background-primary-lighter-fresh {
  background-color: #e8f3fd !important; }

.section-padding {
  margin-top: 0;
  margin-bottom: 0;
  padding: 80px 0 60px;
  min-height: 300px; }
  .section-padding.no-minheight {
    min-height: auto; }
  .section-padding.overflow-x-hidden {
    overflow-x: hidden; }
  .section-padding.dense {
    padding: 80px 0 60px; }
  @media screen and (min-width: 1200px) {
    .section-padding {
      padding: 140px 0; } }

.title {
  line-height: 1.2em; }

.subtitle {
  font-size: 24px;
  font-weight: 400; }

.testimonial {
  color: #2286e6;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2em; }

.element {
  padding: 80 100 60; }

.left-wrapper {
  margin: 0 0 20px; }
  @media screen and (min-width: 768px) {
    .left-wrapper {
      margin: 0 0 30px; } }
  @media screen and (min-width: 1200px) {
    .left-wrapper {
      margin: 0 0 60px; } }

.right-wrapper {
  margin: 20px 0 0; }
  @media screen and (min-width: 768px) {
    .right-wrapper {
      margin: 40px 60px 0 0; } }
  @media screen and (min-width: 1200px) {
    .right-wrapper {
      margin: 40px 120px 0 0;
      padding: 0; } }

.shadow {
  box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1); }

.card-holder {
  display: flex;
  align-content: center;
  flex-direction: column; }
  @media screen and (min-width: 768px) {
    .card-holder {
      flex-direction: row; } }

.homepage-article-card {
  cursor: pointer;
  border: 1px solid transparent; }
  .homepage-article-card:hover, .homepage-article-card:active, .homepage-article-card:focus {
    text-decoration: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #f5f7fa; }

.card.shadow {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05); }

.card .card-text {
  padding-top: 24px;
  flex: 1 0 auto;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden; }

@media screen and (min-width: 768px) {
  .card .card-image.fixed-sibling {
    height: 200px;
    padding-top: 10px; } }

.card .btn-card {
  font-size: 18px;
  padding: 12px 20px; }

.btn-round {
  border-radius: 5px; }

.btn-bold,
.bold {
  font-weight: 600; }

.btn-float {
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .btn-float:hover, .btn-float:active, .btn-float:focus {
    transform: translateY(-8px); }

.btn-block-xs {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .btn-block-xs {
      width: auto; } }

.row-flex {
  display: block; }
  @media screen and (min-width: 768px) {
    .row-flex {
      display: flex;
      align-items: center; } }

.font-16 {
  font-size: 16px !important; }

.font-18 {
  font-size: 18px !important; }

.font-20 {
  font-size: 20px !important; }

.font-22 {
  font-size: 22px !important; }

.font-24 {
  font-size: 24px !important; }

.font-26 {
  font-size: 26px !important; }

.font-32 {
  font-size: 32px !important; }

.font-36 {
  font-size: 36px !important; }

.enterprise-logo {
  display: inline;
  max-width: 120px;
  height: 60px; }

.connect-title {
  font-size: 28px; }
  @media screen and (min-width: 768px) {
    .connect-title {
      font-size: 40px; } }

.gdpr-title {
  font-size: 28px;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .gdpr-title {
      margin-top: 0;
      font-size: 36px;
      text-align: left; } }

.gdpr-subtitle {
  font-size: 16px;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .gdpr-subtitle {
      width: 67%;
      text-align: left; } }

.gdpr-img {
  width: 280px;
  margin: 0 auto; }
  @media screen and (min-width: 768px) {
    .gdpr-img {
      margin-right: 0; } }

.support-title {
  font-size: 28px; }
  @media screen and (min-width: 768px) {
    .support-title {
      font-size: 40px; } }
  @media screen and (min-width: 992px) {
    .support-title {
      font-size: 50px; } }

.support-subtitle {
  font-size: 26px;
  line-height: 1.2em; }
  @media screen and (min-width: 768px) {
    .support-subtitle {
      font-size: 36px; } }

.stat-title {
  font-size: 49px;
  margin-top: 0;
  margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .stat-title {
      font-size: 60px; } }

.stat-divider {
  margin-top: 80px;
  margin-bottom: 80px;
  border-top: 1px solid rgba(99, 170, 237, 0.47); }

.stat-subtitle {
  font-size: 18px; }
  @media screen and (min-width: 768px) {
    .stat-subtitle {
      font-size: 49px; } }

.connect-section {
  padding-bottom: 0;
  margin-bottom: -80px; }

.connect-subtitle {
  font-weight: 400;
  font-size: 28px; }
  @media screen and (min-width: 768px) {
    .connect-subtitle {
      font-size: 50px; } }

.connect-subtitle {
  font-size: 16px;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .connect-subtitle {
      font-size: 24px; } }

.connect-img {
  padding-bottom: 0;
  margin-bottom: -80px; }
  @media screen and (min-width: 768px) {
    .connect-img {
      margin-left: auto; } }

.consultancy-li {
  text-align: left !important;
  padding: 15px 15px 15px 25px;
  border-radius: 5px;
  margin-bottom: 20px; }
  .consultancy-li .icon {
    margin-right: 10px; }
  @media screen and (min-width: 768px) {
    .consultancy-li {
      display: flex;
      align-items: center; } }

.hero-primary-btn {
  margin-bottom: 20px;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .hero-primary-btn {
      width: auto;
      margin-right: 20px;
      margin-bottom: 0; } }

.stars-img {
  margin: 0 auto;
  width: 86px;
  height: 18px; }

.hero-subtitle {
  font-size: 16px;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    .hero-subtitle {
      font-size: 20px; } }
  @media screen and (min-width: 992px) {
    .hero-subtitle {
      font-size: 24px; } }

.feature-title {
  font-weight: 600;
  font-size: 20px; }
  @media screen and (min-width: 768px) {
    .feature-title {
      font-size: 22px; } }

.feature-subtitle {
  font-size: 16px;
  font-weight: 400; }

.image-dots {
  position: absolute;
  right: -75px;
  width: 30%;
  opacity: 0.1; }

.solution-card {
  padding: 20px; }
  .solution-card .card-text {
    padding-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .solution-card {
      padding: 40px; } }

body.login {
  background-color: #ecf3fa; }
  body.login .page-header {
    display: none; }
    body.login .page-header .subtitle {
      margin-top: 10px;
      font-size: 20px; }
    @media screen and (min-width: 768px) {
      body.login .page-header {
        display: block; } }
  body.login .page-container {
    padding-right: 0;
    padding-left: 0;
    border: none;
    border-radius: none;
    overflow: hidden; }
    @media screen and (min-width: 768px) {
      body.login .page-container {
        border: 1px solid #aab2bd;
        border-radius: 6px; } }
    body.login .page-container .form-container {
      position: relative;
      background-color: #fff;
      padding-top: 30px; }
  body.login .signup-features-container {
    position: relative;
    background-color: #f5f7fa;
    border-left: 1px solid #aab2bd;
    padding: 30px 0 0 16px; }
    body.login .signup-features-container img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 60px; }

.login-form-container {
  position: relative;
  padding: 15px;
  margin: 0 auto 20px; }
  .login-form-container .error-msg {
    color: #d9534f;
    float: right;
    display: none;
    font-size: 12px;
    margin-top: 2px; }
  .login-form-container .has-error .error-msg {
    display: block; }

.login-segmentation-container {
  border: 1px solid #aab2bd;
  border-radius: 6px;
  margin: 30px auto;
  padding: 30px 60px;
  text-align: center;
  background-color: #fff; }
  .login-segmentation-container .question-status {
    font-size: 20px; }
  .login-segmentation-container .question-title {
    font-size: 16px;
    font-weight: bold;
    color: #434a54; }
  .login-segmentation-container .call-to-action {
    display: inline-block;
    width: 220px;
    height: 220px;
    margin: 15px; }

.iti {
  position: relative;
  display: inline-block; }

.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.iti__hide {
  display: none; }

.iti__v-hide {
  visibility: hidden; }

.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0; }

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px; }

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px; }

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555; }

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555; }

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px; }

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal; } }

.iti__flag-box {
  display: inline-block;
  width: 20px; }

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC; }

.iti__country {
  padding: 5px 10px;
  outline: none; }

.iti__dial-code {
  color: #999; }

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05); }

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle; }

.iti__flag-box, .iti__country-name {
  margin-right: 6px; }

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0; }

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0; }

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer; }

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05); }

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default; }

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent; }

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05); }

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px; }

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px; }

.iti--container:hover {
  cursor: pointer; }

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed; }

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%; }

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em; }

.iti__flag {
  width: 20px; }

.iti__flag.iti__be {
  width: 18px; }

.iti__flag.iti__ch {
  width: 15px; }

.iti__flag.iti__mc {
  width: 19px; }

.iti__flag.iti__ne {
  width: 18px; }

.iti__flag.iti__np {
  width: 13px; }

.iti__flag.iti__va {
  width: 15px; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px; } }

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px; }

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px; }

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px; }

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px; }

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px; }

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px; }

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px; }

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px; }

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px; }

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px; }

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px; }

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px; }

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px; }

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px; }

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px; }

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px; }

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px; }

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px; }

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px; }

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px; }

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px; }

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px; }

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px; }

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px; }

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px; }

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px; }

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px; }

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px; }

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px; }

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px; }

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px; }

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px; }

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px; }

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px; }

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px; }

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px; }

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px; }

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px; }

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px; }

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px; }

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px; }

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px; }

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px; }

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px; }

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px; }

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px; }

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px; }

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px; }

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px; }

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px; }

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px; }

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px; }

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px; }

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px; }

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px; }

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px; }

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px; }

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px; }

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px; }

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px; }

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px; }

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px; }

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px; }

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px; }

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px; }

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px; }

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px; }

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px; }

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px; }

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px; }

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px; }

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px; }

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px; }

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px; }

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px; }

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px; }

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px; }

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px; }

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px; }

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px; }

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px; }

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px; }

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px; }

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px; }

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px; }

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px; }

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px; }

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px; }

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px; }

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px; }

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px; }

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px; }

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px; }

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px; }

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px; }

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px; }

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px; }

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px; }

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px; }

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px; }

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px; }

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px; }

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px; }

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px; }

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px; }

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px; }

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px; }

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px; }

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px; }

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px; }

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px; }

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px; }

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px; }

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px; }

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px; }

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px; }

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px; }

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px; }

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px; }

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px; }

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px; }

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px; }

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px; }

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px; }

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px; }

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px; }

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px; }

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px; }

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px; }

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px; }

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px; }

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px; }

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px; }

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px; }

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px; }

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px; }

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px; }

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px; }

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px; }

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px; }

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px; }

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px; }

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px; }

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px; }

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px; }

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px; }

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px; }

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px; }

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px; }

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px; }

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px; }

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px; }

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px; }

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px; }

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px; }

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px; }

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px; }

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px; }

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px; }

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px; }

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px; }

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px; }

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px; }

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px; }

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px; }

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px; }

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px; }

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px; }

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px; }

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px; }

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px; }

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px; }

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px; }

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px; }

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px; }

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px; }

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px; }

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px; }

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px; }

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px; }

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px; }

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px; }

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px; }

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px; }

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px; }

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px; }

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px; }

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px; }

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px; }

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px; }

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px; }

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px; }

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px; }

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px; }

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px; }

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px; }

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px; }

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px; }

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px; }

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px; }

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px; }

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px; }

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px; }

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px; }

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px; }

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px; }

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px; }

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px; }

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px; }

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px; }

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px; }

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px; }

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px; }

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px; }

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px; }

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px; }

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px; }

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px; }

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px; }

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px; }

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px; }

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px; }

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px; }

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px; }

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px; }

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px; }

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px; }

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px; }

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px; }

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px; }

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px; }

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px; }

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px; }

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px; }

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px; }

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px; }

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px; }

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px; }

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px; }

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px; }

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px; }

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px; }

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px; }

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px; }

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px; }

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px; }

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px; }

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px; }

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px; }

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px; }

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px; }

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px; }

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px; }

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px; }

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px; }

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px; }

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px; }

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px; }

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../img/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../img/flags@2x.png"); } }

.iti__flag.iti__np {
  background-color: transparent; }

.iti__flag {
  background-image: url("/assets/flags/flags.png?v=2"); }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("/assets/flags/flags@2x.png?v=2"); } }

body.message, body.exterror, body.forgot-password, body.recurly-success {
  background-color: #fff; }

body.forgot-password .forgot-password-form,
body.forgot-password .reset-password-form {
  max-width: 330px;
  padding: 15px 0;
  margin: 0 auto; }

body.pricing {
  background-color: #fff; }
  body.pricing .page-header {
    margin-bottom: 30px;
    color: #353b48; }
  body.pricing .alert {
    margin-top: 30px; }
  body.pricing .honey {
    display: none; }
  body.pricing .pricing-list li::before {
    display: table-cell;
    content: "";
    background: url("/assets/img/pages/pricing/pricing-check.svg") no-repeat;
    background-size: 13px 13px;
    padding-left: 30px;
    background-position: 0 5px; }
  body.pricing .pricing-list li ul li {
    list-style-type: circle;
    display: list-item; }
    body.pricing .pricing-list li ul li::before {
      content: none;
      background: none; }

.pricing-trial-cta {
  background-color: #fef5e6;
  color: #434a54;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 5px; }
  .pricing-trial-cta .row {
    padding: 0 25px 0 0; }
  @media screen and (max-width: 767px) {
    .pricing-trial-cta {
      margin-top: 150px; } }
  @media screen and (max-width: 480px) {
    .pricing-trial-cta {
      margin-top: 100px; } }
  .pricing-trial-cta .cta-title,
  .pricing-trial-cta .cta-subtitle {
    font-size: 26px;
    line-height: 1.33; }
    .pricing-trial-cta .cta-title strong,
    .pricing-trial-cta .cta-subtitle strong {
      display: block;
      font-weight: 600; }
  .pricing-trial-cta .btn.btn-warning.btn-lg.btn-cta, .pricing-trial-cta .btn-group-lg > .btn.btn-warning.btn-cta {
    padding: 16px 5px; }
  .pricing-trial-cta p:last-child {
    margin-bottom: 0; }
  .pricing-trial-cta .cta-col {
    display: table;
    min-height: 115px;
    padding: 0;
    position: relative;
    left: 15px; }
    @media screen and (max-width: 768px) {
      .pricing-trial-cta .cta-col {
        text-align: center; } }
    .pricing-trial-cta .cta-col .cta-col-inside {
      display: table-cell;
      vertical-align: middle; }
      .pricing-trial-cta .cta-col .cta-col-inside.last {
        padding-right: 35px; }
        @media screen and (max-width: 768px) {
          .pricing-trial-cta .cta-col .cta-col-inside.last {
            padding: 0 25px; } }
  .pricing-trial-cta .illustration-container,
  .pricing-trial-cta .illustration {
    display: block; }
  .pricing-trial-cta .illustration {
    margin: 0;
    height: 115px;
    width: 115px;
    max-width: 115px;
    min-width: 115px; }
    @media screen and (max-width: 768px) {
      .pricing-trial-cta .illustration {
        margin: 0 auto; } }
    .pricing-trial-cta .illustration:hover {
      animation: ShakingBox 1s 1;
      transform-origin: 50% 50%; }

.pricing-nav-tabs .nav-item,
.pricing-nav-tabs .tab {
  display: inline-block;
  vertical-align: bottom;
  float: none; }
  .pricing-nav-tabs .nav-item.tab-warning .nav-link,
  .pricing-nav-tabs .nav-item.tab-warning .tab-link,
  .pricing-nav-tabs .tab.tab-warning .nav-link,
  .pricing-nav-tabs .tab.tab-warning .tab-link {
    line-height: 18px;
    color: #f89d0e;
    border-color: #fef5e6;
    border-bottom-color: #ccd1d9;
    background-color: #fffcf8; }
    .pricing-nav-tabs .nav-item.tab-warning .nav-link:hover, .pricing-nav-tabs .nav-item.tab-warning .nav-link:active, .pricing-nav-tabs .nav-item.tab-warning .nav-link:focus,
    .pricing-nav-tabs .nav-item.tab-warning .tab-link:hover,
    .pricing-nav-tabs .nav-item.tab-warning .tab-link:active,
    .pricing-nav-tabs .nav-item.tab-warning .tab-link:focus,
    .pricing-nav-tabs .tab.tab-warning .nav-link:hover,
    .pricing-nav-tabs .tab.tab-warning .nav-link:active,
    .pricing-nav-tabs .tab.tab-warning .nav-link:focus,
    .pricing-nav-tabs .tab.tab-warning .tab-link:hover,
    .pricing-nav-tabs .tab.tab-warning .tab-link:active,
    .pricing-nav-tabs .tab.tab-warning .tab-link:focus {
      background-color: #fef5e6; }
  .pricing-nav-tabs .nav-item.tab-warning.active .tab-link,
  .pricing-nav-tabs .tab.tab-warning.active .tab-link {
    border-color: #f89d0e;
    border-bottom-color: #fffaf3;
    background-color: #fef5e6;
    color: #f89d0e; }
  .pricing-nav-tabs .nav-item .nav-link,
  .pricing-nav-tabs .nav-item .tab-link,
  .pricing-nav-tabs .tab .nav-link,
  .pricing-nav-tabs .tab .tab-link {
    font-size: 18px;
    height: 61px;
    line-height: 43px; }

.pricing-nav-tabs.with-offer .nav-item,
.pricing-nav-tabs.with-offer .tab {
  display: inline-block; }
  @media screen and (max-width: 768px) {
    .pricing-nav-tabs.with-offer .nav-item,
    .pricing-nav-tabs.with-offer .tab {
      display: block; }
      .pricing-nav-tabs.with-offer .nav-item .nav-link,
      .pricing-nav-tabs.with-offer .nav-item .tab-link,
      .pricing-nav-tabs.with-offer .tab .nav-link,
      .pricing-nav-tabs.with-offer .tab .tab-link {
        border: 1px solid #ccd1d9; } }

.price-box {
  cursor: default;
  border-right: 0;
  padding: 20px;
  text-align: left; }
  .price-box .price-enterprise-contact {
    margin-top: 33px;
    min-height: 92px;
    font-size: 14px;
    color: #434a54; }
  .price-box .btn-select-plan,
  .price-box .highlighted {
    width: 100%; }
  .price-box .highlighted {
    background-color: white;
    color: #656d78;
    border: 1px solid #656d78;
    min-height: 50px; }
    .price-box .highlighted .icon {
      height: 18px; }
  .price-box .feature-list-all-container {
    margin-top: 20px; }
  .price-box .feature-list {
    padding-left: 0; }
    .price-box .feature-list li {
      line-height: 1.4;
      max-width: 100%;
      font-size: 14px;
      padding-bottom: 12px; }
      .price-box .feature-list li::before {
        padding-left: 20px !important;
        background-position: 0 1px !important; }
  .price-box .all-features li {
    max-width: 100%;
    font-size: 15px !important;
    padding-bottom: 10px;
    display: list-item !important;
    list-style-type: disc !important; }
    .price-box .all-features li::before {
      padding-left: 0 !important;
      background: none !important; }
  .price-box .all-features {
    margin-top: 30px;
    display: block; }
  .price-box .price-yearly-discount {
    display: block;
    font-size: 14px;
    color: #434a54;
    margin-top: 16px; }
  .price-box .feature-list-container {
    min-height: 500px; }
    .price-box .feature-list-container h3 {
      font-size: 21px; }
    .price-box .feature-list-container h3.pricing-feature-title {
      color: #353b48;
      font-weight: 700;
      text-align: left;
      margin-top: 54px;
      margin-bottom: 32px; }
  @media screen and (min-width: 768px) {
    .price-box {
      border: solid 1px #e6e9ed;
      border-top: none;
      border-bottom: none; }
      .price-box:last-child {
        border-right: none; }
      .price-box:first-child {
        border-left: none; }
      .price-box:not(:last-child) {
        border-right: 0; } }
  .price-box.price-box-hollow {
    color: #434a54;
    background-color: #fff; }
    .price-box.price-box-hollow .price-box-title {
      color: #353b48;
      font-size: 24px;
      font-weight: 700; }
    .price-box.price-box-hollow .price-box-subtitle {
      color: #434a54;
      font-size: 16px;
      margin-top: 5px;
      padding: 5px 0 0;
      min-height: 85px;
      font-style: normal; }
  .price-box .price-amount {
    color: #434a54;
    font-size: 24px !important; }
  .price-box .btn,
  .price-box .highlighted {
    line-height: 1;
    white-space: normal;
    margin: 30px auto 20px; }
  .price-box .plan-illustration {
    display: block;
    max-width: 100%;
    height: 200px;
    transition: transform 0.1s linear; }
    .price-box .plan-illustration:hover {
      transform: scale(1.1); }
  .price-box .list-group {
    color: #353b48;
    background-color: #fff;
    margin: 0 -10px -10px;
    border: 1px solid #ccd1d9;
    padding-bottom: 0; }
    .price-box .list-group strong,
    .price-box .list-group b {
      font-weight: 600;
      color: #000; }
  .price-box .no-button .list-group {
    margin-top: 107px; }
  .price-box .price {
    position: relative;
    padding: 10px 0;
    line-height: 1;
    min-height: 125px; }
    .price-box .price .amount {
      font-size: 60px;
      font-weight: 600; }
    .price-box .price .price-box-title {
      padding: 0; }
    .price-box .price .prefix-currency {
      display: block;
      min-height: 20px; }
    .price-box .price .price-timeframe {
      white-space: normal;
      display: block;
      margin-top: 5px; }
  .price-box.wide-price {
    margin-top: 30px;
    overflow: hidden; }
    .price-box.wide-price h2 {
      float: left;
      padding-left: 20px; }
    .price-box.wide-price a.no-plan {
      float: right;
      line-height: 88px;
      margin-right: 20px; }
  .price-box .bill-yearly-wrap {
    text-align: center;
    padding: 0; }
  .price-box .bill-yearly {
    display: inline-block;
    padding-top: 12px; }
    .price-box .bill-yearly input {
      margin: -2px 0 0;
      cursor: pointer; }
    .price-box .bill-yearly label {
      margin-left: 4px;
      cursor: pointer; }

.pricing-enterprise-banner {
  background: #f1f1f1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px; }
  .pricing-enterprise-banner h3 {
    font-size: 34px; }
  .pricing-enterprise-banner img {
    width: 33%;
    margin-left: 8%; }
  .pricing-enterprise-banner .pricing-enterprise-banner-content {
    width: 40%;
    font-size: 24px;
    margin-left: 15%; }
  .pricing-enterprise-banner .pricing-enterprise-banner-subtitle {
    margin-bottom: 20px; }

.feature-list {
  text-align: left; }
  @media screen and (max-width: 768px) {
    .feature-list {
      padding-left: 1em; } }
  .feature-list li {
    display: table;
    list-style-type: none;
    font-size: 12px;
    max-width: 90%;
    padding-bottom: 10px;
    line-height: 1.4; }
    @media screen and (min-width: 768px) {
      .feature-list li {
        font-size: 14px; } }
    @media screen and (min-width: 992px) {
      .feature-list li {
        font-size: 16px; } }
    .feature-list li span {
      display: table-cell; }
      .feature-list li span:last-child {
        padding-left: 10px; }

.pricing-trust-woorank {
  padding: 90px 0;
  background-color: #f5f7fa; }
  .pricing-trust-woorank .trust-woorank-title {
    text-align: center;
    padding-bottom: 30px;
    margin: 0; }
  .pricing-trust-woorank .trust-woorank-logos {
    text-align: center;
    vertical-align: middle; }
    .pricing-trust-woorank .trust-woorank-logos .trust-woorank-partners {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px; }

.pricing-faq .pricing-faq-title .icon {
  height: 16px;
  width: 16px;
  fill: #428bca;
  transform: rotate(-180deg);
  transform-origin: center center;
  transition: transform 300ms ease-in-out; }

.pricing-faq .pricing-faq-title.collapsed .icon {
  transform: rotate(0deg);
  fill: #aab2bd; }

.pricing-faq .pricing-faq-content img {
  width: 100%;
  max-width: 100%;
  height: auto; }

body.register,
body.update-billing-details {
  background-color: #ecf3fa; }
  body.register .subtitle,
  body.update-billing-details .subtitle {
    font-size: 18px;
    padding-top: 15px;
    font-weight: 800; }
  body.register .register-change-plan,
  body.update-billing-details .register-change-plan {
    font-size: 14px;
    text-decoration: underline;
    color: #656d78;
    font-weight: normal; }
  body.register .billing-total-due,
  body.update-billing-details .billing-total-due {
    font-size: 18px;
    color: #656d78; }
  body.register .due-subtitle-light,
  body.update-billing-details .due-subtitle-light {
    color: #656d78;
    padding-top: 10px; }
  body.register .due-subtitle-dark,
  body.update-billing-details .due-subtitle-dark {
    font-weight: 600; }
  body.register .payment-title,
  body.update-billing-details .payment-title {
    padding-bottom: 10px;
    font-weight: 600; }
  body.register .row-price-info,
  body.update-billing-details .row-price-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 15px;
    padding-bottom: 15px; }
  body.register .col-due-today,
  body.update-billing-details .col-due-today {
    align-self: flex-end; }
  body.register .page-content,
  body.update-billing-details .page-content {
    background-color: #fff;
    border: 1px solid #aab2bd;
    border-radius: 6px;
    overflow: hidden; }
    body.register .page-content .page-content-panel,
    body.update-billing-details .page-content .page-content-panel {
      padding: 15px; }
      body.register .page-content .page-content-panel:nth-child(2),
      body.update-billing-details .page-content .page-content-panel:nth-child(2) {
        background-color: #f5f7fa;
        border-left: 1px solid #aab2bd; }
  body.register .terms-and-privacy.has-error,
  body.update-billing-details .terms-and-privacy.has-error {
    border: 1px solid #d9534f;
    color: #b94743;
    background-color: #fbeded;
    padding: 0 20px; }
  body.register .terms-and-privacy label,
  body.update-billing-details .terms-and-privacy label {
    display: block;
    margin: 20px 0;
    color: #353b48; }
  body.register .billing-container,
  body.update-billing-details .billing-container {
    background-color: #fff;
    border-radius: 6px;
    color: #434a54; }
    body.register .billing-container hr,
    body.update-billing-details .billing-container hr {
      border-top: 1px solid #aab2bd;
      margin-left: -15px;
      margin-right: -15px; }
    body.register .billing-container .billing-header .due-title,
    body.update-billing-details .billing-container .billing-header .due-title {
      font-size: 28px;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 0; }
    body.register .billing-container .billing-header .due-title-price,
    body.update-billing-details .billing-container .billing-header .due-title-price {
      font-size: 28px;
      align-self: flex-end;
      margin-bottom: 0;
      margin-top: 0; }
      @media screen and (min-width: 768px) {
        body.register .billing-container .billing-header .due-title-price,
        body.update-billing-details .billing-container .billing-header .due-title-price {
          text-align: right; } }
    body.register .billing-container .billing-header .converted-plan-price,
    body.update-billing-details .billing-container .billing-header .converted-plan-price {
      font-weight: bold; }
    body.register .billing-container .billing-form,
    body.update-billing-details .billing-container .billing-form {
      position: relative; }
      body.register .billing-container .billing-form .form-control.invalid, body.register .billing-container .billing-form .generation-bar input.invalid, .generation-bar body.register .billing-container .billing-form input.invalid,
      body.update-billing-details .billing-container .billing-form .form-control.invalid,
      body.update-billing-details .billing-container .billing-form .generation-bar input.invalid,
      .generation-bar body.update-billing-details .billing-container .billing-form input.invalid {
        border-color: #d9534f; }
      body.register .billing-container .billing-form .error,
      body.update-billing-details .billing-container .billing-form .error {
        position: absolute;
        top: 0;
        right: 15px;
        font-size: 13px;
        padding: 5px;
        color: #d9534f;
        background-color: transparent;
        z-index: 1;
        border-radius: 6px; }
    body.register .billing-container .billing_info .btn-block,
    body.update-billing-details .billing-container .billing_info .btn-block {
      text-align: left;
      padding-left: 0; }
      body.register .billing-container .billing_info .btn-block .icon,
      body.update-billing-details .billing-container .billing_info .btn-block .icon {
        margin-right: 5px; }
      body.register .billing-container .billing_info .btn-block .close-text,
      body.update-billing-details .billing-container .billing_info .btn-block .close-text {
        display: none; }
      body.register .billing-container .billing_info .btn-block.collapsed .close-text,
      body.update-billing-details .billing-container .billing_info .btn-block.collapsed .close-text {
        display: block; }
      body.register .billing-container .billing_info .btn-block.collapsed .open-text,
      body.update-billing-details .billing-container .billing_info .btn-block.collapsed .open-text {
        display: none; }
    body.register .billing-container .billing_info .nav-tabs,
    body.update-billing-details .billing-container .billing_info .nav-tabs {
      border-bottom: none;
      margin-bottom: 0; }
    body.register .billing-container .billing_info .tab-content,
    body.update-billing-details .billing-container .billing_info .tab-content {
      background-color: #fff;
      padding: 25px;
      border: 1px solid #ccd1d9;
      overflow: visible; }
      body.register .billing-container .billing_info .tab-content p:last-child,
      body.update-billing-details .billing-container .billing_info .tab-content p:last-child {
        margin-bottom: 0; }
    body.register .billing-container .cc-field,
    body.update-billing-details .billing-container .cc-field {
      padding-top: 0; }
      body.register .billing-container .cc-field div,
      body.update-billing-details .billing-container .cc-field div {
        height: 38px; }
      body.register .billing-container .cc-field.has-error,
      body.update-billing-details .billing-container .cc-field.has-error {
        border: 1px solid #d9534f;
        color: #b94743;
        background-color: #fbeded; }
    body.register .billing-container .expires,
    body.update-billing-details .billing-container .expires {
      margin-bottom: 15px; }
      body.register .billing-container .expires .month::after,
      body.update-billing-details .billing-container .expires .month::after {
        content: "/";
        position: absolute;
        top: 0;
        right: -3%;
        font-size: 18px;
        line-height: 36px;
        color: #aab2bd; }
    body.register .billing-container .coupon .alert,
    body.update-billing-details .billing-container .coupon .alert {
      border-radius: 6px; }
    body.register .billing-container .coupon .error,
    body.update-billing-details .billing-container .coupon .error {
      padding: 10px;
      background-color: transparent; }
  body.register .waiting_for_paypal,
  body.update-billing-details .waiting_for_paypal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    height: 100%;
    width: 100%; }
    body.register .waiting_for_paypal .icon-loader,
    body.update-billing-details .waiting_for_paypal .icon-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 64px;
      width: 64px;
      margin-top: -32px;
      margin-left: -32px; }
  body.register #three-d-secure-panel,
  body.update-billing-details #three-d-secure-panel {
    width: 100%;
    height: 800px; }
    body.register #three-d-secure-panel div,
    body.update-billing-details #three-d-secure-panel div {
      height: 100%;
      display: block; }
  body.register .secure-payment,
  body.update-billing-details .secure-payment {
    position: relative;
    overflow: hidden; }
    body.register .secure-payment .title,
    body.update-billing-details .secure-payment .title {
      position: absolute;
      font-size: 15px;
      font-weight: bold;
      color: #434a54;
      margin: 20px 0 0 20px; }
    body.register .secure-payment .payment-gateways,
    body.update-billing-details .secure-payment .payment-gateways {
      width: 100%;
      height: 100%; }
    body.register .secure-payment .payment-faq,
    body.update-billing-details .secure-payment .payment-faq {
      color: #434a54; }
      body.register .secure-payment .payment-faq h3,
      body.update-billing-details .secure-payment .payment-faq h3 {
        font-size: 14px;
        font-weight: bold; }
  body.register .trial-alert,
  body.update-billing-details .trial-alert {
    margin-top: 15px; }

.seo-guides-header {
  padding: 30px 0; }
  .seo-guides-header .hero-img {
    margin: 0 auto; }
    @media screen and (min-width: 768px) {
      .seo-guides-header .hero-img {
        width: 70%; } }

.seo-guides-footer:hover .seo-guides-footer-item a img {
  opacity: 0.3; }

.seo-guides-footer .seo-guides-footer-item:hover a img {
  opacity: 1; }

.seo-guides-footer .seo-guides-footer-item a {
  display: block; }
  .seo-guides-footer .seo-guides-footer-item a img {
    display: block;
    margin: 0; }

.seo-guides-footer .seo-guides-footer-item h3 {
  display: none; }

body.subusers {
  background-color: #fff; }
  body.subusers .subuser-form {
    display: block;
    clear: both;
    padding-bottom: 30px; }
    body.subusers .subuser-form.error .form-control, body.subusers .subuser-form.error .generation-bar input, .generation-bar body.subusers .subuser-form.error input {
      border-color: #d9534f;
      background-color: #fbeded;
      color: #d9534f; }
    body.subusers .subuser-form .input-group {
      z-index: 0; }
    body.subusers .subuser-form .alert {
      margin-top: 30px;
      margin-bottom: 0; }
    body.subusers .subuser-form .submit-button .wait {
      display: none; }
    body.subusers .subuser-form.busy .submit-button .wait {
      display: block; }
    body.subusers .subuser-form.busy .submit-button .ready {
      display: none; }
  body.subusers .user-table {
    font-size: 13px; }
    body.subusers .user-table th,
    body.subusers .user-table td {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
      vertical-align: middle; }
      body.subusers .user-table th .icon,
      body.subusers .user-table td .icon {
        vertical-align: middle; }
      body.subusers .user-table th a:hover,
      body.subusers .user-table th a:focus,
      body.subusers .user-table td a:hover,
      body.subusers .user-table td a:focus {
        text-decoration: none; }
    body.subusers .user-table .table-head {
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis; }
    body.subusers .user-table .user-list-item .icon-loader {
      display: none; }
    body.subusers .user-table .user-list-item.waiting {
      color: #aab2bd; }
      body.subusers .user-table .user-list-item.waiting .icon-loader {
        display: inline-block; }
      body.subusers .user-table .user-list-item.waiting .remove-user {
        display: none; }
    body.subusers .user-table .col-number {
      width: 50px;
      text-align: center; }
      @media screen and (min-width: 768px) {
        body.subusers .user-table .col-number {
          text-align: left;
          width: 200px; } }
  body.subusers .upgrade-button {
    padding: 30px 0; }
    body.subusers .upgrade-button .btn {
      max-width: 300px;
      margin: 0 auto; }
  body.subusers .fp-small-right {
    font-size: 13px;
    padding-top: 30px; }

body.sync-popup .ga-profile-list {
  max-height: 250px;
  overflow-y: auto; }
  body.sync-popup .ga-profile-list .ga-profile-name {
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box; }
  body.sync-popup .ga-profile-list .ga-profile-id {
    text-align: right;
    box-sizing: border-box; }

.thank-you-page {
  text-align: center;
  padding: 30px 0; }

body.back-from-tp {
  background: #fff; }
  body.back-from-tp .back-from-tp-container {
    padding: 30px; }
  body.back-from-tp .alert .icon {
    margin-right: 5px; }

body.unsubscribed {
  background-color: #fff; }

body.user {
  background-color: #fff; }
  body.user .account-section {
    margin-bottom: 60px; }
  body.user .account-section-sm {
    margin-bottom: 30px; }
  body.user .account-section-xs {
    margin-bottom: 10px; }
  body.user .alert {
    margin-top: 30px; }
  body.user .form-group .feedback {
    margin-left: 15px; }
    body.user .form-group .feedback .feedback-text {
      width: 100%;
      height: 80px; }
  body.user .form-group .reason {
    border: none !important; }

body.woocms .blog-post-breadcrumbs {
  margin-top: 30px; }
  body.woocms .blog-post-breadcrumbs .breadcrumb {
    margin: 0; }

body.woocms.blog-post .addthis_inline_share_toolbox {
  padding-top: 20px; }

body.woocms.blog-post .call-to-action {
  background-color: #f5f7fa; }

body.woocms.blog-post .expert-cta {
  margin-top: 30px; }

body.woocms iframe {
  max-width: 100%;
  display: block;
  margin: 0 auto; }

body.woocms .page-header {
  padding-top: 15px;
  padding-bottom: 0; }

body.woocms .hero-banner {
  margin-bottom: 30px; }
  body.woocms .hero-banner.background-success {
    background-color: #5cb85c !important; }
  body.woocms .hero-banner .seo-image-container {
    padding: 0; }

body.woocms .summary-page-header {
  text-align: center;
  padding-bottom: 45px; }
  body.woocms .summary-page-header .category-header-title {
    font-weight: bold; }

body.woocms .summary-page-breadcrumb {
  padding: 0 15px; }
  body.woocms .summary-page-breadcrumb .breadcrumb {
    background: #fff; }

body.woocms aside div.call-to-action.cta-categories h4.call-to-action-title {
  color: #428bca;
  margin-bottom: 20px; }

body.woocms aside div.call-to-action.cta-categories ul.sidebar-categories {
  list-style: none;
  padding: 0;
  text-align: left; }
  body.woocms aside div.call-to-action.cta-categories ul.sidebar-categories li::before {
    background: #e6e9ed;
    content: '';
    display: inline-block;
    height: 6px;
    margin: 0;
    margin-right: 7px;
    vertical-align: middle;
    width: 6px; }
  body.woocms aside div.call-to-action.cta-categories ul.sidebar-categories a {
    color: #353b48;
    margin-top: 5px;
    display: inline-block;
    font-weight: 400; }

body.woocms aside div.call-to-action.woorank-boost-cta {
  padding: 0; }

body.woocms .blog-article .blog-article-link {
  color: inherit;
  text-decoration: none; }
  body.woocms .blog-article .blog-article-link:hover, body.woocms .blog-article .blog-article-link:active, body.woocms .blog-article .blog-article-link:focus {
    color: #428bca; }

body.woocms .blog-article-meta-container {
  padding-top: 20px; }

body.woocms .blog-article-author-picture .icon {
  float: left;
  height: 50px;
  margin-right: 10px;
  width: 50px; }

body.woocms .page-breadcrumbs {
  white-space: nowrap; }
  body.woocms .page-breadcrumbs ol.breadcrumb li.title-label {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75%;
    vertical-align: bottom; }

body.woocms .widget-recent-posts {
  border-top: 1px solid #ccd1d9;
  margin-top: 50px; }
  body.woocms .widget-recent-posts .recent-posts {
    display: flex; }
    @media screen and (max-width: 992px) {
      body.woocms .widget-recent-posts .recent-posts {
        flex-wrap: wrap; } }
    body.woocms .widget-recent-posts .recent-posts a {
      margin: 10px; }
      @media screen and (min-width: 992px) {
        body.woocms .widget-recent-posts .recent-posts a {
          flex-basis: 33%; } }

body.woocms.mozaic .thumbnail {
  min-height: 200px;
  max-height: 300px;
  overflow: hidden; }

body.woocms.woocms-author-page {
  background-color: #fff; }

body.woocms .author-image {
  margin-top: 10px;
  text-align: center; }
  body.woocms .author-image .icon {
    width: 70%;
    height: auto;
    margin: 20px; }

body.woocms .author-name {
  margin-top: 0;
  margin-bottom: 0; }

body.woocms .author-website,
body.woocms .author-location {
  display: inline-block;
  vertical-align: middle; }
  body.woocms .author-website .icon,
  body.woocms .author-location .icon {
    fill: #fff;
    height: 15px;
    width: 15px; }

body.woocms .author-bio {
  padding-top: 15px; }

body.woocms .author-articles-list .list-group-item {
  position: relative;
  padding-right: 60px; }

body.woocms .author-articles-list .list-group-item-content {
  display: flex;
  margin: 15px 0; }
  body.woocms .author-articles-list .list-group-item-content div.list-group-item-image {
    flex-basis: 33%;
    margin: 0 15px 0 0; }
  body.woocms .author-articles-list .list-group-item-content p.list-group-item-text {
    flex-basis: 66%; }

body.woocms .author-articles-list .icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%); }

body.woocms .expert-cta img {
  margin: 15px auto; }

.grecaptcha-logo {
  display: none; }

.grecaptcha-badge {
  width: 0 !important;
  height: 0 !important;
  box-shadow: none !important; }

.has-grecaptcha .btn-link + div {
  display: inline-block; }

/* Woorank Front specific components rules */
.checklist .checklist-header {
  display: flex;
  flex: 1 0 auto;
  align-items: center; }
  .checklist .checklist-header .checklist-header-text {
    display: inline-flex;
    width: 100%; }
  .checklist .checklist-header .progress {
    float: right; }

.checklist .checklist-item {
  cursor: pointer;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  line-height: 20px;
  padding: 0 12px;
  text-decoration: none !important;
  transition: background-color 300ms ease-out; }
  .checklist .checklist-item .checklist-item-header {
    display: inline-flex;
    padding: 12px 0; }
    .checklist .checklist-item .checklist-item-header .checklist-item-icon {
      margin-right: 6px; }
    .checklist .checklist-item .checklist-item-header .checklist-item-action {
      margin-right: auto; }
  .checklist .checklist-item .checklist-content {
    display: none; }

.checklist .checklist-item {
  background-color: #fff; }
  .checklist .checklist-item .checklist-item-text {
    color: #434a54;
    font-weight: 500; }
  .checklist .checklist-item:hover, .checklist .checklist-item:focus {
    background-color: #f5f7fa; }

.checklist .checklist-item.checked {
  pointer-events: none;
  opacity: 0.7; }
  .checklist .checklist-item.checked .checklist-item-text {
    text-decoration: line-through;
    color: grey; }

.checklist .checklist-item .icon-box-check {
  display: none; }

.checklist .checklist-item .icon-box {
  display: inline-block; }

.checklist .checklist-item.checked .icon-box-check {
  display: inline-block; }

.checklist .checklist-item.checked .icon-box {
  display: none; }

.checklist.bordered .checklist-item {
  border: 1px solid #ccd1d9; }
  .checklist.bordered .checklist-item:not(:last-child) {
    border-bottom: none; }

.accordion .nav-item {
  margin-bottom: 15px; }
  .accordion .nav-item .title {
    display: flex;
    cursor: pointer;
    padding-top: 15px;
    transition: all 0.2s linear;
    font-size: 20px; }
    .accordion .nav-item .title .icon {
      transform: rotate(0deg);
      margin-right: 8px;
      transition: all 0.2s linear; }
      @media screen and (min-width: 768px) {
        .accordion .nav-item .title .icon {
          margin-right: 25px;
          font-size: 24px; } }
    @media screen and (min-width: 768px) {
      .accordion .nav-item .title {
        font-size: 24px; } }
  .accordion .nav-item p.subtitle {
    padding-top: 15px;
    padding-left: 38px;
    display: none;
    visibility: hidden;
    opacity: 0;
    font-size: 16px; }
    @media screen and (min-width: 768px) {
      .accordion .nav-item p.subtitle {
        font-size: 18px;
        padding-left: 60px; } }
  .accordion .nav-item.active .title {
    color: #2286e6; }
    .accordion .nav-item.active .title .icon {
      transform: rotate(180deg); }
  .accordion .nav-item.active p.subtitle {
    display: block;
    visibility: visible;
    opacity: 1; }

.carousel .carousel-control-prev.outer-controls {
  left: -100px; }

.carousel .carousel-control-next.outer-controls {
  right: -100px; }

.confirm-cancel-modal .cancel-button .loader.hidden + .icon-text {
  margin: 0; }

.confirm-cancel-modal .radio .radio-text {
  display: inline-block;
  line-height: 1;
  padding-bottom: 5px; }

.expert-notification {
  background: #f5f7fa;
  border-radius: 5px;
  margin: 0 auto 12px;
  overflow: hidden;
  padding: 15px 35px 15px 15px;
  position: relative; }
  .expert-notification .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999; }
  .expert-notification .expert-notification-content {
    font-size: 13px;
    line-height: 1.5;
    margin-left: 110px; }
  .expert-notification img {
    border-radius: 100px;
    float: left; }

.expert-notification-modal {
  font-size: 13px; }
  .expert-notification-modal .close {
    opacity: 0.8;
    filter: alpha(opacity=80);
    position: absolute;
    top: 25px;
    right: 25px;
    height: 25px;
    width: 25px; }
  .expert-notification-modal .icon-test {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 55px;
    height: 55px;
    position: relative;
    left: 14px;
    top: 9px; }
  .expert-notification-modal h3 {
    margin-bottom: 27px;
    text-align: center;
    font-weight: 300; }
  .expert-notification-modal .modal-backdrop.fade.in {
    z-index: 0; }
  .expert-notification-modal .modal-body {
    max-height: 600px;
    padding: 20px 0 0; }
  .expert-notification-modal .inner {
    padding: 0 20px; }
  .expert-notification-modal .bottom {
    text-align: center;
    border-top: 1px solid #ddd;
    margin-top: 30px;
    padding: 20px; }

.featured-accordion .nav-item {
  display: flex;
  margin-bottom: 15px;
  min-height: 130px;
  cursor: pointer; }
  .featured-accordion .nav-item .title {
    color: #fff;
    display: flex;
    transition: all 0.2s linear;
    font-size: 20px; }
    @media screen and (min-width: 768px) {
      .featured-accordion .nav-item .title {
        font-size: 22px; } }
  .featured-accordion .nav-item p.subtitle {
    color: #fff;
    font-size: 16px; }
  .featured-accordion .nav-item .widget {
    display: flex;
    flex-shrink: 0;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 80px;
    height: 80px;
    background-color: #63aaed;
    color: #fff;
    border-style: solid;
    border-width: 5px;
    border-color: transparent;
    border-radius: 50%;
    font-size: 40px; }
  .featured-accordion .nav-item:not(:last-child) .widget-container::after {
    content: '';
    display: inline-block;
    height: 100%;
    position: absolute;
    border: 1px solid #f5f7fa;
    border-width: 1px !important;
    min-height: 130px;
    border-color: #fff;
    border-style: solid; }
  .featured-accordion .nav-item .widget-container {
    position: relative;
    display: block;
    float: left;
    text-align: center;
    margin-right: 42px; }
  .featured-accordion .nav-item.active .widget {
    background-color: #fff;
    border-color: #e5f0fc;
    color: #2286e6; }

.-flexible {
  display: flex; }

.-centered {
  align-items: center; }

.-right {
  justify-content: flex-end; }

.-left {
  justify-content: flex-start; }

.-wrap {
  flex-wrap: wrap; }

.-reversed {
  flex-direction: row-reverse; }

.generation-bar-in-header-container {
  padding-top: 15px;
  width: 250px; }
  @media screen and (min-width: 992px) {
    .generation-bar-in-header-container {
      width: 400px; } }
  .genbar-mobile-style .generation-bar-in-header-container {
    top: 0;
    padding: 15px; }
    .genbar-mobile-style .generation-bar-in-header-container .arrow {
      margin-right: -20px;
      right: 47px; }

body.no-scrolls .genbar-mobile-style {
  min-height: 999em; }
  body.no-scrolls .genbar-mobile-style .generation-bar-in-header-container {
    border-bottom: none;
    width: 100%; }

@media screen and (max-width: 767px) {
  .generation-bar-in-header-container {
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1060;
    height: 100%;
    visibility: visible;
    padding: 0;
    background-color: transparent;
    border-top: 1px solid #ccd1d9;
    border-bottom: 1px solid #ccd1d9; }
    .generation-bar-in-header-container .arrow {
      position: absolute;
      top: -20px;
      right: 35px;
      margin-top: -1px;
      margin-right: -3px;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ccd1d9; }
      .generation-bar-in-header-container .arrow::after {
        content: " ";
        position: absolute;
        top: -10px;
        left: -10px;
        margin-top: 1px;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff; } }

.sitepoint-form-btn-container {
  padding-top: 8px; }

.hero-banner {
  z-index: 0; }
  .hero-banner.wide-hero-banner {
    height: 358px; }
    @media screen and (min-width: 1200px) {
      .hero-banner.wide-hero-banner {
        height: 560px; } }
    .hero-banner.wide-hero-banner .hero-img {
      display: block;
      height: 100%;
      left: 50%;
      max-width: none;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: auto;
      z-index: 1; }
      @media screen and (min-width: 768px) {
        .hero-banner.wide-hero-banner .hero-img {
          height: auto;
          top: 15%;
          left: 0;
          transform: translateY(-15%);
          width: 100%; } }

.fixed-vertical {
  margin-top: 20px;
  margin-bottom: 20px; }

.hero-sitepoint-login-wrapper {
  background: #fff;
  min-height: 500px;
  box-sizing: border-box;
  margin-top: 12px; }
  .hero-sitepoint-login-wrapper .login-form-container {
    position: relative;
    margin-bottom: 0;
    min-height: 477px; }

.page-hero.page-hero-backdrop-image {
  background-image: url("/assets/img/home/homepage-plant-laptop.jpg");
  background-size: cover;
  background-position: right top; }

.page-hero .generation-bar .disabled, .page-hero .generation-bar .btn.busy, .page-hero .generation-bar .btn[busy],
.page-hero .generation-bar fieldset[busy] .btn, fieldset[busy] .page-hero .generation-bar .btn {
  opacity: 1; }

.homepage-features {
  position: relative;
  padding-bottom: 80px; }
  .homepage-features .feature-background {
    position: absolute;
    right: 0;
    max-height: 480px; }

.features-column {
  padding-left: 0;
  max-width: 320px;
  margin-top: 57px;
  text-align: left; }
  .features-column .column-content,
  .features-column .column-icon {
    display: table-cell;
    vertical-align: top; }
  .features-column .column-icon {
    position: relative;
    padding-right: 20px; }
  .features-column .column-title {
    font-size: 20px;
    font-weight: bold;
    color: #434a54; }
  .features-column .column-content {
    width: 266px;
    font-size: 16px;
    line-height: 1.5;
    color: #656d78; }

.page-prefooter {
  margin-top: 0; }

.safe-no-header {
  position: relative;
  height: 60px; }

.homepage-hero {
  color: #fff !important; }
  @media screen and (min-width: 768px) {
    .homepage-hero {
      min-height: 368px; } }
  @media screen and (min-width: 992px) {
    .homepage-hero {
      min-height: 500px; } }
  .homepage-hero .title {
    font-size: 48px !important; }
    @media screen and (min-width: 768px) {
      .homepage-hero .title {
        font-size: 50px !important; } }
    @media screen and (min-width: 992px) {
      .homepage-hero .title {
        font-size: 65px !important; } }
  .homepage-hero .subtitle {
    font-size: 16px !important; }
    @media screen and (min-width: 768px) {
      .homepage-hero .subtitle {
        font-size: 20px !important; } }
    @media screen and (min-width: 992px) {
      .homepage-hero .subtitle {
        font-size: 24px !important; } }

.-header {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
  .-header .navigation {
    display: flex;
    align-items: center;
    flex: 1 0 0; }
    .-header .navigation .navigation-actions {
      display: flex;
      align-items: center; }
    .-header .navigation .search {
      display: flex;
      align-items: center;
      flex: 1 0 0;
      padding: 0 12px; }
      .-header .navigation .search .toggle-genbar {
        margin-left: 12px;
        display: none; }
  .-header .branding {
    flex: 0 0 0;
    text-align: center; }
  .-header .user-actions {
    flex: 0 0 0;
    text-align: right; }
    @media screen and (min-width: 768px) {
      .-header .user-actions {
        flex: 1 0 0; } }

.-header {
  position: relative; }
  .-header .icon {
    fill: rgba(255, 255, 255, 0.9) !important; }
  .-header .header-logo .icon {
    height: 20px;
    width: 110px;
    margin-top: -6px;
    fill: rgba(255, 255, 255, 0.5) !important; }
  .-header .header-logo:hover .icon, .-header .header-logo:active .icon, .-header .header-logo:focus .icon {
    fill: rgba(255, 255, 255, 0.8) !important; }
  @media screen and (max-width: 767px) {
    .-header._genbar-open_ .navigation-actions,
    .-header._genbar-open_ .user-actions {
      display: none; } }
  @media screen and (max-width: 991px) {
    .-header._genbar-open_ .branding {
      display: none; } }
  @media screen and (max-width: 991px) {
    .-header._genbar-open_ .user-actions {
      flex: 0 0 0; } }
  @media screen and (max-width: 767px) {
    .-header._genbar-open_ .search {
      padding: 0; } }
  @media screen and (max-width: 767px) {
    .-header._genbar-open_ .search .toggle-genbar {
      display: inline-flex; } }
  .-header._genbar-open_ .generation-bar {
    max-width: none; }
    @media screen and (min-width: 768px) {
      .-header._genbar-open_ .generation-bar {
        max-width: 640px; } }
    .-header._genbar-open_ .generation-bar .input-group input {
      padding-left: 34px;
      background-color: rgba(255, 255, 255, 0.9) !important; }
      .-header._genbar-open_ .generation-bar .input-group input:hover {
        background-color: rgba(255, 255, 255, 0.9) !important; }

.-header .-circle:active, .-header .-circle:focus, .-header .-circle:hover {
  background-color: rgba(245, 247, 250, 0.2) !important; }
  .-header .-circle:active .icon, .-header .-circle:focus .icon, .-header .-circle:hover .icon {
    fill: rgba(255, 255, 255, 0.9) !important; }

.-header .generation-bar {
  position: relative;
  flex: 1 1 auto;
  max-width: none;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .-header .generation-bar {
      max-width: 200px;
      transition: max-width 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); } }
  .-header .generation-bar .input-group {
    display: inline-flex; }
    .-header .generation-bar .input-group input {
      font-weight: 600;
      height: 36px;
      background-color: rgba(255, 255, 255, 0.2);
      padding-left: 12px;
      border-radius: 2px !important; }
      .-header .generation-bar .input-group input:hover {
        background-color: rgba(255, 255, 255, 0.3); }
      @media screen and (min-width: 768px) {
        .-header .generation-bar .input-group input {
          transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); } }
    .-header .generation-bar .input-group .input-group-btn {
      display: none; }
  .-header .generation-bar .options-content {
    display: none; }

.-header .dropdown .dropdown-menu {
  margin-top: 10px; }

.dropdown .dropdown-menu .dropdown-header.dropdown-item {
  cursor: default;
  font-style: italic;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #656d78;
  font-size: 14px; }
  .dropdown .dropdown-menu .dropdown-header.dropdown-item:hover, .dropdown .dropdown-menu .dropdown-header.dropdown-item:focus, .dropdown .dropdown-menu .dropdown-header.dropdown-item:active {
    color: #656d78; }

.dropdown .dropdown-menu .dropdown-pdf-v2-section {
  padding: 5px 21px;
  width: 240px; }
  .dropdown .dropdown-menu .dropdown-pdf-v2-section div {
    cursor: default;
    margin: 0 7px 8px;
    font-size: 12px; }
  .dropdown .dropdown-menu .dropdown-pdf-v2-section a {
    font-size: 13px;
    white-space: normal; }

.homepage-partners {
  margin-top: 70px;
  margin-bottom: 70px; }
  .homepage-partners .partners-title {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;
    text-align: center;
    color: #434a54; }
  .homepage-partners .partner-logo {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .homepage-partners .partner-logo img {
        width: 150px;
        margin: 0 auto; } }

.feature-container::before, .feature-container::after {
  content: '';
  height: 80px;
  display: block; }

.feature-container::after {
  border-bottom: 1px solid #e6e9ed; }

.feature-container .title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #434a54;
  margin-bottom: 20px; }
  .feature-container .title .label {
    width: 61px;
    height: 27px;
    margin-right: 27px;
    color: #f89d0e; }

.feature-container .bullet {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
  color: #434a54; }
  .feature-container .bullet span {
    margin-right: 24px; }

.homepage-testimonials {
  padding-top: 50px;
  padding-bottom: 50px; }
  .homepage-testimonials .testimonial-portrait {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto; }
    .homepage-testimonials .testimonial-portrait img {
      border-radius: 50%; }
  .homepage-testimonials .testimonial-author-name {
    width: 310px;
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    color: #fff; }
  .homepage-testimonials .testimonial-author {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px; }
  .homepage-testimonials .author-name {
    display: block;
    color: #fff;
    font-weight: bold;
    line-height: 1.5;
    clear: both; }
  .homepage-testimonials .author-worksfor {
    line-height: 1.43; }
    .homepage-testimonials .author-worksfor a {
      text-decoration: none;
      color: #fff; }

.testimonial-dialog p {
  background-color: #fff;
  border-radius: 6px;
  padding: 18px 30px 22px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #434a54; }

.testimonial-dialog::after {
  content: "";
  display: block;
  height: 20px;
  margin-left: 50px;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/assets/img/home/testimonials/dialog-arrow.png"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-min-moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .testimonial-dialog::after {
      background-image: url("/assets/img/home/testimonials/dialog-arrow@2x.png"); } }

.icon-text-left {
  margin-right: 6px; }

.icon-container {
  min-width: 15px;
  text-align: center;
  display: inline-block;
  vertical-align: middle; }

.importance .icon,
.solvability .icon,
.rating-stars .icon {
  fill: #2b5c86;
  opacity: 0.5;
  font-size: 0;
  margin-left: 2px;
  margin-right: 2px; }
  .importance .icon.icon:first-child,
  .solvability .icon.icon:first-child,
  .rating-stars .icon.icon:first-child {
    margin-left: 0; }
  .importance .icon.icon:last-child,
  .solvability .icon.icon:last-child,
  .rating-stars .icon.icon:last-child {
    margin-right: 0; }

.importance.rating-0 .icon:nth-child(-n+0),
.solvability.rating-0 .icon:nth-child(-n+0),
.rating-stars.rating-0 .icon:nth-child(-n+0) {
  opacity: 1; }

.importance.rating-1 .icon:nth-child(-n+1),
.solvability.rating-1 .icon:nth-child(-n+1),
.rating-stars.rating-1 .icon:nth-child(-n+1) {
  opacity: 1; }

.importance.rating-2 .icon:nth-child(-n+2),
.solvability.rating-2 .icon:nth-child(-n+2),
.rating-stars.rating-2 .icon:nth-child(-n+2) {
  opacity: 1; }

.importance.rating-3 .icon:nth-child(-n+3),
.solvability.rating-3 .icon:nth-child(-n+3),
.rating-stars.rating-3 .icon:nth-child(-n+3) {
  opacity: 1; }

.importance.rating-4 .icon:nth-child(-n+4),
.solvability.rating-4 .icon:nth-child(-n+4),
.rating-stars.rating-4 .icon:nth-child(-n+4) {
  opacity: 1; }

.importance.rating-5 .icon:nth-child(-n+5),
.solvability.rating-5 .icon:nth-child(-n+5),
.rating-stars.rating-5 .icon:nth-child(-n+5) {
  opacity: 1; }

.importance.rating--1 .icon,
.solvability.rating--1 .icon,
.rating-stars.rating--1 .icon {
  fill: none;
  stroke: #2b5c86; }

.modal .modal-title {
  font-size: 18px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 15px;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    .modal .modal-title {
      font-size: 24px !important; } }

.modal .modal-body {
  font-size: 15px; }

.modal .close .icon-cross {
  height: 15px;
  width: 15px; }

.modal .modal-overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #fff;
  z-index: 1071; }
  .modal .modal-overlay .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -24px 0 0 -24px; }

.modal.busy .modal-overlay {
  display: block; }

/* campaign */
body.default-campaign .price-box .strike {
  text-decoration: line-through;
  opacity: 0.8; }

body.default-campaign .price-box.easter-campaign-premium {
  padding-bottom: 30px; }

body.default-campaign .price-box .price-feature-specific {
  padding-bottom: 15px; }
  body.default-campaign .price-box .price-feature-specific p {
    margin-bottom: 0; }
  body.default-campaign .price-box .price-feature-specific ul li {
    display: block;
    font-weight: 600; }
  body.default-campaign .price-box .price-feature-specific ul {
    padding-left: 0; }
  body.default-campaign .price-box .price-feature-specific li {
    padding-top: 5px; }

body.default-campaign .price-box img {
  height: 200px; }

body.default-campaign .price-box.fade {
  opacity: 0.3; }
  body.default-campaign .price-box.fade img {
    transition: filter 0.15s linear;
    filter: grayscale(0.4) hue-rotate(-156deg); }
  body.default-campaign .price-box.fade:hover, body.default-campaign .price-box.fade:focus {
    opacity: 1; }
    body.default-campaign .price-box.fade:hover img, body.default-campaign .price-box.fade:focus img {
      filter: grayscale(0); }

/* show javascript build errors in the page (browserify plugin) */
body > .errorify {
  z-index: 10000000000000000000;
  color: red;
  background-color: white;
  border: none;
  padding: 5px 10px;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
