.generation-bar-in-header-container {
  padding-top: 15px; // meanwhile the new header is launched
  width: 250px;

  @include breakpoint($screen-md) {
    width: 400px;
  }

  .genbar-mobile-style & {
    top: 0;
    padding: 15px;

    .arrow {
      margin-right: -20px;
      right: 47px;
    }
  }
}

body.no-scrolls {
  .genbar-mobile-style {
    min-height: 999em;

    .generation-bar-in-header-container {
      border-bottom: none;
      width: 100%;
    }
  }
}

// This should be removed once the logged in Legacy Header is fully removed
.generation-bar-in-header-container {
  // alow this only works 767px instead of 768px
  @include breakpoint($screen-sm - 1px, true) {
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: $zindex-navbar-fixed;
    height: 100%;
    visibility: visible;
    padding: 0;
    background-color: transparent;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    .arrow {
      position: absolute;
      top: -20px;
      right: 35px;
      margin-top: -1px;
      margin-right: -3px;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $border-color;

      &::after {
        content: " ";
        position: absolute;
        top: -10px;
        left: -10px;
        margin-top: 1px;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
      }
    }
  }
}
