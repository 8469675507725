/*
Button Circle
*/

$button-circle-size: 30px;

.btn {
  &.btn-circle {
    height: $button-circle-size;
    width: $button-circle-size;
    border-radius: ($button-circle-size / 2);
    border: 1px solid $border-color;
    text-align: center;
    vertical-align: middle;
    line-height: 0;
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $grey-medium;
    padding: 0;
    letter-spacing: -0.1em;

    .icon {
      fill: $grey-medium;
      height: 10px;
      width: 10px;
    }

    &.active,
    &:active,
    &:focus:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
