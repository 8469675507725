/*
Hero Banner
*/

.hero-banner {
  clear: both;
  overflow: hidden;
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $white;
    text-align: center;
  }

  h1,
  .h1 {
    font-size: 56px;
  }

  .container {
    padding: 60px 0;
  }

  .hero-img {
    display: block;
    height: auto;
    max-width: none;
    width: 100%;
  }

  &.hero-banner-preview {
    background-color: $primary;

    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba($primary, 0.7);
      z-index: 1;
    }

    .hero-img {
      filter: blur(3px);
    }
  }
}
