body.login {
  background-color: $primary-smoke;

  .page-header {
    .subtitle {
      margin-top: 10px;
      font-size: 20px;
    }

    display: none;

    @include breakpoint($screen-sm) {
      display: block;
    }
  }

  .page-container {
    padding-right: 0;
    padding-left: 0;
    border: none;
    border-radius: none;
    overflow: hidden;

    @include breakpoint($screen-sm) {
      border: 1px solid $grey;
      border-radius: 6px;
    }

    .form-container {
      position: relative;
      background-color: $white;
      padding-top: 30px;
    }
  }

  .signup-features-container {
    position: relative;
    background-color: $grey-smoke;
    border-left: 1px solid $grey;
    padding: 30px 0 0 16px;

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 60px;
    }
  }
}

.login-form-container {
  position: relative;
  padding: 15px;
  margin: 0 auto 20px;

  .error-msg {
    color: $error;
    float: right;
    display: none;
    font-size: 12px;
    margin-top: 2px;
  }

  .has-error .error-msg {
    display: block;
  }
}

.login-segmentation-container {
  border: 1px solid $grey;
  border-radius: 6px;
  margin: 30px auto;
  padding: 30px 60px;
  text-align: center;
  background-color: $white;

  .question-status {
    font-size: 20px;
  }

  .question-title {
    font-size: 16px;
    font-weight: bold;
    color: $grey-dark;
  }

  .call-to-action {
    display: inline-block;
    width: 220px;
    height: 220px;
    margin: 15px;
  }
}

@import 'intl-tel-input/build/css/intlTelInput.css';

.iti__flag {
  background-image: url('/assets/flags/flags.png?v=2');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url('/assets/flags/flags@2x.png?v=2');
  }
}
