// Home page

body.home {
  background-color: $bg;

  .page-header {
    margin-top: 40px;
    padding-bottom: 40px;

    .subtitle {
      font-weight: $font-weight-normal;
    }
  }

  // rule that need to be in the next version of Styleguide (component/hero-banner) >1.0.4
  .hero-banner {
    @include breakpoint($screen-lg) {
      height: 358px;
    }
  }

  .testimonial:nth-child(odd) {
    clear: both;
  }
}

.testimonial-small {
  .what {
    font-size: 26px;
    font-weight: 600;
    color: $primary-fresh;
  }

  .who {
    color: $grey-darker;
    font-size: 18px;
    font-weight: 400;
  }
}

.enterprise-rating-logo {
  display: inline;
  height: 200px;
}
