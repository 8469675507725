/*
Labels
*/

.label {
  font-size: 0.8em;
  font-weight: $font-weight-normal;
  line-height: 1.5;
  padding: 0.1em 0.4em 0.2em;
  position: relative;
  top: -0.1em;

  &.label-default {
    background-color: $grey-smoke;
    color: $grey;
  }

  &.label-primary {
    background-color: $primary-smoke;
    color: $primary-dark;
  }

  &.label-success {
    background-color: $success-smoke;
    color: $success-dark;
  }

  &.label-warning {
    background-color: $warning-smoke;
    color: $warning-dark;
  }

  &.label-danger {
    background-color: $error-smoke;
    color: $error-dark;
  }
}
