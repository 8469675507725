/*
Generation Bar
*/
$genbar-height: 50px;
$genbar-height-small: 30px;

$genbar-height-loader: 20px;
$genbar-height-loader-small: 13px;

$genbar-focus-background-color: $grey-smoke;
$genbar-busy-background-color: $grey-smoke;

$genbar-error-color: $error;

$genbar-busy-btn-color: $grey-medium;
$genbar-busy-btn-background-color: $grey-light;

$genbar-font-size: $font-size-base;
$genbar-font-size-small: $font-size-small;

$genbar-border-radius: 5px;

// Generation Bar Inner primitives
.generation-bar {
  .input-group {
    width: 100%;

    input {
      border-radius: 0 !important; // override bootstrap input-group logic
    }
  }

  input,
  button {
    height: $genbar-height;
    font-size: $genbar-font-size;
  }

  input {
    @extend .form-control;

    &:focus {
      background-color: $genbar-focus-background-color;
    }
  }

  button {
    font-weight: 600;
    border-radius: $genbar-border-radius;
    flex-shrink: 0;
    border: 1px solid transparent !important; // gracefully override removing border on "btn" class

    .addon {
      .icon {
        fill: $genbar-busy-btn-color !important;

        .icon-loader {
          width: 1em;
          height: 1em;
        }
      }
    }
  }

  .error-container {
    height: 25px;
    justify-content: flex-start;
    width: 100%;

    .error-msg {
      display: none;
      visibility: hidden;
      color: $genbar-error-color !important;
      font-size: 14px;
      padding-left: 2.3em;
      padding-top: 4px;
    }
  }
}

// Generation-bar sizes
.generation-bar {
  position: relative;
  margin: 0 auto;
  max-width: 640px;

  .addon {
    .icon-loader {
      display: none;
    }
  }

  input {
    padding-left: 2em;
  }
}

// Generation-bar states
.generation-bar {
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;

    button {
      background-color: $genbar-busy-btn-background-color !important;
    }
  }

  &.busy {
    pointer-events: none;

    input {
      opacity: 0.8 !important;
    }

    button {
      background-color: $genbar-busy-btn-color !important;
      background-color: $genbar-busy-btn-background-color !important;

      .addon {
        .icon {
          &.icon-loader {
            display: flex !important;
          }
        }
      }

      .submit-text {
        display: none;
      }
    }
  }

  &.error {
    .error-container {
      display: flex;
      visibility: visible;

      &.small {
        height: 14px !important;
      }

      .error-msg {
        display: flex;
        visibility: visible;
      }
    }

    input {
      border-color: $genbar-error-color !important;
      color: $genbar-error-color !important;
    }
  }
}

.new-design {
  .generation-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 1140px;
    flex-direction: column;
    background-color: white;
    border-radius: $genbar-border-radius;
    border-style: solid;
    border-width: 1.3px;
    border-color: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0;
    margin-bottom: 40px;
    padding: 30px 30px 4px;
    z-index: 1;

    &.small {
      padding: 10px 16px 0;

      .input-group {
        input {
          margin-left: 30px;
        }
      }

      &:hover {
        border-color: transparent !important;
      }

      .error-container {
        height: 10px !important;
      }
    }

    &:hover {
      border-style: solid;
      border-width: 1.3px;
      border-color: $primary-fresh;
    }

    .input-group {
      input {
        margin-right: 30px;
        border-left: none;
        border-right: none;
        border-top: none;
        box-shadow: none;

        &:focus {
          background-color: transparent;
          border-color: $primary-fresh;
          box-shadow: none;
        }
      }

      input,
      button {
        font-size: 18px;
      }

      button {
        padding-left: 50px;
        padding-right: 50px;

        &.disabled {
          opacity: 1 !important;
          color: $genbar-busy-btn-color !important;
          background-color: $genbar-busy-btn-background-color !important;
        }

        .icon {
          &.icon-loader {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
}
