.confirm-cancel-modal {
  .cancel-button {
    .loader.hidden + .icon-text {
      margin: 0;
    }
  }

  .radio .radio-text {
    display: inline-block;
    line-height: 1;
    padding-bottom: 5px;
  }
}
