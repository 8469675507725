// Flexbox class helpers
.-flexible {
  display: flex;
}

.-centered {
  align-items: center;
}

.-right {
  justify-content: flex-end;
}

.-left {
  justify-content: flex-start;
}

.-wrap {
  flex-wrap: wrap;
}

.-reversed {
  flex-direction: row-reverse;
}
