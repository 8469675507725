.accordion {
  .nav-item {
    margin-bottom: 15px;

    .title {
      .icon {
        transform: rotate(0deg);
        margin-right: 8px;
        transition: all 0.2s linear;

        @include breakpoint($screen-sm) {
          margin-right: 25px;
          font-size: 24px;
        }
      }

      display: flex;
      cursor: pointer;
      padding-top: 15px;
      transition: all 0.2s linear;
      font-size: 20px;

      @include breakpoint($screen-sm) {
        font-size: 24px;
      }
    }

    p.subtitle {
      padding-top: 15px;
      padding-left: 38px;
      display: none;
      visibility: hidden;
      opacity: 0;
      font-size: 16px;

      @include breakpoint($screen-sm) {
        font-size: 18px;
        padding-left: 60px;
      }
    }

    &.active {
      .title {
        color: $primary-fresh;

        .icon {
          transform: rotate(180deg);
        }
      }

      p.subtitle {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
