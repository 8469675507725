body.woocms {
  .blog-post-breadcrumbs {
    margin-top: 30px;

    .breadcrumb {
      margin: 0;
    }
  }

  &.blog-post {
    .addthis_inline_share_toolbox {
      padding-top: 20px;
    }

    .call-to-action {
      background-color: $grey-smoke;
    }

    .expert-cta {
      margin-top: 30px;
    }
  }

  iframe {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  .page-header {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .hero-banner {
    margin-bottom: 30px;

    &.background-success {
      background-color: $success !important;
    }

    .seo-image-container {
      padding: 0;
    }
  }

  .summary-page-header {
    text-align: center;
    padding-bottom: 45px;

    .category-header-title {
      font-weight: bold;
    }
  }

  .summary-page-breadcrumb {
    padding: 0 15px;

    .breadcrumb {
      background: $white;
    }
  }

  aside {
    div.call-to-action {
      &.cta-categories {
        h4.call-to-action-title {
          color: $primary;
          margin-bottom: 20px;
        }

        ul.sidebar-categories {
          list-style: none;
          padding: 0;
          text-align: left;

          li::before {
            background: $grey-lighter;
            content: '';
            display: inline-block;
            height: 6px;
            margin: 0;
            margin-right: 7px;
            vertical-align: middle;
            width: 6px;
          }

          a {
            color: $grey-darker;
            margin-top: 5px;
            display: inline-block;
            font-weight: 400;
          }
        }
      }

      &.woorank-boost-cta {
        padding: 0;
      }
    }
  }

  .blog-article {
    .blog-article-link {
      color: inherit;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: $primary;
      }
    }
  }

  .blog-article-meta-container {
    padding-top: 20px;
  }

  .blog-article-author-picture {
    .icon {
      float: left;
      height: 50px;
      margin-right: 10px;
      width: 50px;
    }
  }

  .page-breadcrumbs {
    white-space: nowrap;

    ol.breadcrumb li.title-label {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 75%;
      vertical-align: bottom;
    }
  }

  .widget-recent-posts {
    border-top: 1px solid $grey-light;
    margin-top: 50px;

    .recent-posts {
      display: flex;

      @include breakpoint($screen-md, true) {
        flex-wrap: wrap;
      }

      a {
        margin: 10px;

        @include breakpoint($screen-md) {
          flex-basis: 33%;
        }
      }
    }
  }

  &.mozaic {
    .thumbnail {
      min-height: 200px;
      max-height: 300px;
      overflow: hidden;
    }
  }

  &.woocms-author-page {
    background-color: $white;
  }

  .author-image {
    margin-top: 10px;
    text-align: center;

    .icon {
      width: 70%;
      height: auto;
      margin: 20px;
    }
  }

  .author-name {
    margin-top: 0;
    margin-bottom: 0;
  }

  .author-website,
  .author-location {
    display: inline-block;
    vertical-align: middle;

    .icon {
      fill: $white;
      height: 15px;
      width: 15px;
    }
  }

  .author-bio {
    padding-top: 15px;
  }

  .author-articles-list {
    .list-group-item {
      position: relative;
      padding-right: 60px;
    }

    .list-group-item-content {
      display: flex;
      margin: 15px 0;

      div.list-group-item-image {
        flex-basis: 33%;
        margin: 0 15px 0 0;
      }

      p.list-group-item-text {
        flex-basis: 66%;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }

  .expert-cta {
    img {
      margin: 15px auto;
    }
  }
}
