.pricing-trial-cta {
  background-color: $warning-smoke;
  color: $grey-dark;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 5px;

  .row {
    padding: 0 25px 0 0;
  }

  @include breakpoint($screen-sm - 1px, true) {
    margin-top: 150px;
  }

  @include breakpoint($screen-xs, true) {
    margin-top: 100px;
  }

  .cta-title,
  .cta-subtitle {
    font-size: 26px;
    line-height: 1.33;

    strong {
      display: block;
      font-weight: $font-weight-bold;
    }
  }

  .btn.btn-warning.btn-lg.btn-cta {
    padding: 16px 5px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .cta-col {
    @include breakpoint($screen-sm, true) {
      text-align: center;
    }

    display: table;
    min-height: 115px;
    padding: 0;
    position: relative;
    left: 15px;

    .cta-col-inside {
      display: table-cell;
      vertical-align: middle;

      &.last {
        padding-right: 35px;

        @include breakpoint($screen-sm, true) {
          padding: 0 25px;
        }
      }
    }
  }

  .illustration-container,
  .illustration {
    display: block;
  }

  .illustration {
    margin: 0;
    height: 115px;
    width: 115px;
    max-width: 115px;
    min-width: 115px;

    @include breakpoint($screen-sm, true) {
      margin: 0 auto;
    }

    &:hover {
      animation: ShakingBox 1s 1;
      transform-origin: 50% 50%;
    }
  }
}
