body.basic-pwd {
  background-color: $white;

  #basicpwd-form {
    .form-control {
      display: inline-block;
      width: auto;
    }

    .btn-submit {
      float: none;
      left: -5px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}
