/*
Navbar
*/
@mixin expanded-full-dropdown {
  .dropdown {
    .dropdown-menu {
      .dropdown-menu-column {
        flex-direction: column;
        flex-basis: 250px;
        margin: 0 10px;
      }

      // fullscreen dropdown menu styling
      &.dropdown-menu-full-container {
        position: fixed;
        min-height: 0;
        top: 54px;
        left: 0;
        width: 100%;
        width: 100vw;
        border-radius: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding: 30px 0 80px;
      }

      .dropdown-menu-full {
        display: flex;
        margin: 0 auto;
        justify-content: space-around;
        max-width: 846px;

        @include breakpoint($screen-lg) {
          max-width: 950px;
        }

        @include breakpoint($screen-xl) {
          max-width: 1140px;
        }

        .dropdown-menu-column {
          .dropdown-divider {
            display: none;
          }
        }
      }//
    }
  }
}

// extended on navbar-collapse depending on navbar-expand-*
@mixin collapsed-menu {
  @include box-shadow(inset 0 -1px 1px $black);

  background-color: $white;
  backface-visibility: hidden;
  bottom: 0;
  padding: 20px;
  position: fixed;
  left: 0;
  top: $navbar-height - 1px;
  visibility: hidden;
  width: 100%;
  min-width: $mobile-nav-size;
  z-index: $zindex-navbar-fixed;

  &.in,
  &.show,
  &.collapsing {
    visibility: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar-menu {
    overflow-y: auto;
    overflow-x: hidden;
    flex-wrap: nowrap;
    flex-grow: 1;
  }

  .navbar-action {
    border-top: 1px solid $grey-smoke;
  }

  .navbar-nav {
    margin: 0;
    width: 100%;

    .nav-item {
      margin-left: 0;

      .nav-link {
        padding: 15px;

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
    }

    // Override transparent background and icon chevron rotation
    .dropdown {
      .dropdown-item {
        background-color: $white;
        padding: 8px 15px;
      }

      .nav-link {
        display: flex;
        align-items: center;

        &:hover,
        &:focus {
          background-color: $grey-smoke;
        }

        .icon {
          margin-left: auto;

          &.icon-chevron-up,
          &.icon-chevron-down {
            transition: all 0.1s linear;
            transform: rotate(0deg);
          }
        }
      }

      &.show {
        .nav-link {
          background-color: $grey-smoke;

          .icon {
            &.icon-chevron-up,
            &.icon-chevron-down {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }//

    // Override opening transition behaviour when not collapsed nav-bar
    .dropdown-menu {
      padding: 0;
      display: none;
      padding-top: 10px;
      padding-bottom: 10px;

      .dropdown-menu-full {
        flex-direction: column;

        .dropdown-menu-column {
          &:not(:first-child) {
            padding-top: 15px;
          }

          &:not(:last-child) {
            padding-bottom: 15px;
          }
        }

        .dropdown-divider {
          margin: 0 10px;
        }
      }

      &.show {
        display: block;
        margin-bottom: 10px;
      }
    }//
  }
}//

// Override 1050px max-width on Containers
.navbar {
  .container {
    @include breakpoint($screen-md) {
      max-width: 1050px;
    }

    @include breakpoint($screen-md) {
      max-width: 1100px;
    }

    @include breakpoint($screen-lg) {
      max-width: 1200px;
    }

    @include breakpoint($screen-xl) {
      max-width: 1600px;
    }
  }
}//

body.navbar-open {
  overflow: hidden;
}

.navbar {
  background-color: $white;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
  max-height: $navbar-height;
  height: $navbar-height;
  transition: all 0.5s linear;

  &.fixed-top {
    z-index: $zindex-navbar-fixed;
  }

  .navbar-nav {
    .nav-item {
      margin-left: 15px;

      .nav-link {
        transition: all 0.2s ease-in-out;
        color: $grey-darker;
        font-size: $font-size-root;
        font-weight: $font-weight-normal;
        border-bottom: 2px solid transparent;

        &:hover {
          border-color: $primary-fresh;
        }
      }
    }

    &.navbar-action {
      margin-left: auto;

      // Override collapsed behaviour until smaller screens
      @include breakpoint($screen-sm) {
        flex-flow: row nowrap;

        .btn {
          flex-grow: 1;
          flex-shrink: 0;
        }
      }//
    }
  }

  &.navbar-expand-sm {
    .navbar-nav {
      .nav-link {
        @include breakpoint($screen-sm) {
          padding: 5px 10px;
        }
      }
    }

    .navbar-collapse {
      // Desktop view of collapsed full menu
      @include breakpoint($screen-sm) {
        @include expanded-full-dropdown;
      }//

      @include breakpoint($screen-sm - 1, $reverse: true) {
        @include collapsed-menu;
      }
    }
  }

  &.navbar-expand-md {
    .navbar-nav {
      .nav-link {
        @include breakpoint($screen-md) {
          padding: 5px 10px;
        }
      }
    }

    .navbar-collapse {
      // Desktop view of collapsed full menu
      @include breakpoint($screen-md) {
        @include expanded-full-dropdown;
      }//

      @include breakpoint($screen-md - 1, $reverse: true) {
        @include collapsed-menu;
      }
    }
  }

  &.navbar-expand-nv {
    .navbar-nav {
      .nav-link {
        @include breakpoint($screen-nv) {
          padding: 10px;
        }
      }
    }

    .navbar-collapse {
      // Desktop view of collapsed full menu
      @include breakpoint($screen-nv) {
        @include expanded-full-dropdown;
      }//

      @include breakpoint($screen-nv - 1, $reverse: true) {
        @include collapsed-menu;
      }
    }
  }
}

.navbar-brand {
  height: 38px;
  line-height: 20px;
  max-width: 125px;

  .icon-logo {
    height: 24px;
    width: 126px;
  }
}

.contact-support {
  .icon-loader {
    display: none;
  }

  &.busy {
    .icon:not(.icon-loader) {
      display: none;
    }

    .icon-loader {
      display: inline-block !important;
    }
  }
}
