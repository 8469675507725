/*
Carousel
*/

.carousel {
  .carousel-indicators {
    bottom: 10px;

    li {
      border-color: $grey;
      height: 12px;
      margin: 0 10px;
      width: 12px;

      &.active {
        background-color: $primary;
        border-color: $primary;
        height: 12px;
        margin: 0 10px;
        width: 12px;
      }
    }
  }

  .carousel-control {
    background-color: $grey-dark;
    background-color: rgba($grey-dark, 0.8);
    background-image: none;
    min-width: 50px;
    opacity: 1;
    width: 5%;

    &:hover,
    &:focus,
    &:active {
      background-color: $white;
      background-color: rgba($white, 0.8);

      .icon {
        fill: $primary;
      }
    }

    .icon {
      fill: $white;
      height: 20px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 20px;
    }
  }
}
