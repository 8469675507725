/*
List Group
*/

.list-group {
  .btn-block + .btn-block {
    margin-top: 0;
  }

  .list-group-item {
    color: $list-group-link-color;

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: $list-group-hover-bg;
      color: $list-group-link-hover-color;
    }
  }
}
