// Subusers pages

body.subusers {
  background-color: $white;

  .subuser-form {
    display: block;
    clear: both;
    padding-bottom: 30px;

    &.error {
      .form-control {
        border-color: $error;
        background-color: $error-smoke;
        color: $error;
      }
    }

    .input-group {
      z-index: 0;
    }

    .alert {
      margin-top: 30px;
      margin-bottom: 0;
    }

    .submit-button {
      .wait {
        display: none;
      }
    }

    &.busy {
      .submit-button {
        .wait {
          display: block;
        }

        .ready {
          display: none;
        }
      }
    }
  }

  .user-table {
    font-size: $font-size-small;

    th,
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
      vertical-align: middle;

      .icon {
        vertical-align: middle;
      }

      a:hover,
      a:focus {
        text-decoration: none;
      }
    }

    .table-head {
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .user-list-item {
      .icon-loader {
        display: none;
      }

      &.waiting {
        color: $grey;

        .icon-loader {
          display: inline-block;
        }

        .remove-user {
          display: none;
        }
      }
    }

    .col-number {
      width: 50px;
      text-align: center;

      @include breakpoint($screen-sm) {
        text-align: left;
        width: 200px;
      }
    }
  }

  .upgrade-button {
    padding: 30px 0;

    .btn {
      max-width: 300px;
      margin: 0 auto;
    }
  }

  .fp-small-right {
    font-size: $font-size-small;
    padding-top: 30px;
  }
}
