/* Icons manager: */

$iconsSizes:
  ('xxs', 10),
  ('xs', 13),
  ('sm', 16),
  ('md', 20),
  ('lg', 32),
  ('2x', 48),
  ('3x', 64),
  ('4x', 128);

.icon {
  background-image: none;
  background-size: contain;
  display: inline-block;
  height: 24px;
  vertical-align: middle;
  width: 24px;

  @each $name, $size in $iconsSizes {
    &.icon-#{$name} {
      height: ($size + px);
      width: ($size + px);
    }
  }

  &.icon-hidden {
    display: none;
  }
}

.icon-text {
  margin-left: 6px;
  vertical-align: middle;
}
