/*
Input Groups
*/

.input-group {
  .input-group-addon {
    padding-top: 0;
    padding-bottom: 0;

    .icon {
      max-height: 18px;
    }
  }

  &.input-group-sm {
    .input-group-addon {
      padding-top: 0;
      padding-bottom: 0;

      .icon {
        margin-top: -5px;
        transform: translateY(2px);
        max-height: 16px;
      }
    }
  }

  .btn {
    .icon-text,
    .icon {
      line-height: 0;
      vertical-align: initial;
    }
  }

  .input-group-addon {
    @each $color, $colorValue in $colors {
      &.#{$color} {
        border-color: $colorValue;
        background-color: $colorValue;
      }
    }
  }
}
