.seo-guides-header {
  padding: 30px 0;

  .hero-img {
    margin: 0 auto;

    @include breakpoint($screen-sm) {
      width: 70%;
    }
  }
}

.seo-guides-footer {
  &:hover {
    .seo-guides-footer-item a img {
      opacity: 0.3;
    }
  }

  .seo-guides-footer-item {
    &:hover a img {
      opacity: 1;
    }

    a {
      display: block;

      img {
        display: block;
        margin: 0;
      }
    }

    h3 {
      display: none;
    }
  }
}
