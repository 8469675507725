.grecaptcha-logo {
  display: none;
}

.grecaptcha-badge {
  // override inline styles of the Google Recaptcha Badge
  width: 0 !important;
  height: 0 !important;
  box-shadow: none !important;
}

.has-grecaptcha {
  .btn-link {
    & + div {
      display: inline-block;
    }
  }
}
