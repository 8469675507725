/*
Breadcrumb
*/

.breadcrumb {
  font-size: 13px;

  li + li::before {
    content: "/";
    color: $grey;
  }
}
