/*
Blog Article
*/

.blog-article {
  background-color: $white;
  margin-bottom: 30px;
  padding: 0 30px 30px;
  border-radius: $border-radius-base;
  overflow: hidden;

  .blog-article-img {
    margin-left: -30px;
    margin-right: -30px;
  }
}
