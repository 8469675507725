// Remove caret from dropdown-menu
$enable-caret: false;

// Override Bootstrap breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  nv: 1100px, // custom for navbar
  lg: 1200px,
  xl: 1600px
);

$container-max-widths: (
  sm: 768px,
  md: 992px,
  nv: 1050px // lg and xl will be 1050px as well for compliance
);

// Override Bootstrap Colors
$blue:    #2286e6;
$indigo:  #428bca;
$purple:  #836990;
$pink:    #eca9a7;
$red:     #d9534f;
$orange:  #d3860c;
$yellow:  #f89d0e;
$green:   #4cd763;
$teal:    #4fceb9;
$cyan:    #539597;

@import "bootstrap/scss/bootstrap";
