/*
Price Box
*/

.price-box {
  padding: 10px;
  color: $white;
  text-align: center;
  margin-bottom: 30px;

  .price-box-title {
    color: $white;
    font-size: 32px;
    font-weight: $font-weight-bold;
    padding: 24px 0 0;
    margin: 0;
  }

  .price-box-subtitle {
    color: $white;
    font-style: italic;
    padding: 0 0 12px;
    margin: 0;
  }

  .price-tag {
    clear: both;
    line-height: 1;
    white-space: nowrap;

    .price-currency {
      font-size: $font-size-large;
      line-height: 1.9;
      vertical-align: bottom;
    }

    .price-amount {
      font-size: 60px;
      font-weight: $font-weight-bold;
    }
  }

  img {
    display: block;
    margin: 15px auto;
  }

  .btn-black {
    margin: 30px auto 20px;
    box-shadow: 0 3px 0 rgba($black, 0.3);
    width: 90%;
  }

  @each $color, $colorValue in $colors {
    &.background-#{$color} {
      .btn-black {
        background-color: get-variant-color($color, "dark");

        &:hover,
        &:focus {
          background-color: $colorValue;
          border-color: get-variant-color($color, "dark");
          box-shadow: none;
        }
      }
    }
  }
}
