.pricing-nav-tabs {
  .nav-item,
  .tab {
    display: inline-block;
    vertical-align: bottom;
    float: none;

    &.tab-warning {
      .nav-link,
      .tab-link {
        line-height: 18px;
        color: $warning;
        border-color: $warning-smoke;
        border-bottom-color: $border-color;
        background-color: mix($white, $warning-smoke, 70%);

        &:hover,
        &:active,
        &:focus {
          background-color: $warning-smoke;
        }
      }

      &.active .tab-link {
        border-color: $warning;
        border-bottom-color: mix($white, $warning-smoke, 50%);
        background-color: $warning-smoke;
        color: $warning;
      }
    }

    .nav-link,
    .tab-link {
      font-size: 18px;
      height: 61px;
      line-height: (61px - 18px);
    }
  }
}

.pricing-nav-tabs.with-offer {
  .nav-item,
  .tab {
    display: inline-block;

    @include breakpoint($screen-sm, true) {
      display: block;

      .nav-link,
      .tab-link {
        border: 1px solid $border-color;
      }
    }
  }
}
