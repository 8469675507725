/*
Dot
*/

$xs-dot-size: 5px;
$small-dot-size: 10px;
$default-dot-size: $font-size-small; // 13px
$medium-dot-size: $font-size-base;  // 15px
$large-dot-size: $font-size-large; // 18px
$xxl-dot-size: 30px;

.dot {
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $border-color;
  color: $white;
  display: inline-block;
  font-weight: $font-weight-bold;
  height: $default-dot-size;
  letter-spacing: -1px;
  text-align: center;
  vertical-align: middle;
  width: $default-dot-size;

  &.dot-extra-small,
  &.dot-xs {
    height: $xs-dot-size;
    width: $xs-dot-size;
  }

  &.dot-small,
  &.dot-sm {
    height: $small-dot-size;
    line-height: $small-dot-size;
    width: $small-dot-size;
  }

  &.dot-medium,
  &.dot-md {
    height: $medium-dot-size;
    line-height: $medium-dot-size;
    width: $medium-dot-size;
  }

  &.dot-large,
  &.dot-lg {
    height: $large-dot-size;
    line-height: $large-dot-size;
    width: $large-dot-size;
  }

  &.dot-xxl {
    font-size: $font-size-large;
    height: $xxl-dot-size;
    line-height: ($xxl-dot-size - 2);
    width: $xxl-dot-size;
  }

  @each $color, $colorValue in $colors {
    &.dot-#{$color} {
      background-color: $colorValue;
      border-color: $colorValue;

      &.active {
        background-color: $white;
        color: $colorValue;
        box-shadow: inset 0 0 5px rgba($colorValue, 0.3);
      }
    }
  }

  @each $color, $colorValue in $colors {
    &.dot-border-#{$color} {
      border-color: $colorValue;
    }
  }
}
