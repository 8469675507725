/* Percentile modifiers */
$lighter  : 40%;
$light    : 20%;
$dark     : 10%;
$darker   : 20%;

/* Colors values */
$black            : #000;
$white            : #fff;

$grey             : #aab2bd;
$grey-smoke       : #f5f7fa;
$grey-lighter     : #e6e9ed;
$grey-light       : #ccd1d9;
$grey-medium      : #656d78;
$grey-dark        : #434a54;
$grey-darker      : #353b48;

$primary          : #428bca;
$primary-smoke    : #ecf3fa;
$primary-lighter  : #cfe2f2;
$primary-light    : #a0c5e4;
$primary-dark     : #3876ac;
$primary-darker   : #2b5c86;

$success          : #5cb85c;
$success-smoke    : #eef8ee;
$success-lighter  : #d7edd6;
$success-light    : #addbad;
$success-dark     : #4e9d4e;
$success-darker   : darken($success, $darker);

$warning          : #f89d0e;
$warning-smoke    : #fef5e6;
$warning-lighter  : #fee6c8;
$warning-light    : #fbce86;
$warning-dark     : #d3860c;
$warning-darker   : darken($warning, $darker);

$error            : #d9534f;
$error-smoke      : #fbeded;
$error-lighter    : #f6d4d4;
$error-light      : #eca9a7;
$error-dark       : #b94743;
$error-darker     : darken($error, $darker);

$danger           : $error;
$danger-smoke     : $error-smoke;
$danger-lighter   : $error-lighter;
$danger-light     : $error-light;
$danger-dark      : $error-dark;
$danger-darker    : darken($danger, $darker);

$competitor       : #539597;
$competitor-light : #afe5e7;
$competitor-dark  : #29494a;
$competitor-darker: darken($competitor, $darker);

/* Flat Colors */
$turquoise        : #4fceb9;
$turquoise-light  : lighten($turquoise, $light);
$turquoise-lighter: lighten($turquoise, $lighter);
$turquoise-dark   : #34ad99;
$turquoise-darker : darken($turquoise, $darker);

$emerald          : #2ecc71;
$emerald-light    : lighten($emerald, $light);
$emerald-lighter  : lighten($emerald, $lighter);
$emerald-dark     : #27ae60;
$emerald-darker   : darken($emerald, $darker);

$amethyst         : #9476a3;
$amethyst-light   : lighten($amethyst, $light);
$amethyst-lighter : lighten($amethyst, $lighter);
$amethyst-dark    : #836990;
$amethyst-darker  : darken($amethyst, $darker);

/* Colors Links */
$active-bg         : $primary-smoke;
$active-border     : $primary-lighter;
$active-color      : $primary-dark;
$bg                : $grey-smoke;
$border-color      : $grey-light;
$border-color-light: $grey-lighter;
$disabled-color    : $grey-light;
$hover-bg          : $grey-smoke;
$hover-border      : $grey-lighter;
$hover-color       : $grey-medium;
$link-color        : $primary;
$link-color-hover  : $primary-dark;
$text-color        : $grey-medium;
$text-color-light  : $grey;
$title-color       : $grey-darker;

$primary-fresh: #2286e6;
$primary-light-fresh: #258df2;
$primary-lighter-fresh: #e8f3fd;
$success-fresh: #00c622;
$success-light-fresh: #4cd763;
$warning-fresh: #ffa700;
$warning-light-fresh: #ffdc99;

/* Colors list */
$colors:
  ('white', $white),
  ('black', $black),
  ('competitor-darker', $competitor-darker),
  ('competitor-dark', $competitor-dark),
  ('competitor', $competitor),
  ('competitor-light', $competitor-light),
  ('danger-smoke', $danger-smoke),
  ('danger-lighter', $danger-lighter),
  ('danger-light', $danger-light),
  ('danger', $danger),
  ('danger-dark', $danger-dark),
  ('danger-darker', $danger-darker),
  ('error-smoke', $error-smoke),
  ('error-lighter', $error-lighter),
  ('error-light', $error-light),
  ('error', $error),
  ('error-dark', $error-dark),
  ('error-darker', $error-darker),
  ('warning-smoke', $warning-smoke),
  ('warning-lighter', $warning-lighter),
  ('warning-light', $warning-light),
  ('warning', $warning),
  ('warning-dark', $warning-dark),
  ('warning-darker', $warning-darker),
  ('success-smoke', $success-smoke),
  ('success-lighter', $success-lighter),
  ('success-light', $success-light-fresh),
  ('success', $success-fresh),
  ('success-dark', $success-dark),
  ('success-darker', $success-darker),
  ('primary-smoke', $primary-smoke),
  ('primary-lighter', $primary-lighter),
  ('primary-light', $primary-light),
  ('primary', $primary),
  ('primary-dark', $primary-dark),
  ('primary-darker', $primary-darker),
  ('grey-darker', $grey-darker),
  ('grey-dark', $grey-dark),
  ('grey-medium', $grey-medium),
  ('grey', $grey),
  ('grey-light', $grey-light),
  ('grey-lighter', $grey-lighter),
  ('grey-smoke', $grey-smoke),
  ('turquoise-light', $turquoise-light),
  ('turquoise-lighter', $turquoise-lighter),
  ('turquoise',$turquoise),
  ('turquoise-dark',$turquoise-dark),
  ('turquoise-darker',$turquoise-darker),
  ('emerald-light', $emerald-light),
  ('emerald-lighter', $emerald-lighter),
  ('emerald',$emerald),
  ('emerald-dark',$emerald-dark),
  ('emerald-darker',$emerald-darker),
  ('amethyst-light', $amethyst-light),
  ('amethyst-lighter', $amethyst-lighter),
  ('amethyst',$amethyst),
  ('amethyst-dark',$amethyst-dark),
  ('amethyst-darker',$amethyst-darker);

$basicColors:
  ('primary', $primary),
  ('success', $success),
  ('warning', $warning),
  ('error', $error),
  ('turquoise', $turquoise),
  ('emerald', $emerald),
  ('amethyst', $amethyst);

$FreshColors:
  ('primary', $primary-fresh),
  ('grey', $grey-dark),
  ('primary-light', $primary-lighter-fresh),
  ('warning', $warning-fresh),
  ('success', $success-fresh);

@each $color, $colorValue in $colors {
  .color-#{$color} {
    color: $colorValue;
  }

  .fill-#{$color} {
    fill: $colorValue;
  }

  .background-#{$color} {
    background-color: $colorValue;
  }

  .banner-#{$color} {
    background-color: $colorValue;
  }

  // Respect button contrast
  .btn-#{$color} {
    @if (str-index($color, 'white') or str-index($color, 'light') or str-index($color, 'lighter') or str-index($color, 'smoke') ) {
      color: $grey-darker !important;
      background-color: $colorValue !important;

      &:hover,
      &:active,
      &:focus {
        color: $grey-darker !important;
        background-color: darken($colorValue, 5%) !important;
      }

      .icon {
        fill: $grey-darker !important;
      }
    }

    @else {
      color: $white !important;
      background-color: $colorValue !important;

      &:hover,
      &:active,
      &:focus {
        color: $white !important;
        background-color: darken($colorValue, 5%) !important;
      }

      .icon {
        fill: $white !important;
      }
    }
  }

  // forced colors
  .color-#{$color}-important {
    color: $colorValue !important;
  }

  .fill-#{$color}-important {
    fill: $colorValue !important;
  }

  .background-#{$color}-important {
    background-color: $colorValue !important;
  }

  .btn-#{$color}-important {
    color: $white;
    background-color: $colorValue;
  }
}

@each $color, $colorValue in $FreshColors {
  .color-#{$color}-fresh {
    color: $colorValue !important;
  }

  .background-#{$color}-fresh {
    background-color: $colorValue;
  }

  .btn-contained-#{$color} {
    color: $colorValue;
    background-color: white;
    box-shadow: 0 5px 10px 0 rgba(24, 94, 161, 0.44);
    border-radius: 5px;
    font-size: 18px;
    padding: 10px 50px;

    &:hover,
    &:active,
    &:focus {
      color: $colorValue;
    }
  }

  .btn-outline-#{$color} {
    color: $colorValue;
    background-color: white;
    border-color: $colorValue;
    border-radius: 5px;
    border-width: 2px;
    font-size: 18px;
    padding: 10px 50px;

    &:hover,
    &:active,
    &:focus {
      color: $colorValue;
      background-color: rgba($colorValue, 0.1);
    }
  }

  .btn-inverted-#{$color} {
    color: $white;
    background-color: transparent;
    border-color: $white;
    border-radius: 5px;
    border-width: 2px;
    font-size: 18px;
    padding: 8px 50px;

    &:hover,
    &:active,
    &:focus {
      color: $colorValue;
      background-color: $white;
    }
  }
}
