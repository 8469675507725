/*
Alert
*/

.alert {
  position: relative;

  @each $color, $colorValue in $colors {
    &.alert-#{$color} {
      background-color: get-variant-color($color, "smoke");
      border-color: get-variant-color($color, "dark");
      color: get-variant-color($color, "dark");

      .icon {
        fill: get-variant-color($color, "dark");
      }
    }
  }

  &:not([class*="alert-"]) {
    background-color: $grey-smoke;
    border-color: $border-color;

    .icon {
      fill: $grey-darker;
    }
  }

  .alert-link {
    color: $black;
    font-weight: $font-weight-normal;
  }

  .close {
    line-height: 0;
    opacity: 1;

    .icon {
      height: 10px;
      width: 10px;
    }
  }
}
