.pricing-trust-woorank {
  padding: 90px 0;
  background-color: $grey-smoke;

  .trust-woorank-title {
    text-align: center;
    padding-bottom: 30px;
    margin: 0;
  }

  .trust-woorank-logos {
    text-align: center;
    vertical-align: middle;

    .trust-woorank-partners {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;
    }
  }
}
